.rate {
  display: block;
  vertical-align: middle;
  color: orange;
  height: 24px;
  width: 70px;
  overflow: hidden;
}

.rating-stars-border {
  position: absolute;
}
.rating-stars-fill {
  position: absolute;
}
.rating-stars-fill > i {
  color: orange;
}
.rating-stars-fill > .partial {
  color:transparent;
}

.rating-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.review-comment{
  color: #fc0407;
  font-size: 14px;
}

.comment{
  max-width: 95%;
  overflow-wrap : break-word;
}
