/* People List Panel */
#people-list-heading {
  font-size: 20px;
}
.people-list-person {
  display: inline-block;
  width: 100%;
}
.people-list-person-picture {
  width: 100%;
  max-height: 120px;
  min-height: 55px;
  max-width: 120px;
  min-width: 55px;
}
.people-list-person-name {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.people-list-person-position {
  display: inline-block;
  width: 100%;
}

