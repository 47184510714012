div.my-page-pane {
  margin-top: -26px;
  font-size: 14px;
}

div.my-page-pane body {
  margin: 5px 0;
}

div.my-page-pane .intro-part {
  background-color: white;
  padding: 26px 16px 0;
}

div.my-page-pane .intro-part.first {
  /* padding-top: 26px;s */
}

div.my-page-pane .intro-panel {
  width: 50%;
  margin: 0 auto;
  /* padding: 8px 16px; */
}

div.my-page-pane .intro-panel img {
  width: 15%;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  float: left;
}

div.my-page-pane .intro-panel .name-bio-area {
  float: right;
  width: 82.5%;
}

div.my-page-pane .name-bio-area .name {
  font-size: 2em;
}

div.my-page-pane .clear {
  clear: both;
}

div.my-page-pane .control-left {
  float: left;
}

div.my-page-pane .control-right {
  float: right;
}

div.my-page-pane .intro-control .control-left p {
  width: 7em;
  text-align: center;
  border-bottom: solid 2px transparent;
  display: inline-block;
  margin: 1em 1em 0 0;
}

div.my-page-pane .intro-control .control-left p.active {
  border-bottom: solid 2px #ed1c94;
}

div.my-page-pane .intro-control .control-left p:hover {
  cursor: pointer;
}

div.my-page-pane .intro-control .control-left span {
  color: #ed1c94;
}

div.my-page-pane .intro-control .control-right a {
  display: block;
  margin: 10px 0 4px 0;
  text-decoration: none;
  color: gray;
  border: solid 1px gray;
  padding: 7px 20px;
  border-radius: 30px;
}

div.my-page-pane .main-panel p {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  div.my-page-pane .intro-panel {
    width: 100%;
    margin: 0 auto;
  }

  div.my-page-pane .intro-control .control-right a {
    margin-top: 5em;
    text-align: center;
    width: 100%;
    right: 0;
  }

  .intro-part {
    padding-bottom: 5em;
    background-color: white;
    display: flex;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {

  div.my-page-pane .intro-panel,
  div#sidebar {
    margin: 35px auto;
  }

  div.my-page-pane .intro-control .control-right a {
    margin-left: 240px;
    width: calc(100% - 240px);
  }
}