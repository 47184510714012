#sidebar {
  position: fixed;
  z-index: 2;
  top: 72px;
  width: 240px;
  height: 100%;
  float: left;
  background-color: #252747;
  padding: 20px 0px 70px 0px;
  overflow-y: auto;
  transition: left 0.4s;
}

.sidebar-shown {
  left: 0px;
}

.sidebar-hidden {
  left: -255px;
}

#sidebar>ul>li:hover {
  background-color: #101240;
}

#sidebar>ul>li.current-selected {
  background-color: #66D7B1;
}

.sidebar-link-button {
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  border: none;
  color: #fff;
  text-align: left;
  padding: 8px 30px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-image: none;
  cursor: pointer;
}

.sidebar-link-button:hover {
  color: #fff;
  text-decoration: none;
}

.sidebar-link-button:focus {
  color: #fff;
  text-decoration: none;
}

.sidebar-link-button-icon {
  font-size: 18px;
  margin-right: 2px;
}

#sidebar span {
  padding: 10px 10px;
  color: #666;
}

#sidebar .btn-default {
  border: none;
  color: #666;
  text-align: left;
  background-color: #272727;
}

#sidebar hr {
  margin: 8px 14px;
  border-color: #666;
}

#lists-search-form {
  margin: 5px 20px;
  width: 200px;
  height: 33px;
  color: #fff;
  border-radius: 4px;
  background-color: #01052E;
  border-color: #01052E;
}

#sidebar::-webkit-scrollbar {
  width: 8px;
}

#sidebar::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, .3);
}