.contribution-snapshot-create {
  background-color: #E6FFED;
  border: 2px solid #5EB663;
  border-radius: 6px;
}

.contribution-snapshot-edit {
  background-color: #EFDAC0;
  border: 2px solid #EFAC59;
  border-radius: 6px;
}

.contribution-snapshot-delete {
  background-color: #FFEEF0;
  border: 2px solid #D75553;
  border-radius: 6px;
}

.contribution-investment-snapshot-table.table {
  margin-bottom: 0;
}

.contribution-investment-snapshot-table>tbody>tr>td {
  border: none;
  padding: 0px 8px;
  line-height: 36px;
}

.contribution-investment-snapshot-table-label {
  font-weight: bold;
  float: right;
}
