#abstract-panel-title-container {
  display: inline-block;
  width: 100%;
}

#abstract-panel-picture-col {
  padding: 0;
  max-width: 100px;
}

#abstract-panel-picture {
  width: 100%;
}

#abstract-panel-title {
  margin-top: 15px;
  font-size: 26px;
}

#abstract-panel-subheading {
  font-size: 16px;
}
