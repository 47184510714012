.vertical-timeline-element-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.vertical-timeline-element-subtitle {
  font-size: 13px;
  color: #888;
  margin-bottom: 10px;
}
.vertical-timeline-element-paragraph {
  display: inline-block;
  font-size: 13px;
  margin-bottom: 10px;
}
.vertical-timeline-element-image {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ddd;
}
.features-page-introduction {
  color: #fff;
  text-align: center;
  margin: auto;
}
