#auth-page-heading {
  margin: 70px 0 30px 0;
  text-align: center;
  color: #fff;
}
.auth-panel {
	max-width: 600px;
	margin: 30px auto;
	background-color: #FAFAFA;
}
.auth-panel-content {
	padding: 10px;
}
.social-auth>h5 {
	font-weight: bold;
	margin: 22px auto;
	text-align: center;
}
.social-buttons {
	height: 70px;
}
.social-or-line {
	text-align: center;
}
.auth-form {
	text-align: center;
}
.auth-link {
  display: inline-block;
  width: 100%;
  padding: 8px 0 0 0;
}
.signup-agreement {
	display: inline-block;
	width: 100%;
  text-align: center;
	color: gray;
	font-size: 12px;
	padding: 14px 0 0 0;
}

