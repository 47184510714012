html body.default_theme {
  background-color: #25264A;
}

html body.default_theme #sidebar {
  background-color: #25264A;
}

html body.default_theme #content-panel>div>div.tags-filter>span,
html body.default_theme .btn-primary {
  color: #FFF !important;
  background-color: #66D7B1 !important;
  border-color: #66D7B1 !important;
}

html body.default_theme #top-navbar .container {
  background-color: #25264A;
}

html body.default_theme #my-account-dropdown {
  background-color: #25264A;
}

html body.default_theme #sidebar>ul>li.current-selected {
  background-color: #66D7B1;
}

html body.default_theme #sidebar>ul>li:hover {
  background-color: #101240;
}

html body.default_theme #footer {
  background-color: #25264A;
}

html body.default_theme a {
  color: #767676;
}

html body.default_theme .sidebar-link-button {
  color: white;
}

html body.default_theme #lists-search-form,
html body.default_theme #top-navbar>nav>div>div.navbar-form>div[role=combobox]>input[type=text] {
  background-color: #010330 !important;
  border-color: #010330 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

html body.default_theme .nav-tabs>li.active>a,
html body.default_theme .nav-tabs>li>a:visited,
html body.default_theme .nav-tabs>li>a:hover,
html body.default_theme .nav-tabs>li.active>a:focus {
  border-bottom: 2px solid #66D7B1;
}

div.panel, div.panel-default {
  border-radius: 8px;
}

.default_theme #top-navbar {
  background-color: #25264A;
}

.default_theme #searchform {
  background-color: #010330;
}

.default_theme #panelBtn {
  color: #66D7B1;
  border: 2px solid #66D7B1;
}

.default_theme #panelBtn:hover {
  color: #fff;
  background: #66D7B1;
}

.default_theme #panelTop {
  background: #25264A;
}


.defailt_theme #rank {
  background: #25264A;
}