.list-action-button-icon {
  vertical-align: middle;
  float: left;
  padding-left: 10px;
}
#quick-add-form>div>div {
  background-color: #fff;
  margin-top: -20px;
  color: #666;
  text-align: left;
  z-index: 5;
  position: absolute;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(102,102,102,1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(102,102,102,1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(102,102,102,1);
}

.suggestion-to-add-to-list {
  display: inline-block;
  margin-top: 4px;
}

.suggestion-to-add-to-list>img {
  margin: 2px 4px;
  width: 30px;
  border: solid 1px #ddd;
}

.notification-logo-style {
  border: solid 1px #ddd;
  width: 45px;
  margin-right: 4px;
  margin-bottom: 10px;
  padding: 0px;
}

.remove-resource-from-list-button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.remove-resource-from-list-button:hover {
  background-color: transparent;
}

.remove-resource-from-list-button>i {
  vertical-align: middle;
  color: #aaa;
  font-size: 18px;
}
