.column-view {
  display: inline-block;
  white-space: normal;
  overflow-y: scroll;
  overflow-x: hidden;
}

.column-view::-webkit-scrollbar {
  width: 4px;
}

.column-view::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}

.column-view-entry-view {
  display: inline-block;
  background-color: #fff;
  padding: 4px;
  margin: 4px;
  width: 100%;
}

.column-view-entry-view-img {
  width: 30px;
  margin-right:10px;
}

.column-view-entry-view-title {
  font-weight: bold;
  color: #666;
  line-height: 30px;
}
