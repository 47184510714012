@media (min-width: 768px) {
  #content-panel {
	  margin-left: 240px;
    padding-top: 76px;
    padding-bottom: 38px;
    background-color: #efefef;
  }
}

@media (max-width: 767px) {
  #content-panel {
    padding-top: 70px;
    padding-bottom: 35px;
    background-color: #efefef;
  }
}
#abstract-description {
  margin-top: 1.25em;
}

html body {
    font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, Roboto, "游ゴシック体", YuGothic, "Yu Gothic Medium", sans-serif;
    font-weight: bold;
}