.datavase-article-container {
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0 20px;
}

.datavase-article-title-preview>h1{
  font-size: 29px;
  font-weight: bold;
}

.datavase-article-markdown-preview>h2{
  font-size: 23px;
  font-weight: bold;
}
.datavase-article-markdown-preview>h3{
  color: #aaa;
  font-size: 20px;
  font-weight: bold;
}

.datavase-article-markdown-preview > p {
  font-size: 16px;
  line-height: 2.3;
  margin: 30px auto;
  word-break: break-all;
}

.datavase-article-markdown-preview iframe {
  max-width: 100%;
}

.datavase-article-markdown-preview img {
  max-width: 100%;
}

@media (min-width: 769px) {
  .datavase-article-markdown-preview > p > img {
    max-width: 728px;
  }
}
