@media (min-width: 768px) {
  #footer {
    display: inline-block;
    padding: 20px 0px 5px 240px;
    background-color: #252747;
    width: 100%;
  }

  .upgrade-footer #footer {
    display: inline-block;
    padding: 20px 0px 5px 80px;
    background-color: #252747;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #footer {
    display: inline-block;
    padding: 20px 0px 5px 0px;
    background-color: #252747;
    width: 100%;
  }
}

#footer-inner-container {
  max-width: 1100px;
  margin: auto;
  padding: 20px;
  text-align: left;
}

#footer-logo {
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 26px;
  text-decoration: none;
}

#footer-logo>img {
  height: 28px;
  margin-right: 8px;
  padding-bottom: 5px;
}

#footer-slogan {
  display: inline-block;
  width: 100%;
  color: #fff;
}

#footer-copyright {
  display: inline-block;
  margin-bottom: 30px;
  margin-top: 30px;
  color: #fff;
}

.footer-column-header {
  display: inline-block;
  font-size: 15px;
  padding-top: 10px;
  color: #fff;
  margin-bottom: 10px;
}

.footer-link {
  display: inline-block;
  width: 100%;
  margin: 4px 0;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: 0.4s;
}

.footer-link:hover {
  color: #66D7B1;
  text-decoration: none;
  transition: 0.4s;
}

.footer-lang-link {
  display: inline-block;
  margin: 4px 8px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: 0.4s;
}

.footer-lang-link:hover {
  color: #66D7B1;
  text-decoration: none;
  transition: 0.4s;
}