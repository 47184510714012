@charset "UTF-8";
/*
*
* Trunk version 1.2.2
*
*/
/**
* Template Style
*
* [Table of contents]
*   1. Custom styles
*     1.1 Main Styles
*     1.2 Typography
*     1.3 Backgrounds
*     1.4 Colors
*     1.5 Main layout
*
*   2. Components
*     2.1  Preloader
*     2.2  Backgrounds
*     2.3  Blogs
*     2.4  Boxes
*     2.5  Breadcrumbs
*     2.6  Buttons
*     2.7  Call To Action
*     2.8  Cards
*     2.9  Comments
*     2.10 Footers
*     2.11 Forms
*     2.12 Gallery Item
*     2.13 Icons
*     2.14 Links
*     2.15 Lists
*     2.16 Modals-custom
*     2.17 Navigation
*     2.18 Pagination-custom
*     2.19 Panel-custom
*     2.20 Posts
*     2.21 Pricing
*     2.22 Quotes
*     2.23 Snackbars
*     2.24 Tables
*     2.25 Team
*     2.26 Thumbnails
*     2.27 Time-line
*     2.28 Tooltip
*
*   3. Helpers
*     3.1 Text Alignment
*     3.2 Footer Backgrounds
*     3.3 Text Styling
*     3.4 Visibility Responsive
*     3.5 Groups
*     3.6 Sections
*     3.7 Offsets
*     3.8 Decorations
*     3.9 Grid Modules
*
*   4. Modules
*     4.1 Flex grid
*     4.2 Unit-responsive
*
*   5 Plugins
*     5.1  Animate
*     5.2  Bg-Video
*     5.3  Bootstrap-Material Datetimepicker
*     5.4  Countdown
*     5.5  Counter
*     5.6  Isotope
*     5.7  Owl Carousel
*     5.8 Rd Navbar
*     5.9 Rd Google Map
*     5.10 Rd Search
*     5.11 Ui To Top
*     5.12 Tabs
*     5.13 Progress Bars
*     5.14 Jquery Circle Progress
*     5.15 Timecircles
*     5.16 Swiper
*     5.17 Select2
*     5.18 Lightgallery
*     5.19 Jplayer
*     5.20 Jquery.Fs.Stepper
*     5.21 Instafeed
*     5.22 Twitter
**/
.upgrade {
  /*
  *
  * Main Styles
  * ==================================================
  */
  /**
  * Body
  */
  /**
  * Links
  */
  /*
  *
  * Typography
  * ==================================================
  */
  /*
  *
  * Colors
  * ==================================================
  */
  /*
  *
  * Main layout
  * ==================================================
  */
  /**
  * Blick
  */
  /*
  *
  * Components
  * ==================================================
  */
  /*
  *
  * 2.1  Preloader
  * --------------------------------------------------
  */
  /**
  * Patch the gap that appear between the two adjacent div.circle-clipper while the
  * spinner is rotating (appears on Chrome 38, Safari 7.1, and IE 11).
  */
  /**
  * 2.2  Backgrounds
  */
  /**
  * Backgrounds Style
  */
  /**
  * Background White
  */
  /**
  * Background Gray Lighter
  */
  /**
  * Common Background Gray Darker, Background Images
  */
  /**
  *  Common Background Gray Darker, Background Images, Background gradient 1
   */
  /**
  * Background mixed
  */
  /**
  * Background Primary
  */
  /**
  * Background Secondary 2
  */
  /**
  * Background Gray Darker
  */
  /**
  * Background decorated
  */
  /**
  * Background Gradients
  */
  /**
  * Background Images
  */
  /**
  * 2.3  Blogs Style
  */
  /**
  * Blog Inline
  */
  /*
  *
  * 2.4  Boxes
  * --------------------------------------------------
  */
  /**
  * Box minimal
  */
  /**
  * Box minimal border
  */
  /**
  * Box minimal modern
  */
  /**
  * Classic Box Style
  */
  /**
  * Border Box
  */
  /**
  * Shadow Box
  */
  /**
  * Box inline
  */
  /**
  * Box element
  */
  /**
  * Contact Info Wrap
  */
  /**
  * Box Info
  */
  /**
  * 2.5  Breadcrumbs
  */
  /*
  *
  * 2.6  Buttons
  * --------------------------------------------------
  */
  /**
  * Button General Styles
  */
  /**
  * Buttons appearance style
  */
  /**
  * Buttons sizes
  */
  /**
  * Button Shapes
  */
  /**
  * Button effects
  */
  /**
  * Button Icon styles
  */
  /**
  * Button Icon Alternate styles
  */
  /**
  * 2.7  Call To Action
  */
  /*
  *
  * 2.8  Cards
  * --------------------------------------------------
  */
  /**
  * Card info
  */
  /*
  *
  * 2.9  Comments
  * --------------------------------------------------
  */
  /**
  * 2.11 Footers
  */
  /**
  * Base Style
  */
  /**
  * Footer Default Style
  */
  /**
  * Footer Alternative Style
  */
  /**
  * Footer Minimal Style
  */
  /**
  * Footer Extended Style
  */
  /**
  * 2.10 Event lightbox
  */
  /*
  *
  * 2.12 Forms
  * --------------------------------------------------
  */
  /**
  * Radio and Checkbox Custom
  */
  /**
  * Base Styles
  */
  /**
  * Icons
  */
  /**
  * Base styles
  */
  /**
  * Alternate styles
  */
  /**
  * Icon sizes
  */
  /**
  * Icon shapes
  */
  /*
  *
  * Links
  * --------------------------------------------------
  */
  /*
  *
  * Lists
  * --------------------------------------------------
  */
  /**
  * Basic lists
  */
  /**
  * List marked
  */
  /**
  * List ordered
  */
  /**
  * List darker
  */
  /**
  * List comma
  */
  /**
  * List twitter
  */
  /*
  *
  * Navigation
  * --------------------------------------------------
  */
  /*
  *
  * Pagination custom
  * --------------------------------------------------
  */
  /**
  * Posts
  */
  /**
  * Post blog article
  *
  */
  /**
  * Post facebook
  */
  /**
  * Post classic
  */
  /**
  * Post minimal
  */
  /**
  * Blog aside
  */
  /**
  * Post modern
  */
  /**
  * Post light
  */
  /**
  * Pricing Table, Box Style
  */
  /**
  * Pricing Box Alternative
  */
  /**
  * Pricing Box LG width Style
  */
  /**
  * Pricing Box Xl Style
  */
  /**
  * Pricing Box Wrap
  */
  /*
  *
  * Quotes
  * --------------------------------------------------
  */
  /**
  * Quote primary
  */
  /**
  * Quote minimal
  */
  /**
  * Quote boxed
  */
  /**
  * Quote vertical
  */
  /**
  * Quote classic
  */
  /**
  * Quote modern
  */
  /*
  *
  * Snackbars
  * --------------------------------------------------
  */
  /**
  * Table custom
  */
  /**
  * Table Shop
  */
  /**
  * Table Hover
  */
  /**
  * Table Color Header
  */
  /*
  *
  * Thumbnails
  * --------------------------------------------------
  */
  /*
  *
  * Thumbnail classic with icon
  *----------------------------------------------------
   */
  /**
  * Thumb Minimal
  */
  /**
  * Thumb Default
  */
  /*
  *
  * Thumbnail Modern
  * --------------------------------------------------
  */
  /**
  * Time Line Styles
  */
  /**
  * Vertical Time Line
  */
  /**
  * Horizontal Time Line
  */
  /*
  *
  * Tooltip Custom
  * --------------------------------------------------
  */
  /**
  * Shop
  ----------------------------- */
  /**
  * Products
  */
  /**
  * Shop Aside
  */
  /**
  * Product Thumb
  */
  /**
  * Product Single Image
  */
  /*
  *
  * Helpers
  * ==================================================
  */
  /*
  *
  * Text Alignment
  * --------------------------------------------------
  */
  /*
  *
  * Text styling
  * --------------------------------------------------
  */
  /*
  *
  * Visibility Responsive
  * --------------------------------------------------
  */
  /*
  *
  * Element groups
  * --------------------------------------------------
  */
  /*
  *
  * Sections
  * --------------------------------------------------
  */
  /**
  * Custom sections
  */
  /**
  * Reverse section layouts
  */
  /*
  *
  * Offsets
  * --------------------------------------------------
  */
  /*
  *
  * Decorations
  * --------------------------------------------------
  */
  /*
  *
  * Grid modules
  * --------------------------------------------------
  */
  /**
  * Grid demonstration
  */
  /**
  * Range bordered
  */
  /*
  *
  * Modules
  * ==================================================
  */
  /*
  *
  * Flex Grid system
  * --------------------------------------------------
  */
  /*
  *
  * Responsive unit
  * --------------------------------------------------
  */
  /*
  *
  * Plugins
  * ==================================================
  */
  /*
  *
  * Animate.css
  * --------------------------------------------------
  */
  /**
  * FadeIn Keyframes Animation
  */
  /**
  * FadeInUp Keyframes Animation
  */
  /**
  * FadeInDown Keyframes Animation
  */
  /**
  * FadeInLeft Keyframes Animation
  */
  /**
  * FadeInRight Keyframes Animation
  */
  /*
  **
  * FadeOut Keyframes Animation
  */
  /**
  * SlideInDown Keyframes Animation
  */
  /**
  * SlideInUp Keyframes Animation
  */
  /**
  * SlideInLeft Keyframes Animation
  */
  /**
  * SlideInRight Keyframes Animation
  */
  /**
  * SlideOutDown Keyframes Animation
  */
  /**
  * Video BG
  */
  /*
  *
  * Counter
  * --------------------------------------------------
  */
  /*
  *
  * Isotope
  * --------------------------------------------------
  */
  /*
  *
  * Owl Carousel
  * --------------------------------------------------
  */
  /*
  * 	Owl Carousel - Auto Height Plugin
  */
  /*
  *  Core Owl Carousel CSS File
  */
  /* No Js */
  /*
  * 	Owl Carousel - Lazy Load Plugin
  */
  /*
  * 	Owl Carousel - Video Plugin
  */
  /*
  * Owl Navigation
  */
  /*
  * Owl Pagination
  */
  /**
  * Owl Carousel Pricing
  */
  /**
  * Owl layout 1
  */
  /**
  * Owl layout 2
  */
  /**
  * Owl layout 3
  */
  /**
  * Owl layout 4
  */
  /*
  *
  * RD Navbar
  * --------------------------------------------------
  */
  /*
  * Navbar components
  */
  /*
  * @subsection   Hybrid  Styles
  */
  /*
  * Static Layout
  */
  /*
  * Fullwidth Layout
  */
  /*
  * Fixed Layout
  */
  /*
  * Sidebar Layout
  */
}
.upgrade .bg-gradient.bg-secondary, .upgrade .bg-secondary.bg-gradient-1, .upgrade .bg-secondary.bg-gradient-2, .upgrade .bg-secondary.bg-gradient-3 {
  background-image: -moz-linear-gradient(left, #ed1c94 0%, #6d0eb1 100%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(left, #ed1c94 0%, #6d0eb1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to right, #ed1c94 0%, #6d0eb1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ed1c94", endColorstr="#6d0eb1", GradientType=1);
  /* IE6-9 */
}
.upgrade .bg-gradient.bg-secondary-2, .upgrade .bg-secondary-2.bg-gradient-1, .upgrade .bg-secondary-2.bg-gradient-2, .upgrade .bg-secondary-2.bg-gradient-3 {
  background-image: -moz-linear-gradient(-39deg, #6d0eb1 59%, #080ab4 100%);
  /* FF3.6-15 */
  background-image: -webkit-linear-gradient(-39deg, #6d0eb1 59%, #080ab4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(135deg, #6d0eb1 59%, #080ab4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6d0eb1", endColorstr="#080ab4", GradientType=1);
}
.upgrade a:focus,
.upgrade button:focus {
  outline: none !important;
}
.upgrade button::-moz-focus-inner {
  border: 0;
}
.upgrade :focus {
  outline: none;
}
.upgrade hr {
  margin-bottom: 0;
}
.upgrade input,
.upgrade button,
.upgrade select,
.upgrade textarea {
  outline: none;
}
.upgrade p {
  margin: 0;
}
.upgrade ul,
.upgrade ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.upgrade ul li,
.upgrade ol li {
  display: block;
}
.upgrade dl {
  margin: 0;
}
.upgrade dt {
  font-weight: inherit;
}
.upgrade address {
  margin-top: 0;
  margin-bottom: 0;
}
.upgrade html p a:hover {
  text-decoration: none;
}
.upgrade form {
  margin-bottom: 0;
}
.upgrade body {
  padding: 0 !important;
}
.upgrade .modal {
  z-index: 1093;
}
.upgrade .modal * + form {
  margin-top: 15px;
}
.upgrade .modal-backdrop {
  z-index: 1092;
}
.upgrade body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.6;
  color: #838386;
  background-color: #fff;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media (min-width: 992px) {
  .upgrade body {
    font-size: 16px;
    line-height: 1.55556;
  }
}
@media (min-width: 1600px) {
  .upgrade body {
    font-size: 18px;
  }
}
.upgrade a {
  color: #ed1c94;
  text-decoration: none;
  transition: 0.25s;
}
.upgrade a:hover, .upgrade a:focus {
  color: #981df0;
  text-decoration: none;
  outline: none;
}
.upgrade a[href*=callto], .upgrade a[href*=mailto] {
  white-space: nowrap;
}
.upgrade .privacy-link {
  display: inline-block;
}
.upgrade .link-hover {
  color: #981df0;
}
.upgrade .link-press {
  color: #981df0;
}
.upgrade img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.upgrade hr {
  border-color: #c2c2c3;
}
.upgrade h1, .upgrade h2, .upgrade h3, .upgrade h4, .upgrade h5, .upgrade h6, .upgrade [class^=heading-] {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #29293a;
}
.upgrade h1 a, .upgrade h2 a, .upgrade h3 a, .upgrade h4 a, .upgrade h5 a, .upgrade h6 a, .upgrade [class^=heading-] a {
  display: inline;
  color: inherit;
  transition: 0.3s all ease;
}
.upgrade h1 a:hover, .upgrade h2 a:hover, .upgrade h3 a:hover, .upgrade h4 a:hover, .upgrade h5 a:hover, .upgrade h6 a:hover, .upgrade [class^=heading-] a:hover {
  color: #981df0;
}
.upgrade h1,
.upgrade .heading-1 {
  font-size: 34px;
  line-height: 1.2;
  letter-spacing: 0.12em;
}
@media (min-width: 768px) {
  .upgrade h1,
.upgrade .heading-1 {
    font-size: 42px;
    line-height: 1.45;
  }
}
@media (min-width: 1200px) {
  .upgrade h1,
.upgrade .heading-1 {
    font-size: 60px;
  }
}
@media (min-width: 1600px) {
  .upgrade h1,
.upgrade .heading-1 {
    font-size: 80px;
  }
}
.upgrade h2,
.upgrade .heading-2 {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  line-height: 1.2;
  text-transform: none;
}
@media (min-width: 768px) {
  .upgrade h2,
.upgrade .heading-2 {
    font-size: 2.4rem;
    line-height: 1.26923;
  }
}
@media (min-width: 1200px) {
  .upgrade h2,
.upgrade .heading-2 {
    font-size: 2.4rem;
  }
}
@media (min-width: 1600px) {
  .upgrade h2,
.upgrade .heading-2 {
    font-size: 2.4rem;
  }
}
.upgrade .heading-2 {
  font-weight: 300;
}
.upgrade h3,
.upgrade .heading-3 {
  font-size: 24px;
  line-height: 1.45;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  .upgrade h3,
.upgrade .heading-3 {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .upgrade h3,
.upgrade .heading-3 {
    font-size: 34px;
    line-height: 1.375;
  }
}
@media (min-width: 1600px) {
  .upgrade h3,
.upgrade .heading-3 {
    font-size: 48px;
  }
}
.upgrade .h3-alternate {
  font-size: 24px;
  line-height: 1.45;
  color: #29293a;
}
@media (min-width: 768px) {
  .upgrade .h3-alternate {
    font-size: 28px;
  }
}
@media (min-width: 1600px) {
  .upgrade .h3-alternate {
    font-size: 42px;
  }
}
.upgrade h4,
.upgrade .heading-4 {
  font-size: 21px;
  line-height: 1.35;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  .upgrade h4,
.upgrade .heading-4 {
    font-size: 26px;
    line-height: 1.42857;
  }
}
@media (min-width: 1600px) {
  .upgrade h4,
.upgrade .heading-4 {
    font-size: 28px;
  }
}
.upgrade h5,
.upgrade .heading-5 {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.5;
  text-transform: none;
}
@media (min-width: 768px) {
  .upgrade h5,
.upgrade .heading-5 {
    line-height: 1.41667;
  }
}
@media (min-width: 1600px) {
  .upgrade h5,
.upgrade .heading-5 {
    font-size: 24px;
  }
}
.upgrade .heading-5 {
  font-weight: 300;
}
.upgrade h6,
.upgrade .heading-6 {
  font-size: 17px;
  line-height: 1.5;
  letter-spacing: 0.12em;
}
@media (min-width: 768px) {
  .upgrade h6,
.upgrade .heading-6 {
    font-size: 19px;
    line-height: 1.5;
  }
}
@media (min-width: 1600px) {
  .upgrade h6,
.upgrade .heading-6 {
    font-size: 24px;
  }
}
.upgrade .big,
.upgrade .custom-subtitle {
  font-size: 18px;
}
@media (min-width: 768px) {
  .upgrade .big,
.upgrade .custom-subtitle {
    font-size: 19px;
  }
}
@media (min-width: 1600px) {
  .upgrade .big,
.upgrade .custom-subtitle {
    font-size: 24px;
  }
}
.upgrade .bigger {
  font-weight: 400;
}
@media (min-width: 992px) {
  .upgrade .bigger {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .upgrade .bigger {
    font-size: 24px;
  }
}
.upgrade small,
.upgrade .small {
  display: block;
  font-size: 16px;
}
.upgrade mark,
.upgrade .mark {
  color: #fff;
  background-color: #981df0;
  padding: 0.1em 0.5em;
}
@media (max-width: 767px) {
  .upgrade .image-responsive {
    width: 100%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
}
.upgrade .page .text-primary {
  color: #981df0;
}
.upgrade .page .text-secondary {
  color: #ed1c94;
}
.upgrade .page .text-secondary-3 {
  color: #080ab4;
}
.upgrade .page .text-gray-1 {
  color: #c2c2c3;
}
.upgrade .page .text-gray-light {
  color: #838386;
}
.upgrade .page .text-gray-4 {
  color: #d8dae2;
}
.upgrade .page .text-gray-darker {
  color: #29293a;
}
.upgrade .page a.text-secondary-1, .upgrade .page a.text-secondary-1:focus, .upgrade .page a.text-secondary-1:active {
  color: inherit;
}
.upgrade .page a.text-secondary-1:hover {
  color: #ffec17;
}
.upgrade .page a.text-gray-darker {
  color: #29293a;
}
.upgrade .page a.text-gray-darker:hover {
  color: #981df0;
}
.upgrade .page {
  position: relative;
  overflow: hidden;
  min-height: 100vh !important;
}
.upgrade .page-header {
  position: relative;
  padding: 0;
  margin: 0;
  border-bottom: none;
}
.upgrade .frame-header {
  width: 100%;
  height: 500px;
  outline: none;
  border: 0;
}
@media (min-width: 992px) {
  .upgrade .frame-header {
    height: 650px;
  }
}
@media (min-width: 1600px) {
  .upgrade .frame-header {
    height: 730px;
  }
}
.upgrade .fullwidth-page {
  position: relative;
  min-height: calc(100vh - 56px);
  display: flex;
  align-items: stretch;
}
.upgrade .fullwidth-page-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
}
.upgrade .fullwidth-page-inner > * {
  position: relative;
  width: 100%;
}
.upgrade .fullwidth-page:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.4);
}
.upgrade .fullwidth-page[class*=bg-gradient]:before {
  content: none;
}
@media (min-width: 992px) {
  .upgrade .desktop .fullwidth-page {
    min-height: 100vh;
  }
  .upgrade .desktop .fullwidth-page > *:nth-child(1) {
    padding-top: 180px;
  }
}
@media (min-width: 1600px) {
  .upgrade .desktop .fullwidth-page > *:nth-child(1) {
    padding-top: 90px;
  }
}
.upgrade .fullwidth-page .page-footer p.right a:hover {
  color: #ed1c94;
}
.upgrade .fullwidth-page * + .button {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .upgrade .fullwidth-page {
    min-height: 100vh;
  }
}
@media (min-width: 1600px) {
  .upgrade .fullwidth-page * + .breadcrumbs-custom-title {
    margin-top: 25px;
  }
  .upgrade .fullwidth-page .heading-1 + p {
    margin-top: 33px;
  }
  .upgrade .fullwidth-page .button {
    margin-top: 50px;
  }
  .upgrade .fullwidth-page * + .countdown-wrap,
.upgrade .fullwidth-page .countdown-wrap + * {
    margin-top: 40px;
  }
}
.upgrade .ie-10 .fullwidth-page .fullwidth-page-inner,
.upgrade .ie-11 .fullwidth-page .fullwidth-page-inner,
.upgrade .ie-edge .fullwidth-page .fullwidth-page-inner {
  min-height: 100vh;
}
@media (min-width: 1800px) {
  .upgrade .image-position-01 {
    margin-top: 10px;
    margin-bottom: -60px;
  }
}
.upgrade .blick-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
  line-height: 0;
}
.upgrade .blick-overlay,
.upgrade .blick-content {
  position: absolute;
}
.upgrade .blick-overlay {
  top: 0;
  right: 0;
  left: 0;
  bottom: 32.16%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.upgrade .blick-content {
  position: absolute;
  overflow: hidden;
  top: 4.3%;
  bottom: 39.5%;
  left: 4.1%;
  right: 4.1%;
}
.upgrade .blick-content img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.upgrade .blick-content[data-blick-content=ipad] {
  top: 5.1%;
  bottom: 5.1%;
  left: 6.9%;
  right: 6.95%;
}
.upgrade .blick-content[data-blick-content=iphone] {
  top: 11.2%;
  bottom: 17.7%;
  left: 14.1%;
  right: 17.6%;
}
.upgrade .ie-10 .blick-wrap > img,
.upgrade .ie-11 .blick-wrap > img {
  width: 100%;
}
.upgrade section {
  position: relative;
}
.upgrade .section-wave {
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  width: 100vw;
  line-height: 0;
}
.upgrade .ie-10 .section-wave,
.upgrade .ie-11 .section-wave,
.upgrade .ie-edge .section-wave {
  top: 1px;
}
.upgrade .section-wave > svg {
  width: 100vw;
  height: auto;
}
.upgrade .ie-10 .section-wave > svg,
.upgrade .ie-11 .section-wave > svg,
.upgrade .ie-edge .section-wave > svg {
  max-height: 45px;
}
.upgrade .tablet .section-wave > svg,
.upgrade .mobile .section-wave > svg {
  max-height: 25px;
}
.upgrade .audio-gel {
  visibility: hidden;
}
.upgrade .page-loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 9999999;
  background: #fff;
  transition: 0.3s all ease;
}
.upgrade .page-loader .page-loader-body {
  margin-top: -5%;
  text-align: center;
}
.upgrade .page-loader .page-loader-body:before {
  content: "周りの人から幸せに";
  display: block;
  margin-bottom: 26px;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 1;
  font-weight: 900;
  text-align: center;
  color: #29293a;
}
@supports (-webkit-background-clip: text) {
  .upgrade .page-loader .page-loader-body:before {
    background: linear-gradient(to right, #981df0 20%, #ed1c94 40%, #ffec17 60%, #981df0 80%);
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.upgrade .ie-10 .page-loader .page-loader-body:before,
.upgrade .ie-11 .page-loader .page-loader-body:before {
  color: #ed1c94;
  background: none;
}
.upgrade [data-x-mode=true] .page-loader {
  display: none !important;
}
.upgrade .preloader-wrapper {
  display: inline-block;
  font-size: 0;
  position: relative;
  width: 50px;
  height: 50px;
}
.upgrade .preloader-wrapper.small {
  width: 36px;
  height: 36px;
}
.upgrade .preloader-wrapper.big {
  width: 64px;
  height: 64px;
}
.upgrade .preloader-wrapper.active {
  -webkit-animation: container-rotate 1568ms linear infinite;
  animation: container-rotate 1568ms linear infinite;
}
@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}
.upgrade .spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #981df0;
}
.upgrade .spinner-blue,
.upgrade .spinner-blue-only {
  border-color: #981df0;
}
.upgrade .spinner-red,
.upgrade .spinner-red-only {
  border-color: #ed1c94;
}
.upgrade .spinner-yellow,
.upgrade .spinner-yellow-only {
  border-color: #ffec17;
}
.upgrade .spinner-green,
.upgrade .spinner-green-only {
  border-color: #080ab4;
}
.upgrade .active .spinner-layer.spinner-blue {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.upgrade .active .spinner-layer.spinner-red {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.upgrade .active .spinner-layer.spinner-yellow {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.upgrade .active .spinner-layer.spinner-green {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.upgrade .active .spinner-layer,
.upgrade .active .spinner-layer.spinner-blue-only,
.upgrade .active .spinner-layer.spinner-red-only,
.upgrade .active .spinner-layer.spinner-yellow-only,
.upgrade .active .spinner-layer.spinner-green-only {
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@-webkit-keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@-webkit-keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.upgrade .gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.upgrade .gap-patch .circle {
  width: 1000%;
  left: -450%;
}
.upgrade .circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.upgrade .circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 2px;
  /* STROKEWIDTH */
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.upgrade .circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  transform: rotate(129deg);
}
.upgrade .circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}
.upgrade .active .circle-clipper.left .circle {
  /* duration: ARCTIME */
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.upgrade .active .circle-clipper.right .circle {
  /* duration: ARCTIME */
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
@-webkit-keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}
@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}
@-webkit-keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
.upgrade #spinnerContainer.cooldown {
  /* duration: SHRINK_TIME */
  -webkit-animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
  animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.upgrade .context-dark, .upgrade .bg-gray-darker, .upgrade .bg-additional-gray-darker .bg-additional-item,
.upgrade .bg-image, .upgrade .bg-additional-image .bg-additional-item,
.upgrade .bg-gradient, .upgrade .bg-gradient-1, .upgrade .bg-gradient-2, .upgrade .bg-gradient-3, .upgrade .bg-primary, .upgrade .bg-primary-accent, .upgrade .bg-secondary-2, .upgrade .breadcrumbs-custom-light {
  color: #fff;
}
.upgrade .context-dark h1, .upgrade .bg-gray-darker h1, .upgrade .bg-additional-gray-darker .bg-additional-item h1,
.upgrade .bg-image h1, .upgrade .bg-additional-image .bg-additional-item h1,
.upgrade .bg-gradient h1, .upgrade .bg-gradient-1 h1, .upgrade .bg-gradient-2 h1, .upgrade .bg-gradient-3 h1, .upgrade .bg-primary h1, .upgrade .bg-primary-accent h1, .upgrade .bg-secondary-2 h1, .upgrade .breadcrumbs-custom-light h1, .upgrade .context-dark h2, .upgrade .bg-gray-darker h2, .upgrade .bg-additional-gray-darker .bg-additional-item h2,
.upgrade .bg-image h2, .upgrade .bg-additional-image .bg-additional-item h2,
.upgrade .bg-gradient h2, .upgrade .bg-gradient-1 h2, .upgrade .bg-gradient-2 h2, .upgrade .bg-gradient-3 h2, .upgrade .bg-primary h2, .upgrade .bg-primary-accent h2, .upgrade .bg-secondary-2 h2, .upgrade .breadcrumbs-custom-light h2, .upgrade .context-dark h3, .upgrade .bg-gray-darker h3, .upgrade .bg-additional-gray-darker .bg-additional-item h3,
.upgrade .bg-image h3, .upgrade .bg-additional-image .bg-additional-item h3,
.upgrade .bg-gradient h3, .upgrade .bg-gradient-1 h3, .upgrade .bg-gradient-2 h3, .upgrade .bg-gradient-3 h3, .upgrade .bg-primary h3, .upgrade .bg-primary-accent h3, .upgrade .bg-secondary-2 h3, .upgrade .breadcrumbs-custom-light h3, .upgrade .context-dark h4, .upgrade .bg-gray-darker h4, .upgrade .bg-additional-gray-darker .bg-additional-item h4,
.upgrade .bg-image h4, .upgrade .bg-additional-image .bg-additional-item h4,
.upgrade .bg-gradient h4, .upgrade .bg-gradient-1 h4, .upgrade .bg-gradient-2 h4, .upgrade .bg-gradient-3 h4, .upgrade .bg-primary h4, .upgrade .bg-primary-accent h4, .upgrade .bg-secondary-2 h4, .upgrade .breadcrumbs-custom-light h4, .upgrade .context-dark h5, .upgrade .bg-gray-darker h5, .upgrade .bg-additional-gray-darker .bg-additional-item h5,
.upgrade .bg-image h5, .upgrade .bg-additional-image .bg-additional-item h5,
.upgrade .bg-gradient h5, .upgrade .bg-gradient-1 h5, .upgrade .bg-gradient-2 h5, .upgrade .bg-gradient-3 h5, .upgrade .bg-primary h5, .upgrade .bg-primary-accent h5, .upgrade .bg-secondary-2 h5, .upgrade .breadcrumbs-custom-light h5, .upgrade .context-dark h6, .upgrade .bg-gray-darker h6, .upgrade .bg-additional-gray-darker .bg-additional-item h6,
.upgrade .bg-image h6, .upgrade .bg-additional-image .bg-additional-item h6,
.upgrade .bg-gradient h6, .upgrade .bg-gradient-1 h6, .upgrade .bg-gradient-2 h6, .upgrade .bg-gradient-3 h6, .upgrade .bg-primary h6, .upgrade .bg-primary-accent h6, .upgrade .bg-secondary-2 h6, .upgrade .breadcrumbs-custom-light h6, .upgrade .context-dark [class^=heading-], .upgrade .bg-gray-darker [class^=heading-], .upgrade .bg-additional-gray-darker .bg-additional-item [class^=heading-],
.upgrade .bg-image [class^=heading-], .upgrade .bg-additional-image .bg-additional-item [class^=heading-],
.upgrade .bg-gradient [class^=heading-], .upgrade .bg-gradient-1 [class^=heading-], .upgrade .bg-gradient-2 [class^=heading-], .upgrade .bg-gradient-3 [class^=heading-], .upgrade .bg-primary [class^=heading-], .upgrade .bg-primary-accent [class^=heading-], .upgrade .bg-secondary-2 [class^=heading-], .upgrade .breadcrumbs-custom-light [class^=heading-] {
  color: #fff;
}
.upgrade .context-dark .text-gray-light, .upgrade .bg-gray-darker .text-gray-light, .upgrade .bg-additional-gray-darker .bg-additional-item .text-gray-light,
.upgrade .bg-image .text-gray-light, .upgrade .bg-additional-image .bg-additional-item .text-gray-light,
.upgrade .bg-gradient .text-gray-light, .upgrade .bg-gradient-1 .text-gray-light, .upgrade .bg-gradient-2 .text-gray-light, .upgrade .bg-gradient-3 .text-gray-light, .upgrade .bg-primary .text-gray-light, .upgrade .bg-primary-accent .text-gray-light, .upgrade .bg-secondary-2 .text-gray-light, .upgrade .breadcrumbs-custom-light .text-gray-light {
  color: #c2c2c3;
}
.upgrade .context-dark .bigger, .upgrade .bg-gray-darker .bigger, .upgrade .bg-additional-gray-darker .bg-additional-item .bigger,
.upgrade .bg-image .bigger, .upgrade .bg-additional-image .bg-additional-item .bigger,
.upgrade .bg-gradient .bigger, .upgrade .bg-gradient-1 .bigger, .upgrade .bg-gradient-2 .bigger, .upgrade .bg-gradient-3 .bigger, .upgrade .bg-primary .bigger, .upgrade .bg-primary-accent .bigger, .upgrade .bg-secondary-2 .bigger, .upgrade .breadcrumbs-custom-light .bigger {
  color: #c2c2c3;
}
.upgrade .context-dark .icon-primary, .upgrade .bg-gray-darker .icon-primary, .upgrade .bg-additional-gray-darker .bg-additional-item .icon-primary,
.upgrade .bg-image .icon-primary, .upgrade .bg-additional-image .bg-additional-item .icon-primary,
.upgrade .bg-gradient .icon-primary, .upgrade .bg-gradient-1 .icon-primary, .upgrade .bg-gradient-2 .icon-primary, .upgrade .bg-gradient-3 .icon-primary, .upgrade .bg-primary .icon-primary, .upgrade .bg-primary-accent .icon-primary, .upgrade .bg-secondary-2 .icon-primary, .upgrade .breadcrumbs-custom-light .icon-primary {
  color: #ed1c94;
}
.upgrade .context-dark .breadcrumbs-custom-title::before, .upgrade .bg-gray-darker .breadcrumbs-custom-title::before, .upgrade .bg-additional-gray-darker .bg-additional-item .breadcrumbs-custom-title::before,
.upgrade .bg-image .breadcrumbs-custom-title::before, .upgrade .bg-additional-image .bg-additional-item .breadcrumbs-custom-title::before,
.upgrade .bg-gradient .breadcrumbs-custom-title::before, .upgrade .bg-gradient-1 .breadcrumbs-custom-title::before, .upgrade .bg-gradient-2 .breadcrumbs-custom-title::before, .upgrade .bg-gradient-3 .breadcrumbs-custom-title::before, .upgrade .bg-primary .breadcrumbs-custom-title::before, .upgrade .bg-primary-accent .breadcrumbs-custom-title::before, .upgrade .bg-secondary-2 .breadcrumbs-custom-title::before, .upgrade .breadcrumbs-custom-light .breadcrumbs-custom-title::before, .upgrade .context-dark .breadcrumbs-custom-title::after, .upgrade .bg-gray-darker .breadcrumbs-custom-title::after, .upgrade .bg-additional-gray-darker .bg-additional-item .breadcrumbs-custom-title::after,
.upgrade .bg-image .breadcrumbs-custom-title::after, .upgrade .bg-additional-image .bg-additional-item .breadcrumbs-custom-title::after,
.upgrade .bg-gradient .breadcrumbs-custom-title::after, .upgrade .bg-gradient-1 .breadcrumbs-custom-title::after, .upgrade .bg-gradient-2 .breadcrumbs-custom-title::after, .upgrade .bg-gradient-3 .breadcrumbs-custom-title::after, .upgrade .bg-primary .breadcrumbs-custom-title::after, .upgrade .bg-primary-accent .breadcrumbs-custom-title::after, .upgrade .bg-secondary-2 .breadcrumbs-custom-title::after, .upgrade .breadcrumbs-custom-light .breadcrumbs-custom-title::after, .upgrade .context-dark .heading-decorated-diamonds::before, .upgrade .bg-gray-darker .heading-decorated-diamonds::before, .upgrade .bg-additional-gray-darker .bg-additional-item .heading-decorated-diamonds::before,
.upgrade .bg-image .heading-decorated-diamonds::before, .upgrade .bg-additional-image .bg-additional-item .heading-decorated-diamonds::before,
.upgrade .bg-gradient .heading-decorated-diamonds::before, .upgrade .bg-gradient-1 .heading-decorated-diamonds::before, .upgrade .bg-gradient-2 .heading-decorated-diamonds::before, .upgrade .bg-gradient-3 .heading-decorated-diamonds::before, .upgrade .bg-primary .heading-decorated-diamonds::before, .upgrade .bg-primary-accent .heading-decorated-diamonds::before, .upgrade .bg-secondary-2 .heading-decorated-diamonds::before, .upgrade .breadcrumbs-custom-light .heading-decorated-diamonds::before, .upgrade .context-dark .heading-decorated-diamonds::after, .upgrade .bg-gray-darker .heading-decorated-diamonds::after, .upgrade .bg-additional-gray-darker .bg-additional-item .heading-decorated-diamonds::after,
.upgrade .bg-image .heading-decorated-diamonds::after, .upgrade .bg-additional-image .bg-additional-item .heading-decorated-diamonds::after,
.upgrade .bg-gradient .heading-decorated-diamonds::after, .upgrade .bg-gradient-1 .heading-decorated-diamonds::after, .upgrade .bg-gradient-2 .heading-decorated-diamonds::after, .upgrade .bg-gradient-3 .heading-decorated-diamonds::after, .upgrade .bg-primary .heading-decorated-diamonds::after, .upgrade .bg-primary-accent .heading-decorated-diamonds::after, .upgrade .bg-secondary-2 .heading-decorated-diamonds::after, .upgrade .breadcrumbs-custom-light .heading-decorated-diamonds::after {
  background: #fff;
}
.upgrade .context-dark .panel-corporate, .upgrade .bg-gray-darker .panel-corporate, .upgrade .bg-additional-gray-darker .bg-additional-item .panel-corporate,
.upgrade .bg-image .panel-corporate, .upgrade .bg-additional-image .bg-additional-item .panel-corporate,
.upgrade .bg-gradient .panel-corporate, .upgrade .bg-gradient-1 .panel-corporate, .upgrade .bg-gradient-2 .panel-corporate, .upgrade .bg-gradient-3 .panel-corporate, .upgrade .bg-primary .panel-corporate, .upgrade .bg-primary-accent .panel-corporate, .upgrade .bg-secondary-2 .panel-corporate, .upgrade .breadcrumbs-custom-light .panel-corporate {
  box-shadow: none;
}
.upgrade .context-dark .box-minimal-light, .upgrade .bg-gray-darker .box-minimal-light, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light,
.upgrade .bg-image .box-minimal-light, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light,
.upgrade .bg-gradient .box-minimal-light, .upgrade .bg-gradient-1 .box-minimal-light, .upgrade .bg-gradient-2 .box-minimal-light, .upgrade .bg-gradient-3 .box-minimal-light, .upgrade .bg-primary .box-minimal-light, .upgrade .bg-primary-accent .box-minimal-light, .upgrade .bg-secondary-2 .box-minimal-light, .upgrade .breadcrumbs-custom-light .box-minimal-light {
  color: #ededed;
}
.upgrade .context-dark .box-minimal-light .box-minimal-icon, .upgrade .bg-gray-darker .box-minimal-light .box-minimal-icon, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light .box-minimal-icon,
.upgrade .bg-image .box-minimal-light .box-minimal-icon, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light .box-minimal-icon,
.upgrade .bg-gradient .box-minimal-light .box-minimal-icon, .upgrade .bg-gradient-1 .box-minimal-light .box-minimal-icon, .upgrade .bg-gradient-2 .box-minimal-light .box-minimal-icon, .upgrade .bg-gradient-3 .box-minimal-light .box-minimal-icon, .upgrade .bg-primary .box-minimal-light .box-minimal-icon, .upgrade .bg-primary-accent .box-minimal-light .box-minimal-icon, .upgrade .bg-secondary-2 .box-minimal-light .box-minimal-icon, .upgrade .breadcrumbs-custom-light .box-minimal-light .box-minimal-icon {
  color: #fff;
}
.upgrade .context-dark .box-minimal-light h1, .upgrade .bg-gray-darker .box-minimal-light h1, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light h1,
.upgrade .bg-image .box-minimal-light h1, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light h1,
.upgrade .bg-gradient .box-minimal-light h1, .upgrade .bg-gradient-1 .box-minimal-light h1, .upgrade .bg-gradient-2 .box-minimal-light h1, .upgrade .bg-gradient-3 .box-minimal-light h1, .upgrade .bg-primary .box-minimal-light h1, .upgrade .bg-primary-accent .box-minimal-light h1, .upgrade .bg-secondary-2 .box-minimal-light h1, .upgrade .breadcrumbs-custom-light .box-minimal-light h1, .upgrade .context-dark .box-minimal-light h2, .upgrade .bg-gray-darker .box-minimal-light h2, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light h2,
.upgrade .bg-image .box-minimal-light h2, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light h2,
.upgrade .bg-gradient .box-minimal-light h2, .upgrade .bg-gradient-1 .box-minimal-light h2, .upgrade .bg-gradient-2 .box-minimal-light h2, .upgrade .bg-gradient-3 .box-minimal-light h2, .upgrade .bg-primary .box-minimal-light h2, .upgrade .bg-primary-accent .box-minimal-light h2, .upgrade .bg-secondary-2 .box-minimal-light h2, .upgrade .breadcrumbs-custom-light .box-minimal-light h2, .upgrade .context-dark .box-minimal-light h3, .upgrade .bg-gray-darker .box-minimal-light h3, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light h3,
.upgrade .bg-image .box-minimal-light h3, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light h3,
.upgrade .bg-gradient .box-minimal-light h3, .upgrade .bg-gradient-1 .box-minimal-light h3, .upgrade .bg-gradient-2 .box-minimal-light h3, .upgrade .bg-gradient-3 .box-minimal-light h3, .upgrade .bg-primary .box-minimal-light h3, .upgrade .bg-primary-accent .box-minimal-light h3, .upgrade .bg-secondary-2 .box-minimal-light h3, .upgrade .breadcrumbs-custom-light .box-minimal-light h3, .upgrade .context-dark .box-minimal-light h4, .upgrade .bg-gray-darker .box-minimal-light h4, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light h4,
.upgrade .bg-image .box-minimal-light h4, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light h4,
.upgrade .bg-gradient .box-minimal-light h4, .upgrade .bg-gradient-1 .box-minimal-light h4, .upgrade .bg-gradient-2 .box-minimal-light h4, .upgrade .bg-gradient-3 .box-minimal-light h4, .upgrade .bg-primary .box-minimal-light h4, .upgrade .bg-primary-accent .box-minimal-light h4, .upgrade .bg-secondary-2 .box-minimal-light h4, .upgrade .breadcrumbs-custom-light .box-minimal-light h4, .upgrade .context-dark .box-minimal-light h5, .upgrade .bg-gray-darker .box-minimal-light h5, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light h5,
.upgrade .bg-image .box-minimal-light h5, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light h5,
.upgrade .bg-gradient .box-minimal-light h5, .upgrade .bg-gradient-1 .box-minimal-light h5, .upgrade .bg-gradient-2 .box-minimal-light h5, .upgrade .bg-gradient-3 .box-minimal-light h5, .upgrade .bg-primary .box-minimal-light h5, .upgrade .bg-primary-accent .box-minimal-light h5, .upgrade .bg-secondary-2 .box-minimal-light h5, .upgrade .breadcrumbs-custom-light .box-minimal-light h5, .upgrade .context-dark .box-minimal-light h6, .upgrade .bg-gray-darker .box-minimal-light h6, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light h6,
.upgrade .bg-image .box-minimal-light h6, .upgrade .bg-additional-image .bg-additional-item .box-minimal-light h6,
.upgrade .bg-gradient .box-minimal-light h6, .upgrade .bg-gradient-1 .box-minimal-light h6, .upgrade .bg-gradient-2 .box-minimal-light h6, .upgrade .bg-gradient-3 .box-minimal-light h6, .upgrade .bg-primary .box-minimal-light h6, .upgrade .bg-primary-accent .box-minimal-light h6, .upgrade .bg-secondary-2 .box-minimal-light h6, .upgrade .breadcrumbs-custom-light .box-minimal-light h6, .upgrade .context-dark .box-minimal-light [class^=heading-], .upgrade .bg-gray-darker .box-minimal-light [class^=heading-], .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-light [class^=heading-],
.upgrade .bg-image .box-minimal-light [class^=heading-], .upgrade .bg-additional-image .bg-additional-item .box-minimal-light [class^=heading-],
.upgrade .bg-gradient .box-minimal-light [class^=heading-], .upgrade .bg-gradient-1 .box-minimal-light [class^=heading-], .upgrade .bg-gradient-2 .box-minimal-light [class^=heading-], .upgrade .bg-gradient-3 .box-minimal-light [class^=heading-], .upgrade .bg-primary .box-minimal-light [class^=heading-], .upgrade .bg-primary-accent .box-minimal-light [class^=heading-], .upgrade .bg-secondary-2 .box-minimal-light [class^=heading-], .upgrade .breadcrumbs-custom-light .box-minimal-light [class^=heading-] {
  color: #ffec17;
}
.upgrade .context-dark .box-minimal-border .box-minimal-text, .upgrade .bg-gray-darker .box-minimal-border .box-minimal-text, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-border .box-minimal-text,
.upgrade .bg-image .box-minimal-border .box-minimal-text, .upgrade .bg-additional-image .bg-additional-item .box-minimal-border .box-minimal-text,
.upgrade .bg-gradient .box-minimal-border .box-minimal-text, .upgrade .bg-gradient-1 .box-minimal-border .box-minimal-text, .upgrade .bg-gradient-2 .box-minimal-border .box-minimal-text, .upgrade .bg-gradient-3 .box-minimal-border .box-minimal-text, .upgrade .bg-primary .box-minimal-border .box-minimal-text, .upgrade .bg-primary-accent .box-minimal-border .box-minimal-text, .upgrade .bg-secondary-2 .box-minimal-border .box-minimal-text, .upgrade .breadcrumbs-custom-light .box-minimal-border .box-minimal-text {
  opacity: 0.6;
}
.upgrade .context-dark .quote-minimal-text, .upgrade .bg-gray-darker .quote-minimal-text, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-minimal-text,
.upgrade .bg-image .quote-minimal-text, .upgrade .bg-additional-image .bg-additional-item .quote-minimal-text,
.upgrade .bg-gradient .quote-minimal-text, .upgrade .bg-gradient-1 .quote-minimal-text, .upgrade .bg-gradient-2 .quote-minimal-text, .upgrade .bg-gradient-3 .quote-minimal-text, .upgrade .bg-primary .quote-minimal-text, .upgrade .bg-primary-accent .quote-minimal-text, .upgrade .bg-secondary-2 .quote-minimal-text, .upgrade .breadcrumbs-custom-light .quote-minimal-text {
  color: #fff;
}
.upgrade .context-dark .quote-boxed-text, .upgrade .bg-gray-darker .quote-boxed-text, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-boxed-text,
.upgrade .bg-image .quote-boxed-text, .upgrade .bg-additional-image .bg-additional-item .quote-boxed-text,
.upgrade .bg-gradient .quote-boxed-text, .upgrade .bg-gradient-1 .quote-boxed-text, .upgrade .bg-gradient-2 .quote-boxed-text, .upgrade .bg-gradient-3 .quote-boxed-text, .upgrade .bg-primary .quote-boxed-text, .upgrade .bg-primary-accent .quote-boxed-text, .upgrade .bg-secondary-2 .quote-boxed-text, .upgrade .breadcrumbs-custom-light .quote-boxed-text, .upgrade .context-dark .quote-vertical-text, .upgrade .bg-gray-darker .quote-vertical-text, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-vertical-text,
.upgrade .bg-image .quote-vertical-text, .upgrade .bg-additional-image .bg-additional-item .quote-vertical-text,
.upgrade .bg-gradient .quote-vertical-text, .upgrade .bg-gradient-1 .quote-vertical-text, .upgrade .bg-gradient-2 .quote-vertical-text, .upgrade .bg-gradient-3 .quote-vertical-text, .upgrade .bg-primary .quote-vertical-text, .upgrade .bg-primary-accent .quote-vertical-text, .upgrade .bg-secondary-2 .quote-vertical-text, .upgrade .breadcrumbs-custom-light .quote-vertical-text, .upgrade .context-dark .quote-modern-text, .upgrade .bg-gray-darker .quote-modern-text, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-modern-text,
.upgrade .bg-image .quote-modern-text, .upgrade .bg-additional-image .bg-additional-item .quote-modern-text,
.upgrade .bg-gradient .quote-modern-text, .upgrade .bg-gradient-1 .quote-modern-text, .upgrade .bg-gradient-2 .quote-modern-text, .upgrade .bg-gradient-3 .quote-modern-text, .upgrade .bg-primary .quote-modern-text, .upgrade .bg-primary-accent .quote-modern-text, .upgrade .bg-secondary-2 .quote-modern-text, .upgrade .breadcrumbs-custom-light .quote-modern-text {
  color: #fff;
}
.upgrade .context-dark .quote-boxed-cite, .upgrade .bg-gray-darker .quote-boxed-cite, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-boxed-cite,
.upgrade .bg-image .quote-boxed-cite, .upgrade .bg-additional-image .bg-additional-item .quote-boxed-cite,
.upgrade .bg-gradient .quote-boxed-cite, .upgrade .bg-gradient-1 .quote-boxed-cite, .upgrade .bg-gradient-2 .quote-boxed-cite, .upgrade .bg-gradient-3 .quote-boxed-cite, .upgrade .bg-primary .quote-boxed-cite, .upgrade .bg-primary-accent .quote-boxed-cite, .upgrade .bg-secondary-2 .quote-boxed-cite, .upgrade .breadcrumbs-custom-light .quote-boxed-cite, .upgrade .context-dark .quote-vertical-cite, .upgrade .bg-gray-darker .quote-vertical-cite, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-vertical-cite,
.upgrade .bg-image .quote-vertical-cite, .upgrade .bg-additional-image .bg-additional-item .quote-vertical-cite,
.upgrade .bg-gradient .quote-vertical-cite, .upgrade .bg-gradient-1 .quote-vertical-cite, .upgrade .bg-gradient-2 .quote-vertical-cite, .upgrade .bg-gradient-3 .quote-vertical-cite, .upgrade .bg-primary .quote-vertical-cite, .upgrade .bg-primary-accent .quote-vertical-cite, .upgrade .bg-secondary-2 .quote-vertical-cite, .upgrade .breadcrumbs-custom-light .quote-vertical-cite, .upgrade .context-dark .quote-modern-cite, .upgrade .bg-gray-darker .quote-modern-cite, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-modern-cite,
.upgrade .bg-image .quote-modern-cite, .upgrade .bg-additional-image .bg-additional-item .quote-modern-cite,
.upgrade .bg-gradient .quote-modern-cite, .upgrade .bg-gradient-1 .quote-modern-cite, .upgrade .bg-gradient-2 .quote-modern-cite, .upgrade .bg-gradient-3 .quote-modern-cite, .upgrade .bg-primary .quote-modern-cite, .upgrade .bg-primary-accent .quote-modern-cite, .upgrade .bg-secondary-2 .quote-modern-cite, .upgrade .breadcrumbs-custom-light .quote-modern-cite {
  color: #ffec17;
}
.upgrade .context-dark .quote-boxed-small, .upgrade .bg-gray-darker .quote-boxed-small, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-boxed-small,
.upgrade .bg-image .quote-boxed-small, .upgrade .bg-additional-image .bg-additional-item .quote-boxed-small,
.upgrade .bg-gradient .quote-boxed-small, .upgrade .bg-gradient-1 .quote-boxed-small, .upgrade .bg-gradient-2 .quote-boxed-small, .upgrade .bg-gradient-3 .quote-boxed-small, .upgrade .bg-primary .quote-boxed-small, .upgrade .bg-primary-accent .quote-boxed-small, .upgrade .bg-secondary-2 .quote-boxed-small, .upgrade .breadcrumbs-custom-light .quote-boxed-small, .upgrade .context-dark .quote-vertical-small, .upgrade .bg-gray-darker .quote-vertical-small, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-vertical-small,
.upgrade .bg-image .quote-vertical-small, .upgrade .bg-additional-image .bg-additional-item .quote-vertical-small,
.upgrade .bg-gradient .quote-vertical-small, .upgrade .bg-gradient-1 .quote-vertical-small, .upgrade .bg-gradient-2 .quote-vertical-small, .upgrade .bg-gradient-3 .quote-vertical-small, .upgrade .bg-primary .quote-vertical-small, .upgrade .bg-primary-accent .quote-vertical-small, .upgrade .bg-secondary-2 .quote-vertical-small, .upgrade .breadcrumbs-custom-light .quote-vertical-small {
  color: #838386;
}
.upgrade .context-dark .quote-modern-small, .upgrade .bg-gray-darker .quote-modern-small, .upgrade .bg-additional-gray-darker .bg-additional-item .quote-modern-small,
.upgrade .bg-image .quote-modern-small, .upgrade .bg-additional-image .bg-additional-item .quote-modern-small,
.upgrade .bg-gradient .quote-modern-small, .upgrade .bg-gradient-1 .quote-modern-small, .upgrade .bg-gradient-2 .quote-modern-small, .upgrade .bg-gradient-3 .quote-modern-small, .upgrade .bg-primary .quote-modern-small, .upgrade .bg-primary-accent .quote-modern-small, .upgrade .bg-secondary-2 .quote-modern-small, .upgrade .breadcrumbs-custom-light .quote-modern-small {
  color: #c2c2c3;
}
.upgrade .context-dark .owl-dot:hover, .upgrade .bg-gray-darker .owl-dot:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .owl-dot:hover,
.upgrade .bg-image .owl-dot:hover, .upgrade .bg-additional-image .bg-additional-item .owl-dot:hover,
.upgrade .bg-gradient .owl-dot:hover, .upgrade .bg-gradient-1 .owl-dot:hover, .upgrade .bg-gradient-2 .owl-dot:hover, .upgrade .bg-gradient-3 .owl-dot:hover, .upgrade .bg-primary .owl-dot:hover, .upgrade .bg-primary-accent .owl-dot:hover, .upgrade .bg-secondary-2 .owl-dot:hover, .upgrade .breadcrumbs-custom-light .owl-dot:hover, .upgrade .context-dark .owl-dot.active, .upgrade .bg-gray-darker .owl-dot.active, .upgrade .bg-additional-gray-darker .bg-additional-item .owl-dot.active,
.upgrade .bg-image .owl-dot.active, .upgrade .bg-additional-image .bg-additional-item .owl-dot.active,
.upgrade .bg-gradient .owl-dot.active, .upgrade .bg-gradient-1 .owl-dot.active, .upgrade .bg-gradient-2 .owl-dot.active, .upgrade .bg-gradient-3 .owl-dot.active, .upgrade .bg-primary .owl-dot.active, .upgrade .bg-primary-accent .owl-dot.active, .upgrade .bg-secondary-2 .owl-dot.active, .upgrade .breadcrumbs-custom-light .owl-dot.active {
  background-color: #ed1c94;
}
.upgrade .context-dark .slider-widget, .upgrade .bg-gray-darker .slider-widget, .upgrade .bg-additional-gray-darker .bg-additional-item .slider-widget,
.upgrade .bg-image .slider-widget, .upgrade .bg-additional-image .bg-additional-item .slider-widget,
.upgrade .bg-gradient .slider-widget, .upgrade .bg-gradient-1 .slider-widget, .upgrade .bg-gradient-2 .slider-widget, .upgrade .bg-gradient-3 .slider-widget, .upgrade .bg-primary .slider-widget, .upgrade .bg-primary-accent .slider-widget, .upgrade .bg-secondary-2 .slider-widget, .upgrade .breadcrumbs-custom-light .slider-widget {
  box-shadow: none;
}
.upgrade .context-dark hr, .upgrade .bg-gray-darker hr, .upgrade .bg-additional-gray-darker .bg-additional-item hr,
.upgrade .bg-image hr, .upgrade .bg-additional-image .bg-additional-item hr,
.upgrade .bg-gradient hr, .upgrade .bg-gradient-1 hr, .upgrade .bg-gradient-2 hr, .upgrade .bg-gradient-3 hr, .upgrade .bg-primary hr, .upgrade .bg-primary-accent hr, .upgrade .bg-secondary-2 hr, .upgrade .breadcrumbs-custom-light hr {
  border-color: #fff;
  opacity: 0.5;
}
.upgrade .context-dark .divider-default, .upgrade .bg-gray-darker .divider-default, .upgrade .bg-additional-gray-darker .bg-additional-item .divider-default,
.upgrade .bg-image .divider-default, .upgrade .bg-additional-image .bg-additional-item .divider-default,
.upgrade .bg-gradient .divider-default, .upgrade .bg-gradient-1 .divider-default, .upgrade .bg-gradient-2 .divider-default, .upgrade .bg-gradient-3 .divider-default, .upgrade .bg-primary .divider-default, .upgrade .bg-primary-accent .divider-default, .upgrade .bg-secondary-2 .divider-default, .upgrade .breadcrumbs-custom-light .divider-default {
  opacity: 1;
}
.upgrade .context-dark .box-minimal-border .box-minimal-icon, .upgrade .bg-gray-darker .box-minimal-border .box-minimal-icon, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-border .box-minimal-icon,
.upgrade .bg-image .box-minimal-border .box-minimal-icon, .upgrade .bg-additional-image .bg-additional-item .box-minimal-border .box-minimal-icon,
.upgrade .bg-gradient .box-minimal-border .box-minimal-icon, .upgrade .bg-gradient-1 .box-minimal-border .box-minimal-icon, .upgrade .bg-gradient-2 .box-minimal-border .box-minimal-icon, .upgrade .bg-gradient-3 .box-minimal-border .box-minimal-icon, .upgrade .bg-primary .box-minimal-border .box-minimal-icon, .upgrade .bg-primary-accent .box-minimal-border .box-minimal-icon, .upgrade .bg-secondary-2 .box-minimal-border .box-minimal-icon, .upgrade .breadcrumbs-custom-light .box-minimal-border .box-minimal-icon {
  color: #fff;
}
.upgrade .context-dark .box-minimal-border .box-minimal-icon svg, .upgrade .bg-gray-darker .box-minimal-border .box-minimal-icon svg, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-border .box-minimal-icon svg,
.upgrade .bg-image .box-minimal-border .box-minimal-icon svg, .upgrade .bg-additional-image .bg-additional-item .box-minimal-border .box-minimal-icon svg,
.upgrade .bg-gradient .box-minimal-border .box-minimal-icon svg, .upgrade .bg-gradient-1 .box-minimal-border .box-minimal-icon svg, .upgrade .bg-gradient-2 .box-minimal-border .box-minimal-icon svg, .upgrade .bg-gradient-3 .box-minimal-border .box-minimal-icon svg, .upgrade .bg-primary .box-minimal-border .box-minimal-icon svg, .upgrade .bg-primary-accent .box-minimal-border .box-minimal-icon svg, .upgrade .bg-secondary-2 .box-minimal-border .box-minimal-icon svg, .upgrade .breadcrumbs-custom-light .box-minimal-border .box-minimal-icon svg {
  fill: #fff;
}
.upgrade .context-dark .box-minimal-border .box-minimal-title, .upgrade .bg-gray-darker .box-minimal-border .box-minimal-title, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-border .box-minimal-title,
.upgrade .bg-image .box-minimal-border .box-minimal-title, .upgrade .bg-additional-image .bg-additional-item .box-minimal-border .box-minimal-title,
.upgrade .bg-gradient .box-minimal-border .box-minimal-title, .upgrade .bg-gradient-1 .box-minimal-border .box-minimal-title, .upgrade .bg-gradient-2 .box-minimal-border .box-minimal-title, .upgrade .bg-gradient-3 .box-minimal-border .box-minimal-title, .upgrade .bg-primary .box-minimal-border .box-minimal-title, .upgrade .bg-primary-accent .box-minimal-border .box-minimal-title, .upgrade .bg-secondary-2 .box-minimal-border .box-minimal-title, .upgrade .breadcrumbs-custom-light .box-minimal-border .box-minimal-title {
  color: #fff;
}
.upgrade .context-dark .box-minimal-border:hover, .upgrade .bg-gray-darker .box-minimal-border:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-border:hover,
.upgrade .bg-image .box-minimal-border:hover, .upgrade .bg-additional-image .bg-additional-item .box-minimal-border:hover,
.upgrade .bg-gradient .box-minimal-border:hover, .upgrade .bg-gradient-1 .box-minimal-border:hover, .upgrade .bg-gradient-2 .box-minimal-border:hover, .upgrade .bg-gradient-3 .box-minimal-border:hover, .upgrade .bg-primary .box-minimal-border:hover, .upgrade .bg-primary-accent .box-minimal-border:hover, .upgrade .bg-secondary-2 .box-minimal-border:hover, .upgrade .breadcrumbs-custom-light .box-minimal-border:hover {
  background: #6d0eb1;
  border-color: #6d0eb1;
}
.upgrade .context-dark .box-minimal-modern, .upgrade .bg-gray-darker .box-minimal-modern, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-modern,
.upgrade .bg-image .box-minimal-modern, .upgrade .bg-additional-image .bg-additional-item .box-minimal-modern,
.upgrade .bg-gradient .box-minimal-modern, .upgrade .bg-gradient-1 .box-minimal-modern, .upgrade .bg-gradient-2 .box-minimal-modern, .upgrade .bg-gradient-3 .box-minimal-modern, .upgrade .bg-primary .box-minimal-modern, .upgrade .bg-primary-accent .box-minimal-modern, .upgrade .bg-secondary-2 .box-minimal-modern, .upgrade .breadcrumbs-custom-light .box-minimal-modern {
  background-color: #fff;
}
.upgrade .context-dark .box-minimal-modern .box-minimal-title, .upgrade .bg-gray-darker .box-minimal-modern .box-minimal-title, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-modern .box-minimal-title,
.upgrade .bg-image .box-minimal-modern .box-minimal-title, .upgrade .bg-additional-image .bg-additional-item .box-minimal-modern .box-minimal-title,
.upgrade .bg-gradient .box-minimal-modern .box-minimal-title, .upgrade .bg-gradient-1 .box-minimal-modern .box-minimal-title, .upgrade .bg-gradient-2 .box-minimal-modern .box-minimal-title, .upgrade .bg-gradient-3 .box-minimal-modern .box-minimal-title, .upgrade .bg-primary .box-minimal-modern .box-minimal-title, .upgrade .bg-primary-accent .box-minimal-modern .box-minimal-title, .upgrade .bg-secondary-2 .box-minimal-modern .box-minimal-title, .upgrade .breadcrumbs-custom-light .box-minimal-modern .box-minimal-title {
  color: #29293a;
}
.upgrade .context-dark .box-minimal-modern .box-minimal-text, .upgrade .bg-gray-darker .box-minimal-modern .box-minimal-text, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-modern .box-minimal-text,
.upgrade .bg-image .box-minimal-modern .box-minimal-text, .upgrade .bg-additional-image .bg-additional-item .box-minimal-modern .box-minimal-text,
.upgrade .bg-gradient .box-minimal-modern .box-minimal-text, .upgrade .bg-gradient-1 .box-minimal-modern .box-minimal-text, .upgrade .bg-gradient-2 .box-minimal-modern .box-minimal-text, .upgrade .bg-gradient-3 .box-minimal-modern .box-minimal-text, .upgrade .bg-primary .box-minimal-modern .box-minimal-text, .upgrade .bg-primary-accent .box-minimal-modern .box-minimal-text, .upgrade .bg-secondary-2 .box-minimal-modern .box-minimal-text, .upgrade .breadcrumbs-custom-light .box-minimal-modern .box-minimal-text {
  color: #838386;
}
.upgrade .context-dark .counter-wrap .icon-secondary, .upgrade .bg-gray-darker .counter-wrap .icon-secondary, .upgrade .bg-additional-gray-darker .bg-additional-item .counter-wrap .icon-secondary,
.upgrade .bg-image .counter-wrap .icon-secondary, .upgrade .bg-additional-image .bg-additional-item .counter-wrap .icon-secondary,
.upgrade .bg-gradient .counter-wrap .icon-secondary, .upgrade .bg-gradient-1 .counter-wrap .icon-secondary, .upgrade .bg-gradient-2 .counter-wrap .icon-secondary, .upgrade .bg-gradient-3 .counter-wrap .icon-secondary, .upgrade .bg-primary .counter-wrap .icon-secondary, .upgrade .bg-primary-accent .counter-wrap .icon-secondary, .upgrade .bg-secondary-2 .counter-wrap .icon-secondary, .upgrade .breadcrumbs-custom-light .counter-wrap .icon-secondary {
  color: #ffec17;
}
.upgrade .page .context-dark .icon-primary, .upgrade .page .bg-gray-darker .icon-primary, .upgrade .page .bg-additional-gray-darker .bg-additional-item .icon-primary, .upgrade .bg-additional-gray-darker .page .bg-additional-item .icon-primary, .upgrade .page .bg-image .icon-primary,
.upgrade .page .bg-additional-image .bg-additional-item .icon-primary, .upgrade .bg-additional-image .page .bg-additional-item .icon-primary, .upgrade .page .bg-gradient .icon-primary,
.upgrade .page .bg-gradient-1 .icon-primary, .upgrade .page .bg-gradient-2 .icon-primary, .upgrade .page .bg-gradient-3 .icon-primary, .upgrade .page .bg-primary .icon-primary, .upgrade .page .bg-primary-accent .icon-primary, .upgrade .page .bg-secondary-2 .icon-primary, .upgrade .page .breadcrumbs-custom-light .icon-primary {
  color: #ed1c94;
}
.upgrade .context-dark .post-blog-article, .upgrade .bg-gray-darker .post-blog-article, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article,
.upgrade .bg-image .post-blog-article, .upgrade .bg-additional-image .bg-additional-item .post-blog-article,
.upgrade .bg-gradient .post-blog-article, .upgrade .bg-gradient-1 .post-blog-article, .upgrade .bg-gradient-2 .post-blog-article, .upgrade .bg-gradient-3 .post-blog-article, .upgrade .bg-primary .post-blog-article, .upgrade .bg-primary-accent .post-blog-article, .upgrade .bg-secondary-2 .post-blog-article, .upgrade .breadcrumbs-custom-light .post-blog-article {
  color: #c2c2c3;
}
.upgrade .context-dark .post-blog-article-title, .upgrade .bg-gray-darker .post-blog-article-title, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article-title,
.upgrade .bg-image .post-blog-article-title, .upgrade .bg-additional-image .bg-additional-item .post-blog-article-title,
.upgrade .bg-gradient .post-blog-article-title, .upgrade .bg-gradient-1 .post-blog-article-title, .upgrade .bg-gradient-2 .post-blog-article-title, .upgrade .bg-gradient-3 .post-blog-article-title, .upgrade .bg-primary .post-blog-article-title, .upgrade .bg-primary-accent .post-blog-article-title, .upgrade .bg-secondary-2 .post-blog-article-title, .upgrade .breadcrumbs-custom-light .post-blog-article-title, .upgrade .context-dark .post-blog-article .big, .upgrade .bg-gray-darker .post-blog-article .big, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article .big,
.upgrade .bg-image .post-blog-article .big, .upgrade .bg-additional-image .bg-additional-item .post-blog-article .big,
.upgrade .bg-gradient .post-blog-article .big, .upgrade .bg-gradient-1 .post-blog-article .big, .upgrade .bg-gradient-2 .post-blog-article .big, .upgrade .bg-gradient-3 .post-blog-article .big, .upgrade .bg-primary .post-blog-article .big, .upgrade .bg-primary-accent .post-blog-article .big, .upgrade .bg-secondary-2 .post-blog-article .big, .upgrade .breadcrumbs-custom-light .post-blog-article .big {
  color: #fff;
}
.upgrade .context-dark .post-blog-article .box-inline a, .upgrade .bg-gray-darker .post-blog-article .box-inline a, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article .box-inline a,
.upgrade .bg-image .post-blog-article .box-inline a, .upgrade .bg-additional-image .bg-additional-item .post-blog-article .box-inline a,
.upgrade .bg-gradient .post-blog-article .box-inline a, .upgrade .bg-gradient-1 .post-blog-article .box-inline a, .upgrade .bg-gradient-2 .post-blog-article .box-inline a, .upgrade .bg-gradient-3 .post-blog-article .box-inline a, .upgrade .bg-primary .post-blog-article .box-inline a, .upgrade .bg-primary-accent .post-blog-article .box-inline a, .upgrade .bg-secondary-2 .post-blog-article .box-inline a, .upgrade .breadcrumbs-custom-light .post-blog-article .box-inline a {
  color: #c2c2c3;
}
.upgrade .context-dark .post-blog-article .box-inline a:hover, .upgrade .bg-gray-darker .post-blog-article .box-inline a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article .box-inline a:hover,
.upgrade .bg-image .post-blog-article .box-inline a:hover, .upgrade .bg-additional-image .bg-additional-item .post-blog-article .box-inline a:hover,
.upgrade .bg-gradient .post-blog-article .box-inline a:hover, .upgrade .bg-gradient-1 .post-blog-article .box-inline a:hover, .upgrade .bg-gradient-2 .post-blog-article .box-inline a:hover, .upgrade .bg-gradient-3 .post-blog-article .box-inline a:hover, .upgrade .bg-primary .post-blog-article .box-inline a:hover, .upgrade .bg-primary-accent .post-blog-article .box-inline a:hover, .upgrade .bg-secondary-2 .post-blog-article .box-inline a:hover, .upgrade .breadcrumbs-custom-light .post-blog-article .box-inline a:hover {
  color: #ffec17;
}
.upgrade .context-dark .breadcrumbs-custom-path > li.active, .upgrade .bg-gray-darker .breadcrumbs-custom-path > li.active, .upgrade .bg-additional-gray-darker .bg-additional-item .breadcrumbs-custom-path > li.active,
.upgrade .bg-image .breadcrumbs-custom-path > li.active, .upgrade .bg-additional-image .bg-additional-item .breadcrumbs-custom-path > li.active,
.upgrade .bg-gradient .breadcrumbs-custom-path > li.active, .upgrade .bg-gradient-1 .breadcrumbs-custom-path > li.active, .upgrade .bg-gradient-2 .breadcrumbs-custom-path > li.active, .upgrade .bg-gradient-3 .breadcrumbs-custom-path > li.active, .upgrade .bg-primary .breadcrumbs-custom-path > li.active, .upgrade .bg-primary-accent .breadcrumbs-custom-path > li.active, .upgrade .bg-secondary-2 .breadcrumbs-custom-path > li.active, .upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path > li.active {
  color: #fff;
}
.upgrade .context-dark .breadcrumbs-custom-path > li a, .upgrade .bg-gray-darker .breadcrumbs-custom-path > li a, .upgrade .bg-additional-gray-darker .bg-additional-item .breadcrumbs-custom-path > li a,
.upgrade .bg-image .breadcrumbs-custom-path > li a, .upgrade .bg-additional-image .bg-additional-item .breadcrumbs-custom-path > li a,
.upgrade .bg-gradient .breadcrumbs-custom-path > li a, .upgrade .bg-gradient-1 .breadcrumbs-custom-path > li a, .upgrade .bg-gradient-2 .breadcrumbs-custom-path > li a, .upgrade .bg-gradient-3 .breadcrumbs-custom-path > li a, .upgrade .bg-primary .breadcrumbs-custom-path > li a, .upgrade .bg-primary-accent .breadcrumbs-custom-path > li a, .upgrade .bg-secondary-2 .breadcrumbs-custom-path > li a, .upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path > li a {
  color: #fff;
}
.upgrade .context-dark .breadcrumbs-custom-path > li a:hover, .upgrade .bg-gray-darker .breadcrumbs-custom-path > li a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .breadcrumbs-custom-path > li a:hover,
.upgrade .bg-image .breadcrumbs-custom-path > li a:hover, .upgrade .bg-additional-image .bg-additional-item .breadcrumbs-custom-path > li a:hover,
.upgrade .bg-gradient .breadcrumbs-custom-path > li a:hover, .upgrade .bg-gradient-1 .breadcrumbs-custom-path > li a:hover, .upgrade .bg-gradient-2 .breadcrumbs-custom-path > li a:hover, .upgrade .bg-gradient-3 .breadcrumbs-custom-path > li a:hover, .upgrade .bg-primary .breadcrumbs-custom-path > li a:hover, .upgrade .bg-primary-accent .breadcrumbs-custom-path > li a:hover, .upgrade .bg-secondary-2 .breadcrumbs-custom-path > li a:hover, .upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path > li a:hover {
  color: #ffec17;
}
.upgrade .context-dark .parallax-text, .upgrade .bg-gray-darker .parallax-text, .upgrade .bg-additional-gray-darker .bg-additional-item .parallax-text,
.upgrade .bg-image .parallax-text, .upgrade .bg-additional-image .bg-additional-item .parallax-text,
.upgrade .bg-gradient .parallax-text, .upgrade .bg-gradient-1 .parallax-text, .upgrade .bg-gradient-2 .parallax-text, .upgrade .bg-gradient-3 .parallax-text, .upgrade .bg-primary .parallax-text, .upgrade .bg-primary-accent .parallax-text, .upgrade .bg-secondary-2 .parallax-text, .upgrade .breadcrumbs-custom-light .parallax-text {
  opacity: 0.1;
}
@media (min-width: 992px) {
  .upgrade .context-dark .isotope-filters-list > li > a, .upgrade .bg-gray-darker .isotope-filters-list > li > a, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list > li > a,
.upgrade .bg-image .isotope-filters-list > li > a, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-list > li > a,
.upgrade .bg-gradient .isotope-filters-list > li > a, .upgrade .bg-gradient-1 .isotope-filters-list > li > a, .upgrade .bg-gradient-2 .isotope-filters-list > li > a, .upgrade .bg-gradient-3 .isotope-filters-list > li > a, .upgrade .bg-primary .isotope-filters-list > li > a, .upgrade .bg-primary-accent .isotope-filters-list > li > a, .upgrade .bg-secondary-2 .isotope-filters-list > li > a, .upgrade .breadcrumbs-custom-light .isotope-filters-list > li > a {
    color: #c2c2c3;
  }
  .upgrade .context-dark .isotope-filters-list > li > a.active, .upgrade .bg-gray-darker .isotope-filters-list > li > a.active, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list > li > a.active,
.upgrade .bg-image .isotope-filters-list > li > a.active, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-list > li > a.active,
.upgrade .bg-gradient .isotope-filters-list > li > a.active, .upgrade .bg-gradient-1 .isotope-filters-list > li > a.active, .upgrade .bg-gradient-2 .isotope-filters-list > li > a.active, .upgrade .bg-gradient-3 .isotope-filters-list > li > a.active, .upgrade .bg-primary .isotope-filters-list > li > a.active, .upgrade .bg-primary-accent .isotope-filters-list > li > a.active, .upgrade .bg-secondary-2 .isotope-filters-list > li > a.active, .upgrade .breadcrumbs-custom-light .isotope-filters-list > li > a.active, .upgrade .context-dark .isotope-filters-list > li > a:hover, .upgrade .bg-gray-darker .isotope-filters-list > li > a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list > li > a:hover,
.upgrade .bg-image .isotope-filters-list > li > a:hover, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-list > li > a:hover,
.upgrade .bg-gradient .isotope-filters-list > li > a:hover, .upgrade .bg-gradient-1 .isotope-filters-list > li > a:hover, .upgrade .bg-gradient-2 .isotope-filters-list > li > a:hover, .upgrade .bg-gradient-3 .isotope-filters-list > li > a:hover, .upgrade .bg-primary .isotope-filters-list > li > a:hover, .upgrade .bg-primary-accent .isotope-filters-list > li > a:hover, .upgrade .bg-secondary-2 .isotope-filters-list > li > a:hover, .upgrade .breadcrumbs-custom-light .isotope-filters-list > li > a:hover {
    color: #ffec17;
  }
}
@media (min-width: 992px) {
  .upgrade .context-dark .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gray-darker .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons),
.upgrade .bg-image .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-additional-image .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons),
.upgrade .bg-gradient .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gradient-1 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gradient-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gradient-3 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-primary .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-primary-accent .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-secondary-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .breadcrumbs-custom-light .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .context-dark .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gray-darker .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons),
.upgrade .bg-image .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-additional-image .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons),
.upgrade .bg-gradient .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gradient-1 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gradient-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-gradient-3 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-primary .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-primary-accent .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .bg-secondary-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons), .upgrade .breadcrumbs-custom-light .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) {
    border-bottom: 1px solid rgba(242, 243, 249, 0.1);
  }
  .upgrade .context-dark .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gray-darker .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a,
.upgrade .bg-image .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a,
.upgrade .bg-gradient .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gradient-1 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gradient-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gradient-3 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-primary .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-primary-accent .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-secondary-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .breadcrumbs-custom-light .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .context-dark .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gray-darker .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a,
.upgrade .bg-image .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a,
.upgrade .bg-gradient .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gradient-1 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gradient-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-gradient-3 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-primary .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-primary-accent .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .bg-secondary-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a, .upgrade .breadcrumbs-custom-light .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a {
    color: #c2c2c3;
  }
  .upgrade .context-dark .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gray-darker .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after,
.upgrade .bg-image .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after,
.upgrade .bg-gradient .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gradient-1 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gradient-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gradient-3 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-primary .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-primary-accent .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-secondary-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .breadcrumbs-custom-light .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .context-dark .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gray-darker .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after,
.upgrade .bg-image .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after,
.upgrade .bg-gradient .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gradient-1 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gradient-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-gradient-3 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-primary .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-primary-accent .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .bg-secondary-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after, .upgrade .breadcrumbs-custom-light .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:after {
    background: #ffec17;
  }
  .upgrade .context-dark .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gray-darker .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active,
.upgrade .bg-image .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active,
.upgrade .bg-gradient .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gradient-1 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gradient-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gradient-3 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-primary .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-primary-accent .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-secondary-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .breadcrumbs-custom-light .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .context-dark .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gray-darker .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover,
.upgrade .bg-image .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover,
.upgrade .bg-gradient .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gradient-1 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gradient-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gradient-3 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-primary .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-primary-accent .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-secondary-2 .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .breadcrumbs-custom-light .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .context-dark .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gray-darker .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active,
.upgrade .bg-image .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active,
.upgrade .bg-gradient .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gradient-1 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gradient-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-gradient-3 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-primary .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-primary-accent .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .bg-secondary-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .breadcrumbs-custom-light .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a.active, .upgrade .context-dark .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gray-darker .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover,
.upgrade .bg-image .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-additional-image .bg-additional-item .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover,
.upgrade .bg-gradient .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gradient-1 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gradient-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-gradient-3 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-primary .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-primary-accent .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .bg-secondary-2 .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover, .upgrade .breadcrumbs-custom-light .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list:not(.isotope-filters-list-buttons) a:hover {
    color: #ffec17;
  }
}
@media (min-width: 1200px) {
  .upgrade .context-dark .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-gray-darker .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list,
.upgrade .bg-image .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-additional-image .bg-additional-item .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list,
.upgrade .bg-gradient .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-gradient-1 .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-gradient-2 .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-gradient-3 .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-primary .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-primary-accent .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .bg-secondary-2 .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .breadcrumbs-custom-light .isotope-wrap .isotope-filters.isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list {
    border-bottom: none;
    border-right: 1px solid rgba(242, 243, 249, 0.1);
  }
}
.upgrade .context-dark .post-blog-article-meta, .upgrade .bg-gray-darker .post-blog-article-meta, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article-meta,
.upgrade .bg-image .post-blog-article-meta, .upgrade .bg-additional-image .bg-additional-item .post-blog-article-meta,
.upgrade .bg-gradient .post-blog-article-meta, .upgrade .bg-gradient-1 .post-blog-article-meta, .upgrade .bg-gradient-2 .post-blog-article-meta, .upgrade .bg-gradient-3 .post-blog-article-meta, .upgrade .bg-primary .post-blog-article-meta, .upgrade .bg-primary-accent .post-blog-article-meta, .upgrade .bg-secondary-2 .post-blog-article-meta, .upgrade .breadcrumbs-custom-light .post-blog-article-meta {
  color: #c2c2c3;
}
.upgrade .context-dark .post-blog-article-meta .box-inline a, .upgrade .bg-gray-darker .post-blog-article-meta .box-inline a, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article-meta .box-inline a,
.upgrade .bg-image .post-blog-article-meta .box-inline a, .upgrade .bg-additional-image .bg-additional-item .post-blog-article-meta .box-inline a,
.upgrade .bg-gradient .post-blog-article-meta .box-inline a, .upgrade .bg-gradient-1 .post-blog-article-meta .box-inline a, .upgrade .bg-gradient-2 .post-blog-article-meta .box-inline a, .upgrade .bg-gradient-3 .post-blog-article-meta .box-inline a, .upgrade .bg-primary .post-blog-article-meta .box-inline a, .upgrade .bg-primary-accent .post-blog-article-meta .box-inline a, .upgrade .bg-secondary-2 .post-blog-article-meta .box-inline a, .upgrade .breadcrumbs-custom-light .post-blog-article-meta .box-inline a {
  color: #c2c2c3;
}
.upgrade .context-dark .post-blog-article-meta .box-inline a:hover, .upgrade .bg-gray-darker .post-blog-article-meta .box-inline a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-article-meta .box-inline a:hover,
.upgrade .bg-image .post-blog-article-meta .box-inline a:hover, .upgrade .bg-additional-image .bg-additional-item .post-blog-article-meta .box-inline a:hover,
.upgrade .bg-gradient .post-blog-article-meta .box-inline a:hover, .upgrade .bg-gradient-1 .post-blog-article-meta .box-inline a:hover, .upgrade .bg-gradient-2 .post-blog-article-meta .box-inline a:hover, .upgrade .bg-gradient-3 .post-blog-article-meta .box-inline a:hover, .upgrade .bg-primary .post-blog-article-meta .box-inline a:hover, .upgrade .bg-primary-accent .post-blog-article-meta .box-inline a:hover, .upgrade .bg-secondary-2 .post-blog-article-meta .box-inline a:hover, .upgrade .breadcrumbs-custom-light .post-blog-article-meta .box-inline a:hover {
  color: #ed1c94;
}
.upgrade .context-dark .post-blog-large-text, .upgrade .bg-gray-darker .post-blog-large-text, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog-large-text,
.upgrade .bg-image .post-blog-large-text, .upgrade .bg-additional-image .bg-additional-item .post-blog-large-text,
.upgrade .bg-gradient .post-blog-large-text, .upgrade .bg-gradient-1 .post-blog-large-text, .upgrade .bg-gradient-2 .post-blog-large-text, .upgrade .bg-gradient-3 .post-blog-large-text, .upgrade .bg-primary .post-blog-large-text, .upgrade .bg-primary-accent .post-blog-large-text, .upgrade .bg-secondary-2 .post-blog-large-text, .upgrade .breadcrumbs-custom-light .post-blog-large-text {
  color: #29293a;
}
@media (max-width: 767px) {
  .upgrade .context-dark .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-gray-darker .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-additional-gray-darker .bg-additional-item .tabs-custom.tabs-line .nav-tabs,
.upgrade .bg-image .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-additional-image .bg-additional-item .tabs-custom.tabs-line .nav-tabs,
.upgrade .bg-gradient .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-gradient-1 .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-gradient-2 .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-gradient-3 .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-primary .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-primary-accent .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-secondary-2 .tabs-custom.tabs-line .nav-tabs, .upgrade .breadcrumbs-custom-light .tabs-custom.tabs-line .nav-tabs {
    border-color: #29293a;
  }
}
.upgrade .context-dark .time_circles > div > h4, .upgrade .bg-gray-darker .time_circles > div > h4, .upgrade .bg-additional-gray-darker .bg-additional-item .time_circles > div > h4,
.upgrade .bg-image .time_circles > div > h4, .upgrade .bg-additional-image .bg-additional-item .time_circles > div > h4,
.upgrade .bg-gradient .time_circles > div > h4, .upgrade .bg-gradient-1 .time_circles > div > h4, .upgrade .bg-gradient-2 .time_circles > div > h4, .upgrade .bg-gradient-3 .time_circles > div > h4, .upgrade .bg-primary .time_circles > div > h4, .upgrade .bg-primary-accent .time_circles > div > h4, .upgrade .bg-secondary-2 .time_circles > div > h4, .upgrade .breadcrumbs-custom-light .time_circles > div > h4, .upgrade .context-dark .time_circles > div > span, .upgrade .bg-gray-darker .time_circles > div > span, .upgrade .bg-additional-gray-darker .bg-additional-item .time_circles > div > span,
.upgrade .bg-image .time_circles > div > span, .upgrade .bg-additional-image .bg-additional-item .time_circles > div > span,
.upgrade .bg-gradient .time_circles > div > span, .upgrade .bg-gradient-1 .time_circles > div > span, .upgrade .bg-gradient-2 .time_circles > div > span, .upgrade .bg-gradient-3 .time_circles > div > span, .upgrade .bg-primary .time_circles > div > span, .upgrade .bg-primary-accent .time_circles > div > span, .upgrade .bg-secondary-2 .time_circles > div > span, .upgrade .breadcrumbs-custom-light .time_circles > div > span {
  color: #fff;
}
.upgrade .context-dark .titled-gallery-caption a, .upgrade .bg-gray-darker .titled-gallery-caption a, .upgrade .bg-additional-gray-darker .bg-additional-item .titled-gallery-caption a,
.upgrade .bg-image .titled-gallery-caption a, .upgrade .bg-additional-image .bg-additional-item .titled-gallery-caption a,
.upgrade .bg-gradient .titled-gallery-caption a, .upgrade .bg-gradient-1 .titled-gallery-caption a, .upgrade .bg-gradient-2 .titled-gallery-caption a, .upgrade .bg-gradient-3 .titled-gallery-caption a, .upgrade .bg-primary .titled-gallery-caption a, .upgrade .bg-primary-accent .titled-gallery-caption a, .upgrade .bg-secondary-2 .titled-gallery-caption a, .upgrade .breadcrumbs-custom-light .titled-gallery-caption a {
  color: #ffec17;
}
.upgrade .context-dark .titled-gallery-caption a:hover, .upgrade .bg-gray-darker .titled-gallery-caption a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .titled-gallery-caption a:hover,
.upgrade .bg-image .titled-gallery-caption a:hover, .upgrade .bg-additional-image .bg-additional-item .titled-gallery-caption a:hover,
.upgrade .bg-gradient .titled-gallery-caption a:hover, .upgrade .bg-gradient-1 .titled-gallery-caption a:hover, .upgrade .bg-gradient-2 .titled-gallery-caption a:hover, .upgrade .bg-gradient-3 .titled-gallery-caption a:hover, .upgrade .bg-primary .titled-gallery-caption a:hover, .upgrade .bg-primary-accent .titled-gallery-caption a:hover, .upgrade .bg-secondary-2 .titled-gallery-caption a:hover, .upgrade .breadcrumbs-custom-light .titled-gallery-caption a:hover {
  color: #fff;
}
.upgrade .context-light {
  color: #838386;
}
.upgrade .context-light h1, .upgrade .context-light h2, .upgrade .context-light h3, .upgrade .context-light h4, .upgrade .context-light h5, .upgrade .context-light h6, .upgrade .context-light [class^=heading-] {
  color: #29293a;
}
.upgrade .element-boxed .bg-white,
.upgrade .element-boxed .bg-gray-lighter {
  color: #838386;
}
.upgrade .element-boxed .bg-white h1, .upgrade .element-boxed .bg-white h2, .upgrade .element-boxed .bg-white h3, .upgrade .element-boxed .bg-white h4, .upgrade .element-boxed .bg-white h5, .upgrade .element-boxed .bg-white h6, .upgrade .element-boxed .bg-white [class^=heading-],
.upgrade .element-boxed .bg-gray-lighter h1,
.upgrade .element-boxed .bg-gray-lighter h2,
.upgrade .element-boxed .bg-gray-lighter h3,
.upgrade .element-boxed .bg-gray-lighter h4,
.upgrade .element-boxed .bg-gray-lighter h5,
.upgrade .element-boxed .bg-gray-lighter h6,
.upgrade .element-boxed .bg-gray-lighter [class^=heading-] {
  color: #29293a;
}
.upgrade .element-boxed.bg-gray-darker, .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item,
.upgrade .element-boxed.bg-gray-darker h1, .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item h1, .upgrade .element-boxed.bg-gray-darker h2, .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item h2, .upgrade .element-boxed.bg-gray-darker h3, .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item h3, .upgrade .element-boxed.bg-gray-darker h4, .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item h4, .upgrade .element-boxed.bg-gray-darker h5, .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item h5, .upgrade .element-boxed.bg-gray-darker h6, .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item h6, .upgrade .element-boxed.bg-gray-darker [class^=heading-], .upgrade .bg-additional-gray-darker .element-boxed.bg-additional-item [class^=heading-] {
  color: #fff;
}
.upgrade .bg-gray-lighter .post-blog .post-blog-caption,
.upgrade .bg-gray-darker .post-blog .post-blog-caption, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog .post-blog-caption {
  background-color: #fff;
}
.upgrade .bg-gray-lighter .post-blog .post-blog-caption-footer,
.upgrade .bg-gray-darker .post-blog .post-blog-caption-footer, .upgrade .bg-additional-gray-darker .bg-additional-item .post-blog .post-blog-caption-footer {
  border-color: #f2f3f9;
}
.upgrade .bg-white {
  background-color: #fff;
  fill: #fff;
}
.upgrade .bg-white .way-point > svg {
  fill: inherit;
}
.upgrade .bg-white + .bg-white {
  padding-top: 0;
}
.upgrade .bg-white .bg-decoration > svg {
  fill: #fff;
}
.upgrade .bg-white .time-line-vertical-element:before,
.upgrade .bg-white .time-line-vertical-element .unit-body:before {
  background-color: #fff;
}
.upgrade .bg-white .twitter-feed {
  background-color: #f2f3f9;
}
.upgrade .bg-white .slider-widget {
  background: #f2f3f9;
}
.upgrade .bg-gray-lighter {
  background-color: #f2f3f9;
  fill: #f2f3f9;
}
.upgrade .bg-gray-lighter .way-point > svg {
  fill: inherit;
}
.upgrade .bg-gray-lighter + .bg-gray-lighter {
  padding-top: 0;
}
.upgrade .bg-gray-lighter .bg-decoration > svg {
  fill: #f2f3f9;
}
.upgrade .bg-gray-lighter .parallax-text {
  color: #fff;
}
.upgrade .bg-gray-lighter .time-line-vertical-element:before,
.upgrade .bg-gray-lighter .time-line-vertical-element .unit-body:before {
  background-color: #f2f3f9;
}
.upgrade .bg-gray-lighter .pricing-box {
  background-color: #fff;
}
.upgrade .bg-gray-lighter .slider-widget {
  background: #fff;
}
.upgrade .bg-gray-lighter .post-classic {
  background: #fff;
}
.upgrade .bg-gray-lighter .post-modern-body {
  background: #fff;
}
.upgrade .bg-gray-lighter .panel-light:first-child .panel-title {
  border-top-color: #c2c2c3;
}
.upgrade .bg-gray-lighter .panel-light .panel-title a.collapsed {
  border-bottom-color: #c2c2c3;
}
.upgrade .bg-gray-lighter .panel-light .panel-collapse {
  color: #838386;
  border-bottom-color: #c2c2c3;
}
.upgrade .bg-gray-lighter .post-facebook {
  background: #fff;
}
.upgrade .bg-gray-lighter .rd-mailform-inline .rd-mailform-inline-inner::before,
.upgrade .bg-gray-lighter .form-input {
  background-color: #fff;
}
.upgrade .bg-gray-lighter + .bg-gray-lighter.page-footer-default {
  background-color: #fff;
}
.upgrade .bg-gray-lighter + .bg-gray-lighter.page-footer-default .rd-mailform-inline .rd-mailform-inline-inner::before,
.upgrade .bg-gray-lighter + .bg-gray-lighter.page-footer-default .form-input {
  background-color: #f2f3f9;
}
.upgrade .bg-gray-lighter .box-minimal-border:hover {
  background: #fff;
  border-color: #fff;
}
.upgrade .bg-gray-lighter .box-minimal-modern {
  background-color: #fff;
}
.upgrade .bg-gray-lighter .breadcrumbs-custom-path a, .upgrade .bg-gray-lighter .breadcrumbs-custom-path a:active, .upgrade .bg-gray-lighter .breadcrumbs-custom-path a:focus {
  color: #981df0;
}
.upgrade .bg-gray-lighter .breadcrumbs-custom-path a:hover {
  color: #ed1c94;
}
.upgrade .bg-gray-lighter .breadcrumbs-custom-path li.active {
  color: #981df0;
}
.upgrade .bg-gray-darker .pricing-box, .upgrade .bg-additional-gray-darker .bg-additional-item .pricing-box,
.upgrade .bg-image .pricing-box, .upgrade .bg-additional-image .bg-additional-item .pricing-box,
.upgrade .bg-gradient .pricing-box, .upgrade .bg-gradient-1 .pricing-box, .upgrade .bg-gradient-2 .pricing-box, .upgrade .bg-gradient-3 .pricing-box {
  background-color: #fff;
}
.upgrade .bg-gray-darker .list-comma, .upgrade .bg-additional-gray-darker .bg-additional-item .list-comma,
.upgrade .bg-image .list-comma, .upgrade .bg-additional-image .bg-additional-item .list-comma,
.upgrade .bg-gradient .list-comma, .upgrade .bg-gradient-1 .list-comma, .upgrade .bg-gradient-2 .list-comma, .upgrade .bg-gradient-3 .list-comma {
  color: #fff;
}
.upgrade .bg-gray-darker .list-comma li:after, .upgrade .bg-additional-gray-darker .bg-additional-item .list-comma li:after,
.upgrade .bg-image .list-comma li:after, .upgrade .bg-additional-image .bg-additional-item .list-comma li:after,
.upgrade .bg-gradient .list-comma li:after, .upgrade .bg-gradient-1 .list-comma li:after, .upgrade .bg-gradient-2 .list-comma li:after, .upgrade .bg-gradient-3 .list-comma li:after {
  color: inherit;
}
.upgrade .bg-gray-darker .panel-light:first-child .panel-title, .upgrade .bg-additional-gray-darker .bg-additional-item .panel-light:first-child .panel-title,
.upgrade .bg-image .panel-light:first-child .panel-title, .upgrade .bg-additional-image .bg-additional-item .panel-light:first-child .panel-title,
.upgrade .bg-gradient .panel-light:first-child .panel-title, .upgrade .bg-gradient-1 .panel-light:first-child .panel-title, .upgrade .bg-gradient-2 .panel-light:first-child .panel-title, .upgrade .bg-gradient-3 .panel-light:first-child .panel-title {
  border-top-color: #555555;
}
.upgrade .bg-gray-darker .panel-light .panel-title a, .upgrade .bg-additional-gray-darker .bg-additional-item .panel-light .panel-title a,
.upgrade .bg-image .panel-light .panel-title a, .upgrade .bg-additional-image .bg-additional-item .panel-light .panel-title a,
.upgrade .bg-gradient .panel-light .panel-title a, .upgrade .bg-gradient-1 .panel-light .panel-title a, .upgrade .bg-gradient-2 .panel-light .panel-title a, .upgrade .bg-gradient-3 .panel-light .panel-title a {
  color: #fff;
}
.upgrade .bg-gray-darker .panel-light .panel-title a.collapsed, .upgrade .bg-additional-gray-darker .bg-additional-item .panel-light .panel-title a.collapsed,
.upgrade .bg-image .panel-light .panel-title a.collapsed, .upgrade .bg-additional-image .bg-additional-item .panel-light .panel-title a.collapsed,
.upgrade .bg-gradient .panel-light .panel-title a.collapsed, .upgrade .bg-gradient-1 .panel-light .panel-title a.collapsed, .upgrade .bg-gradient-2 .panel-light .panel-title a.collapsed, .upgrade .bg-gradient-3 .panel-light .panel-title a.collapsed {
  border-bottom-color: #555555;
}
.upgrade .bg-gray-darker .panel-light .panel-collapse, .upgrade .bg-additional-gray-darker .bg-additional-item .panel-light .panel-collapse,
.upgrade .bg-image .panel-light .panel-collapse, .upgrade .bg-additional-image .bg-additional-item .panel-light .panel-collapse,
.upgrade .bg-gradient .panel-light .panel-collapse, .upgrade .bg-gradient-1 .panel-light .panel-collapse, .upgrade .bg-gradient-2 .panel-light .panel-collapse, .upgrade .bg-gradient-3 .panel-light .panel-collapse {
  color: #c2c2c3;
  border-bottom-color: #555555;
}
.upgrade .bg-gray-darker .list-twitter a, .upgrade .bg-additional-gray-darker .bg-additional-item .list-twitter a,
.upgrade .bg-image .list-twitter a, .upgrade .bg-additional-image .bg-additional-item .list-twitter a,
.upgrade .bg-gradient .list-twitter a, .upgrade .bg-gradient-1 .list-twitter a, .upgrade .bg-gradient-2 .list-twitter a, .upgrade .bg-gradient-3 .list-twitter a {
  color: #ffec17;
}
.upgrade .bg-gray-darker .list-twitter a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .list-twitter a:hover,
.upgrade .bg-image .list-twitter a:hover, .upgrade .bg-additional-image .bg-additional-item .list-twitter a:hover,
.upgrade .bg-gradient .list-twitter a:hover, .upgrade .bg-gradient-1 .list-twitter a:hover, .upgrade .bg-gradient-2 .list-twitter a:hover, .upgrade .bg-gradient-3 .list-twitter a:hover {
  color: #ed1c94;
}
.upgrade .bg-gray-darker .tabs-custom.tabs-line .icon-primary, .upgrade .bg-additional-gray-darker .bg-additional-item .tabs-custom.tabs-line .icon-primary,
.upgrade .bg-image .tabs-custom.tabs-line .icon-primary, .upgrade .bg-additional-image .bg-additional-item .tabs-custom.tabs-line .icon-primary,
.upgrade .bg-gradient .tabs-custom.tabs-line .icon-primary, .upgrade .bg-gradient-1 .tabs-custom.tabs-line .icon-primary, .upgrade .bg-gradient-2 .tabs-custom.tabs-line .icon-primary, .upgrade .bg-gradient-3 .tabs-custom.tabs-line .icon-primary {
  color: #ed1c94;
}
@media (min-width: 768px) {
  .upgrade .bg-gray-darker .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-additional-gray-darker .bg-additional-item .tabs-custom.tabs-line .nav-tabs,
.upgrade .bg-image .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-additional-image .bg-additional-item .tabs-custom.tabs-line .nav-tabs,
.upgrade .bg-gradient .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-gradient-1 .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-gradient-2 .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-gradient-3 .tabs-custom.tabs-line .nav-tabs {
    border-color: #c2c2c3;
  }
  .upgrade .bg-gray-darker .tabs-custom.tabs-line .nav-tabs li a, .upgrade .bg-additional-gray-darker .bg-additional-item .tabs-custom.tabs-line .nav-tabs li a,
.upgrade .bg-image .tabs-custom.tabs-line .nav-tabs li a, .upgrade .bg-additional-image .bg-additional-item .tabs-custom.tabs-line .nav-tabs li a,
.upgrade .bg-gradient .tabs-custom.tabs-line .nav-tabs li a, .upgrade .bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li a, .upgrade .bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li a, .upgrade .bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li a {
    color: #c2c2c3;
  }
  .upgrade .bg-gray-darker .tabs-custom.tabs-line .nav-tabs li a:after, .upgrade .bg-additional-gray-darker .bg-additional-item .tabs-custom.tabs-line .nav-tabs li a:after,
.upgrade .bg-image .tabs-custom.tabs-line .nav-tabs li a:after, .upgrade .bg-additional-image .bg-additional-item .tabs-custom.tabs-line .nav-tabs li a:after,
.upgrade .bg-gradient .tabs-custom.tabs-line .nav-tabs li a:after, .upgrade .bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li a:after, .upgrade .bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li a:after, .upgrade .bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li a:after {
    background-color: #ffec17;
  }
  .upgrade .bg-gray-darker .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-additional-gray-darker .bg-additional-item .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-gray-darker .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .tabs-custom.tabs-line .nav-tabs li a:hover,
.upgrade .bg-image .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-additional-image .bg-additional-item .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-image .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .bg-additional-image .bg-additional-item .tabs-custom.tabs-line .nav-tabs li a:hover,
.upgrade .bg-gradient .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-gradient .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li a:hover {
    color: #ffec17;
  }
}
.upgrade .bg-gray-darker .box-inline a, .upgrade .bg-additional-gray-darker .bg-additional-item .box-inline a,
.upgrade .bg-image .box-inline a, .upgrade .bg-additional-image .bg-additional-item .box-inline a,
.upgrade .bg-gradient .box-inline a, .upgrade .bg-gradient-1 .box-inline a, .upgrade .bg-gradient-2 .box-inline a, .upgrade .bg-gradient-3 .box-inline a {
  color: #fff;
}
.upgrade .bg-gray-darker .box-inline a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .box-inline a:hover,
.upgrade .bg-image .box-inline a:hover, .upgrade .bg-additional-image .bg-additional-item .box-inline a:hover,
.upgrade .bg-gradient .box-inline a:hover, .upgrade .bg-gradient-1 .box-inline a:hover, .upgrade .bg-gradient-2 .box-inline a:hover, .upgrade .bg-gradient-3 .box-inline a:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker .radio .radio-custom-dummy, .upgrade .bg-additional-gray-darker .bg-additional-item .radio .radio-custom-dummy,
.upgrade .bg-gray-darker .radio-inline .radio-custom-dummy, .upgrade .bg-additional-gray-darker .bg-additional-item .radio-inline .radio-custom-dummy,
.upgrade .bg-image .radio .radio-custom-dummy, .upgrade .bg-additional-image .bg-additional-item .radio .radio-custom-dummy,
.upgrade .bg-image .radio-inline .radio-custom-dummy, .upgrade .bg-additional-image .bg-additional-item .radio-inline .radio-custom-dummy,
.upgrade .bg-gradient .radio .radio-custom-dummy, .upgrade .bg-gradient-1 .radio .radio-custom-dummy, .upgrade .bg-gradient-2 .radio .radio-custom-dummy, .upgrade .bg-gradient-3 .radio .radio-custom-dummy,
.upgrade .bg-gradient .radio-inline .radio-custom-dummy, .upgrade .bg-gradient-1 .radio-inline .radio-custom-dummy, .upgrade .bg-gradient-2 .radio-inline .radio-custom-dummy, .upgrade .bg-gradient-3 .radio-inline .radio-custom-dummy {
  border-color: #fff;
}
.upgrade .bg-gray-darker .radio .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-additional-gray-darker .bg-additional-item .radio .radio-custom:checked + .radio-custom-dummy,
.upgrade .bg-gray-darker .radio-inline .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-additional-gray-darker .bg-additional-item .radio-inline .radio-custom:checked + .radio-custom-dummy,
.upgrade .bg-image .radio .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-additional-image .bg-additional-item .radio .radio-custom:checked + .radio-custom-dummy,
.upgrade .bg-image .radio-inline .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-additional-image .bg-additional-item .radio-inline .radio-custom:checked + .radio-custom-dummy,
.upgrade .bg-gradient .radio .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-gradient-1 .radio .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-gradient-2 .radio .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-gradient-3 .radio .radio-custom:checked + .radio-custom-dummy,
.upgrade .bg-gradient .radio-inline .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-gradient-1 .radio-inline .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-gradient-2 .radio-inline .radio-custom:checked + .radio-custom-dummy, .upgrade .bg-gradient-3 .radio-inline .radio-custom:checked + .radio-custom-dummy {
  border-color: #981df0;
}
.upgrade .bg-gray-darker .divider-thin, .upgrade .bg-additional-gray-darker .bg-additional-item .divider-thin,
.upgrade .bg-image .divider-thin, .upgrade .bg-additional-image .bg-additional-item .divider-thin,
.upgrade .bg-gradient .divider-thin, .upgrade .bg-gradient-1 .divider-thin, .upgrade .bg-gradient-2 .divider-thin, .upgrade .bg-gradient-3 .divider-thin {
  border-color: #d2d5e4;
}
.upgrade .bg-gray-darker .twitter-feed-minimal-content, .upgrade .bg-additional-gray-darker .bg-additional-item .twitter-feed-minimal-content,
.upgrade .bg-image .twitter-feed-minimal-content, .upgrade .bg-additional-image .bg-additional-item .twitter-feed-minimal-content,
.upgrade .bg-gradient .twitter-feed-minimal-content, .upgrade .bg-gradient-1 .twitter-feed-minimal-content, .upgrade .bg-gradient-2 .twitter-feed-minimal-content, .upgrade .bg-gradient-3 .twitter-feed-minimal-content {
  color: #fff;
}
.upgrade .bg-gray-darker .twitter-feed-minimal-content a, .upgrade .bg-additional-gray-darker .bg-additional-item .twitter-feed-minimal-content a, .upgrade .bg-gray-darker .twitter-feed-minimal-content a:focus, .upgrade .bg-additional-gray-darker .bg-additional-item .twitter-feed-minimal-content a:focus, .upgrade .bg-gray-darker .twitter-feed-minimal-content a:active, .upgrade .bg-additional-gray-darker .bg-additional-item .twitter-feed-minimal-content a:active,
.upgrade .bg-image .twitter-feed-minimal-content a, .upgrade .bg-additional-image .bg-additional-item .twitter-feed-minimal-content a,
.upgrade .bg-image .twitter-feed-minimal-content a:focus, .upgrade .bg-additional-image .bg-additional-item .twitter-feed-minimal-content a:focus,
.upgrade .bg-image .twitter-feed-minimal-content a:active, .upgrade .bg-additional-image .bg-additional-item .twitter-feed-minimal-content a:active,
.upgrade .bg-gradient .twitter-feed-minimal-content a, .upgrade .bg-gradient-1 .twitter-feed-minimal-content a, .upgrade .bg-gradient-2 .twitter-feed-minimal-content a, .upgrade .bg-gradient-3 .twitter-feed-minimal-content a,
.upgrade .bg-gradient .twitter-feed-minimal-content a:focus, .upgrade .bg-gradient-1 .twitter-feed-minimal-content a:focus, .upgrade .bg-gradient-2 .twitter-feed-minimal-content a:focus, .upgrade .bg-gradient-3 .twitter-feed-minimal-content a:focus,
.upgrade .bg-gradient .twitter-feed-minimal-content a:active, .upgrade .bg-gradient-1 .twitter-feed-minimal-content a:active, .upgrade .bg-gradient-2 .twitter-feed-minimal-content a:active, .upgrade .bg-gradient-3 .twitter-feed-minimal-content a:active {
  color: #ffec17;
}
.upgrade .bg-gray-darker .twitter-feed-minimal-content a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .twitter-feed-minimal-content a:hover,
.upgrade .bg-image .twitter-feed-minimal-content a:hover, .upgrade .bg-additional-image .bg-additional-item .twitter-feed-minimal-content a:hover,
.upgrade .bg-gradient .twitter-feed-minimal-content a:hover, .upgrade .bg-gradient-1 .twitter-feed-minimal-content a:hover, .upgrade .bg-gradient-2 .twitter-feed-minimal-content a:hover, .upgrade .bg-gradient-3 .twitter-feed-minimal-content a:hover {
  color: #981df0;
}
.upgrade .bg-gray-darker .page-footer-inline-list a, .upgrade .bg-additional-gray-darker .bg-additional-item .page-footer-inline-list a, .upgrade .bg-gray-darker .page-footer-inline-list a:focus, .upgrade .bg-additional-gray-darker .bg-additional-item .page-footer-inline-list a:focus, .upgrade .bg-gray-darker .page-footer-inline-list a:active, .upgrade .bg-additional-gray-darker .bg-additional-item .page-footer-inline-list a:active,
.upgrade .bg-gray-darker .list-darker a, .upgrade .bg-additional-gray-darker .bg-additional-item .list-darker a,
.upgrade .bg-gray-darker .list-darker a:focus, .upgrade .bg-additional-gray-darker .bg-additional-item .list-darker a:focus,
.upgrade .bg-gray-darker .list-darker a:active, .upgrade .bg-additional-gray-darker .bg-additional-item .list-darker a:active,
.upgrade .bg-image .page-footer-inline-list a, .upgrade .bg-additional-image .bg-additional-item .page-footer-inline-list a,
.upgrade .bg-image .page-footer-inline-list a:focus, .upgrade .bg-additional-image .bg-additional-item .page-footer-inline-list a:focus,
.upgrade .bg-image .page-footer-inline-list a:active, .upgrade .bg-additional-image .bg-additional-item .page-footer-inline-list a:active,
.upgrade .bg-image .list-darker a, .upgrade .bg-additional-image .bg-additional-item .list-darker a,
.upgrade .bg-image .list-darker a:focus, .upgrade .bg-additional-image .bg-additional-item .list-darker a:focus,
.upgrade .bg-image .list-darker a:active, .upgrade .bg-additional-image .bg-additional-item .list-darker a:active,
.upgrade .bg-gradient .page-footer-inline-list a, .upgrade .bg-gradient-1 .page-footer-inline-list a, .upgrade .bg-gradient-2 .page-footer-inline-list a, .upgrade .bg-gradient-3 .page-footer-inline-list a,
.upgrade .bg-gradient .page-footer-inline-list a:focus, .upgrade .bg-gradient-1 .page-footer-inline-list a:focus, .upgrade .bg-gradient-2 .page-footer-inline-list a:focus, .upgrade .bg-gradient-3 .page-footer-inline-list a:focus,
.upgrade .bg-gradient .page-footer-inline-list a:active, .upgrade .bg-gradient-1 .page-footer-inline-list a:active, .upgrade .bg-gradient-2 .page-footer-inline-list a:active, .upgrade .bg-gradient-3 .page-footer-inline-list a:active,
.upgrade .bg-gradient .list-darker a, .upgrade .bg-gradient-1 .list-darker a, .upgrade .bg-gradient-2 .list-darker a, .upgrade .bg-gradient-3 .list-darker a,
.upgrade .bg-gradient .list-darker a:focus, .upgrade .bg-gradient-1 .list-darker a:focus, .upgrade .bg-gradient-2 .list-darker a:focus, .upgrade .bg-gradient-3 .list-darker a:focus,
.upgrade .bg-gradient .list-darker a:active, .upgrade .bg-gradient-1 .list-darker a:active, .upgrade .bg-gradient-2 .list-darker a:active, .upgrade .bg-gradient-3 .list-darker a:active {
  color: #fff;
}
.upgrade .bg-gray-darker .page-footer-inline-list a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .page-footer-inline-list a:hover,
.upgrade .bg-gray-darker .list-darker a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .list-darker a:hover,
.upgrade .bg-image .page-footer-inline-list a:hover, .upgrade .bg-additional-image .bg-additional-item .page-footer-inline-list a:hover,
.upgrade .bg-image .list-darker a:hover, .upgrade .bg-additional-image .bg-additional-item .list-darker a:hover,
.upgrade .bg-gradient .page-footer-inline-list a:hover, .upgrade .bg-gradient-1 .page-footer-inline-list a:hover, .upgrade .bg-gradient-2 .page-footer-inline-list a:hover, .upgrade .bg-gradient-3 .page-footer-inline-list a:hover,
.upgrade .bg-gradient .list-darker a:hover, .upgrade .bg-gradient-1 .list-darker a:hover, .upgrade .bg-gradient-2 .list-darker a:hover, .upgrade .bg-gradient-3 .list-darker a:hover {
  color: #981df0;
}
.upgrade .bg-gray-darker p.right a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item p.right a:hover,
.upgrade .bg-image p.right a:hover, .upgrade .bg-additional-image .bg-additional-item p.right a:hover,
.upgrade .bg-gradient p.right a:hover, .upgrade .bg-gradient-1 p.right a:hover, .upgrade .bg-gradient-2 p.right a:hover, .upgrade .bg-gradient-3 p.right a:hover {
  color: #fff;
}
.upgrade .bg-gray-darker a.icon-gray-light, .upgrade .bg-additional-gray-darker .bg-additional-item a.icon-gray-light, .upgrade .bg-gray-darker a.icon-gray-light:focus, .upgrade .bg-additional-gray-darker .bg-additional-item a.icon-gray-light:focus, .upgrade .bg-gray-darker a.icon-gray-light:active, .upgrade .bg-additional-gray-darker .bg-additional-item a.icon-gray-light:active, .upgrade .bg-gray-darker a.icon-gray-light:not(:hover), .upgrade .bg-additional-gray-darker .bg-additional-item a.icon-gray-light:not(:hover),
.upgrade .bg-image a.icon-gray-light, .upgrade .bg-additional-image .bg-additional-item a.icon-gray-light,
.upgrade .bg-image a.icon-gray-light:focus, .upgrade .bg-additional-image .bg-additional-item a.icon-gray-light:focus,
.upgrade .bg-image a.icon-gray-light:active, .upgrade .bg-additional-image .bg-additional-item a.icon-gray-light:active,
.upgrade .bg-image a.icon-gray-light:not(:hover), .upgrade .bg-additional-image .bg-additional-item a.icon-gray-light:not(:hover),
.upgrade .bg-gradient a.icon-gray-light, .upgrade .bg-gradient-1 a.icon-gray-light, .upgrade .bg-gradient-2 a.icon-gray-light, .upgrade .bg-gradient-3 a.icon-gray-light,
.upgrade .bg-gradient a.icon-gray-light:focus, .upgrade .bg-gradient-1 a.icon-gray-light:focus, .upgrade .bg-gradient-2 a.icon-gray-light:focus, .upgrade .bg-gradient-3 a.icon-gray-light:focus,
.upgrade .bg-gradient a.icon-gray-light:active, .upgrade .bg-gradient-1 a.icon-gray-light:active, .upgrade .bg-gradient-2 a.icon-gray-light:active, .upgrade .bg-gradient-3 a.icon-gray-light:active,
.upgrade .bg-gradient a.icon-gray-light:not(:hover), .upgrade .bg-gradient-1 a.icon-gray-light:not(:hover), .upgrade .bg-gradient-2 a.icon-gray-light:not(:hover), .upgrade .bg-gradient-3 a.icon-gray-light:not(:hover) {
  color: #c2c2c3;
}
.upgrade .bg-gray-darker.page-footer-alternative .divider-xl, .upgrade .bg-additional-gray-darker .page-footer-alternative.bg-additional-item .divider-xl,
.upgrade .bg-image.page-footer-alternative .divider-xl, .upgrade .bg-additional-image .page-footer-alternative.bg-additional-item .divider-xl,
.upgrade .bg-gradient.page-footer-alternative .divider-xl, .upgrade .page-footer-alternative.bg-gradient-1 .divider-xl, .upgrade .page-footer-alternative.bg-gradient-2 .divider-xl, .upgrade .page-footer-alternative.bg-gradient-3 .divider-xl {
  border-color: rgba(255, 255, 255, 0.2);
}
.upgrade .bg-gray-darker.page-footer-alternative a.icon.icon-gray-light[class*=instagram]:hover, .upgrade .bg-additional-gray-darker .page-footer-alternative.bg-additional-item a.icon.icon-gray-light[class*=instagram]:hover,
.upgrade .bg-image.page-footer-alternative a.icon.icon-gray-light[class*=instagram]:hover, .upgrade .bg-additional-image .page-footer-alternative.bg-additional-item a.icon.icon-gray-light[class*=instagram]:hover,
.upgrade .bg-gradient.page-footer-alternative a.icon.icon-gray-light[class*=instagram]:hover, .upgrade .page-footer-alternative.bg-gradient-1 a.icon.icon-gray-light[class*=instagram]:hover, .upgrade .page-footer-alternative.bg-gradient-2 a.icon.icon-gray-light[class*=instagram]:hover, .upgrade .page-footer-alternative.bg-gradient-3 a.icon.icon-gray-light[class*=instagram]:hover {
  color: #c13584;
}
.upgrade .bg-gray-darker .team-classic-title, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic-title,
.upgrade .bg-image .team-classic-title, .upgrade .bg-additional-image .bg-additional-item .team-classic-title,
.upgrade .bg-gradient .team-classic-title, .upgrade .bg-gradient-1 .team-classic-title, .upgrade .bg-gradient-2 .team-classic-title, .upgrade .bg-gradient-3 .team-classic-title {
  color: #ffec17;
}
.upgrade .bg-gray-darker .team-classic-title:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic-title:hover,
.upgrade .bg-image .team-classic-title:hover, .upgrade .bg-additional-image .bg-additional-item .team-classic-title:hover,
.upgrade .bg-gradient .team-classic-title:hover, .upgrade .bg-gradient-1 .team-classic-title:hover, .upgrade .bg-gradient-2 .team-classic-title:hover, .upgrade .bg-gradient-3 .team-classic-title:hover {
  color: #fff;
}
.upgrade .bg-gray-darker .team-classic-job-position, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic-job-position,
.upgrade .bg-image .team-classic-job-position, .upgrade .bg-additional-image .bg-additional-item .team-classic-job-position,
.upgrade .bg-gradient .team-classic-job-position, .upgrade .bg-gradient-1 .team-classic-job-position, .upgrade .bg-gradient-2 .team-classic-job-position, .upgrade .bg-gradient-3 .team-classic-job-position {
  color: #c2c2c3;
}
.upgrade .bg-gray-darker .team-inline-title:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .team-inline-title:hover,
.upgrade .bg-image .team-inline-title:hover, .upgrade .bg-additional-image .bg-additional-item .team-inline-title:hover,
.upgrade .bg-gradient .team-inline-title:hover, .upgrade .bg-gradient-1 .team-inline-title:hover, .upgrade .bg-gradient-2 .team-inline-title:hover, .upgrade .bg-gradient-3 .team-inline-title:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker a.icon.icon-gray-1:hover, .upgrade .bg-additional-gray-darker .bg-additional-item a.icon.icon-gray-1:hover,
.upgrade .bg-image a.icon.icon-gray-1:hover, .upgrade .bg-additional-image .bg-additional-item a.icon.icon-gray-1:hover,
.upgrade .bg-gradient a.icon.icon-gray-1:hover, .upgrade .bg-gradient-1 a.icon.icon-gray-1:hover, .upgrade .bg-gradient-2 a.icon.icon-gray-1:hover, .upgrade .bg-gradient-3 a.icon.icon-gray-1:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker .team-classic .button-default-outline, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic .button-default-outline, .upgrade .bg-gray-darker .team-classic .button-default-outline:focus, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic .button-default-outline:focus,
.upgrade .bg-image .team-classic .button-default-outline, .upgrade .bg-additional-image .bg-additional-item .team-classic .button-default-outline,
.upgrade .bg-image .team-classic .button-default-outline:focus, .upgrade .bg-additional-image .bg-additional-item .team-classic .button-default-outline:focus,
.upgrade .bg-gradient .team-classic .button-default-outline, .upgrade .bg-gradient-1 .team-classic .button-default-outline, .upgrade .bg-gradient-2 .team-classic .button-default-outline, .upgrade .bg-gradient-3 .team-classic .button-default-outline,
.upgrade .bg-gradient .team-classic .button-default-outline:focus, .upgrade .bg-gradient-1 .team-classic .button-default-outline:focus, .upgrade .bg-gradient-2 .team-classic .button-default-outline:focus, .upgrade .bg-gradient-3 .team-classic .button-default-outline:focus {
  color: #fff;
  background-color: #ed1c94;
  border-color: #ed1c94;
}
.upgrade .bg-gray-darker .team-classic .button-default-outline:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic .button-default-outline:hover, .upgrade .bg-gray-darker .team-classic .button-default-outline:active, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic .button-default-outline:active, .upgrade .bg-gray-darker .team-classic .button-default-outline:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .team-classic .button-default-outline:hover,
.upgrade .bg-image .team-classic .button-default-outline:hover, .upgrade .bg-additional-image .bg-additional-item .team-classic .button-default-outline:hover,
.upgrade .bg-image .team-classic .button-default-outline:active, .upgrade .bg-additional-image .bg-additional-item .team-classic .button-default-outline:active,
.upgrade .bg-image .team-classic .button-default-outline:hover, .upgrade .bg-additional-image .bg-additional-item .team-classic .button-default-outline:hover,
.upgrade .bg-gradient .team-classic .button-default-outline:hover, .upgrade .bg-gradient-1 .team-classic .button-default-outline:hover, .upgrade .bg-gradient-2 .team-classic .button-default-outline:hover, .upgrade .bg-gradient-3 .team-classic .button-default-outline:hover,
.upgrade .bg-gradient .team-classic .button-default-outline:active, .upgrade .bg-gradient-1 .team-classic .button-default-outline:active, .upgrade .bg-gradient-2 .team-classic .button-default-outline:active, .upgrade .bg-gradient-3 .team-classic .button-default-outline:active,
.upgrade .bg-gradient .team-classic .button-default-outline:hover, .upgrade .bg-gradient-1 .team-classic .button-default-outline:hover, .upgrade .bg-gradient-2 .team-classic .button-default-outline:hover, .upgrade .bg-gradient-3 .team-classic .button-default-outline:hover {
  color: #fff;
  background-color: #981df0;
  border-color: #981df0;
}
.upgrade .bg-gray-darker .team-inline-text, .upgrade .bg-additional-gray-darker .bg-additional-item .team-inline-text,
.upgrade .bg-image .team-inline-text, .upgrade .bg-additional-image .bg-additional-item .team-inline-text,
.upgrade .bg-gradient .team-inline-text, .upgrade .bg-gradient-1 .team-inline-text, .upgrade .bg-gradient-2 .team-inline-text, .upgrade .bg-gradient-3 .team-inline-text {
  color: #ededed;
}
.upgrade .bg-gray-darker .event-default-title, .upgrade .bg-additional-gray-darker .bg-additional-item .event-default-title,
.upgrade .bg-image .event-default-title, .upgrade .bg-additional-image .bg-additional-item .event-default-title,
.upgrade .bg-gradient .event-default-title, .upgrade .bg-gradient-1 .event-default-title, .upgrade .bg-gradient-2 .event-default-title, .upgrade .bg-gradient-3 .event-default-title {
  color: #ffec17;
}
.upgrade .bg-gray-darker .event-default-title:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .event-default-title:hover,
.upgrade .bg-image .event-default-title:hover, .upgrade .bg-additional-image .bg-additional-item .event-default-title:hover,
.upgrade .bg-gradient .event-default-title:hover, .upgrade .bg-gradient-1 .event-default-title:hover, .upgrade .bg-gradient-2 .event-default-title:hover, .upgrade .bg-gradient-3 .event-default-title:hover {
  color: #fff;
}
.upgrade .bg-gray-darker .event-default-inner .event-default-link, .upgrade .bg-additional-gray-darker .bg-additional-item .event-default-inner .event-default-link,
.upgrade .bg-image .event-default-inner .event-default-link, .upgrade .bg-additional-image .bg-additional-item .event-default-inner .event-default-link,
.upgrade .bg-gradient .event-default-inner .event-default-link, .upgrade .bg-gradient-1 .event-default-inner .event-default-link, .upgrade .bg-gradient-2 .event-default-inner .event-default-link, .upgrade .bg-gradient-3 .event-default-inner .event-default-link {
  color: #c2c2c3;
}
.upgrade .bg-gray-darker .event-default-inner .event-default-link:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .event-default-inner .event-default-link:hover,
.upgrade .bg-image .event-default-inner .event-default-link:hover, .upgrade .bg-additional-image .bg-additional-item .event-default-inner .event-default-link:hover,
.upgrade .bg-gradient .event-default-inner .event-default-link:hover, .upgrade .bg-gradient-1 .event-default-inner .event-default-link:hover, .upgrade .bg-gradient-2 .event-default-inner .event-default-link:hover, .upgrade .bg-gradient-3 .event-default-inner .event-default-link:hover {
  color: #ffec17;
}
.upgrade .bg-gradient-1 .box-cta-title,
.upgrade .bg-gradient-2 .box-cta-title,
.upgrade .bg-image .box-cta-title, .upgrade .bg-additional-image .bg-additional-item .box-cta-title,
.upgrade .bg-secondary-2 .box-cta-title,
.upgrade .bg-gray-darker .box-cta-title, .upgrade .bg-additional-gray-darker .bg-additional-item .box-cta-title {
  color: #ffec17;
}
.upgrade .bg-gradient-1 .box-cta,
.upgrade .bg-gradient-2 .box-cta,
.upgrade .bg-image .box-cta, .upgrade .bg-additional-image .bg-additional-item .box-cta,
.upgrade .bg-secondary-2 .box-cta,
.upgrade .bg-gray-darker .box-cta, .upgrade .bg-additional-gray-darker .bg-additional-item .box-cta {
  color: #fff;
}
.upgrade .bg-gradient-1 .link-bold,
.upgrade .bg-gradient-2 .link-bold,
.upgrade .bg-image .link-bold, .upgrade .bg-additional-image .bg-additional-item .link-bold,
.upgrade .bg-secondary-2 .link-bold,
.upgrade .bg-gray-darker .link-bold, .upgrade .bg-additional-gray-darker .bg-additional-item .link-bold {
  color: #ffec17;
}
.upgrade .bg-gradient-1 .link-bold:hover,
.upgrade .bg-gradient-2 .link-bold:hover,
.upgrade .bg-image .link-bold:hover, .upgrade .bg-additional-image .bg-additional-item .link-bold:hover,
.upgrade .bg-secondary-2 .link-bold:hover,
.upgrade .bg-gray-darker .link-bold:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .link-bold:hover {
  color: #fff;
}
.upgrade .bg-gradient-1 .box-minimal-title,
.upgrade .bg-gradient-2 .box-minimal-title,
.upgrade .bg-image .box-minimal-title, .upgrade .bg-additional-image .bg-additional-item .box-minimal-title,
.upgrade .bg-secondary-2 .box-minimal-title,
.upgrade .bg-gray-darker .box-minimal-title, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-title {
  color: #ffec17;
}
.upgrade .bg-gradient-1 .box-minimal-text,
.upgrade .bg-gradient-2 .box-minimal-text,
.upgrade .bg-image .box-minimal-text, .upgrade .bg-additional-image .bg-additional-item .box-minimal-text,
.upgrade .bg-secondary-2 .box-minimal-text,
.upgrade .bg-gray-darker .box-minimal-text, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-text {
  color: #fff;
  opacity: 0.6;
}
.upgrade .bg-gradient-1 .box-minimal-header .box-minimal-icon,
.upgrade .bg-gradient-2 .box-minimal-header .box-minimal-icon,
.upgrade .bg-image .box-minimal-header .box-minimal-icon, .upgrade .bg-additional-image .bg-additional-item .box-minimal-header .box-minimal-icon,
.upgrade .bg-secondary-2 .box-minimal-header .box-minimal-icon,
.upgrade .bg-gray-darker .box-minimal-header .box-minimal-icon, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-header .box-minimal-icon {
  color: #fff;
}
.upgrade .bg-gradient-1 .box-minimal-header .box-minimal-icon svg,
.upgrade .bg-gradient-2 .box-minimal-header .box-minimal-icon svg,
.upgrade .bg-image .box-minimal-header .box-minimal-icon svg, .upgrade .bg-additional-image .bg-additional-item .box-minimal-header .box-minimal-icon svg,
.upgrade .bg-secondary-2 .box-minimal-header .box-minimal-icon svg,
.upgrade .bg-gray-darker .box-minimal-header .box-minimal-icon svg, .upgrade .bg-additional-gray-darker .bg-additional-item .box-minimal-header .box-minimal-icon svg {
  fill: #fff;
}
.upgrade .bg-gradient-1 .thumbnail-classic .divider-default,
.upgrade .bg-gradient-2 .thumbnail-classic .divider-default,
.upgrade .bg-image .thumbnail-classic .divider-default, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic .divider-default,
.upgrade .bg-secondary-2 .thumbnail-classic .divider-default,
.upgrade .bg-gray-darker .thumbnail-classic .divider-default, .upgrade .bg-additional-gray-darker .bg-additional-item .thumbnail-classic .divider-default {
  background: #fff;
}
.upgrade .bg-gradient-1 .thumbnail-classic-title,
.upgrade .bg-gradient-2 .thumbnail-classic-title,
.upgrade .bg-image .thumbnail-classic-title, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic-title,
.upgrade .bg-secondary-2 .thumbnail-classic-title,
.upgrade .bg-gray-darker .thumbnail-classic-title, .upgrade .bg-additional-gray-darker .bg-additional-item .thumbnail-classic-title {
  color: #ffec17;
}
.upgrade .bg-gradient-1 .thumbnail-classic-icon,
.upgrade .bg-gradient-2 .thumbnail-classic-icon,
.upgrade .bg-image .thumbnail-classic-icon, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic-icon,
.upgrade .bg-secondary-2 .thumbnail-classic-icon,
.upgrade .bg-gray-darker .thumbnail-classic-icon, .upgrade .bg-additional-gray-darker .bg-additional-item .thumbnail-classic-icon {
  color: #fff;
}
.upgrade .bg-gradient-1 .thumbnail-classic-secondary .divider-default,
.upgrade .bg-gradient-2 .thumbnail-classic-secondary .divider-default,
.upgrade .bg-image .thumbnail-classic-secondary .divider-default, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic-secondary .divider-default,
.upgrade .bg-secondary-2 .thumbnail-classic-secondary .divider-default,
.upgrade .bg-gray-darker .thumbnail-classic-secondary .divider-default, .upgrade .bg-additional-gray-darker .bg-additional-item .thumbnail-classic-secondary .divider-default {
  background: #981df0;
}
.upgrade .bg-gradient-1 .thumbnail-classic-secondary .thumbnail-classic-title,
.upgrade .bg-gradient-2 .thumbnail-classic-secondary .thumbnail-classic-title,
.upgrade .bg-image .thumbnail-classic-secondary .thumbnail-classic-title, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic-secondary .thumbnail-classic-title,
.upgrade .bg-secondary-2 .thumbnail-classic-secondary .thumbnail-classic-title,
.upgrade .bg-gray-darker .thumbnail-classic-secondary .thumbnail-classic-title, .upgrade .bg-additional-gray-darker .bg-additional-item .thumbnail-classic-secondary .thumbnail-classic-title {
  color: #fff;
}
.upgrade .bg-gradient-1 .thumbnail-classic-secondary .thumbnail-classic-icon,
.upgrade .bg-gradient-2 .thumbnail-classic-secondary .thumbnail-classic-icon,
.upgrade .bg-image .thumbnail-classic-secondary .thumbnail-classic-icon, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic-secondary .thumbnail-classic-icon,
.upgrade .bg-secondary-2 .thumbnail-classic-secondary .thumbnail-classic-icon,
.upgrade .bg-gray-darker .thumbnail-classic-secondary .thumbnail-classic-icon, .upgrade .bg-additional-gray-darker .bg-additional-item .thumbnail-classic-secondary .thumbnail-classic-icon {
  color: #ffec17;
}
.upgrade .bg-gradient-1 .thumbnail-classic-secondary .thumbnail-classic-icon::after,
.upgrade .bg-gradient-2 .thumbnail-classic-secondary .thumbnail-classic-icon::after,
.upgrade .bg-image .thumbnail-classic-secondary .thumbnail-classic-icon::after, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic-secondary .thumbnail-classic-icon::after,
.upgrade .bg-secondary-2 .thumbnail-classic-secondary .thumbnail-classic-icon::after,
.upgrade .bg-gray-darker .thumbnail-classic-secondary .thumbnail-classic-icon::after, .upgrade .bg-additional-gray-darker .bg-additional-item .thumbnail-classic-secondary .thumbnail-classic-icon::after {
  border-color: #ffec17;
}
.upgrade .bg-gradient-1 .image-shadow img,
.upgrade .bg-gradient-2 .image-shadow img,
.upgrade .bg-image .image-shadow img, .upgrade .bg-additional-image .bg-additional-item .image-shadow img,
.upgrade .bg-secondary-2 .image-shadow img,
.upgrade .bg-gray-darker .image-shadow img, .upgrade .bg-additional-gray-darker .bg-additional-item .image-shadow img {
  box-shadow: 0 10px 30px 0 rgba(30, 31, 34, 0.5);
}
.upgrade .bg-image .thumbnail-classic-secondary .thumbnail-classic-text, .upgrade .bg-additional-image .bg-additional-item .thumbnail-classic-secondary .thumbnail-classic-text,
.upgrade .bg-gradient .thumbnail-classic-secondary .thumbnail-classic-text, .upgrade .bg-gradient-1 .thumbnail-classic-secondary .thumbnail-classic-text, .upgrade .bg-gradient-2 .thumbnail-classic-secondary .thumbnail-classic-text, .upgrade .bg-gradient-3 .thumbnail-classic-secondary .thumbnail-classic-text,
.upgrade .bg-secondary-2 .thumbnail-classic-secondary .thumbnail-classic-text {
  color: rgba(255, 255, 255, 0.8);
}
.upgrade .bg-image .typed-text, .upgrade .bg-additional-image .bg-additional-item .typed-text,
.upgrade .bg-image .typed-cursor, .upgrade .bg-additional-image .bg-additional-item .typed-cursor,
.upgrade .bg-gradient .typed-text, .upgrade .bg-gradient-1 .typed-text, .upgrade .bg-gradient-2 .typed-text, .upgrade .bg-gradient-3 .typed-text,
.upgrade .bg-gradient .typed-cursor, .upgrade .bg-gradient-1 .typed-cursor, .upgrade .bg-gradient-2 .typed-cursor, .upgrade .bg-gradient-3 .typed-cursor,
.upgrade .bg-secondary-2 .typed-text,
.upgrade .bg-secondary-2 .typed-cursor {
  color: #ffec17;
}
.upgrade .bg-primary-accent .button-default-outline, .upgrade .bg-primary-accent .button-default-outline:focus,
.upgrade .bg-secondary-2 .button-default-outline,
.upgrade .bg-secondary-2 .button-default-outline:focus {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.upgrade .bg-primary-accent .button-default-outline:hover, .upgrade .bg-primary-accent .button-default-outline:active, .upgrade .bg-primary-accent .button-default-outline:hover,
.upgrade .bg-secondary-2 .button-default-outline:hover,
.upgrade .bg-secondary-2 .button-default-outline:active,
.upgrade .bg-secondary-2 .button-default-outline:hover {
  color: #fff;
  background-color: #ed1c94;
  border-color: #ed1c94;
}
.upgrade .bg-primary {
  background-color: #981df0;
  fill: #981df0;
}
.upgrade .bg-primary .way-point > svg {
  fill: inherit;
}
.upgrade .bg-primary + .bg-primary {
  padding-top: 0;
}
.upgrade .bg-primary .bg-decoration > svg {
  fill: #981df0;
}
.upgrade .bg-primary-accent {
  background-color: #6a0dac;
  fill: #6a0dac;
}
.upgrade .bg-primary-accent .way-point > svg {
  fill: inherit;
}
.upgrade .bg-primary-accent + .bg-primary-accent {
  padding-top: 0;
}
.upgrade .bg-primary-accent .bg-decoration > svg {
  fill: #6a0dac;
}
.upgrade .bg-primary-accent .parallax-text {
  color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.upgrade .bg-secondary-2 {
  background-color: #6d0eb1;
  fill: #6d0eb1;
}
.upgrade .bg-secondary-2 .way-point > svg {
  fill: inherit;
}
.upgrade .bg-secondary-2 + .bg-secondary-2 {
  padding-top: 0;
}
.upgrade .bg-secondary-2 .bg-decoration > svg {
  fill: #6d0eb1;
}
.upgrade .bg-secondary-2 .thumbnail-classic .divider-default {
  background: #fff;
}
.upgrade .bg-secondary-2 .thumbnail-classic-title {
  color: #ffec17;
}
.upgrade .bg-secondary-2 .box-minimal-border:hover {
  background: #29293a;
  border-color: #29293a;
}
.upgrade .bg-gray-darker, .upgrade .bg-additional-gray-darker .bg-additional-item {
  background-color: #29293a;
  fill: #29293a;
}
.upgrade .bg-gray-darker .way-point > svg, .upgrade .bg-additional-gray-darker .bg-additional-item .way-point > svg {
  fill: inherit;
}
.upgrade .bg-gray-darker + .bg-gray-darker, .upgrade .bg-additional-gray-darker .bg-additional-item + .bg-gray-darker, .upgrade .bg-additional-gray-darker .bg-gray-darker + .bg-additional-item, .upgrade .bg-additional-gray-darker .bg-additional-item + .bg-additional-item {
  padding-top: 0;
}
.upgrade .bg-gray-darker .bg-decoration > svg, .upgrade .bg-additional-gray-darker .bg-additional-item .bg-decoration > svg {
  fill: #29293a;
}
.upgrade .bg-gray-darker h1 a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item h1 a:hover, .upgrade .bg-gray-darker h2 a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item h2 a:hover, .upgrade .bg-gray-darker h3 a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item h3 a:hover, .upgrade .bg-gray-darker h4 a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item h4 a:hover, .upgrade .bg-gray-darker h5 a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item h5 a:hover, .upgrade .bg-gray-darker h6 a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item h6 a:hover, .upgrade .bg-gray-darker [class^=heading-] a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item [class^=heading-] a:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker .time-line-vertical:before, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-vertical:before,
.upgrade .bg-gray-darker .time-line-vertical-element:last-child .unit-body:after, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-vertical-element:last-child .unit-body:after,
.upgrade .bg-gray-darker .time-line-vertical-element:before, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-vertical-element:before,
.upgrade .bg-gray-darker .time-line-vertical-element .unit-body:before, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-vertical-element .unit-body:before {
  background-color: #29293a;
  border-color: #ffec17;
}
.upgrade .bg-gray-darker .twitter, .upgrade .bg-additional-gray-darker .bg-additional-item .twitter {
  color: #fff;
}
.upgrade .bg-gray-darker .time-line-time, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-time,
.upgrade .bg-gray-darker .time-line-content, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-content {
  color: #c2c2c3;
}
.upgrade .bg-gray-darker .radio a, .upgrade .bg-additional-gray-darker .bg-additional-item .radio a, .upgrade .bg-gray-darker .radio-inline a, .upgrade .bg-additional-gray-darker .bg-additional-item .radio-inline a,
.upgrade .bg-gray-darker .checkbox a, .upgrade .bg-additional-gray-darker .bg-additional-item .checkbox a, .upgrade .bg-gray-darker .checkbox-inline a, .upgrade .bg-additional-gray-darker .bg-additional-item .checkbox-inline a {
  color: #ed1c94;
}
.upgrade .bg-gray-darker .radio a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .radio a:hover, .upgrade .bg-gray-darker .radio-inline a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .radio-inline a:hover,
.upgrade .bg-gray-darker .checkbox a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .checkbox a:hover, .upgrade .bg-gray-darker .checkbox-inline a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .checkbox-inline a:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker .isotope-filters-list-buttons a, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list-buttons a, .upgrade .bg-gray-darker .isotope-filters-list-buttons a:focus, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list-buttons a:focus {
  color: #29293a;
  background-color: #fff;
  border-color: #ededed;
}
.upgrade .bg-gray-darker .isotope-filters-list-buttons a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list-buttons a:hover, .upgrade .bg-gray-darker .isotope-filters-list-buttons a:active, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list-buttons a:active, .upgrade .bg-gray-darker .isotope-filters-list-buttons a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .isotope-filters-list-buttons a:hover {
  color: #fff;
  background-color: #6a0dac;
  border-color: #6a0dac;
}
.upgrade .bg-gray-darker .button-default-outline, .upgrade .bg-additional-gray-darker .bg-additional-item .button-default-outline, .upgrade .bg-gray-darker .button-default-outline:focus, .upgrade .bg-additional-gray-darker .bg-additional-item .button-default-outline:focus {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.upgrade .bg-gray-darker .button-default-outline:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .button-default-outline:hover, .upgrade .bg-gray-darker .button-default-outline:active, .upgrade .bg-additional-gray-darker .bg-additional-item .button-default-outline:active, .upgrade .bg-gray-darker .button-default-outline:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .button-default-outline:hover {
  color: #fff;
  background-color: #ed1c94;
  border-color: #ed1c94;
}
.upgrade .bg-decorated {
  position: relative;
  z-index: 10;
}
.upgrade .bg-decoration {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 0;
  line-height: 0;
}
.upgrade .bg-decoration-top {
  top: 1px;
  transform: translateY(-100%);
}
.upgrade .bg-decoration-bottom {
  bottom: 1px;
  transform: translateY(100%);
}
.upgrade .bg-decoration svg {
  min-width: 1000px;
  width: 100%;
  fill: #fff;
}
.upgrade .bg-gradient .divider-default, .upgrade .bg-gradient-1 .divider-default, .upgrade .bg-gradient-2 .divider-default, .upgrade .bg-gradient-3 .divider-default {
  background: #fff;
}
.upgrade .bg-gradient-1 {
  background-image: linear-gradient(to right, #6d0eb1 20%, #ed1c94);
}
.upgrade .bg-gradient-1 .box-cta .button-secondary, .upgrade .bg-gradient-1 .box-cta .button-secondary:focus {
  color: #fff;
  background-color: transparent;
  border-color: #ededed;
}
.upgrade .bg-gradient-1 .box-cta .button-secondary:hover, .upgrade .bg-gradient-1 .box-cta .button-secondary:active, .upgrade .bg-gradient-1 .box-cta .button-secondary:hover {
  color: #fff;
  background-color: #6a0dac;
  border-color: #6a0dac;
}
.upgrade .bg-gradient-2 {
  background-image: linear-gradient(to left, #6d0eb1 20%, #ed1c94);
}
.upgrade .bg-gradient-2 .box-minimal-border:hover {
  background: #29293a;
  border-color: #29293a;
}
.upgrade .bg-gradient-3 {
  background-image: linear-gradient(-45deg, #310cb3 10%, #520db2 45%, #6d0eb1 80%);
}
.upgrade .bg-gradient-3 .button-default-outline, .upgrade .bg-gradient-3 .button-default-outline:focus {
  color: #fff;
  background-color: transparent;
  border-color: #ededed;
}
.upgrade .bg-gradient-3 .button-default-outline:hover, .upgrade .bg-gradient-3 .button-default-outline:active, .upgrade .bg-gradient-3 .button-default-outline:hover {
  color: #fff;
  background-color: #6a0dac;
  border-color: #6a0dac;
}
.upgrade .bg-image, .upgrade .bg-additional-image .bg-additional-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.upgrade .bg-image, .upgrade .bg-additional-image .bg-additional-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.upgrade .bg-image .box-minimal-icon, .upgrade .bg-additional-image .bg-additional-item .box-minimal-icon {
  color: #ffec17;
}
.upgrade .bg-image .box-minimal-icon svg, .upgrade .bg-additional-image .bg-additional-item .box-minimal-icon svg {
  fill: #ffec17;
}
.upgrade .bg-image .quote-boxed-small, .upgrade .bg-additional-image .bg-additional-item .quote-boxed-small,
.upgrade .bg-image .quote-vertical-small, .upgrade .bg-additional-image .bg-additional-item .quote-vertical-small {
  color: #c2c2c3;
}
.upgrade .bg-image .button-default-outline, .upgrade .bg-additional-image .bg-additional-item .button-default-outline, .upgrade .bg-image .button-default-outline:focus, .upgrade .bg-additional-image .bg-additional-item .button-default-outline:focus {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.upgrade .bg-image .button-default-outline:hover, .upgrade .bg-additional-image .bg-additional-item .button-default-outline:hover, .upgrade .bg-image .button-default-outline:active, .upgrade .bg-additional-image .bg-additional-item .button-default-outline:active, .upgrade .bg-image .button-default-outline:hover, .upgrade .bg-additional-image .bg-additional-item .button-default-outline:hover {
  color: #fff;
  background-color: #6a0dac;
  border-color: #6a0dac;
}
.upgrade .bg-image .radio, .upgrade .bg-additional-image .bg-additional-item .radio, .upgrade .bg-image .radio-inline, .upgrade .bg-additional-image .bg-additional-item .radio-inline,
.upgrade .bg-image .checkbox, .upgrade .bg-additional-image .bg-additional-item .checkbox, .upgrade .bg-image .checkbox-inline, .upgrade .bg-additional-image .bg-additional-item .checkbox-inline {
  color: #fff;
}
.upgrade .bg-image .radio a, .upgrade .bg-additional-image .bg-additional-item .radio a, .upgrade .bg-image .radio-inline a, .upgrade .bg-additional-image .bg-additional-item .radio-inline a,
.upgrade .bg-image .checkbox a, .upgrade .bg-additional-image .bg-additional-item .checkbox a, .upgrade .bg-image .checkbox-inline a, .upgrade .bg-additional-image .bg-additional-item .checkbox-inline a {
  color: #ffec17;
}
.upgrade .bg-image .radio a:hover, .upgrade .bg-additional-image .bg-additional-item .radio a:hover, .upgrade .bg-image .radio-inline a:hover, .upgrade .bg-additional-image .bg-additional-item .radio-inline a:hover,
.upgrade .bg-image .checkbox a:hover, .upgrade .bg-additional-image .bg-additional-item .checkbox a:hover, .upgrade .bg-image .checkbox-inline a:hover, .upgrade .bg-additional-image .bg-additional-item .checkbox-inline a:hover {
  color: #fff;
}
.upgrade .bg-image .bg-image-poster, .upgrade .bg-additional-image .bg-additional-item .bg-image-poster {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.upgrade .bg-image .bg-image-poster img, .upgrade .bg-additional-image .bg-additional-item .bg-image-poster img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  object-position: right center;
  width: 100%;
  height: 100%;
}
.upgrade .bg-image.section-reverse .bg-image-poster, .upgrade .bg-additional-image .section-reverse.bg-additional-item .bg-image-poster {
  transform: scale(-1, 1);
}
.upgrade .bg-image.section-reverse .bg-image-poster img, .upgrade .bg-additional-image .section-reverse.bg-additional-item .bg-image-poster img {
  object-position: left center;
}
@media (max-width: 1199px) {
  .upgrade .bg-image.section-reverse .bg-image-poster.bg-image-poster-lg, .upgrade .bg-additional-image .section-reverse.bg-additional-item .bg-image-poster.bg-image-poster-lg {
    transform: none;
  }
  .upgrade .bg-image.section-reverse .bg-image-poster.bg-image-poster-lg img, .upgrade .bg-additional-image .section-reverse.bg-additional-item .bg-image-poster.bg-image-poster-lg img {
    object-position: right center;
  }
}
.upgrade .ie-10 .bg-image .bg-image-poster img, .upgrade .ie-10 .bg-additional-image .bg-additional-item .bg-image-poster img, .upgrade .bg-additional-image .ie-10 .bg-additional-item .bg-image-poster img,
.upgrade .ie-11 .bg-image .bg-image-poster img,
.upgrade .ie-11 .bg-additional-image .bg-additional-item .bg-image-poster img, .upgrade .bg-additional-image .ie-11 .bg-additional-item .bg-image-poster img {
  right: auto;
  bottom: auto;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-width: 135%;
  max-height: 135%;
  min-height: 101%;
  min-width: 101%;
  transform: translate(-50%, -50%);
}
@media (min-width: 992px) {
  .upgrade html.desktop .bg-fixed {
    background-attachment: fixed;
  }
}
.upgrade [class*=bg-] {
  background-size: cover;
  background-position: center center;
}
.upgrade .bg-additional-item {
  -webkit-align-self: stretch;
  align-self: stretch;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.upgrade .bg-additional-item > * {
  width: 100%;
}
.upgrade .blog-inline {
  max-width: 318px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.02em;
}
.upgrade .blog-inline-title {
  color: #ed1c94;
  font-weight: 400;
}
.upgrade .blog-inline-title a {
  display: block;
}
.upgrade .blog-inline-title > a, .upgrade .blog-inline-title > a:focus, .upgrade .blog-inline-title > a:active {
  color: inherit;
}
.upgrade .blog-inline-title > a:hover {
  color: #981df0;
}
.upgrade .blog-inline-meta {
  color: #838386;
  font-style: italic;
  margin-left: -20px;
  margin-bottom: -10px;
  transform: translateY(-10px);
}
.upgrade .blog-inline-meta a, .upgrade .blog-inline-meta a:focus, .upgrade .blog-inline-meta a:active {
  color: #838386;
}
.upgrade .blog-inline-meta a:hover {
  color: #981df0;
}
.upgrade .blog-inline-meta > li {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.upgrade .blog-inline-meta > li + li:after {
  content: "|";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  color: #979799;
}
.upgrade * + .blog-inline {
  margin-top: 25px;
}
.upgrade * + .blog-inline-wrap {
  margin-top: 25px;
}
.upgrade * + .blog-inline-meta {
  margin-top: 5px;
}
@media (min-width: 768px) {
  .upgrade * + .blog-inline {
    margin-top: 43px;
  }
  .upgrade * + .blog-inline-wrap {
    margin-top: 43px;
  }
}
@media (min-width: 992px) {
  .upgrade .blog-inline {
    margin-left: 0;
    margin-right: auto;
  }
}
.upgrade .box-minimal {
  max-width: 390px;
}
.upgrade .box-minimal * + p {
  margin-top: 15px;
}
.upgrade .box-minimal .box-minimal-icon {
  font-size: 30px;
  line-height: 1;
  color: #981df0;
}
.upgrade .box-minimal-icon svg {
  fill: #981df0;
}
.upgrade .box-minimal-title {
  color: #29293a;
}
.upgrade .shell-box-minimal-wrap .box-minimal {
  max-width: 570px;
}
.upgrade .box-accent p {
  letter-spacing: 0.02em;
}
@media (min-width: 992px) {
  .upgrade .box-accent h6 + p, .upgrade .box-accent .heading-6 + p {
    margin-top: 26px;
  }
}
@media (min-width: 1600px) {
  .upgrade .box-accent {
    padding-right: 50px;
  }
}
.upgrade .box-minimal-header {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  transform: translateY(-20px);
  margin-bottom: -20px;
  margin-left: -20px;
}
.upgrade .box-minimal-header > * {
  display: inline-block;
  margin-top: 20px;
  margin-left: 20px;
}
.upgrade .box-minimal-light .box-minimal-icon {
  color: #ed1c94;
}
.upgrade .box-minimal-light .box-minimal-icon svg {
  fill: #ed1c94;
}
.upgrade .box-minimal-text {
  color: #838386;
}
.upgrade * + .box-minimal-text {
  margin-top: 22px;
}
.upgrade .box-minimal-border {
  padding: 20px 15px;
  border-radius: 8px;
  border: 1px solid #c2c2c3;
  transition: 450ms ease-in-out;
}
.upgrade .box-minimal-border .box-minimal-header > * {
  display: block;
  width: 100%;
}
.upgrade .box-minimal-border .box-minimal-icon {
  font-size: 30px;
  color: #29293a;
}
.upgrade .box-minimal-border .box-minimal-icon svg {
  fill: #29293a;
}
.upgrade .box-minimal-border .box-minimal-title {
  font-weight: 400;
}
.upgrade .box-minimal-border * + hr {
  margin-top: 20px;
}
.upgrade .box-minimal-border:hover {
  background: #f2f3f9;
  border-color: #f2f3f9;
}
.upgrade .box-minimal-modern {
  padding: 0 15px 30px;
  margin-top: 50px;
  background-color: #f2f3f9;
  border-radius: 8px;
  transition: 750ms ease-in-out;
}
.upgrade .box-minimal-modern .box-minimal-icon {
  position: relative;
  top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-bottom: -50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  font-size: 30px;
  color: #fff;
  background: linear-gradient(-45deg, #ed1c94, #ffec17);
}
.upgrade .box-minimal-modern .box-minimal-icon svg {
  fill: #fff;
}
.upgrade .box-minimal-modern .box-minimal-icon:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: linear-gradient(-45deg, #6d0eb1, #ed1c94);
  opacity: 0;
  border-radius: 100%;
  transition: 750ms ease-in-out;
}
.upgrade .box-minimal-modern .box-minimal-icon:before {
  position: relative;
  z-index: 1;
}
.upgrade .box-minimal-modern .box-minimal-icon.mdi {
  font-size: 34px;
}
.upgrade .box-minimal-modern:hover {
  box-shadow: 0 8px 29px rgba(41, 41, 58, 0.09);
}
.upgrade .box-minimal-modern:hover .box-minimal-icon:after {
  opacity: 1;
}
@media (min-width: 992px) {
  .upgrade .box-minimal .box-minimal-icon {
    font-size: 40px;
  }
  .upgrade .box-minimal .box-minimal-icon-md {
    font-size: 36px;
  }
  .upgrade .box-minimal .box-minimal-icon-sm {
    font-size: 30px;
  }
  .upgrade .box-minimal-border {
    padding: 40px 25px 45px;
    min-height: 365px;
  }
  .upgrade .box-minimal-border .box-minimal-icon {
    font-size: 48px;
  }
  .upgrade .box-minimal-border * + hr {
    margin-top: 30px;
  }
  .upgrade .box-minimal-modern {
    padding: 0 28px 45px;
    min-height: 300px;
  }
  .upgrade .box-minimal-modern .box-minimal-icon {
    font-size: 40px;
  }
  .upgrade .box-minimal-modern .box-minimal-icon.mdi {
    font-size: 44px;
  }
  .upgrade .box-minimal-modern * + .box-minimal-title {
    margin-top: 30px;
  }
  .upgrade .box-minimal-icon.box-minimal-icon-lg {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  .upgrade .box-minimal-border {
    padding: 40px 35px 45px;
  }
}
@media (min-width: 1600px) {
  .upgrade .box-minimal-icon.box-minimal-icon-lg {
    font-size: 52px;
  }
  .upgrade .box-minimal-border {
    padding: 40px 45px 45px;
  }
}
.upgrade .box-classic {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  padding: 10% 7%;
  text-align: left;
  border: 1px solid transparent;
  border-radius: 9px;
}
.upgrade .box-classic-title > * {
  text-transform: uppercase;
}
.upgrade .box-classic-content {
  letter-spacing: 0.02em;
}
.upgrade * + .box-classic {
  margin-top: 60px;
}
.upgrade * + .box-classic-content {
  margin-top: 10px;
}
@media (min-width: 992px) {
  .upgrade .box-classic {
    padding: 5% 5% 6% 5%;
  }
  .upgrade * + .box-classic-content {
    margin-top: 20px;
  }
}
.upgrade .box-bordered {
  border-color: #c2c2c3;
}
.upgrade .box-shadow {
  border-color: #ededed;
  box-shadow: 0 6px 8px 0 rgba(110, 123, 157, 0.17);
}
.upgrade .box-width-1 {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}
.upgrade .box-width-2 {
  max-width: 730px;
}
.upgrade .box-width-small {
  max-width: 420px;
}
@media (min-width: 1600px) {
  .upgrade .box-wrap-1 {
    margin-left: -80px;
    padding-right: 37px;
  }
}
@media (min-width: 1600px) {
  .upgrade .box-wrap-2 {
    padding-left: 70px;
  }
}
.upgrade .box-inline {
  margin-left: -7px;
  display: table;
  text-align: left;
}
.upgrade .box-inline a {
  color: #29293a;
}
.upgrade .box-inline a:hover {
  color: #ed1c94;
}
.upgrade .box-inline > * {
  display: table-cell;
  vertical-align: top;
  padding: 0 6px;
}
.upgrade .box-inline .icon-md-smaller {
  position: relative;
  top: 3px;
}
.upgrade .box-inline-gray a {
  color: #838386;
}
.upgrade .box-inline-gray a:hover {
  color: #ed1c94;
}
.upgrade .box-element {
  padding: 20px;
}
.upgrade * + .box-element {
  margin-top: 20px;
}
.upgrade .box-element + .box-element {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .upgrade * + .box-element {
    margin-top: 35px;
  }
  .upgrade .box-element + .box-element {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .upgrade * + .box-element {
    margin-top: 50px;
  }
  .upgrade .box-element + .box-element {
    margin-top: 35px;
  }
}
@media (min-width: 1600px) {
  .upgrade * + .box-element {
    margin-top: 90px;
  }
  .upgrade .box-element + .box-element {
    margin-top: 55px;
  }
}
.upgrade .contact-info-wrap {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}
.upgrade .element-boxed {
  padding: 35px 20px;
}
@media (min-width: 768px) {
  .upgrade .element-boxed {
    padding: 40px;
  }
}
@media (min-width: 1200px) {
  .upgrade .element-boxed {
    padding: 50px 55px;
  }
}
@media (min-width: 1600px) {
  .upgrade .element-boxed {
    padding: 90px 70px;
  }
}
@media (min-width: 1200px) {
  .upgrade .box-range-content {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
  }
}
.upgrade .box-range-content.box-range-content-1 {
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 1600px) {
  .upgrade .box-range-content-1 {
    max-width: 638px;
    margin-left: auto;
    margin-right: 0;
  }
  .upgrade .section-reverse .box-range-content-1 {
    margin-left: 0;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .upgrade .box-2-columns {
    -moz-columns: 2;
    columns: 2;
    -moz-column-gap: 4em;
    column-gap: 4em;
  }
}
.upgrade .box-info {
  border-radius: 9px;
  border: 1px solid #ededed;
  transition: 0.3s ease-out all;
}
.upgrade .box-info p {
  letter-spacing: 0.02em;
}
.upgrade .box-info .box-inline {
  color: #29293a;
}
.upgrade .box-info:hover {
  box-shadow: 0 6px 8px 0 rgba(110, 123, 157, 0.17);
  transform: translateY(-3px);
}
.upgrade .box-info .heading-6 + p,
.upgrade .box-info h6 + p {
  margin-top: 8px;
}
.upgrade .bg-gray-lighter .box-info,
.upgrade .bg-gray-darker .box-info, .upgrade .bg-additional-gray-darker .bg-additional-item .box-info {
  background-color: #fff;
}
.upgrade h6.box-info-title,
.upgrade .box-info-title {
  color: #ed1c94;
}
.upgrade .box-info-inner {
  padding: 20px 15px;
}
.upgrade .box-info-footer {
  padding: 12px 15px 10px;
  border-top: 1px solid #ededed;
  letter-spacing: 0.02em;
}
.upgrade .box-info-footer .box-inline > * {
  display: table-cell;
  vertical-align: middle;
}
@media (min-width: 992px) {
  .upgrade .box-info-inner {
    min-height: 208px;
  }
}
@media (min-width: 1200px) {
  .upgrade .box-info-inner {
    min-height: 228px;
  }
}
@media (min-width: 1600px) {
  .upgrade .box-info .heading-6 + p,
.upgrade .box-info h6 + p {
    margin-top: 21px;
  }
  .upgrade .box-info-footer .icon {
    font-size: 24px;
  }
  .upgrade .box-info-inner {
    min-height: 294px;
    padding: 42px 37px;
  }
  .upgrade .box-info-footer {
    padding: 12px 37px 10px;
  }
}
@media (min-width: 1800px) {
  .upgrade .box-info-inner {
    min-height: 270px;
  }
}
.upgrade .swiper-box-info > * + * {
  margin-top: 25px;
}
.upgrade .swiper-box-info > * + *:last-child {
  margin-top: 31px;
}
.upgrade .box-price-info {
  margin-left: auto;
  margin-right: auto;
}
.upgrade .box-price-info > * + * {
  margin-top: 25px;
}
@media (min-width: 480px) {
  .upgrade .box-price-info {
    width: 80%;
  }
}
@media (min-width: 1600px) {
  .upgrade .box-price-info {
    width: 100%;
  }
  .upgrade .box-price-info > * + * {
    margin-top: 50px;
  }
  .upgrade .box-price-info > * + *:last-child {
    margin-top: 25px;
  }
}
.upgrade * + .box-price-info {
  margin-top: 30px;
}
.upgrade .box-price-info-image {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 93px;
}
.upgrade .ie-10 .box-price-info-image:after,
.upgrade .ie-11 .box-price-info-image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: inherit;
}
.upgrade .breadcrumbs-custom {
  position: relative;
  z-index: 1;
  padding: 35px 0 40px;
  vertical-align: middle;
  text-align: center;
}
.upgrade .breadcrumbs-custom > .shell {
  position: relative;
  z-index: 2;
}
.upgrade .breadcrumbs-custom-wrap {
  position: relative;
}
.upgrade .breadcrumbs-custom-path a {
  display: inline;
  vertical-align: baseline;
}
.upgrade .breadcrumbs-custom-path a, .upgrade .breadcrumbs-custom-path a:active, .upgrade .breadcrumbs-custom-path a:focus {
  color: #838386;
}
.upgrade .breadcrumbs-custom-path a:hover {
  color: #ed1c94;
}
.upgrade .breadcrumbs-custom-path li {
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.06em;
}
.upgrade .breadcrumbs-custom-path li:not(:last-child) {
  margin-right: 32px;
}
.upgrade .breadcrumbs-custom-path li:after {
  content: "";
  font-family: "Material Design Icons";
  position: absolute;
  top: 53%;
  right: -25px;
  display: inline-block;
  font-style: normal;
  color: #c2c2c3;
  transform: translateY(-50%);
  font-size: 15px;
}
.upgrade .breadcrumbs-custom-path li:last-child {
  padding-right: 0;
}
.upgrade .breadcrumbs-custom-path li:last-child:after {
  display: none;
}
.upgrade .breadcrumbs-custom-path li.active {
  color: #838386;
}
.upgrade .breadcrumbs-custom__shape {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  max-width: 1600px;
  height: 100%;
}
@media (min-width: 768px) {
  .upgrade .breadcrumbs-custom__shape {
    max-width: 2200px;
  }
}
@media (min-width: 1200px) {
  .upgrade .breadcrumbs-custom__shape {
    max-width: none;
  }
}
.upgrade .breadcrumbs-custom-title {
  position: relative;
  display: inline-block;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .upgrade .breadcrumbs-custom-title {
    padding: 0 40px;
  }
  .upgrade .breadcrumbs-custom-title::before, .upgrade .breadcrumbs-custom-title::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 15px;
    height: 15px;
    background: #29293a;
  }
  .upgrade .breadcrumbs-custom-title::before {
    left: -20px;
  }
  .upgrade .breadcrumbs-custom-title::after {
    right: -16px;
  }
}
.upgrade .breadcrumbs-custom-subtitle {
  font-size: 20px;
  line-height: 1.3;
}
.upgrade * + .breadcrumbs-custom-title {
  margin-top: 12px;
}
.upgrade * + .breadcrumbs-custom-path {
  margin-top: 20px;
}
.upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path a {
  display: inline;
  vertical-align: baseline;
}
.upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path a, .upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path a:active, .upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path a:focus {
  color: #fff;
}
.upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path a:hover {
  color: #ffec17;
}
.upgrade .breadcrumbs-custom-light .breadcrumbs-custom-path li.active {
  color: #fff;
}
.upgrade .breadcrumbs-blog-post {
  padding-bottom: 20px;
}
.upgrade .breadcrumbs-blog-post .breadcrumbs-custom-title {
  max-width: 720px;
}
.upgrade .breadcrumbs-blog-post .breadcrumbs-custom-title:before, .upgrade .breadcrumbs-blog-post .breadcrumbs-custom-title:after {
  content: none;
}
.upgrade .breadcrumbs-blog-post .box-inline {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .upgrade .breadcrumbs-custom-title {
    font-size: 26px;
  }
}
@media (min-width: 480px) {
  .upgrade .breadcrumbs-custom-path > li:not(:last-child) {
    margin-right: 52px;
  }
  .upgrade .breadcrumbs-custom-path > li:after {
    right: -35px;
  }
}
@media (min-width: 768px) {
  .upgrade .breadcrumbs-custom {
    padding: 70px 0 80px;
  }
  .upgrade .breadcrumbs-custom-subtitle {
    font-size: 26px;
  }
  .upgrade * + .breadcrumbs-custom-path {
    margin-top: 50px;
  }
  .upgrade .breadcrumbs-blog-post {
    padding-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .upgrade .breadcrumbs-custom {
    padding: 120px 0 50px;
  }
  .upgrade .breadcrumbs-custom-subtitle {
    font-size: 30px;
  }
  .upgrade .breadcrumbs-custom-path li {
    font-size: 18px;
  }
  .upgrade .breadcrumbs-custom-svg-var-2 {
    padding-top: 235px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1600px) {
  .upgrade .breadcrumbs-blog-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    max-height: 870px;
  }
  .upgrade .breadcrumbs-blog-post * + .breadcrumbs-custom-path {
    margin-top: auto;
  }
  .upgrade .breadcrumbs-blog-post * + .breadcrumbs-custom-title {
    margin-top: 5vh;
  }
  .upgrade .breadcrumbs-blog-post * + .box-inline {
    margin-top: 5vh;
  }
  .upgrade * + .breadcrumbs-custom-path {
    margin-top: 100px;
  }
}
.upgrade .button {
  position: relative;
  display: inline-block;
  max-width: 100%;
  padding: 9px 30px;
  min-width: 210px;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  border: 2px solid;
  border-radius: 35px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: 0.25s all ease;
  white-space: pre-wrap;
}
.upgrade .button:focus, .upgrade .button:active, .upgrade .button:active:focus {
  outline: none;
}
.upgrade .button.disabled {
  color: black;
  background-color: gray;
}
.upgrade .button .caret {
  margin-left: 8px;
}
@media (min-width: 768px) {
  .upgrade .button {
    padding: 14px 38px;
  }
}
@media (min-width: 992px) {
  .upgrade .button {
    min-width: 230px;
  }
}
.upgrade [data-x-mode=true] .button {
  white-space: normal;
}
.upgrade .button-block {
  display: block;
  width: 100%;
}
.upgrade .button-default, .upgrade .button-default:focus {
  color: #29293a;
  background-color: #ededed;
  border-color: #ededed;
}
.upgrade .button-default:hover, .upgrade .button-default:active, .upgrade .button-default:hover {
  color: #fff;
  background-color: #981df0;
  border-color: #981df0;
}
.upgrade .button-primary, .upgrade .button-primary:focus {
  color: #fff;
  background-color: #981df0;
  border-color: #981df0;
}
.upgrade .button-primary:hover, .upgrade .button-primary:active, .upgrade .button-primary:hover {
  color: #fff;
  background-color: #8b0fe4;
  border-color: #8b0fe4;
}
.upgrade .button-secondary, .upgrade .button-secondary:focus {
  color: #fff;
  background-color: #ed1c94;
  border-color: #ed1c94;
}
.upgrade .button-secondary:hover, .upgrade .button-secondary:active, .upgrade .button-secondary:hover {
  color: #fff;
  background-color: #981df0;
  border-color: #981df0;
}
.upgrade .button-default-outline, .upgrade .button-default-outline:focus {
  color: #29293a;
  background-color: #fff;
  border-color: #ededed;
}
.upgrade .button-default-outline:hover, .upgrade .button-default-outline:active, .upgrade .button-default-outline:hover {
  color: #fff;
  background-color: #6d0eb1;
  border-color: #6d0eb1;
}
.upgrade .button-default-outline.button-default-outline_facebook:hover {
  border-color: #4d70a8;
  background: #4d70a8;
}
.upgrade .button-default-outline.button-default-outline_twitter:hover {
  border-color: #02bcf3;
  background: #02bcf3;
}
.upgrade .button-default-outline.button-default-outline_google:hover {
  border-color: #e2411e;
  background: #e2411e;
}
.upgrade .button-facebook, .upgrade .button-facebook:focus {
  color: #fff;
  background-color: #4d70a8;
  border-color: #4d70a8;
}
.upgrade .button-facebook:hover, .upgrade .button-facebook:active, .upgrade .button-facebook:hover {
  color: #fff;
  background-color: #3e5a86;
  border-color: #3e5a86;
}
.upgrade .button-twitter, .upgrade .button-twitter:focus {
  color: #fff;
  background-color: #02bcf3;
  border-color: #02bcf3;
}
.upgrade .button-twitter:hover, .upgrade .button-twitter:active, .upgrade .button-twitter:hover {
  color: #fff;
  background-color: #008ee0;
  border-color: #008ee0;
}
.upgrade .button-google, .upgrade .button-google:focus {
  color: #fff;
  background-color: #e2411e;
  border-color: #e2411e;
}
.upgrade .button-google:hover, .upgrade .button-google:active, .upgrade .button-google:hover {
  color: #fff;
  background-color: #b53418;
  border-color: #b53418;
}
.upgrade .button-facebook-outline, .upgrade .button-facebook-outline:focus {
  color: #4d70a8;
  background-color: transparent;
  border-color: #4d70a8;
}
.upgrade .button-facebook-outline:hover, .upgrade .button-facebook-outline:active, .upgrade .button-facebook-outline:hover {
  color: #fff;
  background-color: #4d70a8;
  border-color: #4d70a8;
}
.upgrade .button-twitter-outline, .upgrade .button-twitter-outline:focus {
  color: #02bcf3;
  background-color: transparent;
  border-color: #02bcf3;
}
.upgrade .button-twitter-outline:hover, .upgrade .button-twitter-outline:active, .upgrade .button-twitter-outline:hover {
  color: #fff;
  background-color: #02bcf3;
  border-color: #02bcf3;
}
.upgrade .button-google-outline, .upgrade .button-google-outline:focus {
  color: #e2411e;
  background-color: transparent;
  border-color: #e2411e;
}
.upgrade .button-google-outline:hover, .upgrade .button-google-outline:active, .upgrade .button-google-outline:hover {
  color: #fff;
  background-color: #e2411e;
  border-color: #e2411e;
}
@media (min-width: 1160px) {
  .upgrade .wrap-buttons-sm .button-sm {
    min-width: 120px;
  }
}
.upgrade .button-shadow {
  box-shadow: 0 6px 8px 0 rgba(110, 123, 157, 0.17);
}
.upgrade .button-shadow:active {
  box-shadow: none;
}
.upgrade .button-shadow.button-secondary {
  box-shadow: 0 6px 10px 0 rgba(237, 28, 148, 0.36);
}
.upgrade .button-shadow.button-secondary:hover {
  box-shadow: 0 6px 10px 0 rgba(152, 29, 240, 0.36);
}
.upgrade .button-shadow.button-secondary:active {
  box-shadow: none;
}
.upgrade .button-xs {
  padding: 9px 28px;
  font-size: 14px;
  line-height: 24px;
  min-width: 150px;
}
.upgrade .button-sm {
  min-width: 170px;
  padding: 8.8px 28px;
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 768px) {
  .upgrade .button-sm {
    padding: 11px 35px;
  }
}
@media (min-width: 1200px) {
  .upgrade .button-sm {
    min-width: 190px;
  }
}
@media (min-width: 1600px) {
  .upgrade .button-sm {
    font-size: 16px;
  }
}
.upgrade .button-lg {
  padding: 17px 65px;
  font-size: 18px;
  line-height: 22px;
}
.upgrade .button-ellipse-md {
  border-radius: 4px;
}
.upgrade .button-ellipse-lg {
  border-radius: 8px;
}
.upgrade .button-square {
  border-radius: 0;
}
.upgrade .button-nina {
  position: relative;
  overflow: hidden;
  line-height: 1.3;
  transition: background 0.3s 0.01s, border-color 0.3s 0.01s;
}
.upgrade .button-nina > span {
  display: none;
  vertical-align: middle;
  line-height: 24px;
}
.upgrade .button-nina .button-original-content {
  display: block;
}
@media (max-width: 991px) {
  .upgrade html:not(.desktop) .button-original-content {
    transition: 0.22s;
    transition-delay: 0s !important;
  }
}
@media (min-width: 992px) {
  .upgrade .desktop .button-nina > span {
    position: relative;
    overflow: hidden;
    display: inline-block;
    line-height: 24px;
    text-align: center;
    opacity: 0.01;
    color: inherit;
    transform: translate3d(0, -10px, 0);
    transition: 0.22s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }
  .upgrade .desktop .button-nina > span:first-child {
    padding-left: 4px;
  }
  .upgrade .desktop .button-nina > span:nth-last-child(2) {
    padding-right: 4px;
  }
  .upgrade .desktop .button-nina > span:empty::after {
    display: inline-block;
    padding: 0 2px;
    content: "\a";
  }
  .upgrade .desktop .button-nina > span:only-child {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .upgrade .desktop .button-nina .button-original-content {
    position: absolute;
    z-index: 1;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    padding: inherit;
    transform: translate3d(0, 0, 0);
    letter-spacing: 0.12em;
    min-width: inherit;
    background-color: transparent;
  }
  .upgrade .desktop .button-nina:hover > span {
    opacity: 1;
    top: 0;
    transform: translate3d(0, 0, 0);
  }
  .upgrade .desktop .button-nina:hover .button-original-content {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition-delay: 0s !important;
  }
}
.upgrade .button-icon .icon,
.upgrade .button-icon-alternate .icon {
  position: relative;
  font-size: 24px;
  vertical-align: middle;
  color: inherit;
}
.upgrade .button-icon.button-xs .icon,
.upgrade .button-icon-alternate.button-xs .icon {
  font-size: 19px;
}
.upgrade .button-icon.button-sm .icon,
.upgrade .button-icon-alternate.button-sm .icon {
  font-size: 22px;
}
.upgrade .button-icon .icon {
  margin-top: -1px;
}
.upgrade .button-icon.button-icon-left .icon {
  padding-right: 5px;
}
.upgrade .button-icon.button-icon-right .icon {
  margin-top: -2px;
  padding-left: 5px;
}
.upgrade .button-icon-alternate {
  position: relative;
  z-index: 1;
  vertical-align: middle;
}
.upgrade .button-icon-alternate .icon,
.upgrade .button-icon-alternate .button-inner {
  display: inline-block;
}
.upgrade .button-icon-alternate::before,
.upgrade .button-icon-alternate .icon {
  width: 60px;
}
.upgrade .button-icon-alternate::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  z-index: -1;
  background: #ededed;
  transition: 0.33s;
}
.upgrade .button-icon-alternate .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.upgrade .button-icon-alternate.button:hover::before {
  width: calc(100% + 4px);
}
.upgrade .ie-10 .button-icon-alternate.button:hover::before,
.upgrade .ie-11 .button-icon-alternate.button:hover::before {
  width: 102%;
}
.upgrade .button-icon-alternate.button-icon-left {
  padding-left: 80px;
  padding-right: 40px;
}
@media (min-width: 768px) {
  .upgrade .button-icon-alternate.button-icon-left {
    padding-left: 100px;
  }
}
.upgrade .button-icon-alternate.button-icon-left::before {
  left: -2px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.upgrade .button-icon-alternate.button-icon-left .icon {
  left: 0;
}
.upgrade .button-icon-alternate.button-icon-left:hover::before {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.upgrade .button-icon-alternate.button-icon-left.button-sm {
  padding-left: 80px;
  padding-right: 30px;
}
.upgrade .button-icon-alternate.button-icon-left.button-xs {
  padding-left: 60px;
  padding-right: 20px;
}
.upgrade .button-icon-alternate.button-icon-right {
  padding-left: 40px;
  padding-right: 80px;
}
@media (min-width: 768px) {
  .upgrade .button-icon-alternate.button-icon-right {
    padding-right: 100px;
  }
}
.upgrade .button-icon-alternate.button-icon-right::before {
  right: -2px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.upgrade .button-icon-alternate.button-icon-right .icon {
  right: 0;
}
.upgrade .button-icon-alternate.button-icon-right:hover::before {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.upgrade .button-icon-alternate.button-icon-right.button-sm {
  padding-left: 30px;
  padding-right: 80px;
}
.upgrade .button-icon-alternate.button-icon-right.button-xs {
  padding-left: 20px;
  padding-right: 60px;
}
.upgrade .button-icon-alternate.button-sm::before,
.upgrade .button-icon-alternate.button-sm .icon {
  width: 50px;
}
.upgrade .button-icon-alternate.button-xs::before,
.upgrade .button-icon-alternate.button-xs .icon {
  width: 42px;
}
.upgrade .button-icon-alternate.button-secondary::before {
  background: rgba(0, 0, 0, 0.2);
}
.upgrade .button-icon-alternate.button-secondary:hover {
  background: #ed1c94;
  border-color: #ed1c94;
}
.upgrade .button-icon-alternate.button-secondary.button-shadow:hover {
  box-shadow: 0 6px 10px 0 rgba(237, 28, 148, 0.36);
}
.upgrade .button-icon-alternate.button-default-outline:hover {
  color: #29293a;
  background-color: transparent;
  border-color: #ededed;
}
.upgrade .button-icon-alternate.button-facebook::before {
  background: #3e5a86;
}
.upgrade .button-icon-alternate.button-facebook-outline .icon {
  color: #fff;
}
.upgrade .button-icon-alternate.button-facebook-outline::before {
  background: #4d70a8;
}
.upgrade .button-icon-alternate.button-facebook-outline:hover {
  background-color: transparent;
}
.upgrade .button-icon-alternate.button-twitter::before {
  background: #008ee0;
}
.upgrade .button-icon-alternate.button-twitter-outline .icon {
  color: #fff;
}
.upgrade .button-icon-alternate.button-twitter-outline::before {
  background: #02bcf3;
}
.upgrade .button-icon-alternate.button-twitter-outline:hover {
  background-color: transparent;
}
.upgrade .button-icon-alternate.button-google::before {
  background: #b53418;
}
.upgrade .button-icon-alternate.button-google-outline .icon {
  color: #fff;
}
.upgrade .button-icon-alternate.button-google-outline::before {
  background: #e2411e;
}
.upgrade .button-icon-alternate.button-google-outline:hover {
  background-color: transparent;
}
.upgrade .btn {
  padding: 10px 20px;
  border-radius: 35px;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  transition: 0.22s;
}
.upgrade .btn-sm {
  font-size: 12px;
  line-height: 1.2;
}
.upgrade .btn-primary, .upgrade .btn-primary:focus {
  color: #fff;
  background-color: #ed1c94;
  border-color: #ed1c94;
}
.upgrade .btn-primary:hover, .upgrade .btn-primary:active, .upgrade .btn-primary:hover {
  color: #fff;
  background-color: #981df0;
  border-color: #981df0;
}
.upgrade .btn-primary:active:focus {
  background: #981df0;
  border-color: #981df0;
  box-shadow: none;
}
.upgrade .btn-cancel {
  border-color: #838386;
}
.upgrade .box-cta {
  font-size: 18px;
  color: #838386;
  margin-left: -20px;
  margin-right: -20px;
}
.upgrade .box-cta > * {
  padding-left: 20px;
  padding-right: 20px;
}
.upgrade .box-cta-title {
  color: #6d0eb1;
}
.upgrade .box-cta-title + * {
  margin-top: 20px;
}
.upgrade .box-cta .button {
  font-size: 18px;
}
.upgrade .box-cta-inline > * + * {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade .box-cta-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.upgrade .box-cta-thin .label-cta {
  margin-right: 18px;
}
.upgrade .label-cta {
  padding: 5px 18px;
  font-size: 16px;
  font-weight: 700;
}
.upgrade .label-cta-primary {
  color: #fff;
  background: #981df0;
}
@media (min-width: 768px) {
  .upgrade .box-cta {
    font-size: 19px;
  }
  .upgrade .box-cta-vertical > * + * {
    margin-top: 40px;
  }
  .upgrade .box-cta-vertical * + .button {
    margin-top: 45px;
  }
}
@media (min-width: 1600px) {
  .upgrade .box-cta {
    font-size: 24px;
  }
  .upgrade .cta-avatar {
    max-width: none;
  }
}
.upgrade .card-info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 730px;
  text-align: left;
}
.upgrade .card-info * + .card-info-subtitle {
  margin-top: 5px;
}
.upgrade .card-info-image {
  border-radius: 9px;
}
.upgrade .card-info-title {
  color: #ed1c94;
}
.upgrade .card-info-subtitle {
  font-style: italic;
}
.upgrade .card-info-aside {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  max-width: 30%;
}
.upgrade .card-info-main {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.upgrade * + .card-info-title {
  margin-top: 10px;
}
.upgrade * + .card-info-main {
  padding-top: 15px;
}
@media (min-width: 480px) {
  .upgrade .card-info {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade * + .card-info-main {
    padding-top: 0;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .upgrade * + .card-info-main {
    padding-left: 30px;
  }
}
@media (min-width: 1600px) {
  .upgrade .card-info * + p {
    margin-top: 26px;
  }
  .upgrade * + .card-info-main {
    padding-left: 45px;
  }
}
.upgrade .comment {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  text-align: left;
}
.upgrade .comment-group .comment-group {
  padding-left: 20px;
}
.upgrade .comment-group .comment-group .comment-group {
  padding-left: 10px;
}
.upgrade .comment-body {
  padding-left: 12px;
}
.upgrade .comment-header {
  display: inline-table;
  transform: translateY(-8px);
  margin-bottom: -8px;
  margin-left: -8px;
}
.upgrade .comment-header > * {
  display: inline-block;
  margin-top: 8px;
  margin-left: 8px;
}
.upgrade .comment-avatar img {
  width: auto;
  height: auto;
  max-width: 30px;
  border-radius: 50%;
}
.upgrade .comment-title {
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  color: #ed1c94;
}
.upgrade .comment-time {
  font-style: italic;
}
.upgrade .comment-text {
  color: #29293a;
}
.upgrade .comment-reply {
  font-style: italic;
  color: #838386;
}
.upgrade .comment-reply::before {
  position: relative;
  top: 2px;
  display: inline-block;
  padding-right: 5px;
  content: "";
  font: 400 20px/20px "Material Design Icons";
  color: #c2c2c3;
}
@media (min-width: 768px) {
  .upgrade .comment-reply::before {
    font-size: 28px;
    line-height: 28px;
  }
}
.upgrade .comment-link-reply {
  letter-spacing: 0.02em;
  color: #ed1c94;
  vertical-align: middle;
}
.upgrade .comment-link-reply::before {
  position: relative;
  top: 2px;
  display: inline-block;
  padding-right: 5px;
  content: "";
  font: 400 20px/20px "Material Design Icons";
}
.upgrade .comment-link-reply:hover {
  color: #981df0;
}
@media (min-width: 768px) {
  .upgrade .comment-link-reply::before {
    font-size: 28px;
    line-height: 28px;
  }
}
.upgrade * + .comment-text {
  margin-top: 8px;
}
.upgrade * + .comment-footer {
  margin-top: 8px;
}
.upgrade * + .comment-group {
  margin-top: 30px;
}
.upgrade * + .comment {
  margin-top: 24px;
}
.upgrade * + .comment {
  margin-top: 24px;
}
.upgrade .comment + * {
  margin-top: 24px;
}
.upgrade * + .comment-link-reply {
  margin-top: 10px;
}
.upgrade .comment-group + .comment-group {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade .comment-group .comment-group {
    padding-left: 80px;
  }
  .upgrade .comment-group .comment-group .comment-group {
    padding-left: 40px;
  }
  .upgrade .comment-body {
    padding-left: 30px;
  }
  .upgrade .comment-header {
    transform: translateY(-5px);
    margin-bottom: -5px;
    margin-left: -35px;
  }
  .upgrade .comment-header > * {
    display: inline-block;
    margin-top: 5px;
    margin-left: 35px;
  }
  .upgrade .comment-avatar img {
    width: auto;
    max-width: 80px;
  }
  .upgrade .comment-title {
    font-size: 18px;
  }
  .upgrade * + .comment-group {
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .upgrade .comment-group .comment-group {
    padding-left: 110px;
  }
  .upgrade .comment-group .comment-group .comment-group {
    padding-left: 55px;
  }
  .upgrade * + .comment-group {
    margin-top: 50px;
  }
  .upgrade .comment-group + .comment-group {
    margin-top: 45px;
  }
}
.upgrade .page-footer .thumbnail-instafeed .caption .list-inline {
  font-size: 16px;
}
.upgrade .page-footer .thumbnail-instafeed .caption .list-inline span {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1800px) {
  .upgrade .page-footer .thumbnail-instafeed .caption .list-inline span {
    max-width: 70px;
  }
}
.upgrade .page-footer-extended .thumbnail-instafeed .caption .icon,
.upgrade .page-footer-default .thumbnail-instafeed .caption .icon,
.upgrade .page-footer-alternative .thumbnail-instafeed .caption .icon {
  font-size: 16px;
}
.upgrade .page-footer-extended .thumbnail-instafeed-minimal .caption .list-inline > li + li,
.upgrade .page-footer-default .thumbnail-instafeed-minimal .caption .list-inline > li + li,
.upgrade .page-footer-alternative .thumbnail-instafeed-minimal .caption .list-inline > li + li {
  margin-top: 5px;
}
.upgrade [class*=bg-] + .page-footer-default,
.upgrade * + .page-footer-default,
.upgrade .page-footer-default {
  padding: 60px 0 20px;
}
.upgrade .page-footer-default .instafeed {
  max-width: 470px;
  margin-right: auto;
}
.upgrade .page-footer-default h6 + * {
  margin-top: 22px;
}
@media (min-width: 1600px) {
  .upgrade .page-footer-default h6 + * {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .upgrade .page-footer-default {
    text-align: left;
  }
  .upgrade [class*=bg-] + .page-footer-default,
.upgrade * + .page-footer-default,
.upgrade .page-footer-default {
    padding: 90px 0 30px;
  }
}
.upgrade .page-footer-alternative {
  padding: 60px 0 40px;
  text-align: left;
}
.upgrade .page-footer-alternative .twitter-feed-minimal-content {
  font-size: 18px;
}
.upgrade .page-footer-alternative .divider-xl {
  margin: 50px auto 40px;
}
.upgrade .page-footer-alternative .instafeed {
  max-width: 320px;
}
.upgrade .page-footer-alternative .list-xs {
  letter-spacing: 0.02em;
}
.upgrade .page-footer-alternative .list-xs > li + li {
  margin-top: 21px;
}
.upgrade .page-footer-alternative .list-comma > li {
  display: block;
}
.upgrade .page-footer-alternative * + .instafeed {
  margin-top: 30px;
}
.upgrade .page-footer-alternative * + .twitter {
  margin-top: 30px;
}
.upgrade .page-footer-alternative * + .list-xs {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade .page-footer-alternative {
    padding: 90px 0 40px;
  }
}
@media (min-width: 1200px) {
  .upgrade .page-footer-alternative {
    padding: 90px 0 50px;
  }
  .upgrade .page-footer-alternative .divider-xl {
    margin: 70px auto 40px;
  }
}
@media (min-width: 1600px) {
  .upgrade .divider-xl {
    margin: 120px auto 40px;
  }
}
.upgrade .page-footer-minimal {
  padding: 55px 0;
}
.upgrade .page-footer-extended {
  position: relative;
  overflow: hidden;
  padding: 70px 0 30px;
}
.upgrade .page-footer-extended * + h6 {
  margin-top: 30px;
}
.upgrade .page-footer-extended * + .instafeed {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .upgrade .page-footer-extended * + h6 {
    margin-top: 40px;
  }
  .upgrade .page-footer-extended * + .flickr {
    margin-top: 40px;
  }
  .upgrade .page-footer-extended * + .instafeed {
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .upgrade .page-footer-extended {
    padding: 60px 0 40px;
  }
  .upgrade .page-footer-extended * + h6 {
    margin-top: 65px;
  }
  .upgrade .page-footer-extended .rd-google-map-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: calc(50% + 250px);
  }
  .upgrade .page-footer-extended .rd-google-map {
    height: 100%;
    max-width: none;
  }
}
@media (min-width: 1600px) {
  .upgrade .page-footer-extended {
    padding: 160px 0 60px;
  }
  .upgrade .page-footer-extended * + h6 {
    margin-top: 115px;
  }
  .upgrade .page-footer-extended .rd-google-map-wrap {
    right: calc(50% + 330px);
  }
}
@media (min-width: 1800px) {
  .upgrade .page-footer-extended p.right {
    margin-left: 15px;
  }
}
.upgrade .page-footer-icon-list {
  margin-bottom: -5px;
  transform: translateY(-5px);
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.upgrade .page-footer-icon-list > li {
  display: inline-block;
  margin-top: 5px;
  margin-left: 7.5px;
  margin-right: 7.5px;
}
@media (min-width: 1600px) {
  .upgrade .page-footer-icon-list {
    margin-bottom: -5px;
    transform: translateY(-5px);
    margin-left: -17.5px;
    margin-right: -17.5px;
  }
  .upgrade .page-footer-icon-list > li {
    display: inline-block;
    margin-top: 5px;
    margin-left: 17.5px;
    margin-right: 17.5px;
  }
}
.upgrade .page-footer-inline-list {
  color: #29293a;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  margin-bottom: -10px;
  transform: translateY(-10px);
  margin-left: -10px;
  margin-right: -10px;
}
.upgrade .page-footer-inline-list > li {
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .upgrade .page-footer-inline-list {
    margin-bottom: -10px;
    transform: translateY(-10px);
    margin-left: -29.5px;
    margin-right: -29.5px;
  }
  .upgrade .page-footer-inline-list > li {
    display: inline-block;
    margin-top: 10px;
    margin-left: 29.5px;
    margin-right: 29.5px;
  }
}
@media (min-width: 1200px) {
  .upgrade .page-footer-inline-list {
    margin-bottom: -10px;
    transform: translateY(-10px);
    margin-left: -12.5px;
    margin-right: -12.5px;
  }
  .upgrade .page-footer-inline-list > li {
    display: inline-block;
    margin-top: 10px;
    margin-left: 12.5px;
    margin-right: 12.5px;
  }
}
@media (min-width: 1600px) {
  .upgrade .page-footer-inline-list {
    margin-bottom: -10px;
    transform: translateY(-10px);
    margin-left: -29.5px;
    margin-right: -29.5px;
  }
  .upgrade .page-footer-inline-list > li {
    display: inline-block;
    margin-top: 10px;
    margin-left: 29.5px;
    margin-right: 29.5px;
  }
}
.upgrade .page-footer-inline-list a {
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.upgrade .page-footer-inline-list a:hover {
  color: #ed1c94;
}
.upgrade p.right {
  letter-spacing: 0.02em;
}
.upgrade p.right a, .upgrade p.right a:focus, .upgrade p.right a:active {
  color: inherit;
}
.upgrade p.right a:hover {
  color: #ed1c94;
}
.upgrade * + .page-footer-icon-list {
  margin-top: 30px;
}
.upgrade * + p.right {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .upgrade * + .page-footer-icon-list {
    margin-top: 35px;
  }
  .upgrade * + p.right {
    margin-top: 50px;
  }
}
@media (min-width: 1600px) {
  .upgrade * + p.right {
    margin-top: 90px;
  }
}
.upgrade .event-default {
  position: relative;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 8px;
  perspective: 1000px;
}
.upgrade .event-default-title {
  color: #981df0;
}
.upgrade .event-default-title:hover {
  color: #ed1c94;
}
.upgrade .event-default-wrap {
  text-align: left;
}
.upgrade .event-default-inner {
  margin-top: 25px;
  color: #838386;
}
.upgrade .event-default-inner > * + * {
  margin-top: 12px;
}
.upgrade .event-default-inner .event-default-link {
  color: #838386;
}
.upgrade .event-default-inner .event-default-link:hover {
  color: #ed1c94;
}
.upgrade .event-default-date-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 5px 15px;
  background-color: #981df0;
  color: #fff;
}
.upgrade .event-default-date-wrap time {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.upgrade .event-default-date,
.upgrade .event-default-time,
.upgrade .event-default-month {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
}
.upgrade .event-default-date {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.upgrade .event-default-month {
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
.upgrade .event-default-time {
  font-weight: 300;
}
.upgrade .event-default-caption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 550ms ease-in-out;
}
.upgrade .event-default-caption > * {
  pointer-events: auto;
}
.upgrade .event-default-caption .button {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .upgrade .desktop .event-default-date-wrap {
    width: auto;
    padding: 5px 5px 10px;
  }
  .upgrade .desktop .event-default-date-wrap time {
    align-items: center;
    flex-direction: column;
  }
  .upgrade .desktop .event-default-date {
    font-size: 22px;
  }
  .upgrade .desktop .event-default-month {
    font-weight: 700;
  }
  .upgrade .desktop .event-default-caption {
    opacity: 0;
  }
  .upgrade .desktop .event-default-caption .button {
    transition: 350ms 200ms opacity, 350ms 200ms transform;
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
    margin-top: 0;
  }
  .upgrade .desktop .event-default-image {
    transition: 550ms ease-in-out;
  }
  .upgrade .desktop .event-default:hover .event-default-caption {
    opacity: 1;
  }
  .upgrade .desktop .event-default:hover .button {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  .upgrade .desktop .event-default:hover .event-default-image {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}
@media (min-width: 1600px) {
  .upgrade .desktop .event-default-date-wrap {
    padding: 10px 15px 10px;
  }
  .upgrade .desktop .event-default-date {
    font-size: 28px;
  }
}
.upgrade .rd-mailform {
  position: relative;
  text-align: left;
}
@media (max-width: 479px) {
  .upgrade .rd-mailform .recaptcha {
    transform-origin: 0 50%;
    transform: scale(0.9);
  }
}
.upgrade input:-webkit-autofill ~ label,
.upgrade input:-webkit-autofill ~ .form-validation {
  color: #000000 !important;
}
.upgrade .form-input {
  display: block;
  width: 100%;
  min-height: 60px;
  padding: 18px 25px;
  font-weight: 300;
  line-height: 24px;
  color: #29293a;
  background-color: #f2f3f9;
  background-image: none;
  border: 0px solid #f2f3f9;
  border-radius: 35px;
  -webkit-appearance: none;
}
.upgrade .form-input:focus {
  outline: 0;
}
@media (min-width: 1600px) {
  .upgrade .form-input {
    font-size: 18px;
  }
}
.upgrade textarea.form-input {
  height: 150px;
  min-height: 120px;
  max-height: 255px;
  resize: vertical;
}
.upgrade textarea.form-input.form-input-lg {
  height: 200px;
  min-height: 160px;
  max-height: 340px;
}
.upgrade .form-wrap {
  position: relative;
}
.upgrade .form-wrap + .form-wrap {
  margin-top: 20px;
}
.upgrade .form-wrap-inline + .form-wrap-inline {
  margin-top: 10px;
}
.upgrade * + .button.form-button,
.upgrade * + .form-button {
  margin-top: 20px;
}
.upgrade .form-label,
.upgrade .form-label-outside {
  font-weight: 300;
  line-height: 24px;
  color: #838386;
}
@media (min-width: 1600px) {
  .upgrade .form-label,
.upgrade .form-label-outside {
    font-size: 18px;
  }
}
.upgrade .form-label {
  position: absolute;
  top: 30px;
  left: 25px;
  z-index: 9;
  transition: 0.3s;
  transform: translateY(-50%);
  pointer-events: none;
}
.upgrade .form-label.focus {
  opacity: 0;
}
.upgrade .form-label.auto-fill {
  color: #29293a;
}
.upgrade .form-label-outside {
  padding-left: 21px;
  margin-bottom: 3px;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #838386;
  pointer-events: auto;
  cursor: pointer;
}
.upgrade .form-label-outside, .upgrade .form-label-outside.focus, .upgrade .form-label-outside.auto-fill {
  opacity: 1;
  transform: none;
}
.upgrade .form-label-outside + .form-input + .form-validation {
  top: 36px;
}
.upgrade .form-validation {
  position: absolute;
  right: 21px;
  top: 3px;
  font-size: 10px;
  line-height: 11px;
  font-weight: 400;
  color: #d9534f;
  transition: 0.3s;
  z-index: 11;
}
.upgrade .form-validation-left .form-validation {
  right: auto;
  top: 100%;
  left: 0;
}
.upgrade #form-output-global {
  position: fixed;
  bottom: 0;
  visibility: hidden;
  transform: translateY(calc(100% + 30px));
  transition: 0.3s all ease;
  z-index: 9999999;
  margin: 15px;
}
.upgrade #form-output-global.active {
  transform: translateY(0);
  visibility: visible;
}
@media (min-width: 768px) {
  .upgrade #form-output-global {
    margin: 30px;
  }
}
.upgrade .form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 2px;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.upgrade .form-output.active {
  opacity: 1;
  visibility: visible;
}
.upgrade .form-output.error {
  color: #d9534f;
}
.upgrade .form-output.success {
  color: #5cb85c;
}
.upgrade .radio, .upgrade .radio-inline,
.upgrade .checkbox, .upgrade .checkbox-inline {
  font-weight: 300;
  color: #838386;
}
.upgrade .radio a, .upgrade .radio-inline a,
.upgrade .checkbox a, .upgrade .checkbox-inline a {
  font-weight: inherit;
}
.upgrade .radio .radio-custom,
.upgrade .radio-inline .radio-custom,
.upgrade .checkbox .checkbox-custom,
.upgrade .checkbox-inline .checkbox-custom {
  opacity: 0;
}
.upgrade .radio .radio-custom, .upgrade .radio .radio-custom-dummy,
.upgrade .radio-inline .radio-custom,
.upgrade .radio-inline .radio-custom-dummy,
.upgrade .checkbox .checkbox-custom,
.upgrade .checkbox .checkbox-custom-dummy,
.upgrade .checkbox-inline .checkbox-custom,
.upgrade .checkbox-inline .checkbox-custom-dummy {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: 5px;
  outline: none;
  cursor: pointer;
}
.upgrade .radio .radio-custom-dummy,
.upgrade .radio-inline .radio-custom-dummy,
.upgrade .checkbox .checkbox-custom-dummy,
.upgrade .checkbox-inline .checkbox-custom-dummy {
  pointer-events: none;
  background: #fff;
  border: 1px solid #dedfe8;
}
.upgrade .radio .radio-custom-dummy:after,
.upgrade .radio-inline .radio-custom-dummy:after,
.upgrade .checkbox .checkbox-custom-dummy:after,
.upgrade .checkbox-inline .checkbox-custom-dummy:after {
  position: absolute;
  opacity: 0;
}
.upgrade .radio .radio-custom:focus,
.upgrade .radio-inline .radio-custom:focus,
.upgrade .checkbox .checkbox-custom:focus,
.upgrade .checkbox-inline .checkbox-custom:focus {
  outline: none;
}
.upgrade .checkbox a,
.upgrade .radio a,
.upgrade .checkbox-inline a,
.upgrade .radio-inline a {
  display: inline;
  padding: 0 0.2em;
}
.upgrade .radio,
.upgrade .radio-inline {
  padding-left: 36px;
  color: #838386;
  font-weight: 300;
}
.upgrade .radio .radio-custom-dummy,
.upgrade .radio-inline .radio-custom-dummy {
  top: -5px;
  left: 0;
  width: 26px;
  height: 26px;
  box-shadow: inset 0 0 0 0 #dedfe8;
  border-radius: 50%;
  transition: 0.22s;
}
.upgrade .radio .radio-custom:checked + .radio-custom-dummy,
.upgrade .radio-inline .radio-custom:checked + .radio-custom-dummy {
  box-shadow: inset 0 0 0 7px #981df0;
}
@media (min-width: 768px) {
  .upgrade .radio,
.upgrade .radio-inline {
    padding-left: 40px;
  }
  .upgrade .radio .radio-custom-dummy,
.upgrade .radio-inline .radio-custom-dummy {
    right: -3px;
  }
}
.upgrade .checkbox,
.upgrade .checkbox-inline {
  padding-left: 38px;
}
.upgrade .checkbox .checkbox-custom-dummy,
.upgrade .checkbox-inline .checkbox-custom-dummy {
  left: 0;
  width: 26px;
  height: 26px;
  margin-top: 0;
  margin-left: 0;
  pointer-events: none;
  border-radius: 5px;
  transition: 0.22s;
}
.upgrade .checkbox .checkbox-custom-dummy:after,
.upgrade .checkbox-inline .checkbox-custom-dummy:after {
  content: "";
  font-family: "Material Design Icons";
  font-size: 14px;
  line-height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateY(180deg);
  color: #fff;
  opacity: 0;
  transition: 0.22s;
}
.upgrade .checkbox .checkbox-custom:checked + .checkbox-custom-dummy,
.upgrade .checkbox-inline .checkbox-custom:checked + .checkbox-custom-dummy {
  background: #981df0;
  border-color: #981df0;
}
.upgrade .checkbox .checkbox-custom:checked + .checkbox-custom-dummy::after,
.upgrade .checkbox-inline .checkbox-custom:checked + .checkbox-custom-dummy::after {
  opacity: 1;
  transform: translate(-50%, -50%) rotateY(0deg);
}
@media (min-width: 992px) {
  .upgrade .checkbox .checkbox-custom-dummy,
.upgrade .checkbox-inline .checkbox-custom-dummy {
    margin-top: 1px;
  }
}
.upgrade .checkbox.checkbox-material,
.upgrade .checkbox-inline.checkbox-material {
  padding-left: 60px;
}
.upgrade .checkbox.checkbox-material .checkbox-custom-dummy,
.upgrade .checkbox-inline.checkbox-material .checkbox-custom-dummy {
  margin-top: -1px;
  width: 50px;
  height: 28px;
  border-radius: 22px;
}
.upgrade .checkbox.checkbox-material .checkbox-custom-dummy::after,
.upgrade .checkbox-inline.checkbox-material .checkbox-custom-dummy::after {
  content: "";
  width: 20px;
  height: 20px;
  left: 0;
  background: #dedfe8;
  transform: translate(3px, -50%);
  border-radius: 50%;
  opacity: 1;
}
.upgrade .checkbox.checkbox-material .checkbox-custom:checked + .checkbox-custom-dummy,
.upgrade .checkbox-inline.checkbox-material .checkbox-custom:checked + .checkbox-custom-dummy {
  background: #fff;
}
.upgrade .checkbox.checkbox-material .checkbox-custom:checked + .checkbox-custom-dummy::after,
.upgrade .checkbox-inline.checkbox-material .checkbox-custom:checked + .checkbox-custom-dummy::after {
  background: #981df0;
  transform: translate(25px, -50%);
}
.upgrade .radio-group > li + li {
  margin-top: 16px;
}
.upgrade .rd-mailform-inline .rd-mailform-inline-inner {
  position: relative;
  z-index: 1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  transform: translateY(-15px);
  margin-bottom: -15px;
}
.upgrade .rd-mailform-inline .rd-mailform-inline-inner::before {
  content: "";
  position: absolute;
  top: 15px;
  height: 60px;
  left: 0;
  right: 0;
  background: #f2f3f9;
  z-index: -1;
  border-radius: 35px;
}
.upgrade .rd-mailform-inline .rd-mailform-inline-inner > * {
  margin-top: 15px;
}
.upgrade .rd-mailform-inline .form-wrap {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.upgrade .rd-mailform-inline .form-input {
  padding-right: 18px;
  min-width: 150px;
  color: #29293a;
}
.upgrade .rd-mailform-inline .form-button {
  min-width: 130px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.upgrade .rd-mailform-inline .form-validation {
  left: 25px;
  top: 1px;
}
.upgrade .rd-mailform-inline.rd-mailform-sm .rd-mailform-inline-inner::before {
  height: 50px;
}
.upgrade .rd-mailform-inline-modern .rd-mailform-inline-inner::before {
  top: 15px;
  height: 70px;
}
.upgrade .rd-mailform-inline-modern .rd-mailform-inline-inner {
  padding: 6px 5px 5px;
  transform: translateY(-15px);
  margin-bottom: -15px;
}
.upgrade .rd-mailform-inline-modern .rd-mailform-inline-inner > * {
  margin-top: 15px;
}
.upgrade .rd-mailform-inline-modern.rd-mailform-sm .rd-mailform-inline-inner::before {
  height: 62px;
}
.upgrade .rd-mailform-sm .form-input,
.upgrade .rd-mailform-sm .form-label {
  color: #29293a;
}
.upgrade .rd-mailform-sm .form-input,
.upgrade .rd-mailform-sm .button {
  min-height: 50px;
}
.upgrade .rd-mailform-sm .form-input {
  padding-top: 12px;
  padding-bottom: 12px;
}
.upgrade .rd-mailform-sm .form-label {
  top: 25px;
}
.upgrade .form-wrap-icon:before {
  position: absolute;
  top: 9px;
  left: 15px;
  content: "";
  display: inline-block !important;
  font-family: "Material Design Icons";
  font-size: 24px;
  color: #29293a;
}
.upgrade .form-wrap-icon .form-label {
  left: 50px;
}
.upgrade .form-wrap-icon .form-input {
  padding-left: 50px;
}
.upgrade .rd-mailform-small {
  max-width: 370px;
}
.upgrade .text-center .rd-mailform-small {
  margin-left: auto;
  margin-right: auto;
}
.upgrade .form-request {
  padding: 30px 15px;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-color: #fff;
  color: #29293a;
}
@media (min-width: 1200px) {
  .upgrade .form-request {
    padding: 40px 35px;
  }
}
.upgrade .form-request-modern {
  max-width: 566px;
  background-color: #f2f3f9;
}
.upgrade .form-request-modern .select2-container--bootstrap .select2-selection,
.upgrade .form-request-modern .form-input {
  background-color: #fff;
}
.upgrade .form-request-modern .select2-container--bootstrap .select2-selection--single .select2-selection__rendered,
.upgrade .form-request-modern .form-input,
.upgrade .form-request-modern .form-label {
  color: #29293a;
}
.upgrade .form-flex-inline {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-end;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -15px;
  transform: translateY(-15px);
}
.upgrade .form-flex-inline-item {
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 15px;
}
.upgrade .form-flex-inline-item-static {
  width: 100%;
}
@media (min-width: 768px) {
  .upgrade .form-flex-inline-item-static {
    width: 27%;
  }
}
@media (min-width: 992px) {
  .upgrade .form-flex-inline-item-static {
    width: 19%;
    max-width: 185px;
  }
}
@media (min-width: 1200px) {
  .upgrade .form-flex-inline-item-static {
    max-width: 239px;
    width: 14.1%;
  }
}
.upgrade .form-flex-inline-item-custom {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.upgrade .form-flex-inline .form-button .button {
  min-width: 190px;
}
@media (min-width: 768px) {
  .upgrade .form-flex-inline {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .upgrade .form-flex-inline {
    flex-wrap: nowrap;
  }
  .upgrade .form-flex-inline-item-custom {
    flex-grow: 0;
    justify-content: flex-start;
    margin-top: 15px;
  }
}
.upgrade .form-request-modern {
  margin-top: 30px;
  margin-bottom: 30px;
}
.upgrade .form-request-modern * + .rd-mailform {
  margin-top: 10px;
}
.upgrade .form-request-modern * + .form-button {
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .upgrade .form-request-modern {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1600px) {
  .upgrade .form-request-modern * + .form-button {
    margin-top: 40px;
  }
}
.upgrade .form-find {
  box-shadow: 0 4px 29px 0 rgba(110, 123, 157, 0.29);
  background-color: #fff;
  border-radius: 9px;
  text-align: left;
  overflow: hidden;
}
.upgrade .form-find.form-find-white .form-input {
  background-color: #f2f3f9;
}
.upgrade .form-find.form-find-white .form-input,
.upgrade .form-find.form-find-white .form-label {
  letter-spacing: 0.02em;
  color: #29293a;
}
.upgrade .form-find-header {
  padding: 0.9em 2.2em;
  color: #fff;
  background-color: #981df0;
}
.upgrade .form-find-header > * {
  color: inherit;
}
.upgrade .form-find-body {
  padding: 20px 20px 30px;
}
.upgrade .form-find-body.rd-mailform {
  margin-top: 0;
}
@media (min-width: 480px) {
  .upgrade .form-find-body {
    padding: 37px 47px 50px;
  }
}
.upgrade .bg-white .form-find {
  background-color: #fff;
}
.upgrade .bg-white .form-find .form-label,
.upgrade .bg-white .form-find .form-input {
  color: #29293a;
}
.upgrade .gallery-item {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  width: 100.1%;
  perspective: 1000px;
}
.upgrade .gallery-item > * {
  width: 100%;
}
.upgrade .gallery-item figure {
  width: 100%;
  transition: 0.55s all ease-in-out;
}
.upgrade .gallery-item img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: auto;
  max-width: none;
  min-width: 101.5%;
}
.upgrade .gallery-item .caption {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 20px;
  transition: 0.33s all ease-in-out;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);
}
.upgrade .gallery-item .caption > * {
  pointer-events: auto;
  position: relative;
  z-index: 2;
}
.upgrade .gallery-item .caption:before {
  position: absolute;
  bottom: 0;
  right: 0;
  transform-origin: 0 100%;
  transform: rotate(45deg);
  content: "";
  display: inline-block;
  width: 100px;
  height: 160px;
  background-color: #ed1c94;
}
.upgrade .gallery-item .caption:after {
  position: absolute;
  bottom: 0;
  right: 10px;
  content: "";
  font-family: "Material Design Icons";
  font-size: 36px;
  color: #fff;
}
.upgrade .gallery-item .caption-title,
.upgrade .gallery-item .caption-text {
  color: #fff;
}
.upgrade .gallery-item .caption-title {
  position: relative;
  font-size: 24px;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.upgrade .gallery-item .caption-text {
  max-width: 100%;
  margin-top: 25px;
  padding: 0 15px;
  color: #fff;
  opacity: 0.6;
}
.upgrade .titled-gallery-item .caption {
  background: transparent;
}
.upgrade .titled-gallery-item .caption:before {
  content: none;
}
.upgrade .titled-gallery-item .caption:after {
  content: none;
}
.upgrade .titled-gallery-caption {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: left;
  color: #ed1c94;
  transition: 550ms ease-in-out;
  font-weight: 400;
}
.upgrade .title-inside-gallery-text {
  margin-top: 15px;
  color: #ededed;
  transition: 0.33s all ease-in-out;
}
.upgrade .title-inside-gallery-title {
  font-size: 24px;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #fff;
}
.upgrade .title-inside-gallery-title:hover {
  color: #ffec17;
}
.upgrade .title-inside-gallery-caption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 15px 15px 25px;
  text-align: left;
  pointer-events: none;
}
.upgrade .title-inside-gallery-caption > * {
  position: relative;
  pointer-events: auto;
}
.upgrade .title-inside-gallery-caption:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  transition: 0.33s all ease-in-out;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);
}
.upgrade .gallery-item-fullwidth {
  max-width: 100.1%;
  max-height: 300px;
}
@media (min-width: 992px) {
  .upgrade .gallery-item-fullwidth {
    max-height: 100%;
  }
}
@media (min-width: 992px) {
  .upgrade .desktop .gallery-item .caption {
    transition: 0.4s all ease;
    opacity: 0;
  }
  .upgrade .desktop .gallery-item .caption:before {
    transform: rotate(90deg);
    transition: 350ms 250ms transform;
  }
  .upgrade .desktop .gallery-item .caption:after {
    transition: 350ms 450ms transform, 350ms 450ms opacity;
    transform: translateX(100%);
    opacity: 0;
  }
  .upgrade .desktop .gallery-item .caption-title,
.upgrade .desktop .gallery-item .caption-text {
    transition: 300ms 50ms ease-in-out;
    transform: scale3d(0.7, 0.7, 0);
  }
  .upgrade .desktop .gallery-item .caption-text {
    max-width: 340px;
  }
  .upgrade .desktop .gallery-item:hover figure {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  .upgrade .desktop .gallery-item:hover .caption {
    opacity: 1;
  }
  .upgrade .desktop .gallery-item:hover .caption:before {
    transform: rotate(45deg);
  }
  .upgrade .desktop .gallery-item:hover .caption:after {
    transform: translateX(0);
    opacity: 1;
  }
  .upgrade .desktop .gallery-item:hover .caption-title,
.upgrade .desktop .gallery-item:hover .caption-text {
    transform: scale3d(1, 1, 1);
  }
  .upgrade .desktop .titled-gallery-caption {
    font-size: 24px;
  }
  .upgrade .desktop .titled-gallery-item .caption {
    perspective: 1000px;
    background: rgba(237, 28, 148, 0.6);
  }
  .upgrade .desktop .titled-gallery-item .caption:after {
    position: absolute;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    content: "";
    font-family: "Material Design Icons";
    color: #fff;
    z-index: 2;
    font-size: 60px;
    transform: translate3d(-50%, -50%, -100px);
    transition: 550ms ease-in-out;
  }
  .upgrade .desktop .titled-gallery-item:hover ~ .titled-gallery-caption {
    color: #981df0;
  }
  .upgrade .desktop .titled-gallery-item:hover .caption:after {
    transform: translate3d(-50%, -50%, 0);
  }
  .upgrade .desktop .title-inside-gallery-text {
    opacity: 0;
    margin-bottom: -66px;
    max-width: 350px;
  }
  .upgrade .desktop .title-inside-gallery-caption:before {
    opacity: 0;
  }
  .upgrade .desktop .title-inside-gallery-item:hover .title-inside-gallery-text {
    opacity: 1;
    margin-bottom: 0;
  }
  .upgrade .desktop .title-inside-gallery-item:hover .title-inside-gallery-caption:before {
    opacity: 1;
  }
}
@media (min-width: 1600px) {
  .upgrade .title-inside-gallery-caption {
    padding: 35px;
  }
}
.upgrade .icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
}
.upgrade .icon:before {
  display: inline-block;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.upgrade .page .icon-default {
  color: #838386;
}
.upgrade .page .icon-white {
  color: #fff;
}
.upgrade .page .icon-gray {
  color: #555555;
}
.upgrade .page .icon-gray-1 {
  color: #c2c2c3;
}
.upgrade .page .icon-gray-light {
  color: #838386;
}
.upgrade .page .icon-darker {
  color: #29293a;
}
.upgrade .page .icon-primary {
  color: #981df0;
}
.upgrade .page .icon-secondary {
  color: #ed1c94;
}
.upgrade .page .icon-filled {
  color: #fff;
  background: #29293a;
}
.upgrade .page .icon-filled[class*=facebook] {
  background: #4d70a8;
}
.upgrade .page .icon-filled[class*=twitter] {
  background: #02bcf3;
}
.upgrade .page .icon-filled[class*=instagram] {
  background: #444;
}
.upgrade .page .icon-filled[class*=google] {
  background: #e2411e;
}
.upgrade .page .icon-filled[class*=linkedin] {
  background: #1787b1;
}
.upgrade .page .icon-secondary-5-filled {
  color: #c2c2c3;
  background: #21212e;
}
.upgrade .page a.icon-default:hover {
  color: #ed1c94;
}
.upgrade .page a.icon-darker:hover {
  color: #981df0;
}
.upgrade .page a.icon-white:hover {
  color: #ffec17;
}
.upgrade .page a.icon-gray-1:hover {
  color: #ed1c94;
}
.upgrade .page a.icon-gray-light:hover {
  color: #ffec17;
}
.upgrade .page a.icon-gray-light[class*=facebook]:hover {
  color: #3e5a86;
}
.upgrade .page a.icon-gray-light[class*=twitter]:hover {
  color: #008ee0;
}
.upgrade .page a.icon-gray-light[class*=instagram]:hover {
  color: #2b2b2b;
}
.upgrade .page a.icon-gray-light[class*=google]:hover {
  color: #b53418;
}
.upgrade .page a.icon-gray-light[class*=linkedin]:hover {
  color: #116584;
}
.upgrade .page a.icon-filled[class*=facebook]:hover {
  background: #3e5a86;
}
.upgrade .page a.icon-filled[class*=twitter]:hover {
  background: #008ee0;
}
.upgrade .page a.icon-filled[class*=instagram]:hover {
  background: #2b2b2b;
}
.upgrade .page a.icon-filled[class*=google]:hover {
  background: #b53418;
}
.upgrade .page a.icon-filled[class*=linkedin]:hover {
  background: #116584;
}
.upgrade .page a.icon-secondary-5-filled:hover {
  color: #fff;
  background: #ed1c94;
}
.upgrade .page a.icon-secondary-5-filled[class*=facebook]:hover {
  background: #4d70a8;
}
.upgrade .page a.icon-secondary-5-filled[class*=twitter]:hover {
  background: #02bcf3;
}
.upgrade .page a.icon-secondary-5-filled[class*=instagram]:hover {
  background: #444;
}
.upgrade .page a.icon-secondary-5-filled[class*=google]:hover {
  background: #e2411e;
}
.upgrade .page a.icon-secondary-5-filled[class*=linkedin]:hover {
  background: #1787b1;
}
.upgrade .page .icon-xs {
  font-size: 13px;
}
.upgrade .page .icon-sm {
  font-size: 19px;
}
.upgrade .page .icon-sm-bigger {
  font-size: 22px;
}
.upgrade .page .icon-md-smaller {
  font-size: 20px;
}
.upgrade .page .icon-md-middle {
  font-size: 24px;
}
.upgrade .page .icon-md {
  font-size: 27px;
}
.upgrade .page .icon-md-big {
  font-size: 36px;
}
.upgrade .page .icon-lg-smaller {
  font-size: 40px;
}
.upgrade .page .icon-lg {
  font-size: 62px;
  line-height: 62px;
}
@media (min-width: 1600px) {
  .upgrade .page .icon-xs {
    font-size: 13px;
  }
  .upgrade .page .icon-sm {
    font-size: 19px;
  }
  .upgrade .page .icon-sm-bigger {
    font-size: 22px;
  }
  .upgrade .page .icon-md-smaller {
    font-size: 23px;
  }
  .upgrade .page .icon-md-middle {
    font-size: 24px;
  }
  .upgrade .page .icon-md {
    font-size: 27px;
  }
  .upgrade .page .icon-md-big {
    font-size: 36px;
  }
  .upgrade .page .icon-lg-smaller {
    font-size: 40px;
  }
  .upgrade .page .icon-lg {
    font-size: 62px;
    line-height: 62px;
  }
}
.upgrade .icon-circle {
  border-radius: 100%;
}
.upgrade .icon.icon-circle {
  width: 1.75em;
  height: 1.75em;
  line-height: 1.75em;
}
@media (min-width: 768px) {
  .upgrade .icon.icon-circle {
    width: 1.9em;
    height: 1.9em;
    line-height: 1.9em;
  }
}
@media (min-width: 1600px) {
  .upgrade .icon.icon-circle {
    width: 2.25em;
    height: 2.25em;
    line-height: 2.25em;
  }
}
.upgrade .icon.icon-round {
  border-radius: 4px;
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
}
.upgrade .link-video {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  line-height: 1;
}
.upgrade .link-video, .upgrade .link-video:active, .upgrade .link-video:focus {
  color: #fff;
}
.upgrade .link-video:hover {
  color: #ffec17;
}
.upgrade .link-video::before {
  position: static;
  display: inline-block;
  overflow: hidden;
  content: "";
  font-family: "Material Design Icons";
  font-size: 50px;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .upgrade .link-video::before {
    font-size: 75px;
  }
}
@media (min-width: 1600px) {
  .upgrade .link-video::before {
    font-size: 100px;
  }
}
.upgrade .link-bold {
  font-weight: 700;
  color: #ed1c94;
}
.upgrade .link-bold:hover {
  color: #981df0;
}
.upgrade .link-image {
  display: inline-block;
  max-width: 80%;
  text-align: center;
}
.upgrade .link-image img {
  opacity: 0.5;
  width: auto;
  transition: 0.33s;
}
.upgrade .link-image:hover img {
  opacity: 1;
}
.upgrade .link-text-color {
  color: #838386;
}
.upgrade .link-text-color:hover {
  color: #ed1c94;
}
.upgrade .list-xxs > li + li {
  margin-top: 10px;
}
.upgrade .list-xs > li + li {
  margin-top: 17px;
}
.upgrade .list-sm > li + li {
  margin-top: 20px;
}
.upgrade .list-lg > li + li {
  margin-top: 25px;
}
.upgrade .list-xl > li + li {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .upgrade .list-lg > li + li {
    margin-top: 45px;
  }
  .upgrade .list-xl > li + li {
    margin-top: 60px;
  }
}
@media (min-width: 1200px) {
  .upgrade .list-sm > li + li {
    margin-top: 30px;
  }
  .upgrade .list-lg > li + li {
    margin-top: 60px;
  }
  .upgrade .list-xl > li + li {
    margin-top: 80px;
  }
}
@media (min-width: 1600px) {
  .upgrade .list-xl > li + li {
    margin-top: 120px;
  }
}
.upgrade .list-marked {
  padding-left: 25px;
  text-align: left;
}
.upgrade .list-marked > li {
  position: relative;
  padding-left: 25px;
}
.upgrade .list-marked > li:before {
  position: absolute;
  top: 0.21em;
  left: 0;
  content: "";
  font-family: "Material Design Icons";
  font-size: 1.22em;
  font-weight: 400;
  line-height: 1;
  color: #b7b7b7;
}
.upgrade .list-marked a {
  color: #29293a;
}
.upgrade .list-marked a:hover {
  color: #981df0;
}
.upgrade .list-marked > li + li {
  margin-top: 10px;
}
.upgrade .list-marked-primary {
  padding-left: 0;
}
.upgrade .list-marked-primary > li:before {
  color: #c2c2c3;
}
.upgrade .list-marked-primary > li a {
  color: #981df0;
}
.upgrade .list-marked-primary > li a:hover {
  color: #29293a;
}
.upgrade .list-marked-secondary a {
  color: #ed1c94;
}
.upgrade .list-marked-secondary a:hover {
  color: #981df0;
}
@media (min-width: 1200px) {
  .upgrade .list-marked-primary > li {
    padding-left: 35px;
  }
}
.upgrade .list-ordered {
  padding-left: 25px;
  counter-reset: li;
  text-align: left;
}
.upgrade .list-ordered li {
  position: relative;
  padding-left: 25px;
}
.upgrade .list-ordered li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 15px;
  content: counter(li, decimal) ".";
  counter-increment: li;
  color: #29293a;
}
.upgrade .list-ordered li + li {
  margin-top: 10px;
}
.upgrade .list-darker {
  color: #29293a;
}
.upgrade .list-darker a {
  color: inherit;
}
.upgrade .list-darker a:hover {
  color: #981df0;
}
.upgrade .list-comma > li {
  display: inline-block;
}
.upgrade .list-comma > li:not(:last-child):after {
  content: ",";
  display: inline-block;
  margin-right: 3px;
}
.upgrade .list-twitter {
  color: #29293a;
}
.upgrade .list-twitter .twitter-icon {
  color: #02bcf3;
}
.upgrade .list-twitter time {
  display: block;
  color: #c2c2c3;
}
.upgrade .list-twitter * + time {
  margin-top: 8px;
}
.upgrade .list-twitter > li + li {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .upgrade .list-twitter > li + li {
    margin-top: 30px;
  }
}
.upgrade .list-inline-md {
  margin-bottom: -5px;
  transform: translateY(-5px);
  margin-left: -4px;
  margin-right: -4px;
}
.upgrade .list-inline-md > li {
  display: inline-block;
  margin-top: 5px;
  margin-left: 4px;
  margin-right: 4px;
}
@media (min-width: 1600px) {
  .upgrade .list-inline-md {
    margin-bottom: -5px;
    transform: translateY(-5px);
    margin-left: -15px;
    margin-right: -15px;
  }
  .upgrade .list-inline-md > li {
    display: inline-block;
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.upgrade * + .list-inline-md {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .upgrade * + .list-inline-md {
    margin-top: 40px;
  }
}
.upgrade .index-list {
  counter-reset: li;
  text-align: left;
  padding-top: 70px;
}
@media (min-width: 1600px) {
  .upgrade .index-list {
    padding-top: 150px;
  }
}
.upgrade .index-list .index-list-content {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1600px) {
  .upgrade .index-list .index-list-content {
    margin-left: 0;
  }
}
.upgrade .index-list > li .list-index-counter {
  position: relative;
  z-index: 1;
}
.upgrade .index-list > li .list-index-counter:before {
  z-index: -1;
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 170px;
  line-height: 130px;
  font-weight: 900;
  color: #f2f3f9;
  transform: translateY(-78%);
}
.upgrade .index-list > li p {
  letter-spacing: 0.02em;
}
.upgrade .index-list > li h6 + p, .upgrade .index-list > li .heading-6 + p {
  margin-top: 20px;
}
.upgrade .bg-gray-lighter .index-list > li .list-index-counter:before {
  color: #fff;
}
.upgrade .bg-gray-darker .index-list > li .list-index-counter:before, .upgrade .bg-additional-gray-darker .bg-additional-item .index-list > li .list-index-counter:before {
  color: #981df0;
}
.upgrade .bg-gray-darker .index-list-content p, .upgrade .bg-additional-gray-darker .bg-additional-item .index-list-content p {
  color: #c2c2c3;
}
.upgrade .inline-list {
  margin-bottom: -12px;
  transform: translateY(-12px);
  margin-left: -3.5px;
  margin-right: -3.5px;
}
.upgrade .inline-list > li {
  display: inline-block;
  margin-top: 12px;
  margin-left: 3.5px;
  margin-right: 3.5px;
}
@media (min-width: 1200px) {
  .upgrade .inline-list {
    margin-bottom: -10px;
    transform: translateY(-10px);
    margin-left: -20px;
    margin-right: -20px;
  }
  .upgrade .inline-list > li {
    display: inline-block;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.upgrade .inline-list-xs {
  margin-bottom: -12px;
  transform: translateY(-12px);
  margin-left: -10px;
  margin-right: -10px;
}
.upgrade .inline-list-xs > li {
  display: inline-block;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
}
.upgrade * + .inline-list {
  margin-top: 20px;
}
.upgrade .inline-list + .inline-list {
  margin-top: 25px;
}
@media (min-width: 1600px) {
  .upgrade * + .inline-list {
    margin-top: 40px;
  }
  .upgrade .inline-list + .inline-list {
    margin-top: 45px;
  }
}
.upgrade .list-terms dt + dd {
  margin-top: 15px;
}
.upgrade .list-terms dd + dt {
  margin-top: 25px;
}
@media (min-width: 1600px) {
  .upgrade .list-terms dt + dd {
    margin-top: 20px;
  }
  .upgrade .list-terms dd + dt {
    margin-top: 50px;
  }
}
.upgrade .modal-custom {
  padding-left: 20px;
  padding-right: 20px;
  z-index: 9999;
}
.upgrade .modal-custom .modal-dialog {
  width: 100%;
  max-width: 830px;
  margin: 0;
}
.upgrade .modal-custom .modal-header {
  border-bottom: 0;
  padding-right: 40px;
}
.upgrade .modal-custom .modal-content {
  padding: 10px;
  margin: 10px;
  border: 0;
  box-shadow: 0 0 24px rgba(127, 131, 154, 0.42);
}
.upgrade .modal-custom .modal-body {
  padding-top: 5px;
}
.upgrade .modal-custom .close {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 1;
  text-shadow: none;
  z-index: 2;
}
.upgrade .modal-custom .close > span {
  color: #ed1c94;
}
.upgrade .modal-custom .close > span:before {
  content: "";
  font: 400 40px/1 "Material Design Icons";
  display: inline-block;
}
.upgrade .modal-custom .close > span:hover {
  color: #981df0;
}
.upgrade .modal-custom * + form {
  margin-top: 30px;
}
.upgrade .modal-photo .modal-title {
  color: #fff;
}
.upgrade .modal-photo .close {
  background-color: rgba(0, 0, 0, 0.2);
}
.upgrade .modal-account .modal-dialog {
  max-width: 1170px;
}
.upgrade .modal-account-image {
  display: none;
}
.upgrade .modal-product .modal-dialog {
  max-width: 450px;
  margin: 10px auto;
}
.upgrade .modal-product .modal-body {
  display: flex;
  flex-wrap: wrap;
  transform: translateY(-30px);
  margin-bottom: -30px;
  margin-left: -15px;
  margin-right: -15px;
}
.upgrade .modal-product .modal-body > * {
  flex-grow: 1;
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.upgrade .modal-product-image {
  max-width: 200px;
}
.upgrade .modal-product-image img {
  box-shadow: 5px 15px 30px rgba(0, 0, 0, 0.35);
}
.upgrade .modal-price {
  font: 400 24px/1 "Oswald", Helvetica, Arial, sans-serif;
  letter-spacing: 0.12em;
  color: #29293a;
}
.upgrade .modal-sale .modal-dialog {
  max-width: 1170px;
}
.upgrade .sale-body-subtitle {
  font-size: 18px;
}
.upgrade .sale-body-title {
  font-size: 30px;
  line-height: 1;
  font-weight: 900;
}
.upgrade .notification .modal-content {
  border: 0;
}
.upgrade .notification .modal-dialog {
  margin: 10px;
}
.upgrade .notification-open {
  overflow-x: hidden;
  overflow-y: auto;
}
.upgrade .notification-dark .modal-content {
  background-color: #29293a;
  color: #fff;
}
.upgrade .modal.fade.slideRight .modal-dialog {
  transition: 650ms ease-in-out;
  transform: translate3d(0, 0, 0);
}
.upgrade .modal.fade.slideRight:not(.in) .modal-dialog {
  transform: translate3d(100%, 0, 0);
}
@media (min-width: 768px) {
  .upgrade .modal-custom .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .upgrade .modal-custom.fade .modal-dialog {
    transform: translate(-50%, -75%);
  }
  .upgrade .modal-custom.in .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .upgrade .notification {
    top: auto;
    left: auto;
    padding: 20px;
  }
  .upgrade .notification .modal-dialog {
    position: static;
    top: auto;
    left: auto;
    width: 100%;
    max-width: 830px;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .upgrade .modal-custom .modal-content {
    position: relative;
    overflow: hidden;
    padding: 30px 115px 30px 40px;
  }
  .upgrade .modal-custom .modal-content:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 70px;
    content: "";
    display: inline-block;
    background-color: #f2f3f9;
  }
  .upgrade .modal-custom .close {
    top: 15px;
    right: 15px;
  }
  .upgrade .modal-photo .modal-content:before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .upgrade .modal-photo .close {
    background-color: transparent;
  }
  .upgrade .modal-account .modal-account-image {
    display: block;
    margin: 0 auto;
  }
  .upgrade .modal-account .modal-header {
    padding: 0;
  }
  .upgrade .modal-account .modal-content {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .upgrade .modal-account .modal-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  .upgrade .modal-account .modal-body-inner {
    flex-grow: 1;
  }
  .upgrade .modal-account .modal-body-inner:first-of-type {
    max-width: 410px;
  }
  .upgrade .modal-account .modal-body-inner:last-of-type {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .upgrade .modal-product .modal-dialog {
    max-width: 1170px;
  }
  .upgrade .modal-product .modal-content {
    padding: 55px 115px 80px 40px;
  }
  .upgrade .modal-product .modal-body {
    flex-wrap: nowrap;
  }
  .upgrade .modal-product .modal-body-inner {
    padding-left: 70px;
  }
  .upgrade .modal-product * + .divider {
    margin-top: 30px;
  }
  .upgrade .modal-product * + p,
.upgrade .modal-product p + p {
    margin-top: 30px;
  }
  .upgrade .modal-product * + .button {
    margin-top: 40px;
  }
  .upgrade .modal-product-image {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
  .upgrade .modal-sale .modal-content {
    min-height: 600px;
    display: flex;
    align-items: center;
    padding-left: 95px;
  }
  .upgrade .ie-10 .modal-sale .modal-content,
.upgrade .ie-11 .modal-sale .modal-content,
.upgrade .ie-edge .modal-sale .modal-content {
    height: 600px;
  }
  .upgrade .modal-sale * + .button {
    margin-top: 45px;
  }
  .upgrade .sale-body-subtitle {
    font-size: 24px;
  }
  .upgrade .sale-body-title {
    font-size: 52px;
  }
  .upgrade .notification .modal-dialog {
    margin: 30px;
    width: auto;
  }
  .upgrade .notification .rd-mailform {
    width: 100%;
    min-width: 370px;
  }
  .upgrade .notification-dark .modal-content:before {
    background: rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 1200px) {
  .upgrade .modal-account .modal-body-inner:first-of-type {
    max-width: 470px;
  }
}
.upgrade .navigation-pills li a {
  display: inline-block;
  padding: 3px 9px;
  border: 1px solid #981df0;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.2s;
}
.upgrade .navigation-pills li a.active, .upgrade .navigation-pills li a:hover, .upgrade .navigation-pills li a:focus {
  background-color: #981df0;
  color: #fff;
}
.upgrade .pagination-custom {
  display: inline-table;
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -10px;
}
.upgrade .pagination-custom > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}
.upgrade .pagination-custom > li,
.upgrade .pagination-custom > li > a {
  border-radius: 10px;
}
.upgrade .pagination-custom > li {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.12em;
  min-width: 50px;
  color: #fff;
  background: #ed1c94;
  vertical-align: middle;
}
.upgrade .pagination-custom > li > a {
  display: block;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  color: inherit;
  background: inherit;
  text-align: center;
}
.upgrade .pagination-custom > li > a:hover {
  color: #fff;
  background: #6d0eb1;
}
.upgrade .pagination-custom > li.disabled,
.upgrade .pagination-custom > li.active {
  pointer-events: none;
}
.upgrade .pagination-custom > li.active {
  color: #fff;
  background: #6d0eb1;
}
.upgrade .pagination-custom > li.disabled {
  color: #fff;
  background: #f47bc1;
}
.upgrade .pagination-custom .pagination-control a::before {
  color: inherit;
  font-family: "Material Design Icons";
}
.upgrade .pagination-custom .pagination-control:first-child a::before {
  content: "";
}
.upgrade .pagination-custom .pagination-control:last-child a::before {
  content: "";
}
.upgrade * + .pagination-custom {
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .upgrade * + .pagination-custom {
    margin-top: 50px;
  }
}
.upgrade .panel-group-custom {
  margin-bottom: 0;
}
.upgrade .panel-group-custom .panel-heading + .panel-collapse > .panel-body,
.upgrade .panel-group-custom .panel-heading + .panel-collapse > .list-group {
  border-top: 0;
}
.upgrade .panel-group-custom .panel + .panel {
  margin-top: 0;
}
.upgrade .panel-group.panel-group-corporate .panel + .panel {
  margin-top: 20px;
}
.upgrade .panel-custom {
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}
.upgrade .panel-custom a {
  display: block;
}
.upgrade .panel-custom .panel-heading {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
}
.upgrade .panel-custom .panel-body {
  padding: 0;
  border: 0;
}
.upgrade * + .panel-group-custom {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .upgrade * + .panel-group-custom {
    margin-top: 50px;
  }
}
@media (min-width: 1600px) {
  .upgrade * + .panel-group-custom {
    margin-top: 60px;
  }
}
.upgrade .panel-corporate {
  position: relative;
  z-index: 1;
  text-align: left;
  background: #fff;
  box-shadow: 0px 5px 12px 0px #dcdeec;
}
.upgrade .panel-corporate.panel {
  border-radius: 10px;
}
.upgrade .panel-corporate .panel-title a {
  padding: 18px 82px 18px 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  color: #29293a;
  transition: inherit;
  border-radius: 10px 10px 0 0;
}
.upgrade .panel-corporate .panel-title a .panel-arrow {
  transition: 0.33s;
}
.upgrade .panel-corporate .panel-title a.collapsed {
  border-bottom-width: 0;
  border-radius: 10px;
}
.upgrade .panel-corporate .panel-title a.collapsed .panel-arrow::before {
  opacity: 1;
}
.upgrade .panel-corporate .panel-title a.collapsed .panel-arrow::after {
  opacity: 0;
}
.upgrade .panel-corporate .panel-arrow {
  position: absolute;
  top: 0;
  bottom: -1px;
  right: -1px;
  z-index: 2;
  width: 70px;
  background: #ed1c94;
  border-radius: 0 10px 10px 0;
  transition: 0.33s all ease;
}
.upgrade .panel-corporate .panel-arrow::before, .upgrade .panel-corporate .panel-arrow::after {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  font-family: "Material Design Icons";
  color: #fff;
  transition: 0.22s;
}
.upgrade .panel-corporate .panel-arrow::before {
  content: "";
  opacity: 0;
}
.upgrade .panel-corporate .panel-arrow::after {
  content: "";
  opacity: 1;
}
.upgrade .panel-corporate .panel-arrow:hover {
  background: #981df0;
}
.upgrade .panel-corporate .panel-collapse {
  position: relative;
  z-index: 1;
  color: #838386;
  border-radius: 0 0 10px 10px;
}
.upgrade .panel-corporate .panel-body {
  padding: 0 82px 25px 32px;
}
@media (max-width: 767px) {
  .upgrade .panel-corporate .panel-title a,
.upgrade .panel-corporate .panel-body {
    padding-left: 25px;
  }
}
@media (min-width: 768px) {
  .upgrade .panel-corporate .panel-title a {
    font-size: 24px;
    line-height: 1.41667;
  }
  .upgrade .panel-corporate .panel-body {
    padding-right: 150px;
  }
}
.upgrade .panel-light:first-child .panel-title {
  border-top: 1px solid #ededed;
}
.upgrade .panel-light .panel-title:nth-child(n+2) {
  margin-top: -1px;
}
.upgrade .panel-light .panel-title a {
  position: relative;
  padding: 19px 55px 19px 32px;
  border-bottom: 1px solid transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  color: #29293a;
  transition: 0.2s all ease;
}
.upgrade .panel-light .panel-title a .panel-arrow::after {
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all ease;
}
.upgrade .panel-light .panel-title a.collapsed {
  border-bottom-color: #ededed;
}
.upgrade .panel-light .panel-title a.collapsed .panel-arrow::after {
  opacity: 1;
  visibility: visible;
}
.upgrade .panel-light .panel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 26px;
  transition: 0.33s;
  will-change: transform;
}
.upgrade .panel-light .panel-arrow::before, .upgrade .panel-light .panel-arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #ed1c94;
}
.upgrade .panel-light .panel-arrow::before {
  width: 18px;
  height: 2px;
  right: 0;
}
.upgrade .panel-light .panel-arrow::after {
  width: 2px;
  height: 18px;
  right: 8px;
}
.upgrade .panel-light .panel-collapse {
  position: relative;
  margin-top: -1px;
  border-bottom: 1px solid #ededed;
  color: rgba(131, 131, 134, 0.47);
  will-change: transform;
}
.upgrade .panel-light .panel-body {
  padding: 0 44px 25px 32px;
}
@media (max-width: 767px) {
  .upgrade .panel-light .panel-title a,
.upgrade .panel-light .panel-body {
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .upgrade .panel-light .panel-title a {
    font-size: 24px;
    line-height: 1.41667;
  }
}
.upgrade .post-blog {
  position: relative;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
  transition: 450ms ease-in-out;
  color: #29293a;
  will-change: transform;
}
.upgrade .post-blog-title {
  color: #981df0;
}
.upgrade .post-blog-title:hover {
  color: #ed1c94;
}
.upgrade .post-blog:hover {
  box-shadow: 0 3px 20px rgba(110, 123, 157, 0.5);
}
.upgrade .post-blog .post-blog-video,
.upgrade .post-blog .post-blog-quote,
.upgrade .post-blog .post-blog-image {
  position: relative;
  min-height: 220px;
}
.upgrade .post-blog .post-blog-video figure, .upgrade .post-blog .post-blog-video img,
.upgrade .post-blog .post-blog-quote figure,
.upgrade .post-blog .post-blog-quote img,
.upgrade .post-blog .post-blog-image figure,
.upgrade .post-blog .post-blog-image img {
  min-height: inherit;
}
.upgrade .post-blog .post-blog-video img,
.upgrade .post-blog .post-blog-quote img,
.upgrade .post-blog .post-blog-image img {
  width: 100%;
}
.upgrade .post-blog .post-blog-video {
  border-radius: 0;
}
.upgrade .post-blog .post-blog-quote {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 10px 10px 20px;
  font-size: 21px;
  font-style: italic;
  color: #fff;
  background: #6d0eb1;
  border-radius: 0;
  text-align: center;
}
.upgrade .post-blog .post-blog-quote:hover {
  color: #ffec17;
}
.upgrade .post-blog .post-blog-quote::before {
  position: relative;
  display: block;
  transform: scale(-1, -1);
  content: "";
  font-family: "Material Design Icons";
  font-size: 60px;
  line-height: 1;
  font-style: normal;
  color: inherit;
}
@media (min-width: 768px) {
  .upgrade .post-blog .post-blog-quote {
    font-size: 24px;
  }
}
@media (min-width: 1600px) {
  .upgrade .post-blog .post-blog-video,
.upgrade .post-blog .post-blog-quote,
.upgrade .post-blog .post-blog-image {
    min-height: 305px;
  }
}
@supports (object-fit: cover) {
  .upgrade .post-blog .post-blog-video img,
.upgrade .post-blog .post-blog-quote img,
.upgrade .post-blog .post-blog-image img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.upgrade .post-blog-caption {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  padding-top: 25px;
  background-color: #f2f3f9;
}
.upgrade .post-blog-caption .post-blog-caption-footer {
  margin-top: auto;
}
.upgrade .post-blog + .post-blog {
  margin-top: 30px;
}
.upgrade .post-blog-image {
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}
.upgrade .post-blog-image img {
  transition: 750ms ease-in-out;
}
.upgrade .post-blog-image:hover img {
  transform: scale3d(1.05, 1.05, 1.05);
}
.upgrade .post-blog-caption-header,
.upgrade .post-blog-caption-body,
.upgrade .post-blog-caption-footer {
  padding-left: 15px;
  padding-right: 15px;
}
.upgrade .post-blog-caption-body h1, .upgrade .post-blog-caption-body h2, .upgrade .post-blog-caption-body h3, .upgrade .post-blog-caption-body h4, .upgrade .post-blog-caption-body h5, .upgrade .post-blog-caption-body h6, .upgrade .post-blog-caption-body [class^=heading-] > a {
  display: block;
}
@media (min-width: 480px) {
  .upgrade .post-blog-caption-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
.upgrade .post-blog-caption-body {
  padding-top: 20px;
  padding-bottom: 20px;
}
.upgrade .post-blog-caption-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #fff;
}
.upgrade .post-blog-caption-footer > * {
  color: #838386;
}
.upgrade .post-comment > * {
  vertical-align: middle;
}
.upgrade .post-comment .icon {
  padding-right: 5px;
}
.upgrade .post-blog-container[class*=cell-] {
  display: flex;
}
.upgrade .post-blog-container[class*=cell-] > .range {
  flex: 1 1 auto;
}
@media (min-width: 992px) {
  .upgrade .post-blog-caption-header,
.upgrade .post-blog-caption-body,
.upgrade .post-blog-caption-footer {
    padding-left: 38px;
    padding-right: 38px;
  }
}
@media (min-width: 1800px) {
  .upgrade .post-blog {
    min-height: 640px;
  }
  .upgrade .post-blog-caption-body {
    min-height: 200px;
  }
}
.upgrade .post-blog-quote {
  display: block;
  position: relative;
  border-radius: 8px;
  background-color: #6d0eb1;
  padding: 20px;
}
.upgrade .post-blog-quote .quote-primary-text,
.upgrade .post-blog-quote .quote-primary-mark {
  transition: 350ms ease-in-out;
}
.upgrade .post-blog-quote .quote-primary-text {
  color: #fff;
}
.upgrade .post-blog-quote .quote-primary-mark {
  fill: #fff;
}
.upgrade .post-blog-quote .quote-primary-meta {
  margin-top: 33px;
  color: #f2f3f9;
}
.upgrade .post-blog-quote .quote-primary-meta .cite {
  color: #ffec17;
}
.upgrade .post-blog-quote:hover .quote-primary-text {
  color: #ffec17;
}
.upgrade .post-blog-quote:hover .quote-primary-mark {
  fill: #ffec17;
}
.upgrade .post-blog-quote-text {
  width: 100%;
}
@media (min-width: 768px) {
  .upgrade .post-blog-quote {
    padding: 40px 45px 45px;
  }
}
.upgrade .post-blog-video {
  display: block;
  position: relative;
  border-radius: 8px;
  background-color: #000000;
  overflow: hidden;
  will-change: transform;
}
.upgrade .post-blog-video figure {
  border-radius: 8px;
  opacity: 0.7;
}
.upgrade .post-blog-video figure img {
  height: auto;
  width: 101%;
  transition: 750ms ease-in-out;
}
.upgrade .post-blog-video .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  font-size: 4.4em;
  transition: 350ms ease-in-out;
}
.upgrade .post-blog-video:hover img {
  transform: scale3d(1.05, 1.05, 1.05);
}
.upgrade .post-blog-video:hover .icon {
  color: #ffec17;
}
.upgrade .post-blog-large {
  position: relative;
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  overflow: hidden;
}
.upgrade .post-blog-large .post-blog-meta {
  position: absolute;
  top: 15px;
  left: 15px;
}
.upgrade .post-blog-large .post-blog-meta li {
  color: #ededed;
}
.upgrade .post-blog-large .post-blog-meta a {
  color: #ededed;
}
.upgrade .post-blog-large .post-blog-meta a:focus, .upgrade .post-blog-large .post-blog-meta a:hover {
  color: #ffec17;
}
.upgrade .post-blog-large-caption {
  text-align: left;
  padding: 15px 15px 20px;
  background-color: #fff;
}
.upgrade .post-blog-large-caption * + .button {
  margin-top: 25px;
}
.upgrade .post-blog-large-title {
  display: block;
  font-size: 28px;
  line-height: 1.2;
}
.upgrade .post-blog-large-image {
  background-color: #000000;
}
.upgrade .post-blog-large-image img {
  width: 100.3%;
  opacity: 0.5;
}
.upgrade .post-blog-meta {
  transform: translateY(-5px);
  margin-bottom: -5px;
  margin-left: -7px;
  text-align: left;
  color: #838386;
}
.upgrade .post-blog-meta > * {
  display: inline-block;
  margin-top: 5px;
  margin-left: 7px;
}
.upgrade .post-blog-meta li {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
}
.upgrade .post-blog-meta li:before {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  content: "";
  display: inline-block;
  border-left: 1px solid;
}
.upgrade .post-blog-meta li:last-of-type:before {
  content: none;
}
.upgrade .post-blog-tags {
  margin-left: -7px;
  margin-right: -7px;
}
.upgrade .post-blog-tags > li {
  display: inline-block;
  padding: 5px 7px;
}
.upgrade .button-tags {
  display: inline-block;
  padding: 4px 11px;
  border-radius: 20px;
  font: 300 14px/1 "Lato", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.upgrade .button-tags, .upgrade .button-tags:focus {
  color: #fff;
  background-color: #ed1c94;
  border-color: #ed1c94;
}
.upgrade .button-tags:hover, .upgrade .button-tags:active, .upgrade .button-tags:hover {
  color: #fff;
  background-color: #981df0;
  border-color: #981df0;
}
.upgrade .post-blog-article {
  color: #29293a;
  letter-spacing: 0.02em;
}
.upgrade .post-blog-article .big {
  letter-spacing: 0;
  line-height: 1.4;
}
.upgrade .post-blog-article * + .post-video,
.upgrade .post-blog-article * + img {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .upgrade .post-blog-article .quote-primary {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.upgrade .post-blog-article-title {
  font-size: 26px;
  line-height: 1.3;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .upgrade .post-blog-article-title {
    font-size: 32px;
  }
}
.upgrade .post-blog-article-meta {
  color: #838386;
}
.upgrade .post-blog-article-meta .box-inline a {
  color: #838386;
}
.upgrade .post-blog-article-meta .box-inline a:hover {
  color: #ed1c94;
}
.upgrade * + .post-blog-article-meta {
  margin-top: 15px;
}
.upgrade * + .post-blog-large-title {
  margin-top: 10px;
}
@media (min-width: 992px) {
  .upgrade {
    /**
    * Post blog article
    *
    */
  }
  .upgrade .post-blog-article * + .big {
    margin-top: 30px;
  }
  .upgrade .post-blog-article * + img {
    margin-top: 55px;
  }
  .upgrade .post-blog-article img + * {
    margin-top: 45px;
  }
  .upgrade .post-blog-article img + video {
    margin-top: 0;
  }
  .upgrade .post-blog-article-title {
    font-size: 42px;
    line-height: 1.25;
  }
  .upgrade * + .post-blog-article-meta {
    margin-top: 35px;
  }
}
@media (min-width: 1600px) {
  .upgrade .post-blog-large .post-blog-meta {
    left: 47px;
    margin-left: -25px;
    margin-right: -25px;
  }
  .upgrade .post-blog-large .post-blog-meta li {
    padding-left: 25px;
    padding-right: 25px;
  }
  .upgrade .post-blog-large-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 48%, 0);
    width: 100%;
    max-width: 620px;
    padding-left: 47px;
    color: #c2c2c3;
    background-color: transparent;
    transition: 570ms ease-in-out;
  }
  .upgrade .post-blog-large-caption .post-blog-large-text,
.upgrade .post-blog-large-caption .button {
    opacity: 0;
    transition: 350ms opacity;
  }
  .upgrade .post-blog-large-caption .post-blog-large-text {
    color: #c2c2c3;
  }
  .upgrade .post-blog-large-title {
    font-size: 36px;
    line-height: 1.23;
    color: #fff;
    max-height: 88px;
    overflow: hidden;
  }
  .upgrade .post-blog-large-title:focus, .upgrade .post-blog-large-title:hover {
    color: #ffec17;
  }
  .upgrade .post-blog-large-image {
    opacity: 0.8;
  }
  .upgrade .post-blog-large-image img {
    opacity: 1;
  }
  .upgrade .post-blog-large {
    background-color: #000000;
  }
  .upgrade .post-blog-large-image {
    transition: 750ms ease-in-out;
  }
  .upgrade .post-blog-large:hover:before {
    opacity: 1;
  }
  .upgrade .post-blog-large:hover .post-blog-large-image {
    transform: scale3d(1.05, 1.05, 1.05);
    opacity: 0.5;
  }
  .upgrade .post-blog-large:hover .post-blog-large-caption {
    transform: translate3d(0, 0, 0);
  }
  .upgrade .post-blog-large:hover .post-blog-large-caption .post-blog-large-text,
.upgrade .post-blog-large:hover .post-blog-large-caption .button {
    opacity: 1;
  }
  .upgrade .post-blog-article + [class*=section-] {
    margin-top: 60px;
  }
}
@media (min-width: 1800px) {
  .upgrade .post-blog-large-caption {
    padding-bottom: 48px;
  }
  .upgrade .post-blog-large-caption * + .button {
    margin-top: 30px;
  }
  .upgrade .post-blog-large-title {
    font-size: 42px;
    max-height: 110px;
  }
  .upgrade .post-blog-large-image {
    opacity: 1;
  }
  .upgrade .post-blog-large .post-blog-meta {
    top: 40px;
  }
  .upgrade * + .post-blog-large-title {
    margin-top: 20px;
  }
  .upgrade * + .post-blog-large-text {
    margin-top: 25px;
  }
}
.upgrade .post-facebook {
  padding: 25px;
  border-radius: 9px;
  text-align: left;
  background: #f2f3f9;
}
.upgrade .post-facebook time {
  color: #838386;
}
.upgrade .post-facebook-profile-name {
  font-size: 15px;
  color: #29293a;
  font-weight: 400;
}
.upgrade .post-facebook-image {
  width: 70px;
  height: auto;
  border-radius: 50%;
}
.upgrade .post-facebook-header {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  transform: translateY(-15px);
  margin-bottom: -15px;
  margin-left: -15px;
  word-break: break-all;
}
.upgrade .post-facebook-header > * {
  display: inline-block;
  margin-top: 15px;
  margin-left: 15px;
}
.upgrade .post-facebook-attachment {
  border-left: 2px solid #c2c2c3;
  color: #838386;
}
.upgrade .post-facebook-attachment > * {
  padding: 0 15px;
}
.upgrade .post-facebook-attachment a {
  font-weight: 400;
  color: #ed1c94;
}
.upgrade .post-facebook-attachment a:hover {
  color: #29293a;
}
.upgrade .post-facebook-attachment * + p {
  margin-top: 5px;
}
@media (min-width: 480px) {
  .upgrade .post-facebook-attachment {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .upgrade .post-facebook-attachment > * + * {
    margin-top: 15px;
  }
}
.upgrade .post-facebook-attachment:empty {
  margin-top: 0;
  display: none;
}
.upgrade .post-facebook-attachment-image {
  position: relative;
  overflow: hidden;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  max-width: 50%;
  max-height: 200px;
}
@supports (object-fit: cover) {
  .upgrade .post-facebook-attachment-image img {
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
  }
}
.upgrade .post-facebook-attachment-left {
  max-width: 120px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.upgrade .post-facebook-attachment-left img {
  width: 100%;
}
.upgrade .post-facebook-attachment-text {
  position: relative;
  max-height: 3.11111em;
  overflow: hidden;
}
.upgrade .post-facebook-attachment-text:empty {
  display: none;
}
.upgrade .post-facebook-meta {
  transform: translateY(-5px);
  margin-bottom: -5px;
  margin-left: -20px;
  color: #29293a;
}
.upgrade .post-facebook-meta > * {
  display: inline-block;
  margin-top: 5px;
  margin-left: 20px;
}
.upgrade .post-facebook-meta .icon {
  font-size: 28px;
  color: #c2c2c3;
}
.upgrade .post-facebook-meta span {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.15em;
}
.upgrade .post-facebook-meta span:first-child {
  padding-left: 0;
}
.upgrade * + .post-facebook {
  margin-top: 30px;
}
.upgrade * + .post-facebook-attachment {
  margin-top: 20px;
}
.upgrade * + .post-facebook-meta {
  margin-top: 20px;
}
@media (min-width: 480px) {
  .upgrade .post-facebook-profile-name {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .upgrade * + .post-facebook-text {
    margin-top: 28px;
  }
  .upgrade * + .post-facebook-attachment {
    margin-top: 30px;
  }
  .upgrade * + .post-facebook-meta {
    margin-top: 35px;
  }
}
@media (max-width: 1599px) {
  .upgrade .post-facebook .post-facebook-attachment-title {
    font-size: 19px;
    word-break: break-all;
  }
}
@media (min-width: 1600px) {
  .upgrade .post-facebook {
    padding-right: 55px;
  }
  .upgrade .post-facebook-attachment-left {
    max-width: 170px;
  }
  .upgrade .post-facebook-profile-name {
    font-size: 24px;
  }
  .upgrade .post-facebook-header {
    transform: translateY(-15px);
    margin-bottom: -15px;
    margin-left: -30px;
  }
  .upgrade .post-facebook-header > * {
    display: inline-block;
    margin-top: 15px;
    margin-left: 30px;
  }
}
.upgrade .post-classic {
  position: relative;
  overflow: hidden;
  border-radius: 9px;
  background: #f2f3f9;
  text-align: left;
}
.upgrade .post-classic .post-blog-video, .upgrade .post-classic .post-blog-video figure {
  border-radius: 0;
}
.upgrade .post-classic-media {
  display: block;
  position: relative;
  overflow: hidden;
  min-height: 250px;
  border-radius: 9px 9px 0 0;
  background: #29293a;
}
.upgrade .post-classic-media figure {
  min-height: inherit;
}
.upgrade .post-classic-media img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: none;
  max-height: none;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: 0.33s;
}
.upgrade .post-classic-media .post-classic-video {
  min-height: inherit;
}
.upgrade .post-classic-media:hover > img {
  opacity: 0.8;
  transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
}
.upgrade .post-classic-media .post-blog-video:hover img {
  transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1.05);
}
@supports (object-fit: cover) {
  .upgrade .post-classic-media > img {
    left: auto;
    top: auto;
    object-fit: cover;
    transform: none;
    object-position: center 20%;
    width: 100%;
    height: 100%;
    min-height: inherit;
  }
  .upgrade .post-classic-media:hover > img {
    transform: scale3d(1.05, 1.05, 1.05);
  }
}
.upgrade .post-quote-wrap {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background: #6d0eb1;
}
.upgrade .post-quote-wrap > * {
  max-width: 100%;
}
.upgrade .post-classic-quote {
  position: relative;
  font-style: italic;
  color: #fff;
  text-align: center;
  padding: 20px;
}
.upgrade .post-classic-quote::before {
  position: relative;
  transform: scale(-1, -1);
  content: "";
  font-family: "Material Design Icons";
  margin-top: -10px;
  font-size: 60px;
  line-height: 1;
  font-style: normal;
  font-weight: 400;
}
.upgrade .post-classic-quote .post-classic-title {
  padding: 0;
  color: #fff;
}
.upgrade .post-classic-body {
  padding: 25px 20px;
}
.upgrade .post-classic-footer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -20px;
}
.upgrade .post-classic-footer > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
}
.upgrade .post-classic-meta {
  display: inline-table;
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -20px;
  left: -3px;
  color: #838386;
}
.upgrade .post-classic-meta > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
}
.upgrade .post-classic-meta > li {
  vertical-align: middle;
}
.upgrade .post-classic-meta > li * {
  padding: 0 3px;
  vertical-align: middle;
}
.upgrade .post-classic-meta a {
  position: relative;
  top: -2px;
  display: inline-block;
  line-height: inherit;
  color: inherit;
}
.upgrade .post-classic-meta a:hover {
  color: #ed1c94;
}
.upgrade .post-classic-meta .icon {
  font-size: 1.55em;
  color: #981df0;
}
.upgrade .post-classic-info {
  display: inline-table;
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -20px;
  color: #838386;
  font-style: italic;
}
.upgrade .post-classic-info > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
}
.upgrade .post-classic-info > li {
  vertical-align: middle;
}
.upgrade .post-classic-info > li * {
  padding: 0 3px;
  vertical-align: middle;
}
.upgrade .post-classic-info .icon {
  position: relative;
  top: 1px;
  font-size: 1.333em;
  font-style: normal;
  color: #c2c2c3;
  transition: 0.22s;
}
.upgrade .post-classic-info a {
  color: inherit;
  transition: 0.33s;
}
.upgrade .post-classic-info a:hover {
  color: #ed1c94;
}
.upgrade .post-classic-info a:hover .icon {
  color: #ed1c94;
}
.upgrade .post-classic-title {
  font-size: 18px;
  font-weight: 300;
  color: #ed1c94;
}
.upgrade .post-classic-text {
  color: #29293a;
}
.upgrade .post-audio.jp-player-init .jp-audio {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
.upgrade .post-audio.jp-player-init .jp-btn:before {
  font-size: 15px;
}
.upgrade .post-audio.jp-player-init.jp-state-playing .jp-play:before {
  content: "";
}
.upgrade .post-audio.jp-player-init .jp-audio .jp-no-solution {
  margin: 5px 0;
  padding: 10px;
  background: rgba(237, 28, 148, 0.1);
  border: 2px dashed rgba(237, 28, 148, 0.2);
}
.upgrade .post-audio.jp-player-init .jp-audio .jp-no-solution span {
  display: block;
  font-weight: 700;
}
.upgrade .post-audio.jp-player-init .jp-audio .jp-no-solution a {
  color: #29293a;
}
.upgrade .post-audio.jp-player-init .jp-audio .jp-no-solution a:hover {
  color: #29293a;
  text-decoration: underline;
}
.upgrade .post-audio.jp-player-init .jp-play-bar,
.upgrade .post-audio.jp-player-init .jp-volume-bar-value {
  box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3);
  background-size: 0.4375em 0.4375em;
  background-color: #a55708;
  background-repeat: repeat-x;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d6710a), color-stop(100%, #a55708));
  background-image: -webkit-linear-gradient(#d6710a, #a55708);
  background-image: linear-gradient(#d6710a, #a55708);
  background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
}
.upgrade * + .post-classic {
  margin-top: 40px;
}
.upgrade * + .post-classic-meta {
  margin-top: 10px;
}
.upgrade * + .post-classic-title {
  margin-top: 10px;
}
.upgrade * + .post-audio {
  margin-top: 15px;
}
.upgrade * + .post-classic-text {
  margin-top: 15px;
}
.upgrade * + .post-classic-footer {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .upgrade .post-classic-title {
    font-size: 32px;
    line-height: 1.2381;
  }
  .upgrade .post-classic-quote {
    padding: 30px 50px;
  }
  .upgrade .post-classic-info {
    transform: translateY(-10px);
    margin-bottom: -10px;
    margin-left: -35px;
  }
  .upgrade .post-classic-info > * {
    display: inline-block;
    margin-top: 10px;
    margin-left: 35px;
  }
  .upgrade * + .post-classic-meta {
    margin-top: 25px;
  }
  .upgrade * + .post-classic-player {
    margin-top: 35px;
  }
  .upgrade * + .post-classic-text {
    margin-top: 25px;
  }
  .upgrade * + .post-classic-footer {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .upgrade .post-classic-media a .icon {
    font-size: 120px;
  }
}
@media (min-width: 1200px) {
  .upgrade .post-classic-title {
    padding-right: 50px;
  }
  .upgrade .post-classic-media {
    min-height: 340px;
  }
  .upgrade .ie-10 .post-quote-wrap,
.upgrade .ie-11 .post-quote-wrap {
    display: block;
    vertical-align: middle;
    white-space: nowrap;
  }
  .upgrade .ie-10 .post-quote-wrap > *,
.upgrade .ie-11 .post-quote-wrap > * {
    display: inline-block;
    vertical-align: middle;
    max-width: 99%;
    white-space: normal;
  }
  .upgrade .ie-10 .post-quote-wrap::after,
.upgrade .ie-11 .post-quote-wrap::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    min-height: inherit;
  }
  .upgrade .post-classic-meta {
    transform: translateY(-14px);
    margin-bottom: -14px;
    margin-left: -40px;
  }
  .upgrade .post-classic-meta > * {
    display: inline-block;
    margin-top: 14px;
    margin-left: 40px;
  }
  .upgrade * + .post-classic-meta {
    margin-top: 25px;
  }
}
@media (min-width: 1600px) {
  .upgrade .post-classic-title {
    font-size: 42px;
  }
  .upgrade .post-classic-media {
    min-height: 580px;
  }
  .upgrade .post-classic-body {
    padding: 37px;
  }
  .upgrade .post-classic-quote {
    padding: 100px;
  }
  .upgrade .post-classic-meta {
    transform: translateY(-14px);
    margin-bottom: -14px;
    margin-left: -60px;
  }
  .upgrade .post-classic-meta > * {
    display: inline-block;
    margin-top: 14px;
    margin-left: 60px;
  }
}
.upgrade .post-minimal {
  text-align: left;
}
.upgrade .post-minimal > * + * {
  margin-top: 12px;
}
.upgrade .post-minimal-title {
  color: #ed1c94;
}
.upgrade .post-minimal-time {
  display: block;
  color: #c2c2c3;
}
.upgrade .post-minimal-md .post-minimal-title {
  font-weight: 400;
}
@media (min-width: 768px) {
  .upgrade .post-minimal-md .post-minimal-title {
    font-size: 20px;
  }
}
@media (min-width: 1600px) {
  .upgrade .post-minimal-md .post-minimal-title {
    font-size: 24px;
  }
}
.upgrade * + .post-minimal {
  margin-top: 20px;
}
.upgrade .blog-aside {
  position: relative;
  transform: translateY(-30px);
  margin-bottom: -30px;
  pointer-events: none;
}
.upgrade .blog-aside > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade .blog-aside > * {
  margin-top: 30px;
  pointer-events: auto;
}
.upgrade .blog-aside h6 + * {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .upgrade .blog-aside {
    transform: translateY(-60px);
    margin-bottom: -60px;
  }
  .upgrade .blog-aside > * {
    margin-top: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .upgrade .blog-aside {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .upgrade .blog-aside-item {
    width: 50%;
    padding: 0 15px;
  }
}
@media (min-width: 1600px) {
  .upgrade .blog-aside {
    transform: translateY(-120px);
    margin-bottom: -120px;
  }
  .upgrade .blog-aside h6 + * {
    margin-top: 28px;
  }
  .upgrade .blog-aside > * {
    margin-top: 120px;
  }
}
.upgrade .shop-aside {
  position: relative;
  transform: translateY(-30px);
  margin-bottom: -30px;
  pointer-events: none;
}
.upgrade .shop-aside > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade .shop-aside > * {
  margin-top: 30px;
  pointer-events: auto;
}
.upgrade .shop-aside h6 + * {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .upgrade .shop-aside {
    transform: translateY(-60px);
    margin-bottom: -60px;
  }
  .upgrade .shop-aside > * {
    margin-top: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .upgrade .shop-aside {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .upgrade .shop-aside-item {
    width: 50%;
    padding: 0 15px;
  }
}
@media (min-width: 1600px) {
  .upgrade .shop-aside {
    transform: translateY(-120px);
    margin-bottom: -120px;
  }
  .upgrade .shop-aside h6 + * {
    margin-top: 28px;
  }
  .upgrade .shop-aside > * {
    margin-top: 120px;
  }
}
.upgrade .post-modern {
  position: relative;
  overflow: hidden;
}
.upgrade .post-modern .post-blog-video, .upgrade .post-modern .post-blog-video figure {
  border-radius: 0;
}
.upgrade .post-modern > * + * {
  margin-top: 25px;
}
.upgrade .post-modern-aside {
  width: 130px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.upgrade .post-modern-aside time {
  display: block;
  max-width: 120px;
  margin-left: auto;
  margin-right: auto;
}
.upgrade .post-modern-main {
  position: relative;
  overflow: hidden;
  border-radius: 9px;
  background: #f2f3f9;
  text-align: left;
  color: #29293a;
}
.upgrade .post-modern-body {
  padding: 25px 20px;
}
.upgrade .post-modern-footer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.upgrade .post-modern-image {
  display: block;
  background: #29293a;
}
.upgrade .post-modern-image img {
  opacity: 1;
  transition: 0.33s;
}
.upgrade .post-modern-image:hover > img {
  opacity: 0.8;
}
.upgrade .post-modern-avatar {
  display: block;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #fff;
  box-shadow: 0 5px 9px 0 rgba(150, 150, 150, 0.18);
}
.upgrade .post-modern-title {
  color: #ed1c94;
}
.upgrade .post-modern-title > a {
  display: block;
}
.upgrade .post-modern-title:hover {
  color: #981df0;
}
.upgrade .post-modern-author {
  color: #ed1c94;
}
.upgrade .post-modern-meta {
  display: inline-table;
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -20px;
  left: -3px;
  color: #838386;
}
.upgrade .post-modern-meta > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
}
.upgrade .post-modern-meta > li {
  vertical-align: middle;
}
.upgrade .post-modern-meta > li * {
  padding: 0 3px;
  vertical-align: middle;
}
.upgrade .post-modern-meta a {
  color: inherit;
}
.upgrade .post-modern-meta a:hover {
  color: #ed1c94;
}
.upgrade .post-modern-meta .icon {
  font-size: 1.55em;
  color: #c2c2c3;
}
.upgrade .post-modern-info {
  display: inline-table;
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -20px;
  color: #838386;
  font-style: italic;
}
.upgrade .post-modern-info > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 20px;
}
.upgrade .post-modern-info > li {
  vertical-align: middle;
}
.upgrade .post-modern-info > li * {
  padding: 0 3px;
  vertical-align: middle;
}
.upgrade .post-modern-info .icon {
  position: relative;
  top: 1px;
  font-size: 1.333em;
  font-style: normal;
  color: #c2c2c3;
  transition: 0.22s;
}
.upgrade .post-modern-info a {
  color: inherit;
  transition: 0.33s;
}
.upgrade .post-modern-info a:hover {
  color: #ed1c94;
}
.upgrade .post-modern-info a:hover .icon {
  color: #ed1c94;
}
.upgrade * + .post-modern {
  margin-top: 30px;
}
.upgrade * + .post-modern-author {
  margin-top: 12px;
}
.upgrade * + .post-modern-avatar-wrap {
  margin-top: 15px;
}
.upgrade * + .post-modern-footer {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .upgrade .post-modern {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .post-modern > * + * {
    margin-top: 0;
  }
  .upgrade .post-modern-aside {
    position: relative;
    padding-bottom: 20px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .upgrade .post-modern-aside::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 100vh;
    transform: translate(0, 100%);
    border-right: 1px solid #c2c2c3;
  }
  .upgrade .post-modern-avatar-wrap {
    position: relative;
  }
  .upgrade .post-modern-avatar-wrap::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 400px;
    border-bottom: 1px solid #c2c2c3;
  }
  .upgrade .post-modern-avatar {
    position: relative;
    z-index: 2;
  }
  .upgrade .post-modern-main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    margin-left: 30px;
  }
  .upgrade .post-modern-title {
    max-width: 81%;
  }
}
@media (min-width: 1200px) {
  .upgrade .post-modern-main {
    margin-left: 60px;
  }
  .upgrade .post-modern-body {
    padding: 40px 35px 30px 30px;
  }
  .upgrade * + .post-modern-author {
    margin-top: 20px;
  }
}
@media (min-width: 1600px) {
  .upgrade .post-modern {
    margin-left: 80px;
  }
  .upgrade .post-modern-main {
    margin-left: 100px;
  }
  .upgrade * + .post-modern-avatar-wrap {
    margin-top: 30px;
  }
}
.upgrade .post-light {
  text-align: left;
}
.upgrade .post-light .unit-left a {
  background: #29293a;
  display: block;
}
.upgrade .post-light .unit-left a img {
  opacity: 1;
  transition: 0.33s;
}
.upgrade .post-light .unit-left a:hover img {
  opacity: 0.8;
}
.upgrade .post-light-title {
  max-width: 90%;
  color: #ed1c94;
}
.upgrade .post-light-time {
  display: block;
  color: #c2c2c3;
}
.upgrade .post-light + .post-light {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .upgrade * + .post-light-time {
    margin-top: 10px;
  }
}
@media (min-width: 1200px) {
  .upgrade .post-light + .post-light {
    margin-top: 50px;
  }
}
@media (min-width: 1600px) {
  .upgrade .post-light + .post-light {
    margin-top: 65px;
  }
}
.upgrade .pricing-shell-wrap .pricing-box {
  max-width: 420px;
}
.upgrade .pricing-box {
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: #f2f3f9;
  border-radius: 9px;
  box-shadow: 0 7px 24px 0 rgba(110, 123, 157, 0.42);
  transition: 0.3s ease-out all;
}
.upgrade .pricing-box .pricing-box-price {
  color: #29293a;
  font-weight: 500;
}
.upgrade .pricing-box .pricing-box-price .heading-2 {
  font-weight: inherit;
}
.upgrade .pricing-box .pricing-box-price > * {
  color: inherit;
}
.upgrade .pricing-box .button {
  min-width: 0;
  padding: 11px 23px;
}
.upgrade .pricing-box * + .button {
  margin-top: 18px;
}
.upgrade .pricing-box-header {
  padding: 25px 10px;
  color: #fff;
  background-color: #981df0;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  transition: 0.3s ease-out all;
}
.upgrade .pricing-box-header > * {
  color: inherit;
}
.upgrade .pricing-box-body {
  padding: 28px 10px;
}
.upgrade .pricing-box-list {
  letter-spacing: 0.02em;
  color: #29293a;
}
.upgrade .pricing-box-list > li + li {
  margin-top: 10px;
}
.upgrade .pricing-box-list-disable {
  color: #c2c2c3;
  text-decoration: line-through;
}
@media (min-width: 1600px) {
  .upgrade .pricing-box-body {
    padding: 36px 10px;
  }
  .upgrade .pricing-box .button {
    padding: 14px 45px;
  }
}
.upgrade .desktop .pricing-box {
  box-shadow: none;
}
.upgrade .desktop .pricing-box .pricing-box-header {
  background-color: #ed1c94;
}
.upgrade .desktop .pricing-box-primary,
.upgrade .desktop .pricing-box:hover {
  box-shadow: 0 7px 24px 0 rgba(110, 123, 157, 0.42);
}
.upgrade .desktop .pricing-box-primary .pricing-box-header,
.upgrade .desktop .pricing-box:hover .pricing-box-header {
  background-color: #981df0;
}
.upgrade * + .pricing-box-price {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade .pricing-box * + .button {
    margin-top: 19px;
  }
  .upgrade * + .pricing-box-price {
    margin-top: 30px;
  }
}
@media (min-width: 1600px) {
  .upgrade * + .pricing-box-price {
    margin-top: 40px;
  }
}
.upgrade .pricing-box-alternative {
  position: relative;
  z-index: 10;
  max-width: 294px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding: 15px 20px 15px;
  border: 1px solid #ededed;
  transition: 0.3s ease-out all;
}
.upgrade .pricing-box-alternative:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: -40px;
  bottom: -90px;
  left: 0;
  right: 0;
  background-color: #6d0eb1;
  border-radius: 9px;
}
.upgrade .pricing-box-alternative * + .pricing-box-list {
  margin-top: 15px;
}
.upgrade .pricing-box-alternative * + .pricing-box-price {
  margin-top: 25px;
}
.upgrade .pricing-box-alternative .pricing-box-button {
  margin-top: 20px;
  position: relative;
}
.upgrade .pricing-box-alternative .pricing-box-button .button {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
}
.upgrade .pricing-box-alternative-header {
  color: #ed1c94;
}
.upgrade .pricing-box-alternative-header > * {
  color: inherit;
}
.upgrade .pricing-box-rating > li {
  display: inline-block;
  margin: 0 -4px;
}
.upgrade .pricing-box-rating .mdi-star {
  color: #981df0;
}
.upgrade .pricing-box-rating .mdi-star-outline {
  color: #ededed;
}
.upgrade * + .pricing-box-rating {
  margin-top: 30px;
}
.upgrade .tablet .pricing-box-alternative:before,
.upgrade .mobile .pricing-box-alternative:before {
  visibility: visible;
  opacity: 1;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-button .button,
.upgrade .mobile .pricing-box-alternative .pricing-box-button .button {
  visibility: visible;
  opacity: 1;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-rating .mdi-star,
.upgrade .mobile .pricing-box-alternative .pricing-box-rating .mdi-star {
  color: #ffec17;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-rating .mdi-star-outline,
.upgrade .mobile .pricing-box-alternative .pricing-box-rating .mdi-star-outline {
  color: #fff;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-alternative-header,
.upgrade .mobile .pricing-box-alternative .pricing-box-alternative-header {
  color: #fff;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-list,
.upgrade .mobile .pricing-box-alternative .pricing-box-list {
  color: #fff;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-list-disable,
.upgrade .mobile .pricing-box-alternative .pricing-box-list-disable {
  color: #d3d3d4;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-price,
.upgrade .mobile .pricing-box-alternative .pricing-box-price {
  color: #fff;
}
.upgrade .tablet .pricing-box-alternative .pricing-box-price > *,
.upgrade .mobile .pricing-box-alternative .pricing-box-price > * {
  color: inherit;
}
.upgrade .desktop .pricing-box-alternative {
  padding: 50px 20px 30px;
  margin-bottom: 0;
  z-index: 1;
}
.upgrade .desktop .pricing-box-alternative:before {
  visibility: hidden;
  opacity: 0;
}
.upgrade .desktop .pricing-box-alternative:hover {
  margin-bottom: 50px;
  padding: 15px 20px 15px;
  z-index: 10;
}
.upgrade .desktop .pricing-box-alternative:hover:before {
  visibility: visible;
  opacity: 1;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-button .button {
  visibility: visible;
  opacity: 1;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-rating .mdi-star {
  color: #ffec17;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-rating .mdi-star-outline {
  color: #fff;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-alternative-header {
  color: #fff;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-list {
  color: #fff;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-list-disable {
  color: #d3d3d4;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-price {
  color: #fff;
}
.upgrade .desktop .pricing-box-alternative:hover .pricing-box-price > * {
  color: inherit;
}
@media (min-width: 768px) {
  .upgrade .pricing-box-alternative * + .pricing-box-list {
    margin-top: 25px;
  }
}
@media (min-width: 1200px) {
  .upgrade .pricing-box-rating .icon {
    font-size: 36px;
  }
}
.upgrade .pricing-box-lg {
  max-width: 570px;
}
.upgrade .pricing-box-lg .pricing-box-header {
  padding: 0.9em 2.2em;
}
.upgrade .pricing-box-lg .pricing-box-body {
  padding: 28.8px 20px 28.8px 20px;
}
.upgrade .pricing-box-lg .pricing-box-list {
  display: inline-flex;
  flex-direction: column;
  padding-left: 0;
}
.upgrade .pricing-box-lg .pricing-box-list > li:before {
  color: #b7b7b7;
}
.upgrade .pricing-box-lg .button {
  padding: 8px 27px;
}
.upgrade .pricing-box-lg .pricing-box-price-new {
  color: #29293a;
  font-weight: 400;
}
.upgrade .pricing-box-lg .pricing-box-price-new > * {
  font-weight: inherit;
  color: inherit;
}
.upgrade .pricing-box-lg .pricing-box-price-old {
  color: #c2c2c3;
  font-weight: 400;
}
.upgrade .pricing-box-lg .pricing-box-price-old > * {
  font-weight: inherit;
  color: inherit;
  text-decoration: line-through;
}
.upgrade * + .pricing-box-price-new {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .upgrade .pricing-box-lg .pricing-box-body {
    padding: 28.8px 50px 34.8px 45px;
  }
  .upgrade * + .pricing-box-price-new {
    margin-top: 0;
  }
}
.upgrade .pricing-box-xl {
  max-width: 380px;
}
.upgrade .pricing-box-xl .pricing-box-header {
  padding-top: 15px;
  padding-bottom: 15px;
}
.upgrade .pricing-box-xl .button {
  padding-top: 9px;
  padding-bottom: 9px;
}
.upgrade .pricing-box-xl .pricing-box-price sup {
  font-size: 0.6em;
}
.upgrade .pricing-box-xl .icon-md-big,
.upgrade .pricing-box-xl .icon-md {
  font-size: 25px;
}
.upgrade .pricing-box-xl .pricing-box-body {
  padding: 40px 27px;
}
.upgrade .pricing-box-xl .pricing-box-list > li + li {
  margin-top: 23px;
}
@media (min-width: 480px) {
  .upgrade .pricing-box-xl {
    max-width: 620px;
  }
  .upgrade .pricing-box-xl .pricing-box-body {
    padding: 50px 67px;
  }
}
@media (min-width: 1200px) {
  .upgrade .pricing-box-xl .pricing-box-body {
    padding: 30px 27px;
  }
}
@media (min-width: 1600px) {
  .upgrade .pricing-box-xl .pricing-box-body {
    padding: 50px 67px;
  }
  .upgrade .pricing-box-xl .button {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .upgrade .pricing-box-xl .icon-md {
    font-size: 28px;
  }
  .upgrade .pricing-box-xl .icon-md-big {
    font-size: 36px;
  }
}
@media (max-width: 991px) {
  .upgrade .pricing-box-wrap-element .pricing-box-alternative {
    border-radius: 9px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .upgrade .pricing-box-wrap-element:nth-child(2n+1) .pricing-box-alternative {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
  .upgrade .pricing-box-wrap-element:nth-child(2n+2) .pricing-box-alternative, .upgrade .pricing-box-wrap-element:last-child .pricing-box-alternative {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upgrade .pricing-box-wrap-element:nth-child(3n+1) .pricing-box-alternative {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
  .upgrade .pricing-box-wrap-element:nth-child(3n+3) .pricing-box-alternative, .upgrade .pricing-box-wrap-element:last-child .pricing-box-alternative {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
}
@media (min-width: 1200px) {
  .upgrade .pricing-box-wrap-element:nth-child(4n+1) .pricing-box-alternative {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
  .upgrade .pricing-box-wrap-element:nth-child(4n+4) .pricing-box-alternative, .upgrade .pricing-box-wrap-element:last-child .pricing-box-alternative {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
}
.upgrade .quote-primary {
  text-align: left;
}
.upgrade .quote-primary .cite {
  color: #ed1c94;
}
.upgrade .quote-primary-text {
  font-style: italic;
  font-size: 18px;
  line-height: 1.55556;
}
.upgrade .quote-primary-mark {
  position: relative;
  top: 6px;
  display: block;
  width: 37px;
  height: 27px;
  fill: #981df0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.upgrade .quote-primary-meta {
  margin-left: -0.2em;
  color: #c2c2c3;
}
.upgrade .quote-primary-meta * {
  padding: 0 0.2em;
}
.upgrade * + .quote-primary-text {
  margin-top: 20px;
}
.upgrade * + .quote-primary-meta {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .upgrade .quote-primary-text {
    font-size: 20px;
  }
  .upgrade * + .quote-primary-text {
    margin-top: 30px;
  }
  .upgrade * + .quote-primary-meta {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .upgrade .quote-primary-text {
    font-size: 24px;
    line-height: 1.41667;
  }
}
.upgrade .quote-minimal {
  padding-left: 30px;
  padding-right: 20px;
}
.upgrade .quote-minimal-text {
  font-size: 18px;
  font-style: italic;
  color: #29293a;
}
@media (min-width: 768px) {
  .upgrade .quote-minimal {
    max-width: 90%;
  }
  .upgrade .quote-minimal-text {
    font-size: 24px;
  }
}
.upgrade .quote-boxed {
  text-align: left;
  transform: translateY(-20px);
  margin-bottom: -20px;
  margin-left: -30px;
}
.upgrade .quote-boxed > * {
  display: inline-block;
  margin-top: 20px;
  margin-left: 30px;
}
.upgrade .quote-boxed-image {
  width: 100px;
  height: auto;
  border-radius: 50%;
}
.upgrade .quote-boxed-text,
.upgrade .quote-boxed-cite {
  font-size: 18px;
}
.upgrade .quote-boxed-text {
  position: relative;
  color: #29293a;
}
.upgrade .quote-boxed-text::before {
  position: relative;
  display: inline-block;
  transform: scale(-1, -1);
  top: -5px;
  left: -10px;
  content: "";
  font-family: "Material Design Icons";
  font-size: 45px;
  line-height: 0.8;
  color: #ed1c94;
}
.upgrade .quote-boxed-cite {
  font-weight: 400;
  color: #981df0;
}
.upgrade .quote-boxed-small {
  color: #838386;
}
.upgrade .quote-boxed-meta > * + * {
  margin-top: 0;
}
.upgrade * + .quote-boxed-meta {
  margin-top: 10px;
}
@media (min-width: 480px) {
  .upgrade .quote-boxed {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .quote-boxed-aside {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .upgrade .quote-boxed-main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}
@media (min-width: 768px) {
  .upgrade .quote-boxed-image {
    width: 160px;
  }
  .upgrade .quote-boxed-text,
.upgrade .quote-boxed-cite {
    font-size: 20px;
    line-height: 1.41667;
  }
  .upgrade .quote-boxed-text::before {
    font-size: 60px;
    top: -9px;
  }
  .upgrade * + .quote-boxed-meta {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .upgrade .quote-boxed {
    transform: translateY(-30px);
    margin-bottom: -30px;
    margin-left: -80px;
  }
  .upgrade .quote-boxed > * {
    display: inline-block;
    margin-top: 30px;
    margin-left: 80px;
  }
  .upgrade .quote-boxed-image {
    width: 210px;
  }
  .upgrade .quote-boxed-text,
.upgrade .quote-boxed-cite {
    font-size: 24px;
  }
  .upgrade * + .quote-boxed-meta {
    margin-top: 30px;
  }
}
.upgrade .quote-vertical-image {
  width: 100px;
  height: auto;
  border-radius: 50%;
}
.upgrade .quote-vertical-text {
  position: relative;
  color: #29293a;
}
.upgrade .quote-vertical-text::after {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  content: "";
  font-family: "Material Design Icons";
  font-size: 40px;
  line-height: 1;
  color: #ed1c94;
}
.upgrade .quote-vertical-cite {
  font-weight: 400;
  color: #981df0;
}
.upgrade .quote-vertical-small {
  color: #838386;
}
.upgrade .quote-vertical-meta > * + * {
  margin-top: 0;
}
.upgrade * + .quote-vertical-text {
  margin-top: 15px;
}
.upgrade * + .quote-vertical-meta {
  margin-top: 5px;
}
@media (min-width: 480px) {
  .upgrade .quote-vertical-text,
.upgrade .quote-vertical-cite {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (min-width: 768px) {
  .upgrade .quote-vertical-text,
.upgrade .quote-vertical-cite {
    font-size: 20px;
    line-height: 1.5;
  }
  .upgrade .quote-vertical-text::after {
    margin-top: 20px;
  }
  .upgrade * + .quote-vertical-text {
    margin-top: 25px;
  }
}
@media (min-width: 1200px) {
  .upgrade .quote-vertical-text,
.upgrade .quote-vertical-cite {
    font-size: 24px;
  }
}
.upgrade .quote-classic {
  text-align: left;
}
.upgrade .quote-classic-image {
  width: 70px;
  height: auto;
  border-radius: 50%;
}
.upgrade .quote-classic-header {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  transform: translateY(-20px);
  margin-bottom: -20px;
  margin-left: -20px;
}
.upgrade .quote-classic-header > * {
  display: inline-block;
  margin-top: 20px;
  margin-left: 20px;
}
.upgrade .quote-classic-text {
  font-style: italic;
  color: #29293a;
}
.upgrade .quote-classic-cite {
  font-weight: 400;
  color: #981df0;
}
.upgrade .quote-classic-small {
  font-style: italic;
  color: #838386;
}
.upgrade .quote-classic-meta > * + * {
  margin-top: 0;
}
.upgrade * + .quote-classic-text {
  margin-top: 20px;
}
@media (min-width: 480px) {
  .upgrade .quote-classic-text,
.upgrade .quote-classic-cite {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (min-width: 768px) {
  .upgrade .quote-classic-text,
.upgrade .quote-classic-cite {
    font-size: 20px;
    line-height: 1.5;
  }
}
@media (min-width: 1600px) {
  .upgrade .quote-classic-text,
.upgrade .quote-classic-cite {
    font-size: 24px;
  }
}
.upgrade .quote-modern {
  text-align: left;
}
.upgrade .quote-modern-image {
  width: 80px;
  height: auto;
  border-radius: 50%;
}
.upgrade .quote-modern-footer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  transform: translateY(-20px);
  margin-bottom: -20px;
  margin-left: -20px;
}
.upgrade .quote-modern-footer > * {
  display: inline-block;
  margin-top: 20px;
  margin-left: 20px;
}
.upgrade .quote-modern-text {
  position: relative;
  font-size: 18px;
  color: #29293a;
}
.upgrade .quote-modern-text::before {
  position: relative;
  display: inline-block;
  transform: scale(-1, -1);
  left: -10px;
  content: "";
  font-family: "Material Design Icons";
  font-size: 60px;
  font-style: normal;
  line-height: 1;
  color: #ed1c94;
}
.upgrade .quote-modern-cite {
  font-weight: 400;
  color: #981df0;
}
.upgrade .quote-modern-small {
  color: #838386;
}
.upgrade .quote-modern-meta > * + * {
  margin-top: 0;
}
.upgrade * + .quote-modern-text {
  margin-top: 20px;
}
.upgrade * + .quote-modern-footer {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .upgrade .quote-modern-cite,
.upgrade .quote-modern-text {
    font-size: 20px;
    line-height: 1.5;
  }
}
@media (min-width: 1600px) {
  .upgrade .quote-modern-cite,
.upgrade .quote-modern-text {
    font-size: 24px;
  }
}
.upgrade .snackbars {
  padding: 9px 16px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  text-align: left;
  background-color: #29293a;
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
}
.upgrade .snackbars .icon-xxs {
  font-size: 18px;
}
.upgrade .snackbars p span:last-child {
  padding-left: 14px;
}
.upgrade .snackbars-left {
  display: inline-block;
  margin-bottom: 0;
}
.upgrade .snackbars-right {
  display: inline-block;
  float: right;
  text-transform: uppercase;
}
.upgrade .snackbars-right:hover {
  text-decoration: underline;
}
@media (min-width: 480px) {
  .upgrade .snackbars {
    max-width: 540px;
    padding: 12px 15px;
    font-size: 15px;
  }
}
.upgrade .inline-message {
  border-radius: 9px;
  padding: 20px;
  text-align: left;
  font-size: 15px;
  color: #29293a;
  background: #f2f3f9;
  border: 2px dashed rgba(131, 131, 134, 0.1);
}
.upgrade * + .inline-message {
  margin-top: 20px;
}
.upgrade .table-custom {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  font-weight: 300;
  text-align: left;
  background: #fff;
}
.upgrade .table-custom th,
.upgrade .table-custom td {
  padding: 15px 20px 15px 15px;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: #29293a;
  background: #fff;
  border-bottom: 1px solid #ededed;
}
.upgrade .table-custom th:first-child,
.upgrade .table-custom td:first-child {
  padding: 15px 30px 15px 20px;
}
@media (min-width: 992px) {
  .upgrade .table-custom th,
.upgrade .table-custom td {
    padding: 15px 45px 15px 35px;
  }
  .upgrade .table-custom th:first-child,
.upgrade .table-custom td:first-child {
    padding: 15px 60px 15px 30px;
  }
}
.upgrade .table-custom th {
  color: #838386;
  border-color: #29293a;
}
.upgrade .table-checkout {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.upgrade .table-checkout .table-custom-responsive {
  border: none;
}
.upgrade .table-checkout tr td {
  border-top: 1px solid #ededed;
}
.upgrade .table-checkout tr td:last-child {
  text-align: right;
  padding-right: 25px;
  font-weight: 400;
  color: #29293a;
}
.upgrade .table-checkout .form-wrap {
  margin-left: 30px;
  margin-right: 30px;
}
.upgrade .table-checkout * + .form-wrap,
.upgrade .table-checkout * + .button {
  margin-top: 30px;
}
@media (min-width: 1600px) {
  .upgrade .table-checkout * + .form-wrap,
.upgrade .table-checkout * + .button {
    margin-top: 55px;
  }
}
.upgrade .table-shop {
  min-width: 680px;
}
.upgrade .table-shop th,
.upgrade .table-shop td {
  padding-left: 15px;
  padding-right: 15px;
}
.upgrade .table-shop th:last-child,
.upgrade .table-shop td:last-child {
  text-align: right;
  padding-right: 20px;
}
.upgrade .table-shop td {
  padding-top: 23px;
  padding-bottom: 23px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .upgrade .table-shop th:first-child,
.upgrade .table-shop td:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }
  .upgrade .table-shop th:nth-child(2),
.upgrade .table-shop td:nth-child(2) {
    width: 45%;
  }
  .upgrade .table-shop th:nth-child(3),
.upgrade .table-shop td:nth-child(3) {
    width: 13%;
  }
}
@media (min-width: 768px) {
  .upgrade .table-shop th:nth-child(2),
.upgrade .table-shop td:nth-child(2) {
    width: 43%;
  }
}
@media (min-width: 992px) {
  .upgrade .table-shop th:nth-child(1),
.upgrade .table-shop td:nth-child(1) {
    width: 12%;
  }
  .upgrade .table-shop th:nth-child(2),
.upgrade .table-shop td:nth-child(2) {
    width: 37%;
  }
  .upgrade .table-shop th:nth-child(3),
.upgrade .table-shop td:nth-child(3) {
    width: 21%;
  }
}
.upgrade .table-hover tr:hover td, .upgrade .table-hover tr:hover td {
  background-color: #f2f3f9;
}
.upgrade .table-color-header th {
  border: none;
  color: #fff;
  background-color: #981df0;
}
.upgrade .table-color-header tr:nth-child(even) td {
  background-color: #f2f3f9;
}
.upgrade .table-custom-bordered {
  border: 1px solid #ededed;
}
.upgrade .table-custom-bordered td, .upgrade .table-custom-bordered th {
  border: 1px solid #ededed;
}
.upgrade .table-custom-bordered th {
  border-bottom-color: #29293a;
}
.upgrade .table-custom-bordered tbody > tr:first-of-type > td {
  border-top: 0;
}
.upgrade .table-custom-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 767px) {
  .upgrade .table-custom-responsive {
    width: 100%;
    margin-bottom: 10px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ededed;
  }
  .upgrade .table-custom-responsive > .table-custom > thead > tr > th,
.upgrade .table-custom-responsive > .table-custom > thead > tr > td,
.upgrade .table-custom-responsive > .table-custom > tbody > tr > th,
.upgrade .table-custom-responsive > .table-custom > tbody > tr > td,
.upgrade .table-custom-responsive > .table-custom > tfoot > tr > th,
.upgrade .table-custom-responsive > .table-custom > tfoot > tr > td {
    white-space: nowrap;
  }
}
@media (max-width: 991px) {
  .upgrade .table-shop-responsive {
    width: 100%;
    margin-bottom: 10px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ededed;
  }
  .upgrade .table-shop-responsive > .table-custom > thead > tr > th,
.upgrade .table-shop-responsive > .table-custom > thead > tr > td,
.upgrade .table-shop-responsive > .table-custom > tbody > tr > th,
.upgrade .table-shop-responsive > .table-custom > tbody > tr > td,
.upgrade .table-shop-responsive > .table-custom > tfoot > tr > th,
.upgrade .table-shop-responsive > .table-custom > tfoot > tr > td {
    white-space: nowrap;
  }
}
.upgrade * + .table-custom-responsive {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade * + .table-custom-responsive {
    margin-top: 60px;
  }
}
.upgrade .team-classic {
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.upgrade .team-classic .list-inline {
  margin-left: -6px;
  margin-right: -6px;
}
.upgrade .team-classic .list-inline > li {
  padding-left: 6px;
  padding-right: 6px;
}
@media (min-width: 768px) {
  .upgrade .team-classic .list-inline {
    margin-left: -8px;
    margin-right: -8px;
  }
  .upgrade .team-classic .list-inline > li {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.upgrade .team-classic-circle {
  text-align: center;
}
@media (min-width: 1600px) {
  .upgrade .team-classic-circle {
    max-width: 300px;
  }
}
.upgrade .team-classic-image {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.upgrade .team-classic-image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: rgba(152, 29, 240, 0.7);
  border-radius: 0 0 8px 8px;
}
.upgrade .team-classic-caption {
  margin-top: 22px;
}
.upgrade .team-classic-caption * + .button {
  margin-top: 20px;
}
.upgrade .team-classic-caption * + .list-inline {
  margin-top: 15px;
}
.upgrade .team-classic-title {
  color: #ed1c94;
  font-weight: 400;
}
.upgrade .team-classic-title:hover {
  color: #981df0;
}
.upgrade .team-classic-job-position {
  font-size: 18px;
  font-style: italic;
  color: #838386;
}
.upgrade * + .team-classic-job-position {
  margin-top: 0;
}
.upgrade .team-inline {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.upgrade .team-inline * + .team-inline-text {
  margin-top: 20px;
}
.upgrade .team-inline * + .list-inline {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .upgrade .team-inline {
    max-width: 100%;
  }
  .upgrade .team-inline .team-classic-image {
    max-width: 300px;
  }
  .upgrade .team-inline * + .team-classic-job-position {
    margin-top: 15px;
  }
  .upgrade .team-inline * + .team-inline-text {
    margin-top: 40px;
  }
  .upgrade .team-inline * + .list-inline {
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .upgrade .team-inline .team-classic-image {
    max-width: 100%;
  }
}
.upgrade .team-inline-title {
  text-transform: uppercase;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  letter-spacing: 0.12em;
}
.upgrade .desktop .team-classic-image-caption {
  transition: 450ms ease-in-out;
  opacity: 0;
  transform: rotate3d(1, 0, 0, 90deg);
  transform-origin: 0 100%;
}
.upgrade .desktop .team-classic {
  perspective: 1200px;
}
.upgrade .desktop .team-classic:hover .team-classic-image-caption {
  opacity: 1;
  transform: rotate3d(1, 0, 0, 0deg);
}
.upgrade .figure-light {
  color: #c2c2c3;
}
.upgrade .image-default {
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .upgrade .image-default img {
    max-width: 360px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.upgrade .image-video {
  position: relative;
  display: inline-block;
  width: auto;
}
.upgrade .image-video .link-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upgrade .image-shadow img {
  box-shadow: 0px 10px 30px 0px rgba(142, 149, 166, 0.5);
}
.upgrade .thumbnail-classic * + .divider {
  margin-top: 20px;
}
.upgrade .thumbnail-classic span {
  position: relative;
}
.upgrade .thumbnail-classic:hover .thumbnail-classic-icon {
  color: #fff;
}
.upgrade .thumbnail-classic:hover .thumbnail-classic-icon:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  box-shadow: 0 8px 29px rgba(63, 9, 102, 0.25);
}
.upgrade .thumbnail-classic:hover .thumbnail-classic-icon:after {
  opacity: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .upgrade .thumbnail-classic.unit-sm-horizontal {
    text-align: left;
  }
  .upgrade .thumbnail-classic.unit-sm-horizontal .thumbnail-classic-caption {
    margin-top: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upgrade .thumbnail-classic.unit-md-horizontal {
    text-align: left;
  }
  .upgrade .thumbnail-classic.unit-md-horizontal .thumbnail-classic-caption {
    margin-top: 0;
  }
}
.upgrade .thumbnail-classic-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  transition: 450ms ease-in-out;
  color: #ed1c94;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.upgrade .thumbnail-classic-icon:before, .upgrade .thumbnail-classic-icon:after {
  position: absolute;
  content: "";
  display: inline-block;
  border-radius: 50%;
  transition: 450ms ease-in-out;
}
.upgrade .thumbnail-classic-icon:after {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid #d8dae2;
}
.upgrade .thumbnail-classic-icon:before {
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  opacity: 0;
  background: linear-gradient(-45deg, #ed1c94 15%, #ffec17);
}
.upgrade .thumbnail-classic-icon .icon {
  font-size: 48px;
}
.upgrade .thumbnail-classic-secondary .thumbnail-classic-text {
  letter-spacing: 0.02em;
  color: #838386;
  opacity: 1;
}
.upgrade .thumbnail-classic-secondary .thumbnail-classic-icon::after {
  border-color: #ed1c94;
}
.upgrade .thumbnail-classic-secondary .divider {
  background: #981df0;
}
.upgrade .thumbnail-classic-caption {
  margin-top: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .upgrade .thumbnail-classic-caption {
    padding-right: 15px;
  }
}
@media (min-width: 1600px) {
  .upgrade .thumbnail-classic-caption {
    margin-top: 20px;
  }
  .upgrade .thumbnail-classic-caption * + .divider {
    margin-top: 30px;
  }
  .upgrade .thumbnail-classic-caption .divider + p {
    margin-top: 30px;
  }
}
.upgrade .thumb-minimal {
  display: inline-block;
  position: relative;
}
.upgrade .thumb-minimal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(237, 28, 148, 0.7);
  transition: 0.3s ease-out all;
}
.upgrade .thumb-minimal-overlay:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 36px;
  color: #fff;
  font-family: "Material Design Icons";
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .upgrade .desktop .thumb-minimal-overlay {
    visibility: hidden;
    opacity: 0;
  }
  .upgrade .desktop .thumb-minimal:hover .thumb-minimal-overlay {
    visibility: visible;
    opacity: 1;
  }
}
.upgrade .thumb-default {
  position: relative;
  padding-bottom: 100%;
}
.upgrade .thumb-default a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.upgrade .thumb-default img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.upgrade .thumb-default-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(237, 28, 148, 0.25);
  transition: 0.3s ease-out all;
}
.upgrade .thumb-default-overlay:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 36px;
  color: #fff;
  font-family: "Material Design Icons";
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .upgrade .desktop .thumb-default-overlay {
    visibility: hidden;
    opacity: 0;
    background-color: rgba(237, 28, 148, 0.7);
  }
  .upgrade .desktop .thumb-default:hover .thumb-default-overlay {
    visibility: visible;
    opacity: 1;
  }
}
.upgrade .ie-10 .thumb-default,
.upgrade .ie-11 .thumb-default {
  overflow: hidden;
}
.upgrade .ie-10 .thumb-default img,
.upgrade .ie-11 .thumb-default img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 135%;
  max-height: 135%;
  min-height: 101%;
  min-width: 101%;
}
.upgrade .thumbnail-simple {
  text-align: left;
}
.upgrade .thumbnail-simple-image-wrap {
  display: block;
  position: relative;
  border-radius: 9px;
  overflow: hidden;
}
.upgrade .thumbnail-simple-image-wrap img {
  width: 100%;
}
.upgrade .thumbnail-simple-image-wrap:hover {
  box-shadow: 0 6px 8px 0 rgba(110, 123, 157, 0.17);
  transform: translate3d(0, -5px, 0);
}
.upgrade .thumbnail-simple-title {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 400;
}
.upgrade .thumbnail-simple-subtitle {
  font-style: italic;
}
.upgrade * + .thumbnail-simple-subtitle {
  margin-top: 0;
}
@media (min-width: 1200px) {
  .upgrade .thumbnail-simple-title {
    font-size: 20px;
  }
}
@media (min-width: 1800px) {
  .upgrade .thumbnail-simple-title {
    font-size: 24px;
  }
}
.upgrade .thumbnail-modern {
  display: block;
  position: relative;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
.upgrade .thumbnail-modern-image-wrap {
  display: block;
  position: relative;
  overflow: hidden;
  will-change: transform;
  border-radius: 9px;
  box-shadow: 0 5px 12px 0 #dcdeec;
}
.upgrade .thumbnail-modern-image-wrap img, .upgrade .thumbnail-modern-image-wrap::after {
  border-radius: 9px;
}
.upgrade .thumbnail-modern-image-wrap::before, .upgrade .thumbnail-modern-image-wrap::after {
  pointer-events: none;
}
.upgrade .thumbnail-modern-image {
  min-width: 100%;
}
.upgrade * + .thumbnail-modern-caption {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .upgrade .desktop .thumbnail-modern-image-wrap {
    transition: 0.33s;
  }
  .upgrade .desktop .thumbnail-modern-image-wrap img {
    transition: 0.7s;
    transform: scale3d(1, 1, 1);
  }
  .upgrade .desktop .thumbnail-modern-image-wrap::before {
    content: "";
    position: absolute;
    display: block;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-filter: blur(0);
    font-family: "Material Design Icons";
    font-size: 60px;
    color: #fff;
    opacity: 0;
    transition: 0.4s 0.1s;
  }
  .upgrade .desktop .thumbnail-modern-image-wrap::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #ed1c94;
    opacity: 0;
    transition: 0.4s;
  }
  .upgrade .desktop .thumbnail-modern-image-wrap:hover {
    box-shadow: 0 6px 8px 0 rgba(110, 123, 157, 0.17);
  }
  .upgrade .desktop .thumbnail-modern-image-wrap:hover img {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  .upgrade .desktop .thumbnail-modern-image-wrap:hover::before {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
  .upgrade .desktop .thumbnail-modern-image-wrap:hover::after {
    opacity: 0.6;
  }
}
@media (min-width: 768px) {
  .upgrade * + .thumbnail-modern-caption {
    margin-top: 22px;
  }
}
@media (min-width: 1600px) {
  .upgrade * + .thumbnail-modern-caption {
    margin-top: 35px;
  }
}
.upgrade .time-line-vertical-element:before, .upgrade .time-line-horizontal-element .time-line-time:before {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ed1c94;
}
.upgrade .time-line-time {
  color: #29293a;
  letter-spacing: 0.02em;
}
.upgrade .time-line-content * + img {
  margin-top: 20px;
}
.upgrade .time-line-content img + * {
  margin-top: 20px;
}
.upgrade .time-line-content img + p {
  margin-top: 20px;
}
.upgrade .time-line-content p {
  letter-spacing: 0.02em;
  color: #838386;
}
.upgrade .time-line-content * + p {
  margin-top: 10px;
}
.upgrade .bg-gray-darker .time-line-content p, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-content p {
  color: #c2c2c3;
}
.upgrade .time-line-vertical {
  position: relative;
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}
.upgrade .time-line-vertical:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
  transform: translateX(-50%);
  border-left: 2px solid #ed1c94;
}
.upgrade .time-line-vertical-element {
  position: relative;
  padding-left: 35px;
}
.upgrade .time-line-vertical-element:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
}
.upgrade .time-line-vertical-element + .time-line-vertical-element {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .upgrade .time-line-vertical:before,
.upgrade .time-line-vertical-element:before {
    display: none;
  }
  .upgrade .time-line-vertical-element {
    padding-left: 0;
  }
  .upgrade .time-line-vertical-element .time-line-content {
    position: relative;
    top: -3px;
  }
  .upgrade .time-line-vertical-element .unit-left {
    max-width: 150px;
    width: 100%;
    text-align: right;
  }
  .upgrade .time-line-vertical-element .unit-body {
    position: relative;
  }
  .upgrade .time-line-vertical-element .unit-body:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 48px;
    top: 8px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ed1c94;
  }
  .upgrade .time-line-vertical-element:last-child .unit-body:after {
    content: "";
    position: absolute;
    left: 54px;
    top: -250vh;
    bottom: -250vh;
    border-left: 2px solid #ed1c94;
  }
  .upgrade .time-line-vertical-element + .time-line-vertical-element {
    margin-top: 45px;
  }
  .upgrade .time-line-vertical-element-offset + .time-line-vertical-element-offset {
    margin-top: 55px;
  }
}
.upgrade * + .time-line-vertical {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade * + .time-line-vertical {
    margin-top: 45px;
  }
}
@media (min-width: 1200px) {
  .upgrade * + .time-line-vertical {
    margin-top: 80px;
  }
}
.upgrade .time-line-horizontal {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}
.upgrade .time-line-horizontal-element {
  padding-left: 5px;
  padding-right: 5px;
  max-width: 330px;
  text-align: left;
}
.upgrade .time-line-horizontal-element .time-line-content * + p {
  margin-top: 16px;
}
.upgrade .time-line-horizontal-element .time-line-time {
  position: relative;
  padding-bottom: 23px;
}
.upgrade .time-line-horizontal-element .time-line-time time {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.upgrade .time-line-horizontal-element .time-line-time:before {
  content: "";
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: -7px;
}
.upgrade .time-line-horizontal-element .time-line-time:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -40px;
  right: -100vw;
  border-bottom: 2px solid #ed1c94;
}
.upgrade .time-line-horizontal-element * + .time-line-content {
  margin-top: 20px;
}
@media (min-width: 1600px) {
  .upgrade .time-line-horizontal-element .time-line-time {
    padding-bottom: 33px;
  }
  .upgrade .time-line-horizontal-element * + .time-line-content {
    margin-top: 40px;
  }
}
.upgrade .bg-gray-lighter .time-line-horizontal-element .time-line-time:before {
  background-color: #f2f3f9;
}
.upgrade .bg-gray-darker .time-line-content, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-content {
  color: #c2c2c3;
}
.upgrade .bg-gray-darker .time-line-horizontal-element .time-line-time:after, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-horizontal-element .time-line-time:after,
.upgrade .bg-gray-darker .time-line-horizontal-element .time-line-time:before, .upgrade .bg-additional-gray-darker .bg-additional-item .time-line-horizontal-element .time-line-time:before {
  background-color: #29293a;
  border-color: #ffec17;
}
.upgrade .tooltip-custom {
  display: inline-block;
  cursor: pointer;
}
.upgrade .tooltip-custom + .tooltip.in {
  opacity: 1;
}
.upgrade .tooltip-custom + .tooltip.top .tooltip-arrow {
  bottom: -2px;
  margin-left: -7px;
  border-width: 7px 7px 0;
  border-top-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 7px;
  margin-bottom: -7px;
  border-width: 7px 7px 0;
  border-top-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip.top-right .tooltip-arrow {
  left: 7px;
  margin-bottom: -7px;
  border-width: 7px 7px 0;
  border-top-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip.right .tooltip-arrow {
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip.left .tooltip-arrow {
  margin-top: -7px;
  border-width: 7px 0 7px 7px;
  border-left-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip.bottom .tooltip-arrow {
  margin-left: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip.bottom-left .tooltip-arrow {
  right: 7px;
  margin-top: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 7px;
  margin-top: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: #981df0;
}
.upgrade .tooltip-custom + .tooltip .tooltip-inner {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  max-width: 253px;
  padding: 5px 12px;
  font-size: 18px;
  font-weight: 300;
  border-radius: 0;
  letter-spacing: 0.02em;
  background: #981df0;
}
.upgrade .product {
  position: relative;
  z-index: 1;
  padding: 30px 15px;
  text-align: center;
  border-radius: 9px;
  background-color: #f2f3f9;
  box-shadow: 0 5px 12px 0 #dcdeec;
}
.upgrade .product .product-price {
  color: #29293a;
}
.upgrade .product .product-price > * {
  color: inherit;
}
.upgrade .product-price-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.upgrade .product-price-wrap .product-price {
  margin-top: 0;
}
.upgrade .product-price-wrap .product-price p {
  color: #838386;
}
.upgrade .product-price-wrap .product-price p + * {
  margin-top: 0;
}
.upgrade * + .product-price-wrap {
  margin-top: 15px;
}
.upgrade .bg-primary-accent .product {
  box-shadow: 0 4px 24px 0 #3f0966;
}
.upgrade .product,
.upgrade .product-button {
  transition: 0.32s cubic-bezier(0.55, 0.09, 0.68, 0.53) all;
}
.upgrade .product-button {
  margin-top: 35px;
}
.upgrade .product-title a {
  color: #ed1c94;
}
.upgrade .product-title a:hover {
  color: #981df0;
}
.upgrade .product-image a {
  display: inline-block;
  box-shadow: 0 12px 29px 0 rgba(76, 82, 99, 0.5);
  transform: translateY(0px);
  transition: 0.32s cubic-bezier(0.55, 0.06, 0.68, 0.19) all;
}
.upgrade .product-image a:hover {
  transform: translateY(-5px);
  box-shadow: 0 18px 45px 0 rgba(76, 82, 99, 0.5);
}
.upgrade * + .product-title {
  margin-top: 20px;
}
.upgrade * + .product-price {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .upgrade .product {
    padding: 40px 25px;
  }
  .upgrade * + .product-title {
    margin-top: 45px;
  }
}
@media (min-width: 992px) {
  .upgrade .product {
    padding: 40px 27px 40px;
  }
}
@media (min-width: 1600px) {
  .upgrade * + .product-price {
    margin-top: 30px;
  }
  .upgrade .product {
    padding: 60px 30px 70px;
  }
}
@media (min-width: 1800px) {
  .upgrade .product {
    padding: 60px 47px 70px;
  }
}
.upgrade .desktop .product {
  box-shadow: none;
}
.upgrade .desktop .product:hover {
  z-index: 2;
  box-shadow: 0 5px 12px 0 #dcdeec;
  margin-bottom: -60px;
}
.upgrade .desktop .product:hover .product-title a {
  color: #981df0;
}
.upgrade .desktop .product:hover .product-title a:hover {
  color: #ed1c94;
}
.upgrade .desktop .product:hover .product-button {
  visibility: visible;
  opacity: 1;
  margin-top: 19px;
  margin-bottom: 60px;
}
.upgrade .desktop .bg-primary-accent .product {
  box-shadow: none;
}
.upgrade .desktop .bg-primary-accent .product:hover {
  box-shadow: 0 4px 24px 0 #3f0966;
}
.upgrade .desktop .product-button {
  position: relative;
  visibility: hidden;
  opacity: 0;
  transform-origin: 50% 50%;
  margin-top: 0;
}
.upgrade .desktop .product-button .button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
@media (min-width: 1600px) {
  .upgrade .desktop .product:hover {
    margin-bottom: -80px;
  }
  .upgrade .desktop .product:hover .product-button {
    margin-top: 35px;
    margin-bottom: 80px;
  }
}
.upgrade .bg-gray-lighter .product {
  background-color: #fff;
}
.upgrade .shop-aside h6 + * {
  margin-top: 15px;
}
@media (min-width: 1600px) {
  .upgrade .shop-aside-item {
    margin-left: 30px;
  }
  .upgrade .shop-aside h6 + * {
    margin-top: 28px;
  }
}
@media (min-width: 1800px) {
  .upgrade .shop-aside-item {
    margin-left: 60px;
  }
}
.upgrade .product-thumbnails {
  position: relative;
  transform: translateY(-20px);
  margin-bottom: -20px;
  pointer-events: none;
  text-align: center;
}
.upgrade .product-thumbnails > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade .product-thumbnails > * {
  margin-top: 20px;
  pointer-events: auto;
}
.upgrade .product-thumbnails > li {
  width: 100px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.3s ease-out all;
}
.upgrade .product-thumbnails > li:hover, .upgrade .product-thumbnails > li.active {
  border-color: #c2c2c3;
}
.upgrade .product-single-preview {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
.upgrade .product-single-image.unit-right {
  flex-shrink: 1;
}
@media (min-width: 1600px) {
  .upgrade .product-single-image.unit-right {
    flex-shrink: 0;
  }
}
.upgrade .product-single-image-element {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upgrade .product-single-image-element img[src="images/shop-01-420x550.png"] {
  box-shadow: 0 12px 29px 0 rgba(76, 82, 99, 0.5);
}
@media (max-width: 767px) {
  .upgrade .product-single-image-element img {
    max-height: 300px;
  }
}
@media (min-width: 992px) {
  .upgrade .product-single-preview {
    max-width: 570px;
    margin-right: 0;
  }
}
.upgrade .animateImageIn {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-out opacity;
}
.upgrade .animateImageOut {
  opacity: 0;
  visibility: hidden;
}
.upgrade .product-counter {
  padding-top: 100px;
}
@media (min-width: 1800px) {
  .upgrade .product-counter {
    padding-top: 120px;
  }
}
.upgrade .product-counter-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px;
  background-color: #981df0;
  border-radius: 9px 9px 0 0;
}
.upgrade .product-counter-inner .countdown {
  font-weight: 400;
  color: #fff;
}
@media (min-width: 1200px) {
  .upgrade .product-counter-inner .countdown {
    font-size: 24px;
  }
}
.upgrade .product-auction .product-image a {
  box-shadow: none;
}
@media (min-width: 1200px) {
  .upgrade .product-auction .product-title {
    min-height: 68px;
  }
}
.upgrade .text-left {
  text-align: left;
}
.upgrade .text-center {
  text-align: center;
}
.upgrade .text-right {
  text-align: right;
}
.upgrade .text-middle {
  vertical-align: middle;
}
@media (min-width: 480px) {
  .upgrade html:not(.lt-ie10) .text-xs-left {
    text-align: left;
  }
  .upgrade html:not(.lt-ie10) .text-xs-center {
    text-align: center;
  }
  .upgrade html:not(.lt-ie10) .text-xs-right {
    text-align: right;
  }
  .upgrade html:not(.lt-ie10) .text-xs-justify {
    text-align: justify;
  }
}
@media (min-width: 768px) {
  .upgrade html:not(.lt-ie10) .text-sm-left {
    text-align: left;
  }
  .upgrade html:not(.lt-ie10) .text-sm-center {
    text-align: center;
  }
  .upgrade html:not(.lt-ie10) .text-sm-right {
    text-align: right;
  }
  .upgrade html:not(.lt-ie10) .text-sm-justify {
    text-align: justify;
  }
}
@media (min-width: 992px) {
  .upgrade html:not(.lt-ie10) .text-md-left {
    text-align: left;
  }
  .upgrade html:not(.lt-ie10) .text-md-center {
    text-align: center;
  }
  .upgrade html:not(.lt-ie10) .text-md-right {
    text-align: right;
  }
  .upgrade html:not(.lt-ie10) .text-md-justify {
    text-align: justify;
  }
}
@media (min-width: 1200px) {
  .upgrade html:not(.lt-ie10) .text-lg-left {
    text-align: left;
  }
  .upgrade html:not(.lt-ie10) .text-lg-center {
    text-align: center;
  }
  .upgrade html:not(.lt-ie10) .text-lg-right {
    text-align: right;
  }
  .upgrade html:not(.lt-ie10) .text-lg-justify {
    text-align: justify;
  }
}
.upgrade .bg-gray-darker.page-footer, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item,
.upgrade .bg-image.page-footer, .upgrade .bg-additional-image .page-footer.bg-additional-item,
.upgrade .bg-gradient.page-footer, .upgrade .page-footer.bg-gradient-1, .upgrade .page-footer.bg-gradient-2, .upgrade .page-footer.bg-gradient-3 {
  color: #fff;
}
.upgrade .bg-gray-darker.page-footer h1, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item h1,
.upgrade .bg-image.page-footer h1, .upgrade .bg-additional-image .page-footer.bg-additional-item h1,
.upgrade .bg-gradient.page-footer h1, .upgrade .page-footer.bg-gradient-1 h1, .upgrade .page-footer.bg-gradient-2 h1, .upgrade .page-footer.bg-gradient-3 h1, .upgrade .bg-gray-darker.page-footer h2, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item h2,
.upgrade .bg-image.page-footer h2, .upgrade .bg-additional-image .page-footer.bg-additional-item h2,
.upgrade .bg-gradient.page-footer h2, .upgrade .page-footer.bg-gradient-1 h2, .upgrade .page-footer.bg-gradient-2 h2, .upgrade .page-footer.bg-gradient-3 h2, .upgrade .bg-gray-darker.page-footer h3, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item h3,
.upgrade .bg-image.page-footer h3, .upgrade .bg-additional-image .page-footer.bg-additional-item h3,
.upgrade .bg-gradient.page-footer h3, .upgrade .page-footer.bg-gradient-1 h3, .upgrade .page-footer.bg-gradient-2 h3, .upgrade .page-footer.bg-gradient-3 h3, .upgrade .bg-gray-darker.page-footer h4, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item h4,
.upgrade .bg-image.page-footer h4, .upgrade .bg-additional-image .page-footer.bg-additional-item h4,
.upgrade .bg-gradient.page-footer h4, .upgrade .page-footer.bg-gradient-1 h4, .upgrade .page-footer.bg-gradient-2 h4, .upgrade .page-footer.bg-gradient-3 h4, .upgrade .bg-gray-darker.page-footer h5, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item h5,
.upgrade .bg-image.page-footer h5, .upgrade .bg-additional-image .page-footer.bg-additional-item h5,
.upgrade .bg-gradient.page-footer h5, .upgrade .page-footer.bg-gradient-1 h5, .upgrade .page-footer.bg-gradient-2 h5, .upgrade .page-footer.bg-gradient-3 h5, .upgrade .bg-gray-darker.page-footer h6, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item h6,
.upgrade .bg-image.page-footer h6, .upgrade .bg-additional-image .page-footer.bg-additional-item h6,
.upgrade .bg-gradient.page-footer h6, .upgrade .page-footer.bg-gradient-1 h6, .upgrade .page-footer.bg-gradient-2 h6, .upgrade .page-footer.bg-gradient-3 h6, .upgrade .bg-gray-darker.page-footer [class^=heading-], .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item [class^=heading-],
.upgrade .bg-image.page-footer [class^=heading-], .upgrade .bg-additional-image .page-footer.bg-additional-item [class^=heading-],
.upgrade .bg-gradient.page-footer [class^=heading-], .upgrade .page-footer.bg-gradient-1 [class^=heading-], .upgrade .page-footer.bg-gradient-2 [class^=heading-], .upgrade .page-footer.bg-gradient-3 [class^=heading-] {
  color: #fff;
}
.upgrade .bg-gray-darker.page-footer .list-marked-primary > li:before, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .list-marked-primary > li:before,
.upgrade .bg-image.page-footer .list-marked-primary > li:before, .upgrade .bg-additional-image .page-footer.bg-additional-item .list-marked-primary > li:before,
.upgrade .bg-gradient.page-footer .list-marked-primary > li:before, .upgrade .page-footer.bg-gradient-1 .list-marked-primary > li:before, .upgrade .page-footer.bg-gradient-2 .list-marked-primary > li:before, .upgrade .page-footer.bg-gradient-3 .list-marked-primary > li:before {
  color: #c2c2c3;
}
.upgrade .bg-gray-darker.page-footer .list-marked-primary > li a, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .list-marked-primary > li a,
.upgrade .bg-image.page-footer .list-marked-primary > li a, .upgrade .bg-additional-image .page-footer.bg-additional-item .list-marked-primary > li a,
.upgrade .bg-gradient.page-footer .list-marked-primary > li a, .upgrade .page-footer.bg-gradient-1 .list-marked-primary > li a, .upgrade .page-footer.bg-gradient-2 .list-marked-primary > li a, .upgrade .page-footer.bg-gradient-3 .list-marked-primary > li a, .upgrade .bg-gray-darker.page-footer .list-marked-primary > li a:focus, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .list-marked-primary > li a:focus,
.upgrade .bg-image.page-footer .list-marked-primary > li a:focus, .upgrade .bg-additional-image .page-footer.bg-additional-item .list-marked-primary > li a:focus,
.upgrade .bg-gradient.page-footer .list-marked-primary > li a:focus, .upgrade .page-footer.bg-gradient-1 .list-marked-primary > li a:focus, .upgrade .page-footer.bg-gradient-2 .list-marked-primary > li a:focus, .upgrade .page-footer.bg-gradient-3 .list-marked-primary > li a:focus, .upgrade .bg-gray-darker.page-footer .list-marked-primary > li a:active, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .list-marked-primary > li a:active,
.upgrade .bg-image.page-footer .list-marked-primary > li a:active, .upgrade .bg-additional-image .page-footer.bg-additional-item .list-marked-primary > li a:active,
.upgrade .bg-gradient.page-footer .list-marked-primary > li a:active, .upgrade .page-footer.bg-gradient-1 .list-marked-primary > li a:active, .upgrade .page-footer.bg-gradient-2 .list-marked-primary > li a:active, .upgrade .page-footer.bg-gradient-3 .list-marked-primary > li a:active {
  color: #ffec17;
}
.upgrade .bg-gray-darker.page-footer .list-marked-primary > li a:hover, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .list-marked-primary > li a:hover,
.upgrade .bg-image.page-footer .list-marked-primary > li a:hover, .upgrade .bg-additional-image .page-footer.bg-additional-item .list-marked-primary > li a:hover,
.upgrade .bg-gradient.page-footer .list-marked-primary > li a:hover, .upgrade .page-footer.bg-gradient-1 .list-marked-primary > li a:hover, .upgrade .page-footer.bg-gradient-2 .list-marked-primary > li a:hover, .upgrade .page-footer.bg-gradient-3 .list-marked-primary > li a:hover {
  color: #ed1c94;
}
.upgrade .bg-gray-darker.page-footer .right, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .right,
.upgrade .bg-image.page-footer .right, .upgrade .bg-additional-image .page-footer.bg-additional-item .right,
.upgrade .bg-gradient.page-footer .right, .upgrade .page-footer.bg-gradient-1 .right, .upgrade .page-footer.bg-gradient-2 .right, .upgrade .page-footer.bg-gradient-3 .right, .upgrade .bg-gray-darker.page-footer .form-label, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .form-label,
.upgrade .bg-image.page-footer .form-label, .upgrade .bg-additional-image .page-footer.bg-additional-item .form-label,
.upgrade .bg-gradient.page-footer .form-label, .upgrade .page-footer.bg-gradient-1 .form-label, .upgrade .page-footer.bg-gradient-2 .form-label, .upgrade .page-footer.bg-gradient-3 .form-label, .upgrade .bg-gray-darker.page-footer .form-input, .upgrade .bg-additional-gray-darker .page-footer.bg-additional-item .form-input,
.upgrade .bg-image.page-footer .form-input, .upgrade .bg-additional-image .page-footer.bg-additional-item .form-input,
.upgrade .bg-gradient.page-footer .form-input, .upgrade .page-footer.bg-gradient-1 .form-input, .upgrade .page-footer.bg-gradient-2 .form-input, .upgrade .page-footer.bg-gradient-3 .form-input {
  color: #838386;
}
.upgrade .bg-gray-lighter.page-footer-default .rd-mailform-inline .rd-mailform-inline-inner::before, .upgrade .bg-gray-lighter.page-footer-default .form-input {
  background-color: #fff;
}
.upgrade .bg-gray-lighter.page-footer-default .right, .upgrade .bg-gray-lighter.page-footer-default .form-label, .upgrade .bg-gray-lighter.page-footer-default .form-input {
  color: #838386;
}
.upgrade .bg-white.page-footer-minimal,
.upgrade .bg-gray-lighter.page-footer-minimal {
  color: #838386;
}
.upgrade .bg-white.page-footer-minimal .default-logo,
.upgrade .bg-gray-lighter.page-footer-minimal .default-logo {
  display: inline-block;
}
.upgrade .bg-white.page-footer-minimal .inverse-logo,
.upgrade .bg-gray-lighter.page-footer-minimal .inverse-logo {
  display: none;
}
.upgrade .bg-gray-darker.page-footer-minimal .default-logo, .upgrade .bg-additional-gray-darker .page-footer-minimal.bg-additional-item .default-logo,
.upgrade .bg-image.page-footer-minimal .default-logo, .upgrade .bg-additional-image .page-footer-minimal.bg-additional-item .default-logo,
.upgrade .bg-gradient.page-footer-minimal .default-logo, .upgrade .page-footer-minimal.bg-gradient-1 .default-logo, .upgrade .page-footer-minimal.bg-gradient-2 .default-logo, .upgrade .page-footer-minimal.bg-gradient-3 .default-logo {
  display: none;
}
.upgrade .bg-gray-darker.page-footer-minimal .inverse-logo, .upgrade .bg-additional-gray-darker .page-footer-minimal.bg-additional-item .inverse-logo,
.upgrade .bg-image.page-footer-minimal .inverse-logo, .upgrade .bg-additional-image .page-footer-minimal.bg-additional-item .inverse-logo,
.upgrade .bg-gradient.page-footer-minimal .inverse-logo, .upgrade .page-footer-minimal.bg-gradient-1 .inverse-logo, .upgrade .page-footer-minimal.bg-gradient-2 .inverse-logo, .upgrade .page-footer-minimal.bg-gradient-3 .inverse-logo {
  display: inline-block;
}
.upgrade .bg-gray-darker.page-footer-minimal a.icon-secondary-5-filled, .upgrade .bg-additional-gray-darker .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled,
.upgrade .bg-image.page-footer-minimal a.icon-secondary-5-filled, .upgrade .bg-additional-image .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled,
.upgrade .bg-gradient.page-footer-minimal a.icon-secondary-5-filled, .upgrade .page-footer-minimal.bg-gradient-1 a.icon-secondary-5-filled, .upgrade .page-footer-minimal.bg-gradient-2 a.icon-secondary-5-filled, .upgrade .page-footer-minimal.bg-gradient-3 a.icon-secondary-5-filled, .upgrade .bg-gray-darker.page-footer-minimal a.icon-secondary-5-filled:focus, .upgrade .bg-additional-gray-darker .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled:focus,
.upgrade .bg-image.page-footer-minimal a.icon-secondary-5-filled:focus, .upgrade .bg-additional-image .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled:focus,
.upgrade .bg-gradient.page-footer-minimal a.icon-secondary-5-filled:focus, .upgrade .page-footer-minimal.bg-gradient-1 a.icon-secondary-5-filled:focus, .upgrade .page-footer-minimal.bg-gradient-2 a.icon-secondary-5-filled:focus, .upgrade .page-footer-minimal.bg-gradient-3 a.icon-secondary-5-filled:focus, .upgrade .bg-gray-darker.page-footer-minimal a.icon-secondary-5-filled:active, .upgrade .bg-additional-gray-darker .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled:active,
.upgrade .bg-image.page-footer-minimal a.icon-secondary-5-filled:active, .upgrade .bg-additional-image .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled:active,
.upgrade .bg-gradient.page-footer-minimal a.icon-secondary-5-filled:active, .upgrade .page-footer-minimal.bg-gradient-1 a.icon-secondary-5-filled:active, .upgrade .page-footer-minimal.bg-gradient-2 a.icon-secondary-5-filled:active, .upgrade .page-footer-minimal.bg-gradient-3 a.icon-secondary-5-filled:active, .upgrade .bg-gray-darker.page-footer-minimal a.icon-secondary-5-filled:hover, .upgrade .bg-additional-gray-darker .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled:hover,
.upgrade .bg-image.page-footer-minimal a.icon-secondary-5-filled:hover, .upgrade .bg-additional-image .page-footer-minimal.bg-additional-item a.icon-secondary-5-filled:hover,
.upgrade .bg-gradient.page-footer-minimal a.icon-secondary-5-filled:hover, .upgrade .page-footer-minimal.bg-gradient-1 a.icon-secondary-5-filled:hover, .upgrade .page-footer-minimal.bg-gradient-2 a.icon-secondary-5-filled:hover, .upgrade .page-footer-minimal.bg-gradient-3 a.icon-secondary-5-filled:hover {
  color: #fff;
}
.upgrade .bg-white.page-footer-minimal a.icon-secondary-5-filled {
  color: #838386;
  background-color: #f2f3f9;
}
.upgrade .bg-white.page-footer-minimal a.icon-secondary-5-filled:hover {
  color: #fff;
}
.upgrade .bg-gray-lighter.page-footer-minimal a.icon-secondary-5-filled {
  color: #838386;
  background-color: #fff;
}
.upgrade .bg-gray-lighter.page-footer-minimal a.icon-secondary-5-filled:hover {
  color: #fff;
}
.upgrade .bg-white.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a,
.upgrade .bg-gray-lighter.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a {
  color: #838386;
}
.upgrade .bg-white.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-gray-lighter.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-white.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a:hover,
.upgrade .bg-gray-lighter.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a:hover {
  color: #981df0;
}
.upgrade .bg-white.page-footer-extended .box-inline a,
.upgrade .bg-gray-lighter.page-footer-extended .box-inline a, .upgrade .bg-white.page-footer-extended .box-inline a:focus,
.upgrade .bg-gray-lighter.page-footer-extended .box-inline a:focus, .upgrade .bg-white.page-footer-extended .box-inline a:active,
.upgrade .bg-gray-lighter.page-footer-extended .box-inline a:active {
  color: #838386;
}
.upgrade .bg-white.page-footer-extended .box-inline a:hover,
.upgrade .bg-gray-lighter.page-footer-extended .box-inline a:hover {
  color: #981df0;
}
.upgrade .bg-white.page-footer-extended a.icon-secondary-5-filled,
.upgrade .bg-gray-lighter.page-footer-extended a.icon-secondary-5-filled, .upgrade .bg-white.page-footer-extended a.icon-secondary-5-filled:focus,
.upgrade .bg-gray-lighter.page-footer-extended a.icon-secondary-5-filled:focus, .upgrade .bg-white.page-footer-extended a.icon-secondary-5-filled:active,
.upgrade .bg-gray-lighter.page-footer-extended a.icon-secondary-5-filled:active {
  color: #fff;
  background-color: #c2c2c3;
}
.upgrade .bg-white.page-footer-extended a.icon-secondary-5-filled:hover,
.upgrade .bg-gray-lighter.page-footer-extended a.icon-secondary-5-filled:hover {
  color: #fff;
}
.upgrade .bg-gray-darker.page-footer-extended .icon-secondary-5-filled, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .icon-secondary-5-filled,
.upgrade .bg-image.page-footer-extended .icon-secondary-5-filled, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .icon-secondary-5-filled,
.upgrade .bg-gradient.page-footer-extended .icon-secondary-5-filled, .upgrade .page-footer-extended.bg-gradient-1 .icon-secondary-5-filled, .upgrade .page-footer-extended.bg-gradient-2 .icon-secondary-5-filled, .upgrade .page-footer-extended.bg-gradient-3 .icon-secondary-5-filled {
  color: #c2c2c3;
  background-color: #21212e;
}
.upgrade .bg-gray-darker.page-footer-extended .list-darker, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .list-darker,
.upgrade .bg-image.page-footer-extended .list-darker, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .list-darker,
.upgrade .bg-gradient.page-footer-extended .list-darker, .upgrade .page-footer-extended.bg-gradient-1 .list-darker, .upgrade .page-footer-extended.bg-gradient-2 .list-darker, .upgrade .page-footer-extended.bg-gradient-3 .list-darker {
  color: #c2c2c3;
}
.upgrade .bg-gray-darker.page-footer-extended .list-darker a:hover, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .list-darker a:hover,
.upgrade .bg-image.page-footer-extended .list-darker a:hover, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .list-darker a:hover,
.upgrade .bg-gradient.page-footer-extended .list-darker a:hover, .upgrade .page-footer-extended.bg-gradient-1 .list-darker a:hover, .upgrade .page-footer-extended.bg-gradient-2 .list-darker a:hover, .upgrade .page-footer-extended.bg-gradient-3 .list-darker a:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker.page-footer-extended .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs,
.upgrade .bg-image.page-footer-extended .tabs-custom.tabs-line .nav-tabs, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs,
.upgrade .bg-gradient.page-footer-extended .tabs-custom.tabs-line .nav-tabs, .upgrade .page-footer-extended.bg-gradient-1 .tabs-custom.tabs-line .nav-tabs, .upgrade .page-footer-extended.bg-gradient-2 .tabs-custom.tabs-line .nav-tabs, .upgrade .page-footer-extended.bg-gradient-3 .tabs-custom.tabs-line .nav-tabs {
  border-color: rgba(255, 255, 255, 0.1);
}
@media (max-width: 767px) {
  .upgrade .bg-gray-darker.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-image.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-gradient.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .page-footer-extended.bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .page-footer-extended.bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .page-footer-extended.bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li.active a {
    color: #fff;
  }
}
@media (min-width: 768px) {
  .upgrade .bg-gray-darker.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li a,
.upgrade .bg-image.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li a,
.upgrade .bg-gradient.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a, .upgrade .page-footer-extended.bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li a, .upgrade .page-footer-extended.bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li a, .upgrade .page-footer-extended.bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li a {
    color: #c2c2c3;
  }
}
.upgrade .bg-gray-darker.page-footer-extended .right a:hover, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .right a:hover,
.upgrade .bg-image.page-footer-extended .right a:hover, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .right a:hover,
.upgrade .bg-gradient.page-footer-extended .right a:hover, .upgrade .page-footer-extended.bg-gradient-1 .right a:hover, .upgrade .page-footer-extended.bg-gradient-2 .right a:hover, .upgrade .page-footer-extended.bg-gradient-3 .right a:hover {
  color: #fff;
}
.upgrade .bg-gray-darker.page-footer-extended .blog-inline-meta a:hover, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .blog-inline-meta a:hover,
.upgrade .bg-image.page-footer-extended .blog-inline-meta a:hover, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .blog-inline-meta a:hover,
.upgrade .bg-gradient.page-footer-extended .blog-inline-meta a:hover, .upgrade .page-footer-extended.bg-gradient-1 .blog-inline-meta a:hover, .upgrade .page-footer-extended.bg-gradient-2 .blog-inline-meta a:hover, .upgrade .page-footer-extended.bg-gradient-3 .blog-inline-meta a:hover {
  color: #fff;
}
.upgrade .bg-gray-darker.page-footer-extended .blog-inline-title, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .blog-inline-title,
.upgrade .bg-image.page-footer-extended .blog-inline-title, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .blog-inline-title,
.upgrade .bg-gradient.page-footer-extended .blog-inline-title, .upgrade .page-footer-extended.bg-gradient-1 .blog-inline-title, .upgrade .page-footer-extended.bg-gradient-2 .blog-inline-title, .upgrade .page-footer-extended.bg-gradient-3 .blog-inline-title {
  color: #ffec17;
}
.upgrade .bg-gray-darker.page-footer-extended .blog-inline-title a:hover, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .blog-inline-title a:hover,
.upgrade .bg-image.page-footer-extended .blog-inline-title a:hover, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .blog-inline-title a:hover,
.upgrade .bg-gradient.page-footer-extended .blog-inline-title a:hover, .upgrade .page-footer-extended.bg-gradient-1 .blog-inline-title a:hover, .upgrade .page-footer-extended.bg-gradient-2 .blog-inline-title a:hover, .upgrade .page-footer-extended.bg-gradient-3 .blog-inline-title a:hover {
  color: #fff;
}
.upgrade .bg-gray-darker.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a::after, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li a::after,
.upgrade .bg-image.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a::after, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li a::after,
.upgrade .bg-gradient.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a::after, .upgrade .page-footer-extended.bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li a::after, .upgrade .page-footer-extended.bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li a::after, .upgrade .page-footer-extended.bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li a::after {
  background-color: #ffec17;
}
@media (min-width: 768px) {
  .upgrade .bg-gray-darker.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-image.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li.active a,
.upgrade .bg-gradient.page-footer-extended .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .page-footer-extended.bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .page-footer-extended.bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .page-footer-extended.bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li.active a, .upgrade .bg-gray-darker.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .bg-additional-gray-darker .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li a:hover,
.upgrade .bg-image.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .bg-additional-image .page-footer-extended.bg-additional-item .tabs-custom.tabs-line .nav-tabs li a:hover,
.upgrade .bg-gradient.page-footer-extended .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .page-footer-extended.bg-gradient-1 .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .page-footer-extended.bg-gradient-2 .tabs-custom.tabs-line .nav-tabs li a:hover, .upgrade .page-footer-extended.bg-gradient-3 .tabs-custom.tabs-line .nav-tabs li a:hover {
    color: #ffec17;
  }
}
.upgrade .bg-white.page-footer-extended p.right, .upgrade .bg-white.page-footer-default p.right, .upgrade .bg-white.page-footer-alternative p.right,
.upgrade .bg-gray-lighter.page-footer-extended p.right,
.upgrade .bg-gray-lighter.page-footer-default p.right,
.upgrade .bg-gray-lighter.page-footer-alternative p.right {
  color: #838386;
}
.upgrade .text-italic {
  font-style: italic;
}
.upgrade .text-normal {
  font-style: normal;
}
.upgrade .text-underline {
  text-decoration: underline;
}
.upgrade .text-strike {
  text-decoration: line-through;
}
.upgrade .text-thin {
  font-weight: 100;
}
.upgrade .text-elight {
  font-weight: 200;
}
.upgrade .text-light {
  font-weight: 300;
}
.upgrade .text-regular {
  font-weight: 400;
}
.upgrade .text-medium {
  font-weight: 500;
}
.upgrade .text-sbold {
  font-weight: 600;
}
.upgrade .text-bold, .upgrade strong {
  font-weight: 700;
}
.upgrade .text-ubold {
  font-weight: 900;
}
.upgrade .text-spacing-0 {
  letter-spacing: 0;
}
.upgrade .text-spacing-sm {
  letter-spacing: 0.02em;
}
.upgrade .text-spacing-md {
  letter-spacing: 0.06em;
}
.upgrade .text-spacing-lg {
  letter-spacing: 0.12em;
}
.upgrade .reveal-block {
  display: block !important;
}
.upgrade .reveal-inline-block {
  display: inline-block !important;
}
.upgrade .reveal-inline {
  display: inline !important;
}
.upgrade .reveal-flex {
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}
.upgrade .veil {
  display: none !important;
}
@media (min-width: 480px) {
  .upgrade .reveal-xs-block {
    display: block !important;
  }
  .upgrade .reveal-xs-inline-block {
    display: inline-block !important;
  }
  .upgrade .reveal-xs-inline {
    display: inline !important;
  }
  .upgrade .reveal-xs-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
  .upgrade .veil-xs {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .upgrade .reveal-sm-block {
    display: block !important;
  }
  .upgrade .reveal-sm-inline-block {
    display: inline-block !important;
  }
  .upgrade .reveal-sm-inline {
    display: inline !important;
  }
  .upgrade .reveal-sm-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
  .upgrade .veil-sm {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .upgrade .reveal-md-block {
    display: block !important;
  }
  .upgrade .reveal-md-inline-block {
    display: inline-block !important;
  }
  .upgrade .reveal-md-inline {
    display: inline !important;
  }
  .upgrade .reveal-md-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
  .upgrade .veil-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .upgrade .reveal-lg-block {
    display: block !important;
  }
  .upgrade .reveal-lg-inline-block {
    display: inline-block !important;
  }
  .upgrade .reveal-lg-inline {
    display: inline !important;
  }
  .upgrade .reveal-lg-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
  .upgrade .veil-lg {
    display: none !important;
  }
}
@media (min-width: 1600px) {
  .upgrade .reveal-xl-block {
    display: block !important;
  }
  .upgrade .reveal-xl-inline-block {
    display: inline-block !important;
  }
  .upgrade .reveal-xl-inline {
    display: inline !important;
  }
  .upgrade .reveal-xl-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
  .upgrade .veil-xl {
    display: none !important;
  }
}
@media (min-width: 1800px) {
  .upgrade .reveal-xxl-block {
    display: block !important;
  }
  .upgrade .reveal-xxl-inline-block {
    display: inline-block !important;
  }
  .upgrade .reveal-xxl-inline {
    display: inline !important;
  }
  .upgrade .reveal-xxl-flex {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
  .upgrade .veil-xxl {
    display: none !important;
  }
}
.upgrade .page .group {
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -10px;
}
.upgrade .page .group > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}
.upgrade .page .group-xxs {
  transform: translateY(-5px);
  margin-bottom: -5px;
  margin-left: -5px;
}
.upgrade .page .group-xxs > * {
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
}
.upgrade .page .group-xs {
  transform: translateY(-9px);
  margin-bottom: -9px;
  margin-left: -9px;
}
.upgrade .page .group-xs > * {
  display: inline-block;
  margin-top: 9px;
  margin-left: 9px;
}
.upgrade .page .group-md {
  transform: translateY(-30px);
  margin-bottom: -30px;
  margin-left: -30px;
}
.upgrade .page .group-md > * {
  display: inline-block;
  margin-top: 30px;
  margin-left: 30px;
}
.upgrade .page .group-lg {
  transform: translateY(-5px);
  margin-bottom: -5px;
  margin-left: -40px;
}
.upgrade .page .group-lg > * {
  display: inline-block;
  margin-top: 5px;
  margin-left: 40px;
}
.upgrade .page .group-xl {
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -50px;
}
.upgrade .page .group-xl > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 50px;
}
.upgrade .group-middle {
  display: inline-table;
}
.upgrade .group-middle > * {
  vertical-align: middle;
}
.upgrade .section-bottom {
  padding-top: 0;
  padding-bottom: 60px;
}
@media (min-width: 992px) {
  .upgrade .section-bottom {
    padding-bottom: 0;
  }
}
.upgrade .section-bottom.section-reverse {
  padding-top: 60px;
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .upgrade .section-bottom.section-reverse {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.upgrade .section-xs {
  padding: 40px 0;
}
.upgrade .section-sm,
.upgrade .section-md,
.upgrade .section-lg,
.upgrade .section-custom,
.upgrade .section-xl,
.upgrade .section-xxl,
.upgrade .section-variant-1,
.upgrade .section-variant-2 {
  padding: 60px 0;
}
.upgrade .section-lg-top {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .upgrade .section-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .upgrade .section-lg, .upgrade .section-custom {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .upgrade .section-xl {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .upgrade .section-xxl {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .upgrade .section-lg-top {
    padding-top: 80px;
  }
  .upgrade .section-variant-1 {
    padding: 120px 0;
  }
  .upgrade .section-variant-2 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .upgrade .section-xl {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .upgrade .section-md {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .upgrade .section-lg, .upgrade .section-custom {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .upgrade .breadcrumbs-custom-wrap + .section-lg, .upgrade .breadcrumbs-custom-wrap + .section-custom {
    padding-top: 100px;
  }
  .upgrade .section-xl {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .upgrade .breadcrumbs-custom-wrap + .section-xl {
    padding-top: 145px;
  }
  .upgrade .section-xxl {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .upgrade .section-variant-1 {
    padding: 160px 0 100px;
  }
  .upgrade .section-variant-2 {
    padding: 120px 0 140px;
  }
}
@media (min-width: 1600px) {
  .upgrade .section-lg, .upgrade .section-custom {
    padding-top: 125px;
    padding-bottom: 125px;
  }
  .upgrade .section-lg.section-way-point, .upgrade .section-way-point.section-custom {
    padding-top: 165px;
  }
  .upgrade .breadcrumbs-custom-wrap + .section-lg, .upgrade .breadcrumbs-custom-wrap + .section-custom {
    padding-top: 170px;
  }
  .upgrade .section-lg-alternative {
    padding-top: 130px;
    padding-bottom: 140px;
  }
  .upgrade .section-xl {
    padding-top: 165px;
    padding-bottom: 165px;
  }
  .upgrade .breadcrumbs-custom-wrap + .section-xl {
    padding-top: 190px;
  }
  .upgrade .section-xxl {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .upgrade .section-lg-top {
    padding-top: 125px;
  }
  .upgrade .section-variant-1 {
    padding: 220px 0 140px;
  }
  .upgrade .section-variant-2 {
    padding: 160px 0;
  }
}
@media (min-width: 1800px) {
  .upgrade .section-xl {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .upgrade .section-xxl {
    padding-top: 290px;
    padding-bottom: 290px;
  }
}
.upgrade .section-wrap {
  position: relative;
  padding: 1px 0;
  overflow: hidden;
}
.upgrade .section-wrap .section-wrap-inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  transform: translateY(-35px);
  margin-bottom: -35px;
  pointer-events: none;
  width: 100%;
}
.upgrade .section-wrap .section-wrap-inner > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade .section-wrap .section-wrap-inner > * {
  margin-top: 35px;
  pointer-events: auto;
}
.upgrade .section-wrap .section-wrap-inner > * {
  width: 100%;
}
.upgrade .section-wrap .section-wrap-aside {
  bottom: -1px;
}
.upgrade .section-wrap .rd-google-map {
  width: 100%;
  max-width: none;
}
.upgrade .section-wrap .box-minimal {
  width: 100%;
  max-width: none;
}
.upgrade .section-wrap .section-wrap-image {
  position: relative;
  top: -1px;
  overflow: hidden;
  height: 300px;
}
.upgrade .section-wrap .section-wrap-image img {
  position: absolute;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
@supports (object-fit: cover) {
  .upgrade .section-wrap .section-wrap-image img {
    top: 0;
    left: 0;
    transform: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
@media (min-width: 768px) {
  .upgrade .section-wrap .section-wrap-image {
    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .upgrade .section-wrap .box-width-small {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .upgrade .section-wrap .section-wrap-aside {
    position: absolute;
    top: -4px;
    right: 0;
    bottom: -4px;
    height: auto;
    width: calc(50% - 190px);
  }
  .upgrade .section-wrap .section-wrap-aside.section-wrap-aside-custom {
    width: 50%;
  }
  .upgrade .section-wrap .section-wrap-aside .jp-video-single {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .upgrade .section-wrap .section-wrap-aside .jp-video .jp-jplayer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
  }
  .upgrade .section-wrap .section-wrap-aside .jp-video .jp-jplayer img, .upgrade .section-wrap .section-wrap-aside .jp-video .jp-jplayer video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
    height: 100% !important;
  }
  .upgrade .section-wrap .section-wrap-aside .rd-google-map {
    height: calc(100% + 1px);
  }
  .upgrade .section-wrap .section-wrap-aside img {
    height: 100%;
    width: 100%;
  }
  .upgrade .section-wrap .section-wrap-aside.section-wrap-image-right img {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    object-position: 90% center;
  }
  .upgrade .ie-10 .section-wrap .section-wrap-aside.section-wrap-image-right img,
.upgrade .ie-11 .section-wrap .section-wrap-aside.section-wrap-image-right img {
    left: 90%;
    transform: translate(-90%, -50%);
  }
  .upgrade .ie-10 .section-wrap .section-wrap-aside .jp-video .jp-jplayer img,
.upgrade .ie-10 .section-wrap .section-wrap-aside img,
.upgrade .ie-11 .section-wrap .section-wrap-aside .jp-video .jp-jplayer img,
.upgrade .ie-11 .section-wrap .section-wrap-aside img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }
  .upgrade .ie-10 .section-wrap .section-wrap-aside .jp-video .jp-jplayer video,
.upgrade .ie-11 .section-wrap .section-wrap-aside .jp-video .jp-jplayer video {
    left: 50%;
    transform: translateX(-50%);
    width: 110% !important;
    height: 110% !important;
  }
  .upgrade .section-wrap .section-wrap-content {
    max-width: 620px;
  }
}
@media (min-width: 992px) {
  .upgrade .section-wrap.section-wrap-sm .section-wrap-aside {
    width: 41.6%;
  }
  .upgrade .section-wrap.section-wrap-bigger .section-wrap-aside {
    width: 50%;
  }
  .upgrade .section-wrap.section-wrap-equal .section-wrap-aside {
    width: 50%;
  }
  .upgrade .section-wrap.section-wrap-equal .section-wrap-aside .section-wrap-content {
    max-width: 630px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upgrade .section-wrap.section-wrap-lg .section-wrap-aside {
    position: relative;
    top: 1px;
    width: 100%;
    height: 500px;
  }
  .upgrade .section-wrap.section-wrap-lg.section-reverse .section-wrap-inner {
    flex-flow: row wrap;
  }
}
@media (min-width: 1200px) {
  .upgrade .section-wrap .section-wrap-aside {
    width: calc(50% - 160px);
  }
  .upgrade .section-wrap .section-wrap-aside.section-wrap-aside-custom {
    width: calc(50% - 30px);
  }
  .upgrade .section-wrap.section-wrap-bigger .section-wrap-aside {
    width: calc(50% + 100px);
  }
  .upgrade .section-wrap.section-wrap-equal .section-wrap-aside {
    width: 50%;
  }
  .upgrade .section-wrap .section-wrap-content {
    max-width: 770px;
  }
}
@media (min-width: 1600px) {
  .upgrade .section-wrap .section-wrap-aside {
    width: calc(50% - 326.66667px);
  }
  .upgrade .section-wrap .section-wrap-aside.section-wrap-aside-custom {
    width: 50%;
  }
  .upgrade .section-wrap.section-wrap-bigger .section-wrap-aside {
    width: calc(50% + 163.33333px);
  }
  .upgrade .section-wrap .section-wrap-content {
    max-width: 880px;
  }
}
@media (min-width: 992px) {
  .upgrade .section-wrap.section-reverse [class*=section] .range-md-left, .upgrade .section-wrap.section-md-reverse [class*=section] .range-md-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-wrap.section-reverse [class*=section] .range-md-right, .upgrade .section-wrap.section-md-reverse [class*=section] .range-md-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .upgrade .section-wrap.section-reverse [class*=section] .range-lg-left, .upgrade .section-wrap.section-md-reverse [class*=section] .range-lg-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-wrap.section-reverse [class*=section] .range-lg-right, .upgrade .section-wrap.section-md-reverse [class*=section] .range-lg-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1600px) {
  .upgrade .section-wrap.section-reverse [class*=section] .range-xl-left, .upgrade .section-wrap.section-md-reverse [class*=section] .range-xl-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-wrap.section-reverse [class*=section] .range-xl-right, .upgrade .section-wrap.section-md-reverse [class*=section] .range-xl-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1800px) {
  .upgrade .section-wrap.section-reverse [class*=section] .range-xxl-left, .upgrade .section-wrap.section-md-reverse [class*=section] .range-xxl-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-wrap.section-reverse [class*=section] .range-xxl-right, .upgrade .section-wrap.section-md-reverse [class*=section] .range-xxl-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.upgrade .section-wrap.section-reverse .section-wrap-inner {
  flex-flow: row-reverse wrap-reverse;
}
.upgrade .section-wrap.section-reverse .range {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.upgrade .section-wrap.section-reverse .section-wrap-aside {
  left: 0;
  right: auto;
}
@media (min-width: 992px) {
  .upgrade .section-wrap.section-md-reverse .section-wrap-inner {
    flex-flow: row-reverse wrap-reverse;
  }
  .upgrade .section-wrap.section-md-reverse .section-wrap-aside {
    left: 0;
    right: auto;
  }
}
.upgrade .section-reverse.section-2-columns > [class*=shell] > .range {
  flex-flow: row-reverse wrap-reverse;
}
@media (min-width: 992px) {
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-md-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-md-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-lg-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-lg-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1600px) {
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-xl-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-xl-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1800px) {
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-xxl-left {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .section-reverse.section-2-columns > [class*=shell] > .range-xxl-right {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1600px) {
  .upgrade .section-reverse.section-2-columns .box-wrap-1 {
    padding-right: 0;
    padding-left: 37px;
    margin-right: -80px;
    margin-left: 0;
  }
  .upgrade .section-reverse.section-2-columns .box-wrap-2 {
    padding-right: 70px;
    padding-left: 0;
  }
}
.upgrade .section-reverse .box-width-small {
  margin-left: auto;
  margin-right: 0;
}
.upgrade .sections-collapsable [class*=section-] + [class*=section-] {
  padding-top: 0;
}
@media (min-width: 1800px) {
  .upgrade .section-wrap-inner.section-xl {
    padding: 230px 0;
  }
}
.upgrade .custom-wrap-1 {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .upgrade .custom-wrap-1 {
    max-width: 700px;
  }
}
@media (min-width: 1400px) {
  .upgrade .custom-wrap-1 {
    max-width: 1400px;
  }
}
.upgrade .custom-wrap-2 {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .upgrade .custom-wrap-2 {
    max-width: 650px;
  }
}
@media (min-width: 1160px) {
  .upgrade .custom-wrap-2 {
    max-width: 1160px;
  }
}
.upgrade .layout-3-columns .box-minimal {
  display: inline-block;
  width: 100%;
  max-width: 375px;
}
.upgrade .layout-3-columns .box-minimal + .box-minimal {
  margin-top: 30px;
}
.upgrade .layout-3-columns .layout-column-image {
  text-align: center;
}
@media (max-width: 767px) {
  .upgrade .layout-3-columns .layout-column-image img {
    width: auto;
    max-height: 80vh;
  }
}
@media (max-width: 991px) {
  .upgrade .layout-3-columns [class*=cell] {
    -ms-flex-order: 2;
    order: 2;
  }
  .upgrade .layout-3-columns .layout-column-image {
    -ms-flex-order: 1;
    order: 1;
  }
  .upgrade .layout-3-columns .layout-column-image img {
    width: auto;
    max-height: 40vh;
  }
}
@media (min-width: 768px) {
  .upgrade .layout-3-columns .box-minimal + .box-minimal {
    margin-top: 50px;
  }
}
@media (min-width: 992px) {
  .upgrade .layout-3-columns [class*=cell] {
    padding-top: 40px;
  }
  .upgrade .layout-3-columns [class*=cell]:first-child {
    text-align: right;
  }
  .upgrade .layout-3-columns [class*=cell]:first-child .box-minimal-header {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .layout-3-columns .layout-column-image {
    padding-top: 0;
    margin-bottom: -50px;
  }
  .upgrade .layout-3-columns .layout-column-image > * {
    margin-right: -20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upgrade .layout-3-columns .box-minimal h6,
.upgrade .layout-3-columns .box-minimal .heading-6 {
    font-size: 20px;
  }
  .upgrade .layout-3-columns .box-minimal-icon {
    font-size: 30px;
  }
  .upgrade .layout-3-columns .box-minimal-text {
    font-size: 14px;
  }
}
@media (min-width: 1600px) {
  .upgrade .layout-3-columns .box-minimal + .box-minimal {
    margin-top: 90px;
  }
}
.upgrade .section-custom {
  position: relative;
  z-index: 1;
}
@media (min-width: 1200px) {
  .upgrade .page .section-access + .section-custom {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 35px;
  }
  .upgrade .page .section-access + .section-custom .form-find {
    transform: translateY(-50%);
  }
}
@media (max-width: 1199px) {
  .upgrade .section-pre-way-point + .section-way-point {
    margin-top: 90px;
  }
}
.upgrade .mb-1 {
  margin-bottom: -1px;
}
.upgrade * + p {
  margin-top: 15px;
}
.upgrade p + p {
  margin-top: 12px;
}
.upgrade * + figcaption {
  margin-top: 10px;
}
.upgrade * + h6, .upgrade * + .heading-6 {
  margin-top: 20px;
}
.upgrade h1 + p, .upgrade .heading-1 + p {
  margin-top: 20px;
}
.upgrade h2 + p, .upgrade .heading-2 + p {
  margin-top: 20px;
}
.upgrade h3 + p, .upgrade .heading-3 + p {
  margin-top: 15px;
}
.upgrade h4 + p, .upgrade .heading-4 + p {
  margin-top: 18px;
}
.upgrade h5 + p, .upgrade .heading-5 + p {
  margin-top: 20px;
}
.upgrade .heading-5 + h3 {
  margin-top: 5px;
}
@media (min-width: 1600px) {
  .upgrade .heading-5 + h3 {
    margin-top: 23px;
  }
}
.upgrade * + .list-xxs {
  margin-top: 20px;
}
.upgrade h6 + p, .upgrade .heading-6 + p {
  margin-top: 20px;
}
.upgrade p + .list-marked {
  margin-top: 10px;
}
.upgrade p + p + .list-marked {
  margin-top: 20px;
}
.upgrade p + .button {
  margin-top: 25px;
}
.upgrade h3 + .parallax-container {
  margin-top: 40px;
}
.upgrade h3 + .section {
  margin-top: 40px;
}
.upgrade .divider + p {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .upgrade .range + .button-offset-lg {
    margin-top: 40px;
  }
}
.upgrade * + .list-xs {
  margin-top: 25px;
}
.upgrade * + .list-marked {
  margin-top: 20px;
}
.upgrade * + .list-terms {
  margin-top: 30px;
}
.upgrade * + .button {
  margin-top: 20px;
}
.upgrade * + .list-ordered {
  margin-top: 20px;
}
.upgrade * + .figure-light {
  margin-top: 20px;
}
.upgrade * + .rd-mailform {
  margin-top: 30px;
}
.upgrade * + .quote-primary {
  margin-top: 25px;
}
.upgrade * + .quote-minimal {
  margin-top: 25px;
}
.upgrade .quote-minimal + * {
  margin-top: 25px;
}
.upgrade * + .group-xs {
  margin-top: 25px;
}
.upgrade * + .group-md {
  margin-top: 25px;
}
.upgrade * + .rd-mailform-inline {
  margin-top: 25px;
}
.upgrade * + .owl-carousel {
  margin-top: 40px;
}
.upgrade * + .frame-header {
  margin-top: 40px;
}
.upgrade * + .facebook {
  margin-top: 40px;
}
.upgrade * + .flickr {
  margin-top: 40px;
}
.upgrade * + .divider-thin {
  margin-top: 13px;
}
.upgrade * + .list-twitter {
  margin-top: 25px;
}
.upgrade * + .box-inline {
  margin-top: 25px;
}
.upgrade * + .box-2-columns {
  margin-top: 25px;
}
.upgrade * + .button-blog {
  margin-top: 30px;
}
.upgrade * + .rd-search-results {
  margin-top: 30px;
}
.upgrade * + .d3-chart-wrap {
  margin-top: 30px;
}
.upgrade * + .privacy-link {
  margin-top: 30px;
}
.upgrade * + .typed-text-wrap {
  margin-top: 30px;
}
.upgrade * + [class^=custom-wrap] {
  margin-top: 40px;
}
.upgrade .heading-group + .divider-default {
  margin-top: 15px;
}
.upgrade h6 + .rd-mailform-inline,
.upgrade .heading-6 + .rd-mailform-inline {
  margin-top: 10px;
}
.upgrade h2 + .divider, .upgrade .heading-2 + .divider {
  margin-top: 10px;
}
.upgrade .big + form {
  margin-top: 20px;
}
.upgrade .shell + .shell {
  margin-top: 60px;
}
.upgrade .shell + .bg-gradient, .upgrade .shell + .bg-gradient-1, .upgrade .shell + .bg-gradient-2, .upgrade .shell + .bg-gradient-3 {
  margin-top: 60px;
}
.upgrade .range + .range {
  margin-top: 60px;
}
.upgrade .divider-thin + .box-inline {
  margin-top: 15px;
}
.upgrade .divider-thin + .box-inline-wrap {
  margin-top: 15px;
}
.upgrade h3 + .swiper-slider {
  margin-top: 30px;
}
.upgrade .shell + .swiper-slider {
  margin-top: 30px;
}
.upgrade .page * + .offset-top-0 {
  margin-top: 0;
}
.upgrade .page * + .offset-custom-1 {
  margin-top: 20px;
}
.upgrade .page * + .offset-custom-3 {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade * + figcaption {
    margin-top: 20px;
  }
  .upgrade * + hr {
    margin-top: 30px;
  }
  .upgrade * + .quote-primary {
    margin-top: 35px;
  }
  .upgrade * + .group-md {
    margin-top: 35px;
  }
  .upgrade * + .owl-carousel {
    margin-top: 70px;
  }
  .upgrade * + .frame-header {
    margin-top: 60px;
  }
  .upgrade * + .list-twitter {
    margin-top: 47px;
  }
  .upgrade * + .list-terms {
    margin-top: 45px;
  }
  .upgrade * + .button {
    margin-top: 35px;
  }
  .upgrade * + .rd-search-results {
    margin-top: 45px;
  }
  .upgrade * + .typed-text-wrap {
    margin-top: 50px;
  }
  .upgrade .typed-text-wrap + .button {
    margin-top: 35px;
  }
  .upgrade .custom-subtitle + * {
    margin-top: 25px;
  }
  .upgrade .divider + .box-2-columns {
    margin-top: 40px;
  }
  .upgrade h3 + .parallax-container {
    margin-top: 40px;
  }
  .upgrade h3 + .section {
    margin-top: 40px;
  }
  .upgrade hr + .range {
    margin-top: 40px;
  }
  .upgrade h3 + .swiper-slider {
    margin-top: 55px;
  }
  .upgrade .shell + .swiper-slider {
    margin-top: 55px;
  }
  .upgrade h2 + .divider,
.upgrade .heading-2 + .divider {
    margin-top: 25px;
  }
  .upgrade .heading-group + .divider-default {
    margin-top: 30px;
  }
  .upgrade .big + .button {
    margin-top: 30px;
  }
  .upgrade .page * + .offset-custom-1 {
    margin-top: 30px;
  }
  .upgrade .page * + .offset-custom-2 {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .upgrade * + .figure-light {
    margin-top: 40px;
  }
  .upgrade * + hr {
    margin-top: 40px;
  }
  .upgrade * + .d3-chart-wrap {
    margin-top: 50px;
  }
  .upgrade h1 + p, .upgrade .heading-1 + p {
    margin-top: 30px;
  }
  .upgrade h2 + p, .upgrade .heading-2 + p {
    margin-top: 33px;
  }
  .upgrade h3 + p, .upgrade .heading-3 + p {
    margin-top: 25px;
  }
  .upgrade * + .list-xxs {
    margin-top: 30px;
  }
  .upgrade h6 + p, .upgrade .heading-6 + p {
    margin-top: 30px;
  }
  .upgrade h2 + .divider, .upgrade .heading-2 + .divider {
    margin-top: 35px;
  }
  .upgrade .divider-thin + .box-inline {
    margin-top: 30px;
  }
  .upgrade .page * + .offset-custom-2 {
    margin-top: 50px;
  }
}
@media (min-width: 1200px) {
  .upgrade * + .list-xs {
    margin-top: 40px;
  }
  .upgrade * + .group-md {
    margin-top: 60px;
  }
  .upgrade p + p + .list-marked {
    margin-top: 30px;
  }
  .upgrade * + .rd-search-results {
    margin-top: 60px;
  }
  .upgrade .divider + p {
    margin-top: 18px;
  }
  .upgrade .shell + .bg-gradient, .upgrade .shell + .bg-gradient-1, .upgrade .shell + .bg-gradient-2, .upgrade .shell + .bg-gradient-3 {
    margin-top: 90px;
  }
  .upgrade .owl-carousel + .button {
    margin-top: 50px;
  }
  .upgrade .range + .button {
    margin-top: 50px;
  }
  .upgrade .typed-text-wrap + .button {
    margin-top: 55px;
  }
  .upgrade h3 + p,
.upgrade .heading-3 + p {
    margin-top: 33px;
  }
  .upgrade h3 + .big,
.upgrade .heading-3 + .big {
    margin-top: 25px;
  }
}
@media (min-width: 1600px) {
  .upgrade .divider + p {
    margin-top: 40px;
  }
  .upgrade * + .facebook {
    margin-top: 80px;
  }
  .upgrade * + [class^=custom-wrap] {
    margin-top: 60px;
  }
  .upgrade * + .button-blog {
    margin-top: 90px;
  }
  .upgrade * + .rd-search-results {
    margin-top: 90px;
  }
  .upgrade * + .d3-chart-wrap {
    margin-top: 70px;
  }
  .upgrade hr + .big {
    margin-top: 40px;
  }
  .upgrade h1 + p,
.upgrade .heading-1 + p {
    margin-top: 43px;
  }
  .upgrade h3 + .parallax-container {
    margin-top: 60px;
  }
  .upgrade h3 + .section {
    margin-top: 60px;
  }
  .upgrade hr + .range {
    margin-top: 85px;
  }
  .upgrade .range + .range.range-xl {
    margin-top: 90px;
  }
  .upgrade .owl-carousel + .button {
    margin-top: 90px;
  }
  .upgrade .range + .button {
    margin-top: 80px;
  }
  .upgrade .big + .button {
    margin-top: 50px;
  }
  .upgrade .page * + .offset-custom-2 {
    margin-top: 80px;
  }
}
@media (min-width: 1600px) {
  .upgrade .inset-sm {
    padding-left: 20px;
  }
}
@media (min-width: 1600px) {
  .upgrade .inset-md {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1200px) {
  .upgrade .inset-xl {
    padding-right: 5px;
  }
}
@media (min-width: 1600px) {
  .upgrade .inset-xl {
    padding-right: 30px;
  }
}
@media (min-width: 1800px) {
  .upgrade .inset-xl {
    padding-right: 80px;
  }
}
.upgrade html .range-0 {
  position: relative;
  transform: translateY(0);
  margin-bottom: 0;
  pointer-events: none;
}
.upgrade html .range-0 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-0 > * {
  margin-top: 0;
  pointer-events: auto;
}
.upgrade html .range-10 {
  position: relative;
  transform: translateY(-10px);
  margin-bottom: -10px;
  pointer-events: none;
}
.upgrade html .range-10 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-10 > * {
  margin-top: 10px;
  pointer-events: auto;
}
.upgrade html .range-15 {
  position: relative;
  transform: translateY(-15px);
  margin-bottom: -15px;
  pointer-events: none;
}
.upgrade html .range-15 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-15 > * {
  margin-top: 15px;
  pointer-events: auto;
}
.upgrade html .range-20 {
  position: relative;
  transform: translateY(-20px);
  margin-bottom: -20px;
  pointer-events: none;
}
.upgrade html .range-20 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-20 > * {
  margin-top: 20px;
  pointer-events: auto;
}
.upgrade html .range-30 {
  position: relative;
  transform: translateY(-30px);
  margin-bottom: -30px;
  pointer-events: none;
}
.upgrade html .range-30 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-30 > * {
  margin-top: 30px;
  pointer-events: auto;
}
.upgrade html .range-50 {
  position: relative;
  transform: translateY(-50px);
  margin-bottom: -50px;
  pointer-events: none;
}
.upgrade html .range-50 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-50 > * {
  margin-top: 50px;
  pointer-events: auto;
}
.upgrade html .range-60 {
  position: relative;
  transform: translateY(-50px);
  margin-bottom: -50px;
  pointer-events: none;
}
.upgrade html .range-60 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-60 > * {
  margin-top: 50px;
  pointer-events: auto;
}
.upgrade html .range-70 {
  position: relative;
  transform: translateY(-50px);
  margin-bottom: -50px;
  pointer-events: none;
}
.upgrade html .range-70 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-70 > * {
  margin-top: 50px;
  pointer-events: auto;
}
.upgrade html .range-90 {
  position: relative;
  transform: translateY(-50px);
  margin-bottom: -50px;
  pointer-events: none;
}
.upgrade html .range-90 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-90 > * {
  margin-top: 50px;
  pointer-events: auto;
}
.upgrade html .range-shop {
  position: relative;
  transform: translateY(-70px);
  margin-bottom: -70px;
  pointer-events: none;
}
.upgrade html .range-shop > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-shop > * {
  margin-top: 70px;
  pointer-events: auto;
}
.upgrade html .range-100 {
  position: relative;
  transform: translateY(-100px);
  margin-bottom: -100px;
  pointer-events: none;
}
.upgrade html .range-100 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-100 > * {
  margin-top: 100px;
  pointer-events: auto;
}
.upgrade html .range-120 {
  position: relative;
  transform: translateY(-50px);
  margin-bottom: -50px;
  pointer-events: none;
}
.upgrade html .range-120 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-120 > * {
  margin-top: 50px;
  pointer-events: auto;
}
.upgrade html .range-counter {
  position: relative;
  transform: translateY(-130px);
  margin-bottom: -130px;
  pointer-events: none;
}
.upgrade html .range-counter > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-counter > * {
  margin-top: 130px;
  pointer-events: auto;
}
.upgrade html .range-135 {
  position: relative;
  transform: translateY(-50px);
  margin-bottom: -50px;
  pointer-events: none;
}
.upgrade html .range-135 > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade html .range-135 > * {
  margin-top: 50px;
  pointer-events: auto;
}
@media (min-width: 1200px) {
  .upgrade html .range-135 {
    transform: translateY(-65px);
    margin-bottom: -65px;
  }
  .upgrade html .range-135 > * {
    margin-top: 65px;
  }
}
@media (min-width: 1600px) {
  .upgrade html .range-135 {
    transform: translateY(-135px);
    margin-bottom: -135px;
  }
  .upgrade html .range-135 > * {
    margin-top: 135px;
  }
}
.upgrade html.desktop .range-100 {
  transform: translateY(-30px);
  margin-bottom: -30px;
}
.upgrade html.desktop .range-100 > * {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .upgrade html .range-sm-30 {
    transform: translateY(-30px);
    margin-bottom: -30px;
  }
  .upgrade html .range-sm-30 > * {
    margin-top: 30px;
  }
  .upgrade html .range-sm-50 {
    transform: translateY(-50px);
    margin-bottom: -50px;
  }
  .upgrade html .range-sm-50 > * {
    margin-top: 50px;
  }
  .upgrade html .range-60 {
    transform: translateY(-60px);
    margin-bottom: -60px;
  }
  .upgrade html .range-60 > * {
    margin-top: 60px;
  }
  .upgrade html .range-sm-90 {
    transform: translateY(-90px);
    margin-bottom: -90px;
  }
  .upgrade html .range-sm-90 > * {
    margin-top: 90px;
  }
}
@media (min-width: 992px) {
  .upgrade html .range-md-50 {
    transform: translateY(-50px);
    margin-bottom: -50px;
  }
  .upgrade html .range-md-50 > * {
    margin-top: 50px;
  }
  .upgrade html .range-90 {
    position: relative;
    transform: translateY(-90px);
    margin-bottom: -90px;
    pointer-events: none;
  }
  .upgrade html .range-90 > *:before {
    content: "";
    display: table;
    width: 0;
  }
  .upgrade html .range-90 > * {
    margin-top: 90px;
    pointer-events: auto;
  }
}
@media (min-width: 1200px) {
  .upgrade html .range-70 {
    transform: translateY(-70px);
    margin-bottom: -70px;
  }
  .upgrade html .range-70 > * {
    margin-top: 70px;
  }
  .upgrade html .range-lg-50 {
    transform: translateY(-50px);
    margin-bottom: -50px;
  }
  .upgrade html .range-lg-50 > * {
    margin-top: 50px;
  }
  .upgrade html .range-120 {
    transform: translateY(-80px);
    margin-bottom: -80px;
  }
  .upgrade html .range-120 > * {
    margin-top: 80px;
  }
}
@media (min-width: 1600px) {
  .upgrade html .range-70 {
    transform: translateY(-70px);
    margin-bottom: -70px;
  }
  .upgrade html .range-70 > * {
    margin-top: 70px;
  }
  .upgrade html .range-xl-90 {
    transform: translateY(-90px);
    margin-bottom: -90px;
  }
  .upgrade html .range-xl-90 > * {
    margin-top: 90px;
  }
  .upgrade html .range-120 {
    transform: translateY(-120px);
    margin-bottom: -120px;
  }
  .upgrade html .range-120 > * {
    margin-top: 120px;
  }
}
.upgrade * + p.offset-0 {
  margin-top: 0;
}
.upgrade .heading-decorated-diamonds {
  position: relative;
  display: inline-block;
  padding: 0 20px;
}
.upgrade .heading-decorated-diamonds::before, .upgrade .heading-decorated-diamonds::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background: #981df0;
}
.upgrade .heading-decorated-diamonds::before {
  left: 0;
}
.upgrade .heading-decorated-diamonds::after {
  right: 0;
}
@media (min-width: 768px) {
  .upgrade .heading-decorated-diamonds {
    padding: 0 40px;
  }
  .upgrade .heading-decorated-diamonds::before, .upgrade .heading-decorated-diamonds::after {
    width: 15px;
    height: 15px;
  }
}
.upgrade .divider {
  display: inline-block;
  border: 0;
  font-size: 0;
  line-height: 0;
  height: 3px;
  max-width: 100px;
  width: 100%;
}
.upgrade h3 + .divider {
  margin-top: 20px;
}
.upgrade h3 + .divider + h5 {
  margin-top: 35px;
}
.upgrade h3 + .divider + h5 + p {
  margin-top: 15px;
}
@media (min-width: 1600px) {
  .upgrade h3 + .divider {
    margin-top: 40px;
  }
}
.upgrade .divider-sm {
  height: 2px;
  max-width: 60px;
}
.upgrade .divider-default {
  background: linear-gradient(to right, #ed1c94 50%, #981df0 120%);
}
.upgrade .divider-secondary {
  background: #ed1c94;
}
.upgrade .divider-thin {
  border-top: 1px solid #d2d5e4;
}
.upgrade .divider-xl {
  max-width: 1770px;
  width: 100%;
  margin: 30px auto;
  border-bottom: 1px solid #cacbd3;
}
.upgrade .heading-decor {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
.upgrade .heading-decor > span {
  position: relative;
  display: inline-block;
  padding-left: 70px;
  padding-right: 70px;
}
.upgrade .heading-decor > span:before, .upgrade .heading-decor > span:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-top: 1px solid;
  width: 40px;
}
.upgrade .heading-decor > span:before {
  left: 0;
}
.upgrade .heading-decor > span:after {
  right: 0;
}
.upgrade .heading-decor + * {
  margin-top: 20px;
}
@media (min-width: 1600px) {
  .upgrade .heading-decor {
    font-size: 28px;
  }
  .upgrade .heading-decor + * {
    margin-top: 30px;
  }
}
.upgrade .row-no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.upgrade .row-no-gutter > [class*=col] {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1600px) {
  .upgrade .col-xl-3 {
    width: 25%;
  }
}
.upgrade .grid-demonstration {
  padding: 12px 8px;
  letter-spacing: 0;
  text-align: left;
}
@media (max-width: 991px) {
  .upgrade .grid-demonstration h5,
.upgrade .grid-demonstration .heading-5 {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .upgrade .grid-demonstration p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.upgrade .grid-demonstration [class*=col] {
  padding: 8px 5px;
}
@media (max-width: 767px) {
  .upgrade .grid-demonstration [class*=col] p {
    font-size: 14px;
  }
  .upgrade .grid-demonstration [class*=col] * + p {
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .upgrade .grid-demonstration [class*=col] {
    padding: 15px 10px;
  }
}
@media (min-width: 992px) {
  .upgrade .grid-demonstration [class*=col] {
    padding: 25px 15px;
  }
}
@media (min-width: 1200px) {
  .upgrade .grid-demonstration [class*=col] {
    padding: 30px 15px 30px 30px;
  }
}
@media (min-width: 1400px) {
  .upgrade .grid-demonstration [class*=col] {
    padding: 30px 15px;
  }
  .upgrade .grid-demonstration [class*=col] * + p {
    margin-top: 24px;
  }
}
.upgrade .grid-demonstration * + .row {
  margin-top: 30px;
}
.upgrade .grid-demonstration .row + .row {
  margin-top: 0;
}
@media (min-width: 1600px) {
  .upgrade .grid-demonstration * + .row {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .upgrade .container.grid-demonstration .row {
    margin-left: 0;
    margin-right: 0;
  }
}
.upgrade .container-fullwidth {
  width: 100%;
}
@media (min-width: 1600px) {
  .upgrade .container-fullwidth {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.upgrade .column-aside .range {
  position: relative;
  transform: translateY(-35px);
  margin-bottom: -35px;
  pointer-events: none;
}
.upgrade .column-aside .range > *:before {
  content: "";
  display: table;
  width: 0;
}
.upgrade .column-aside .range > * {
  margin-top: 35px;
  pointer-events: auto;
}
@media (min-width: 768px) {
  .upgrade .column-aside .range {
    transform: translateY(-50px);
    margin-bottom: -50px;
  }
  .upgrade .column-aside .range > * {
    margin-top: 50px;
  }
}
@media (min-width: 1600px) {
  .upgrade .column-aside .range {
    transform: translateY(-90px);
    margin-bottom: -90px;
  }
  .upgrade .column-aside .range > * {
    margin-top: 90px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upgrade .column-aside {
    padding-left: 30px;
  }
  .upgrade .section-reverse .column-aside {
    padding-left: 0;
    padding-right: 30px;
  }
}
.upgrade .range-custom-bordered [class*=cell] {
  border-style: solid;
  border-color: #c2c2c3;
  border-width: 0;
}
.upgrade .range-custom-bordered [class*=cell]:nth-child(n+2) {
  border-width: 1px 0 0 0;
}
@media (min-width: 480px) {
  .upgrade .range-custom-bordered .cell-xs-6:nth-child(even) {
    border-width: 0 0 0 1px;
  }
  .upgrade .range-custom-bordered .cell-xs-6:nth-child(odd) {
    border-width: 0;
  }
}
@media (min-width: 768px) {
  .upgrade .range-custom-bordered .cell-sm-3:first-child {
    border-width: 0 1px 0 1px;
  }
  .upgrade .range-custom-bordered .cell-sm-3:nth-child(n+2) {
    border-width: 0 1px 0 0;
  }
}
@media (max-width: 991px) {
  .upgrade .range-custom-bordered .cell-md-3 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 479px) {
  .upgrade .range-custom-bordered .cell-md-3 + .cell-md-3 {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .upgrade .range-custom-bordered .cell-md-3:nth-child(n+2) {
    border-width: 0 0 0 1px;
  }
  .upgrade .range-custom-bordered .cell-md-3:nth-child(4n+1) {
    border-width: 0;
  }
}
.upgrade .shell,
.upgrade .shell-wide,
.upgrade .shell-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.upgrade .shell,
.upgrade .shell-wide {
  min-width: 300px;
  max-width: 480px;
}
@media (min-width: 768px) {
  .upgrade .shell,
.upgrade .shell-wide {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .upgrade .shell,
.upgrade .shell-wide {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .upgrade .shell,
.upgrade .shell-wide {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .upgrade .shell-bigger {
    max-width: 1500px;
  }
}
@media (min-width: 1600px) {
  .upgrade .shell-wide {
    max-width: 1600px;
  }
}
@media (min-width: 1800px) {
  .upgrade .shell-wide {
    max-width: 1800px;
  }
}
.upgrade .shell-custom {
  padding: 0;
  width: 100%;
}
.upgrade .shell-custom .gallery-item {
  width: 100%;
  max-width: 100%;
}
.upgrade .shell-custom .gallery-item .gallery-item-image {
  width: 100%;
}
.upgrade .shell-custom .shell-custom-inner {
  padding: 0 15px;
}
.upgrade .shell-custom [class*=cell] [class*=section]:empty {
  min-height: 300px;
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 480px) {
  .upgrade .shell-custom .range {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .upgrade .shell-custom {
    min-height: 350px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upgrade .shell-custom .shell-custom-inner-1 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 1200px) {
  .upgrade .shell-custom,
.upgrade .shell-custom [class*=cell] {
    padding: 0;
  }
  .upgrade .shell-custom [class*=cell]:first-child .shell-custom-inner {
    margin-left: auto;
    margin-right: 0;
  }
  .upgrade .section-reverse .shell-custom [class*=cell]:first-child .shell-custom-inner {
    margin-left: 0;
    margin-right: auto;
  }
  .upgrade .shell-custom [class*=cell]:last-child .shell-custom-inner {
    margin-left: 0;
    margin-right: auto;
  }
  .upgrade .section-reverse .shell-custom [class*=cell]:last-child .shell-custom-inner {
    margin-left: auto;
    margin-right: 0;
  }
}
.upgrade .shell-custom .shell-custom-inner [class*=section-] {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.upgrade .shell-custom .shell-custom-inner [class*=section-] > * {
  width: 100%;
}
@media (min-width: 1200px) {
  .upgrade .shell-custom .shell-custom-inner {
    padding: 0 40px;
  }
}
@media (min-width: 1600px) {
  .upgrade .shell-custom .shell-custom-inner {
    max-width: 800px;
    padding: 0 125px;
  }
}
@media (min-width: 1800px) {
  .upgrade .shell-custom .shell-custom-inner {
    max-width: 960px;
    padding: 0 150px;
  }
}
@media (min-width: 1200px) {
  .upgrade .shell-custom .shell-custom-inner-right {
    margin-left: 0;
    margin-right: auto;
  }
  .upgrade .section-reverse .shell-custom .shell-custom-inner-right {
    margin-left: auto;
    margin-right: 0;
  }
}
.upgrade .shell-no-gutter {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.upgrade .shell-fullwidth {
  width: 100%;
  max-width: 1800px;
}
@media (min-width: 1200px) {
  .upgrade .shell-fluid-inset-lg-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.upgrade .range {
  margin-left: -15px;
  margin-right: -15px;
}
.upgrade .range > .range {
  margin-left: 0;
  margin-right: 0;
}
.upgrade .range-center {
  -webkit-justify-content: center;
  justify-content: center;
}
.upgrade .range-left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.upgrade .range-right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.upgrade .range-justify {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.upgrade .range-around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.upgrade .range-top {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.upgrade .range-reverse {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.upgrade .range-middle {
  -webkit-align-items: center;
  align-items: center;
}
.upgrade .range-bottom {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.upgrade .range-spacer {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.upgrade [class*=cell-] {
  padding-left: 15px;
  padding-right: 15px;
}
.upgrade html.lt-ie-10 * + .range,
.upgrade * + .range {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .upgrade html.lt-ie-10 * + .range,
.upgrade * + .range {
    margin-top: 35px;
  }
}
@media (min-width: 1600px) {
  .upgrade html.lt-ie-10 * + .range,
.upgrade * + .range {
    margin-top: 50px;
  }
}
.upgrade html.lt-ie-10 * + .range-sm,
.upgrade * + .range-sm {
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .upgrade html.lt-ie-10 * + .range-md,
.upgrade * + .range-md {
    margin-top: 60px;
  }
}
@media (min-width: 1600px) {
  .upgrade html.lt-ie-10 * + .range-lg,
.upgrade * + .range-lg {
    margin-top: 70px;
  }
}
.upgrade html.tablet.lt-ie-10 * + .range-lg.range-100,
.upgrade .tablet * + .range-lg.range-100,
.upgrade .mobile * + .range-lg.range-100 {
  margin-top: 70px;
}
@media (min-width: 992px) {
  .upgrade html.lt-ie-10 * + .range-xl,
.upgrade * + .range-xl {
    margin-top: 50px;
  }
}
@media (min-width: 1200px) {
  .upgrade html.lt-ie-10 * + .range-xl,
.upgrade * + .range-xl {
    margin-top: 90px;
  }
}
.upgrade html.lt-ie-10 .range-condensed,
.upgrade .range-condensed {
  margin-left: 0;
  margin-right: 0;
}
.upgrade html.lt-ie-10 .range-condensed > [class*=cell],
.upgrade .range-condensed > [class*=cell] {
  padding-left: 0;
  padding-right: 0;
  margin-top: -1px;
}
.upgrade html.lt-ie-10 .range-narrow,
.upgrade .range-narrow {
  margin-left: -5px;
  margin-right: -5px;
}
.upgrade html.lt-ie-10 .range-narrow > [class*=cell],
.upgrade .range-narrow > [class*=cell] {
  padding-left: 5px;
  padding-right: 5px;
}
.upgrade html.lt-ie-10 .range-narrow > * + [class*=cell],
.upgrade .range-narrow > * + [class*=cell] {
  padding-left: 5px;
  padding-right: 5px;
}
.upgrade html.lt-ie-10 .range-narrow-md,
.upgrade .range-narrow-md {
  margin-left: -7px;
  margin-right: -8px;
}
.upgrade html.lt-ie-10 .range-narrow-md > [class*=cell],
.upgrade .range-narrow-md > [class*=cell] {
  padding-left: 8px;
  padding-right: 7px;
}
.upgrade html.lt-ie-10 .range-narrow-md > * + [class*=cell],
.upgrade .range-narrow-md > * + [class*=cell] {
  padding-left: 8px;
  padding-right: 7px;
}
.upgrade .range {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.upgrade .range > .range {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.upgrade .range-vertical {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.upgrade .range > [class*=cell] {
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
@media (min-width: 0px) {
  .upgrade * + [class*=cell-xxs-] {
    margin-top: 0;
  }
  .upgrade .range-xxs-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .range-xxs-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .range-xxs-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .range-xxs-justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .upgrade .range-xxs-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .upgrade .range-xxs-top {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .upgrade .range-xxs {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .range-xxs-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .range-xxs-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .range-xxs-bottom {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .upgrade .cell-xxs-top {
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .upgrade .cell-xxs-middle {
    -webkit-align-self: center;
    align-self: center;
  }
  .upgrade .cell-xxs-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .upgrade .range > .cell-xxs-1 {
    -webkit-flex-basis: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .upgrade .range > .cell-xxs-2 {
    -webkit-flex-basis: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .upgrade .range > .cell-xxs-3 {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .upgrade .range > .cell-xxs-4 {
    -webkit-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .upgrade .range > .cell-xxs-5 {
    -webkit-flex-basis: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .upgrade .range > .cell-xxs-6 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range > .cell-xxs-7 {
    -webkit-flex-basis: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .upgrade .range > .cell-xxs-8 {
    -webkit-flex-basis: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .upgrade .range > .cell-xxs-9 {
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .upgrade .range > .cell-xxs-10 {
    -webkit-flex-basis: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .upgrade .range > .cell-xxs-11 {
    -webkit-flex-basis: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .upgrade .range > .cell-xxs-12 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 480px) {
  .upgrade * + [class*=cell-xs-] {
    margin-top: 0;
  }
  .upgrade .range-xs-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .range-xs-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .range-xs-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .range-xs-justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .upgrade .range-xs-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .upgrade .range-xs-top {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .upgrade .range-xs {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .range-xs-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .range-xs-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .range-xs-bottom {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .upgrade .cell-xs-top {
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .upgrade .cell-xs-middle {
    -webkit-align-self: center;
    align-self: center;
  }
  .upgrade .cell-xs-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .upgrade .range > .cell-xs-1 {
    -webkit-flex-basis: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .upgrade .range > .cell-xs-2 {
    -webkit-flex-basis: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .upgrade .range > .cell-xs-3 {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .upgrade .range > .cell-xs-4 {
    -webkit-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .upgrade .range > .cell-xs-5 {
    -webkit-flex-basis: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .upgrade .range > .cell-xs-6 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range > .cell-xs-7 {
    -webkit-flex-basis: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .upgrade .range > .cell-xs-8 {
    -webkit-flex-basis: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .upgrade .range > .cell-xs-9 {
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .upgrade .range > .cell-xs-10 {
    -webkit-flex-basis: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .upgrade .range > .cell-xs-11 {
    -webkit-flex-basis: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .upgrade .range > .cell-xs-12 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .upgrade * + [class*=cell-sm-] {
    margin-top: 0;
  }
  .upgrade .range-sm-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .range-sm-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .range-sm-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .range-sm-justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .upgrade .range-sm-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .upgrade .range-sm-top {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .upgrade .range-sm {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .range-sm-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .range-sm-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .range-sm-bottom {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .upgrade .cell-sm-top {
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .upgrade .cell-sm-middle {
    -webkit-align-self: center;
    align-self: center;
  }
  .upgrade .cell-sm-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .upgrade .range > .cell-sm-1 {
    -webkit-flex-basis: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .upgrade .range > .cell-sm-2 {
    -webkit-flex-basis: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .upgrade .range > .cell-sm-3 {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .upgrade .range > .cell-sm-4 {
    -webkit-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .upgrade .range > .cell-sm-5 {
    -webkit-flex-basis: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .upgrade .range > .cell-sm-6 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range > .cell-sm-7 {
    -webkit-flex-basis: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .upgrade .range > .cell-sm-8 {
    -webkit-flex-basis: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .upgrade .range > .cell-sm-9 {
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .upgrade .range > .cell-sm-10 {
    -webkit-flex-basis: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .upgrade .range > .cell-sm-11 {
    -webkit-flex-basis: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .upgrade .range > .cell-sm-12 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .upgrade * + [class*=cell-md-] {
    margin-top: 0;
  }
  .upgrade .range-md-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .range-md-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .range-md-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .range-md-justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .upgrade .range-md-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .upgrade .range-md-top {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .upgrade .range-md {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .range-md-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .range-md-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .range-md-bottom {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .upgrade .cell-md-top {
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .upgrade .cell-md-middle {
    -webkit-align-self: center;
    align-self: center;
  }
  .upgrade .cell-md-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .upgrade .range > .cell-md-1 {
    -webkit-flex-basis: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .upgrade .range > .cell-md-2 {
    -webkit-flex-basis: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .upgrade .range > .cell-md-3 {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .upgrade .range > .cell-md-4 {
    -webkit-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .upgrade .range > .cell-md-5 {
    -webkit-flex-basis: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .upgrade .range > .cell-md-6 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range > .cell-md-7 {
    -webkit-flex-basis: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .upgrade .range > .cell-md-8 {
    -webkit-flex-basis: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .upgrade .range > .cell-md-9 {
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .upgrade .range > .cell-md-10 {
    -webkit-flex-basis: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .upgrade .range > .cell-md-11 {
    -webkit-flex-basis: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .upgrade .range > .cell-md-12 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .upgrade * + [class*=cell-lg-] {
    margin-top: 0;
  }
  .upgrade .range-lg-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .range-lg-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .range-lg-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .range-lg-justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .upgrade .range-lg-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .upgrade .range-lg-top {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .upgrade .range-lg {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .range-lg-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .range-lg-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .range-lg-bottom {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .upgrade .cell-lg-top {
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .upgrade .cell-lg-middle {
    -webkit-align-self: center;
    align-self: center;
  }
  .upgrade .cell-lg-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .upgrade .range > .cell-lg-1 {
    -webkit-flex-basis: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .upgrade .range > .cell-lg-2 {
    -webkit-flex-basis: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .upgrade .range > .cell-lg-3 {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .upgrade .range > .cell-lg-4 {
    -webkit-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .upgrade .range > .cell-lg-5 {
    -webkit-flex-basis: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .upgrade .range > .cell-lg-6 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range > .cell-lg-7 {
    -webkit-flex-basis: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .upgrade .range > .cell-lg-8 {
    -webkit-flex-basis: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .upgrade .range > .cell-lg-9 {
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .upgrade .range > .cell-lg-10 {
    -webkit-flex-basis: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .upgrade .range > .cell-lg-11 {
    -webkit-flex-basis: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .upgrade .range > .cell-lg-12 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1600px) {
  .upgrade * + [class*=cell-xl-] {
    margin-top: 0;
  }
  .upgrade .range-xl-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .range-xl-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .range-xl-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .range-xl-justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .upgrade .range-xl-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .upgrade .range-xl-top {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .upgrade .range-xl {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .range-xl-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .range-xl-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .range-xl-bottom {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .upgrade .cell-xl-top {
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .upgrade .cell-xl-middle {
    -webkit-align-self: center;
    align-self: center;
  }
  .upgrade .cell-xl-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .upgrade .range > .cell-xl-1 {
    -webkit-flex-basis: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .upgrade .range > .cell-xl-2 {
    -webkit-flex-basis: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .upgrade .range > .cell-xl-3 {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .upgrade .range > .cell-xl-4 {
    -webkit-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .upgrade .range > .cell-xl-5 {
    -webkit-flex-basis: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .upgrade .range > .cell-xl-6 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range > .cell-xl-7 {
    -webkit-flex-basis: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .upgrade .range > .cell-xl-8 {
    -webkit-flex-basis: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .upgrade .range > .cell-xl-9 {
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .upgrade .range > .cell-xl-10 {
    -webkit-flex-basis: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .upgrade .range > .cell-xl-11 {
    -webkit-flex-basis: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .upgrade .range > .cell-xl-12 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1800px) {
  .upgrade * + [class*=cell-xxl-] {
    margin-top: 0;
  }
  .upgrade .range-xxl-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .range-xxl-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .range-xxl-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
  .upgrade .range-xxl-justify {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .upgrade .range-xxl-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .upgrade .range-xxl-top {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .upgrade .range-xxl {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .range-xxl-reverse {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .range-xxl-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .range-xxl-bottom {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .upgrade .cell-xxl-top {
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .upgrade .cell-xxl-middle {
    -webkit-align-self: center;
    align-self: center;
  }
  .upgrade .cell-xxl-bottom {
    -webkit-align-self: flex-end;
    align-self: flex-end;
  }
  .upgrade .range > .cell-xxl-1 {
    -webkit-flex-basis: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .upgrade .range > .cell-xxl-2 {
    -webkit-flex-basis: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .upgrade .range > .cell-xxl-3 {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .upgrade .range > .cell-xxl-4 {
    -webkit-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .upgrade .range > .cell-xxl-5 {
    -webkit-flex-basis: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .upgrade .range > .cell-xxl-6 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range > .cell-xxl-7 {
    -webkit-flex-basis: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .upgrade .range > .cell-xxl-8 {
    -webkit-flex-basis: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .upgrade .range > .cell-xxl-9 {
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .upgrade .range > .cell-xxl-10 {
    -webkit-flex-basis: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .upgrade .range > .cell-xxl-11 {
    -webkit-flex-basis: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .upgrade .range > .cell-xxl-12 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 0px) {
  .upgrade .range-ten > .cell-xxs-1 {
    -webkit-flex-basis: 10%;
    flex-basis: 10%;
    max-width: 10%;
  }
  .upgrade .range-ten > .cell-xxs-2 {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .upgrade .range-ten > .cell-xxs-3 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    max-width: 30%;
  }
  .upgrade .range-ten > .cell-xxs-4 {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
  .upgrade .range-ten > .cell-xxs-5 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range-ten > .cell-xxs-6 {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    max-width: 60%;
  }
  .upgrade .range-ten > .cell-xxs-7 {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    max-width: 70%;
  }
  .upgrade .range-ten > .cell-xxs-8 {
    -webkit-flex-basis: 80%;
    flex-basis: 80%;
    max-width: 80%;
  }
  .upgrade .range-ten > .cell-xxs-9 {
    -webkit-flex-basis: 90%;
    flex-basis: 90%;
    max-width: 90%;
  }
  .upgrade .range-ten > .cell-xxs-10 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 480px) {
  .upgrade .range-ten > .cell-xs-1 {
    -webkit-flex-basis: 10%;
    flex-basis: 10%;
    max-width: 10%;
  }
  .upgrade .range-ten > .cell-xs-2 {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .upgrade .range-ten > .cell-xs-3 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    max-width: 30%;
  }
  .upgrade .range-ten > .cell-xs-4 {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
  .upgrade .range-ten > .cell-xs-5 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range-ten > .cell-xs-6 {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    max-width: 60%;
  }
  .upgrade .range-ten > .cell-xs-7 {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    max-width: 70%;
  }
  .upgrade .range-ten > .cell-xs-8 {
    -webkit-flex-basis: 80%;
    flex-basis: 80%;
    max-width: 80%;
  }
  .upgrade .range-ten > .cell-xs-9 {
    -webkit-flex-basis: 90%;
    flex-basis: 90%;
    max-width: 90%;
  }
  .upgrade .range-ten > .cell-xs-10 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .upgrade .range-ten > .cell-sm-1 {
    -webkit-flex-basis: 10%;
    flex-basis: 10%;
    max-width: 10%;
  }
  .upgrade .range-ten > .cell-sm-2 {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .upgrade .range-ten > .cell-sm-3 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    max-width: 30%;
  }
  .upgrade .range-ten > .cell-sm-4 {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
  .upgrade .range-ten > .cell-sm-5 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range-ten > .cell-sm-6 {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    max-width: 60%;
  }
  .upgrade .range-ten > .cell-sm-7 {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    max-width: 70%;
  }
  .upgrade .range-ten > .cell-sm-8 {
    -webkit-flex-basis: 80%;
    flex-basis: 80%;
    max-width: 80%;
  }
  .upgrade .range-ten > .cell-sm-9 {
    -webkit-flex-basis: 90%;
    flex-basis: 90%;
    max-width: 90%;
  }
  .upgrade .range-ten > .cell-sm-10 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .upgrade .range-ten > .cell-md-1 {
    -webkit-flex-basis: 10%;
    flex-basis: 10%;
    max-width: 10%;
  }
  .upgrade .range-ten > .cell-md-2 {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .upgrade .range-ten > .cell-md-3 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    max-width: 30%;
  }
  .upgrade .range-ten > .cell-md-4 {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
  .upgrade .range-ten > .cell-md-5 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range-ten > .cell-md-6 {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    max-width: 60%;
  }
  .upgrade .range-ten > .cell-md-7 {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    max-width: 70%;
  }
  .upgrade .range-ten > .cell-md-8 {
    -webkit-flex-basis: 80%;
    flex-basis: 80%;
    max-width: 80%;
  }
  .upgrade .range-ten > .cell-md-9 {
    -webkit-flex-basis: 90%;
    flex-basis: 90%;
    max-width: 90%;
  }
  .upgrade .range-ten > .cell-md-10 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .upgrade .range-ten > .cell-lg-1 {
    -webkit-flex-basis: 10%;
    flex-basis: 10%;
    max-width: 10%;
  }
  .upgrade .range-ten > .cell-lg-2 {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .upgrade .range-ten > .cell-lg-3 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    max-width: 30%;
  }
  .upgrade .range-ten > .cell-lg-4 {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
  .upgrade .range-ten > .cell-lg-5 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range-ten > .cell-lg-6 {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    max-width: 60%;
  }
  .upgrade .range-ten > .cell-lg-7 {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    max-width: 70%;
  }
  .upgrade .range-ten > .cell-lg-8 {
    -webkit-flex-basis: 80%;
    flex-basis: 80%;
    max-width: 80%;
  }
  .upgrade .range-ten > .cell-lg-9 {
    -webkit-flex-basis: 90%;
    flex-basis: 90%;
    max-width: 90%;
  }
  .upgrade .range-ten > .cell-lg-10 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1600px) {
  .upgrade .range-ten > .cell-xl-1 {
    -webkit-flex-basis: 10%;
    flex-basis: 10%;
    max-width: 10%;
  }
  .upgrade .range-ten > .cell-xl-2 {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .upgrade .range-ten > .cell-xl-3 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    max-width: 30%;
  }
  .upgrade .range-ten > .cell-xl-4 {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
  .upgrade .range-ten > .cell-xl-5 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range-ten > .cell-xl-6 {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    max-width: 60%;
  }
  .upgrade .range-ten > .cell-xl-7 {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    max-width: 70%;
  }
  .upgrade .range-ten > .cell-xl-8 {
    -webkit-flex-basis: 80%;
    flex-basis: 80%;
    max-width: 80%;
  }
  .upgrade .range-ten > .cell-xl-9 {
    -webkit-flex-basis: 90%;
    flex-basis: 90%;
    max-width: 90%;
  }
  .upgrade .range-ten > .cell-xl-10 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1800px) {
  .upgrade .range-ten > .cell-xxl-1 {
    -webkit-flex-basis: 10%;
    flex-basis: 10%;
    max-width: 10%;
  }
  .upgrade .range-ten > .cell-xxl-2 {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .upgrade .range-ten > .cell-xxl-3 {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    max-width: 30%;
  }
  .upgrade .range-ten > .cell-xxl-4 {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
    max-width: 40%;
  }
  .upgrade .range-ten > .cell-xxl-5 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .upgrade .range-ten > .cell-xxl-6 {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    max-width: 60%;
  }
  .upgrade .range-ten > .cell-xxl-7 {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    max-width: 70%;
  }
  .upgrade .range-ten > .cell-xxl-8 {
    -webkit-flex-basis: 80%;
    flex-basis: 80%;
    max-width: 80%;
  }
  .upgrade .range-ten > .cell-xxl-9 {
    -webkit-flex-basis: 90%;
    flex-basis: 90%;
    max-width: 90%;
  }
  .upgrade .range-ten > .cell-xxl-10 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
.upgrade html.lt-ie-10 .range > .cell-xs-1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 8.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 16.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-4 {
  margin-left: auto;
  margin-right: auto;
  max-width: 33.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-5 {
  margin-left: auto;
  margin-right: auto;
  max-width: 41.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-6 {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-7 {
  margin-left: auto;
  margin-right: auto;
  max-width: 58.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-9 {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: 83.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-11 {
  margin-left: auto;
  margin-right: auto;
  max-width: 91.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xs-12 {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 8.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 16.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-4 {
  margin-left: auto;
  margin-right: auto;
  max-width: 33.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-5 {
  margin-left: auto;
  margin-right: auto;
  max-width: 41.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-6 {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-7 {
  margin-left: auto;
  margin-right: auto;
  max-width: 58.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-9 {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: 83.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-11 {
  margin-left: auto;
  margin-right: auto;
  max-width: 91.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-sm-12 {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.upgrade html.lt-ie-10 .range > .cell-md-1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 8.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-md-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 16.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-md-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
}
.upgrade html.lt-ie-10 .range > .cell-md-4 {
  margin-left: auto;
  margin-right: auto;
  max-width: 33.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-md-5 {
  margin-left: auto;
  margin-right: auto;
  max-width: 41.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-md-6 {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
.upgrade html.lt-ie-10 .range > .cell-md-7 {
  margin-left: auto;
  margin-right: auto;
  max-width: 58.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-md-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-md-9 {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}
.upgrade html.lt-ie-10 .range > .cell-md-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: 83.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-md-11 {
  margin-left: auto;
  margin-right: auto;
  max-width: 91.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-md-12 {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 8.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 16.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-4 {
  margin-left: auto;
  margin-right: auto;
  max-width: 33.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-5 {
  margin-left: auto;
  margin-right: auto;
  max-width: 41.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-6 {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-7 {
  margin-left: auto;
  margin-right: auto;
  max-width: 58.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-9 {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: 83.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-11 {
  margin-left: auto;
  margin-right: auto;
  max-width: 91.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-lg-12 {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 8.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 16.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-4 {
  margin-left: auto;
  margin-right: auto;
  max-width: 33.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-5 {
  margin-left: auto;
  margin-right: auto;
  max-width: 41.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-6 {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-7 {
  margin-left: auto;
  margin-right: auto;
  max-width: 58.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-9 {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: 83.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-11 {
  margin-left: auto;
  margin-right: auto;
  max-width: 91.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xl-12 {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 8.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 16.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: 25%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-4 {
  margin-left: auto;
  margin-right: auto;
  max-width: 33.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-5 {
  margin-left: auto;
  margin-right: auto;
  max-width: 41.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-6 {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-7 {
  margin-left: auto;
  margin-right: auto;
  max-width: 58.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: 66.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-9 {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: 83.33333%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-11 {
  margin-left: auto;
  margin-right: auto;
  max-width: 91.66667%;
}
.upgrade html.lt-ie-10 .range > .cell-xxl-12 {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.upgrade .unit {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex: 0 1 100%;
  -webkit-flex: 0 1 100%;
  flex: 0 1 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.upgrade .unit-body {
  -ms-flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
}
.upgrade .unit-left,
.upgrade .unit-right {
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}
.upgrade .unit-align-center {
  -webkit-justify-content: center;
  justify-content: center;
}
.upgrade .unit-align-left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.upgrade .unit-middle {
  -webkit-align-items: center;
  align-items: center;
}
.upgrade .unit,
.upgrade .unit-vertical {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.upgrade .unit > [class*=unit-]:first-child,
.upgrade .unit-vertical > [class*=unit-]:first-child {
  padding-top: 0;
}
.upgrade .unit > .unit-left + .unit-right,
.upgrade .unit > .unit-left + .unit-body,
.upgrade .unit-vertical > .unit-left + .unit-right,
.upgrade .unit-vertical > .unit-left + .unit-body {
  padding-left: 0;
  padding-top: 15px;
}
.upgrade .unit > .unit-body + .unit-right,
.upgrade .unit-vertical > .unit-body + .unit-right {
  padding-left: 0;
  padding-top: 15px;
}
.upgrade .unit-horizontal {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.upgrade .unit-horizontal > .unit-left + .unit-right,
.upgrade .unit-horizontal > .unit-left + .unit-body {
  padding-top: 0;
  padding-left: 20px;
}
.upgrade .unit-horizontal > .unit-body + .unit-right {
  padding-top: 0;
  padding-left: 20px;
}
.upgrade .unit--inverse {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.upgrade .unit--inverse, .upgrade .unit--inverse.unit-vertical {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.upgrade .unit--inverse > [class*=unit-]:first-child, .upgrade .unit--inverse.unit-vertical > [class*=unit-]:first-child {
  padding-top: 15px;
}
.upgrade .unit--inverse > [class*=unit-]:last-child, .upgrade .unit--inverse.unit-vertical > [class*=unit-]:last-child {
  padding-top: 0;
}
.upgrade .unit--inverse.unit-horizontal {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.upgrade .unit--inverse.unit-horizontal > [class*=unit-]:first-child {
  padding-top: 0;
  padding-left: 20px;
}
.upgrade .unit--inverse.unit-horizontal > [class*=unit-]:last-child {
  padding-left: 0;
}
@media (min-width: 480px) {
  .upgrade .unit-xs-align-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .unit-xs-align-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .unit-xs-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .unit-xs,
.upgrade .unit-xs-vertical {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .upgrade .unit-xs > [class*=unit-]:first-child,
.upgrade .unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-xs > .unit-left + .unit-right,
.upgrade .unit-xs > .unit-left + .unit-body,
.upgrade .unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-xs > .unit-body + .unit-right,
.upgrade .unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-xs-horizontal {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xs--inverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-xs--inverse, .upgrade .unit-xs--inverse.unit-xs-vertical {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 15px;
  }
  .upgrade .unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-xs--inverse.unit-xs-horizontal {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .upgrade .unit-sm-align-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .unit-sm-align-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .unit-sm-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .unit-sm,
.upgrade .unit-sm-vertical {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .upgrade .unit-sm > [class*=unit-]:first-child,
.upgrade .unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-sm > .unit-left + .unit-right,
.upgrade .unit-sm > .unit-left + .unit-body,
.upgrade .unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-sm > .unit-body + .unit-right,
.upgrade .unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-sm-horizontal {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-sm--inverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-sm--inverse, .upgrade .unit-sm--inverse.unit-sm-vertical {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 15px;
  }
  .upgrade .unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-sm--inverse.unit-sm-horizontal {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .upgrade .unit-md-align-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .unit-md-align-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .unit-md-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .unit-md,
.upgrade .unit-md-vertical {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .upgrade .unit-md > [class*=unit-]:first-child,
.upgrade .unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-md > .unit-left + .unit-right,
.upgrade .unit-md > .unit-left + .unit-body,
.upgrade .unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-md > .unit-body + .unit-right,
.upgrade .unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-md-horizontal {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-md--inverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-md--inverse, .upgrade .unit-md--inverse.unit-md-vertical {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 15px;
  }
  .upgrade .unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-md--inverse.unit-md-horizontal {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .upgrade .unit-lg-align-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .unit-lg-align-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .unit-lg-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .unit-lg,
.upgrade .unit-lg-vertical {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .upgrade .unit-lg > [class*=unit-]:first-child,
.upgrade .unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-lg > .unit-left + .unit-right,
.upgrade .unit-lg > .unit-left + .unit-body,
.upgrade .unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-lg > .unit-body + .unit-right,
.upgrade .unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-lg-horizontal {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-lg--inverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-lg--inverse, .upgrade .unit-lg--inverse.unit-lg-vertical {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 15px;
  }
  .upgrade .unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-lg--inverse.unit-lg-horizontal {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .upgrade .unit-xl-align-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .unit-xl-align-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .unit-xl-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .unit-xl,
.upgrade .unit-xl-vertical {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .upgrade .unit-xl > [class*=unit-]:first-child,
.upgrade .unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-xl > .unit-left + .unit-right,
.upgrade .unit-xl > .unit-left + .unit-body,
.upgrade .unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-xl > .unit-body + .unit-right,
.upgrade .unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-xl-horizontal {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xl--inverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-xl--inverse, .upgrade .unit-xl--inverse.unit-xl-vertical {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 15px;
  }
  .upgrade .unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-xl--inverse.unit-xl-horizontal {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1800px) {
  .upgrade .unit-xxl-align-center {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .upgrade .unit-xxl-align-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
  .upgrade .unit-xxl-middle {
    -webkit-align-items: center;
    align-items: center;
  }
  .upgrade .unit-xxl,
.upgrade .unit-xxl-vertical {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .upgrade .unit-xxl > [class*=unit-]:first-child,
.upgrade .unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-xxl > .unit-left + .unit-right,
.upgrade .unit-xxl > .unit-left + .unit-body,
.upgrade .unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-xxl > .unit-body + .unit-right,
.upgrade .unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 15px;
  }
  .upgrade .unit-xxl-horizontal {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .upgrade .unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xxl--inverse {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-xxl--inverse, .upgrade .unit-xxl--inverse.unit-xxl-vertical {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .upgrade .unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 15px;
  }
  .upgrade .unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-xxl--inverse.unit-xxl-horizontal {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .upgrade .unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .upgrade .unit.unit > [class*=unit-]:first-child, .upgrade .unit.unit-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit.unit > .unit-left + .unit-right,
.upgrade .unit.unit > .unit-left + .unit-body, .upgrade .unit.unit-vertical > .unit-left + .unit-right,
.upgrade .unit.unit-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit > .unit-body + .unit-right, .upgrade .unit.unit-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-horizontal > .unit-left + .unit-right,
.upgrade .unit.unit-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit--inverse > [class*=unit-]:first-child, .upgrade .unit.unit--inverse.unit-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit.unit--inverse > [class*=unit-]:last-child, .upgrade .unit.unit--inverse.unit-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit.unit--inverse.unit-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit--inverse.unit-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 480px) {
  .upgrade .unit.unit-xs > [class*=unit-]:first-child, .upgrade .unit.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-xs > .unit-left + .unit-right,
.upgrade .unit.unit-xs > .unit-left + .unit-body, .upgrade .unit.unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit.unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-xs > .unit-body + .unit-right, .upgrade .unit.unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit.unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit.unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 768px) {
  .upgrade .unit.unit-sm > [class*=unit-]:first-child, .upgrade .unit.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-sm > .unit-left + .unit-right,
.upgrade .unit.unit-sm > .unit-left + .unit-body, .upgrade .unit.unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit.unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-sm > .unit-body + .unit-right, .upgrade .unit.unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit.unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit.unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 992px) {
  .upgrade .unit.unit-md > [class*=unit-]:first-child, .upgrade .unit.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-md > .unit-left + .unit-right,
.upgrade .unit.unit-md > .unit-left + .unit-body, .upgrade .unit.unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit.unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-md > .unit-body + .unit-right, .upgrade .unit.unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit.unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit.unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit.unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit.unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 1200px) {
  .upgrade .unit.unit-lg > [class*=unit-]:first-child, .upgrade .unit.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-lg > .unit-left + .unit-right,
.upgrade .unit.unit-lg > .unit-left + .unit-body, .upgrade .unit.unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit.unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-lg > .unit-body + .unit-right, .upgrade .unit.unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit.unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit.unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 1600px) {
  .upgrade .unit.unit-xl > [class*=unit-]:first-child, .upgrade .unit.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-xl > .unit-left + .unit-right,
.upgrade .unit.unit-xl > .unit-left + .unit-body, .upgrade .unit.unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit.unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-xl > .unit-body + .unit-right, .upgrade .unit.unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit.unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit.unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 1800px) {
  .upgrade .unit.unit-xxl > [class*=unit-]:first-child, .upgrade .unit.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-xxl > .unit-left + .unit-right,
.upgrade .unit.unit-xxl > .unit-left + .unit-body, .upgrade .unit.unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit.unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-xxl > .unit-body + .unit-right, .upgrade .unit.unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit.unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit.unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit.unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
.upgrade .unit-spacing-sm.unit > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-vertical > [class*=unit-]:first-child {
  padding-top: 0;
}
.upgrade .unit-spacing-sm.unit > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit > .unit-left + .unit-body, .upgrade .unit-spacing-sm.unit-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-vertical > .unit-left + .unit-body {
  padding-left: 0;
  padding-top: 10px;
}
.upgrade .unit-spacing-sm.unit > .unit-body + .unit-right, .upgrade .unit-spacing-sm.unit-vertical > .unit-body + .unit-right {
  padding-left: 0;
  padding-top: 10px;
}
.upgrade .unit-spacing-sm.unit-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-horizontal > .unit-left + .unit-body {
  padding-top: 0;
  padding-left: 20px;
}
.upgrade .unit-spacing-sm.unit-horizontal > .unit-body + .unit-right {
  padding-top: 0;
  padding-left: 20px;
}
.upgrade .unit-spacing-sm.unit--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit--inverse.unit-vertical > [class*=unit-]:first-child {
  padding-top: 10px;
}
.upgrade .unit-spacing-sm.unit--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-sm.unit--inverse.unit-vertical > [class*=unit-]:last-child {
  padding-top: 0;
}
.upgrade .unit-spacing-sm.unit--inverse.unit-horizontal > [class*=unit-]:first-child {
  padding-top: 0;
  padding-left: 20px;
}
.upgrade .unit-spacing-sm.unit--inverse.unit-horizontal > [class*=unit-]:last-child {
  padding-left: 0;
}
@media (min-width: 480px) {
  .upgrade .unit-spacing-sm.unit-xs > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-xs > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xs > .unit-left + .unit-body, .upgrade .unit-spacing-sm.unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xs > .unit-body + .unit-right, .upgrade .unit-spacing-sm.unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-sm.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .upgrade .unit-spacing-sm.unit-sm > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-sm > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-sm > .unit-left + .unit-body, .upgrade .unit-spacing-sm.unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-sm > .unit-body + .unit-right, .upgrade .unit-spacing-sm.unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-sm.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .upgrade .unit-spacing-sm.unit-md > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-md > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-md > .unit-left + .unit-body, .upgrade .unit-spacing-sm.unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-md > .unit-body + .unit-right, .upgrade .unit-spacing-sm.unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-sm.unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .upgrade .unit-spacing-sm.unit-lg > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-lg > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-lg > .unit-left + .unit-body, .upgrade .unit-spacing-sm.unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-lg > .unit-body + .unit-right, .upgrade .unit-spacing-sm.unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-sm.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .upgrade .unit-spacing-sm.unit-xl > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-xl > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xl > .unit-left + .unit-body, .upgrade .unit-spacing-sm.unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xl > .unit-body + .unit-right, .upgrade .unit-spacing-sm.unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-sm.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1800px) {
  .upgrade .unit-spacing-sm.unit-xxl > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-xxl > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xxl > .unit-left + .unit-body, .upgrade .unit-spacing-sm.unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xxl > .unit-body + .unit-right, .upgrade .unit-spacing-sm.unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-sm.unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-sm.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-sm.unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-sm.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-sm.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 20px;
  }
  .upgrade .unit-spacing-sm.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .upgrade .unit-spacing-md-midle.unit > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit > .unit-left + .unit-body, .upgrade .unit-spacing-md-midle.unit-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit > .unit-body + .unit-right, .upgrade .unit-spacing-md-midle.unit-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit--inverse.unit-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md-midle.unit--inverse.unit-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit--inverse.unit-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit--inverse.unit-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 480px) {
  .upgrade .unit-spacing-md-midle.unit-xs > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-xs > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xs > .unit-left + .unit-body, .upgrade .unit-spacing-md-midle.unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xs > .unit-body + .unit-right, .upgrade .unit-spacing-md-midle.unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md-midle.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 768px) {
  .upgrade .unit-spacing-md-midle.unit-sm > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-sm > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-sm > .unit-left + .unit-body, .upgrade .unit-spacing-md-midle.unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-sm > .unit-body + .unit-right, .upgrade .unit-spacing-md-midle.unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md-midle.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 992px) {
  .upgrade .unit-spacing-md-midle.unit-md > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-md > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-md > .unit-left + .unit-body, .upgrade .unit-spacing-md-midle.unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-md > .unit-body + .unit-right, .upgrade .unit-spacing-md-midle.unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md-midle.unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 1200px) {
  .upgrade .unit-spacing-md-midle.unit-lg > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-lg > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-lg > .unit-left + .unit-body, .upgrade .unit-spacing-md-midle.unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-lg > .unit-body + .unit-right, .upgrade .unit-spacing-md-midle.unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md-midle.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 1600px) {
  .upgrade .unit-spacing-md-midle.unit-xl > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-xl > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xl > .unit-left + .unit-body, .upgrade .unit-spacing-md-midle.unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xl > .unit-body + .unit-right, .upgrade .unit-spacing-md-midle.unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md-midle.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) and (min-width: 1800px) {
  .upgrade .unit-spacing-md-midle.unit-xxl > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xxl > .unit-left + .unit-body, .upgrade .unit-spacing-md-midle.unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl > .unit-body + .unit-right, .upgrade .unit-spacing-md-midle.unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md-midle.unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md-midle.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md-midle.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 50px;
  }
  .upgrade .unit-spacing-md-midle.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
.upgrade .unit-spacing-md.unit > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-vertical > [class*=unit-]:first-child {
  padding-top: 0;
}
.upgrade .unit-spacing-md.unit > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-vertical > .unit-left + .unit-body {
  padding-left: 0;
  padding-top: 20px;
}
.upgrade .unit-spacing-md.unit > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-vertical > .unit-body + .unit-right {
  padding-left: 0;
  padding-top: 20px;
}
.upgrade .unit-spacing-md.unit-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-horizontal > .unit-left + .unit-body {
  padding-top: 0;
  padding-left: 30px;
}
.upgrade .unit-spacing-md.unit-horizontal > .unit-body + .unit-right {
  padding-top: 0;
  padding-left: 30px;
}
.upgrade .unit-spacing-md.unit--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit--inverse.unit-vertical > [class*=unit-]:first-child {
  padding-top: 20px;
}
.upgrade .unit-spacing-md.unit--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit--inverse.unit-vertical > [class*=unit-]:last-child {
  padding-top: 0;
}
.upgrade .unit-spacing-md.unit--inverse.unit-horizontal > [class*=unit-]:first-child {
  padding-top: 0;
  padding-left: 30px;
}
.upgrade .unit-spacing-md.unit--inverse.unit-horizontal > [class*=unit-]:last-child {
  padding-left: 0;
}
@media (min-width: 480px) {
  .upgrade .unit-spacing-md.unit-xs > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xs > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xs > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xs > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .upgrade .unit-spacing-md.unit-sm > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-sm > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-sm > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-sm > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .upgrade .unit-spacing-md.unit-md > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-md > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-md > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-md > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .upgrade .unit-spacing-md.unit-lg > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-lg > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-lg > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-lg > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .upgrade .unit-spacing-md.unit-xl > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xl > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xl > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xl > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1800px) {
  .upgrade .unit-spacing-md.unit-xxl > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xxl > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xxl > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xxl > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 30px;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .upgrade .unit-spacing-md.unit > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit--inverse.unit-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit--inverse.unit-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit--inverse.unit-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit--inverse.unit-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) and (min-width: 480px) {
  .upgrade .unit-spacing-md.unit-xs > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xs > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xs > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xs > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .upgrade .unit-spacing-md.unit-sm > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-sm > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-sm > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-sm > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) and (min-width: 992px) {
  .upgrade .unit-spacing-md.unit-md > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-md > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-md > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-md > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1200px) {
  .upgrade .unit-spacing-md.unit-lg > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-lg > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-lg > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-lg > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1600px) {
  .upgrade .unit-spacing-md.unit-xl > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xl > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xl > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xl > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) and (min-width: 1800px) {
  .upgrade .unit-spacing-md.unit-xxl > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xxl > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xxl > .unit-left + .unit-body, .upgrade .unit-spacing-md.unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xxl > .unit-body + .unit-right, .upgrade .unit-spacing-md.unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-md.unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 65px;
  }
  .upgrade .unit-spacing-md.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
.upgrade .unit-spacing-xxl.unit > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-vertical > [class*=unit-]:first-child {
  padding-top: 0;
}
.upgrade .unit-spacing-xxl.unit > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-vertical > .unit-left + .unit-body {
  padding-left: 0;
  padding-top: 10px;
}
.upgrade .unit-spacing-xxl.unit > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-vertical > .unit-body + .unit-right {
  padding-left: 0;
  padding-top: 10px;
}
.upgrade .unit-spacing-xxl.unit-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-horizontal > .unit-left + .unit-body {
  padding-top: 0;
  padding-left: 15px;
}
.upgrade .unit-spacing-xxl.unit-horizontal > .unit-body + .unit-right {
  padding-top: 0;
  padding-left: 15px;
}
.upgrade .unit-spacing-xxl.unit--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit--inverse.unit-vertical > [class*=unit-]:first-child {
  padding-top: 10px;
}
.upgrade .unit-spacing-xxl.unit--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit--inverse.unit-vertical > [class*=unit-]:last-child {
  padding-top: 0;
}
.upgrade .unit-spacing-xxl.unit--inverse.unit-horizontal > [class*=unit-]:first-child {
  padding-top: 0;
  padding-left: 15px;
}
.upgrade .unit-spacing-xxl.unit--inverse.unit-horizontal > [class*=unit-]:last-child {
  padding-left: 0;
}
@media (min-width: 480px) {
  .upgrade .unit-spacing-xxl.unit-xs > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xs > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xs > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xs > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .upgrade .unit-spacing-xxl.unit-sm > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-sm > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-sm > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-sm > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .upgrade .unit-spacing-xxl.unit-md > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-md > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-md > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-md > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .upgrade .unit-spacing-xxl.unit-lg > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-lg > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-lg > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-lg > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .upgrade .unit-spacing-xxl.unit-xl > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xl > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xl > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xl > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 1800px) {
  .upgrade .unit-spacing-xxl.unit-xxl > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xxl > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xxl > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 10px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 15px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .upgrade .unit-spacing-xxl.unit > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit--inverse.unit-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit--inverse.unit-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit--inverse.unit-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit--inverse.unit-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 480px) {
  .upgrade .unit-spacing-xxl.unit-xs > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xs > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xs > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-xs-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xs-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xs > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-xs-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xs-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xs-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xs-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xs--inverse.unit-xs-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .upgrade .unit-spacing-xxl.unit-sm > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-sm > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-sm > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-sm-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-sm-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-sm > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-sm-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-sm-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-sm-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-sm-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-sm--inverse.unit-sm-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .upgrade .unit-spacing-xxl.unit-md > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-md > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-md > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-md-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-md-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-md > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-md-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-md-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-md-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-md-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-md--inverse.unit-md-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .upgrade .unit-spacing-xxl.unit-lg > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-lg > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-lg > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-lg-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-lg-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-lg > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-lg-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-lg-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-lg-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-lg-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-lg--inverse.unit-lg-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 1600px) {
  .upgrade .unit-spacing-xxl.unit-xl > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xl > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xl > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-xl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xl > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-xl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xl--inverse.unit-xl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 1800px) {
  .upgrade .unit-spacing-xxl.unit-xxl > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xxl > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xxl > .unit-left + .unit-body, .upgrade .unit-spacing-xxl.unit-xxl-vertical > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xxl-vertical > .unit-left + .unit-body {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl > .unit-body + .unit-right, .upgrade .unit-spacing-xxl.unit-xxl-vertical > .unit-body + .unit-right {
    padding-left: 0;
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl-horizontal > .unit-left + .unit-right,
.upgrade .unit-spacing-xxl.unit-xxl-horizontal > .unit-left + .unit-body {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl-horizontal > .unit-body + .unit-right {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse > [class*=unit-]:first-child, .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:first-child {
    padding-top: 20px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse > [class*=unit-]:last-child, .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-vertical > [class*=unit-]:last-child {
    padding-top: 0;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:first-child {
    padding-top: 0;
    padding-left: 115px;
  }
  .upgrade .unit-spacing-xxl.unit-xxl--inverse.unit-xxl-horizontal > [class*=unit-]:last-child {
    padding-left: 0;
  }
}
.upgrade .animated, .upgrade .desktop .pricing-box-alternative, .upgrade .jp-video:before {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 1;
}
.upgrade .animated.infinite, .upgrade .desktop .infinite.pricing-box-alternative, .upgrade .infinite.jp-video:before {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.upgrade .animated.hinge, .upgrade .desktop .hinge.pricing-box-alternative, .upgrade .hinge.jp-video:before {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.upgrade html:not(.lt-ie10) .not-animated {
  opacity: 0;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.upgrade .fadeIn, .upgrade .desktop .pricing-box-alternative:hover, .upgrade .jp-video:hover:before {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.upgrade .fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.upgrade .fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.upgrade .fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.upgrade .fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.upgrade .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.upgrade .slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.upgrade .slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.upgrade .slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.upgrade .slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.upgrade .slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}
.upgrade .fxRotateInDown {
  -webkit-animation: fxRotateInDown 0.6s forwards;
  animation: fxRotateInDown 0.6s forwards;
}
@keyframes fxRotateInDown {
  0% {
    animation-timing-function: linear;
    opacity: 0;
    transform: translate3d(0, -55px, 0) scale(0.8);
    filter: blur(10px);
  }
  100% {
    transform: translate3d(0, 0px, 0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}
.upgrade .fxRotateInUp {
  -webkit-animation: fxRotateInUp 0.6s forwards;
  animation: fxRotateInUp 0.6s forwards;
}
@keyframes fxRotateInUp {
  0% {
    animation-timing-function: linear;
    transform: translate3d(0, 55px, 0) scale(0.8);
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}
.upgrade .fxBlurInRight {
  -webkit-animation: fxBlurInRight 0.4s forwards;
  animation: fxBlurInRight 0.4s forwards;
}
@keyframes fxBlurInRight {
  0% {
    animation-timing-function: linear;
    transform: translate3d(-105px, 0, 0) scale(0.8);
    opacity: 0;
    filter: blur(5px);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}
.upgrade .blurIn {
  -webkit-animation: blurIn 0.7s forwards;
  animation: blurIn 0.7s forwards;
}
@keyframes blurIn {
  0% {
    opacity: 0;
    filter: blur(10px);
    transform: scale3d(0.85, 0.85, 0.85);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: scale3d(1, 1, 1);
  }
}
.upgrade .blurInButton {
  -webkit-animation: blurInButton 0.9s forwards;
  animation: blurInButton 0.9s forwards;
}
@keyframes blurInButton {
  0% {
    opacity: 0;
    filter: blur(5px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.upgrade .hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
.upgrade .fadeInRightSmall {
  animation-name: fadeInRightSmall;
}
@keyframes fadeInRightSmall {
  0% {
    opacity: 0;
    transform: translateX(120px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}
.upgrade .fadeInLeftSmall {
  animation-name: fadeInLeftSmall;
}
@keyframes fadeInLeftSmall {
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.upgrade .fadeInUpSmall {
  animation-name: fadeInUpSmall;
  transform-origin: 50% 100%;
}
@keyframes fadeInUpSmall {
  0% {
    opacity: 0;
    transform: translateY(90px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.upgrade .bg-vide {
  position: relative;
  z-index: 0;
}
.upgrade .video-background {
  position: relative;
  overflow: hidden;
  background-color: #000000;
}
.upgrade .video-background-poster {
  display: none;
}
.upgrade .video-foreground,
.upgrade .video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.upgrade .video-foreground {
  height: 300%;
  top: -100%;
}
.upgrade .video-foreground iframe {
  left: -5px;
  width: calc(100% + 5px);
}
.upgrade .video-background-inner {
  position: relative;
  z-index: 1;
}
.upgrade [data-x-mode=true] .video-foreground,
.upgrade .builder .video-foreground,
.upgrade .ios .video-foreground,
.upgrade .ipad .video-foreground,
.upgrade .tablet .video-foreground,
.upgrade .devices .video-foreground,
.upgrade .mobile .video-foreground {
  display: none;
}
.upgrade [data-x-mode=true] .video-background-poster,
.upgrade .builder .video-background-poster,
.upgrade .ios .video-background-poster,
.upgrade .ipad .video-background-poster,
.upgrade .tablet .video-background-poster,
.upgrade .devices .video-background-poster,
.upgrade .mobile .video-background-poster {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.upgrade [data-x-mode=true] .video-background-poster img,
.upgrade .builder .video-background-poster img,
.upgrade .ios .video-background-poster img,
.upgrade .ipad .video-background-poster img,
.upgrade .tablet .video-background-poster img,
.upgrade .devices .video-background-poster img,
.upgrade .mobile .video-background-poster img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.upgrade * + .bg-vide,
.upgrade * + .video-background {
  margin-top: 50px;
}
@media (min-width: 992px) {
  .upgrade * + .bg-vide,
.upgrade * + .video-background {
    margin-top: 60px;
  }
}
.upgrade .dtp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2000;
}
.upgrade .dtp > .dtp-content {
  position: relative;
  overflow: hidden;
  left: 50%;
  max-width: 320px;
  margin: 0 auto;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 6px 8px 0 rgba(110, 123, 157, 0.17);
}
@media (max-width: 767px) {
  .upgrade .dtp > .dtp-content {
    max-width: 300px;
    max-height: 90vh;
    overflow-y: auto;
  }
}
.upgrade .dtp-header {
  position: relative;
  background: #fff;
  color: #29293a;
  padding: 8px 15px;
  text-align: center;
}
.upgrade .dtp-date, .upgrade .dtp-time {
  background: #fff;
  text-align: center;
  color: #29293a;
  padding: 10px;
}
.upgrade .dtp-date > div {
  padding: 0;
  margin: 0;
  vertical-align: middle;
}
.upgrade .dtp-actual-month, .upgrade .dtp-actual-num, .upgrade .dtp-actual-year {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #29293a;
}
.upgrade .dtp-actual-month {
  font-size: 1.3em;
}
.upgrade .dtp-actual-num, .upgrade .dtp-actual-maxtime {
  font-size: 2.8em;
  line-height: 1;
}
.upgrade .dtp-date .dtp-actual-num {
  padding: 5px 0;
}
.upgrade .dtp-actual-year {
  font-size: 1.5em;
  color: #29293a;
}
.upgrade .dtp-picker {
  padding: 1rem;
  text-align: center;
}
.upgrade .dtp-picker-month, .upgrade .dtp-actual-time {
  text-align: center;
}
.upgrade .dtp-close {
  position: absolute;
  top: 5px;
  bottom: 0;
  right: 2rem;
}
.upgrade .dtp-close > a {
  display: inline-block;
  vertical-align: middle;
  color: #29293a;
}
.upgrade .dtp-close > a:hover {
  color: #981df0;
}
.upgrade .dtp table.dtp-picker-days {
  margin: 0;
  border: none;
}
.upgrade .dtp table.dtp-picker-days tr {
  border: none;
}
.upgrade .dtp table.dtp-picker-days tr > td {
  border: none;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  padding: 1rem 0.3rem;
  color: #c2c2c3;
}
.upgrade .dtp table.dtp-picker-days tr > td > span.dtp-select-day {
  color: #bdbdbd !important;
}
.upgrade .dtp table.dtp-picker-days tr > td > a {
  display: inline-block;
  color: #29293a;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  transition: 0.3s all ease;
}
.upgrade .dtp .dtp-picker-time > a {
  color: #000000;
  padding: 0.4rem 0.5rem 0.5rem 0.6rem;
  border-radius: 50% !important;
}
.upgrade .dtp table.dtp-picker-days tr > td > a {
  border: 1px solid transparent;
}
.upgrade .dtp table.dtp-picker-days tr > td > a.selected {
  background: #ed1c94;
  border-color: #ed1c94;
  color: #fff;
}
.upgrade .dtp table.dtp-picker-days tr > td > a:hover {
  border-color: #ed1c94;
}
.upgrade .dtp table.dtp-picker-days tr > th {
  color: #555555;
  text-align: center;
  font-weight: 300;
  padding: 0.4rem 0.3rem 0.6rem;
  border-bottom: 1px solid #ededed;
}
.upgrade .dtp .p10, .upgrade .dtp .p20, .upgrade .dtp .p60, .upgrade .dtp .p80 {
  display: inline-block;
  vertical-align: middle;
}
.upgrade .dtp .p10 {
  width: 10%;
}
.upgrade .dtp .p10 > a {
  color: #ed1c94;
  font-size: 36px;
  line-height: 1.1;
  transition: 0.3s all ease;
}
.upgrade .dtp .p10 > a:hover {
  color: #981df0;
}
.upgrade .dtp .p20 {
  width: 20%;
}
.upgrade .dtp .p60 {
  width: 60%;
}
.upgrade .dtp .p80 {
  width: 80%;
}
.upgrade .dtp-picker-month {
  display: none !important;
}
.upgrade .dtp-meridien-am, .upgrade .dtp-meridien-pm {
  position: relative;
  top: 10px;
  color: #000000;
  font-weight: 500;
  padding: 0.7rem 0.5rem;
  border-radius: 50% !important;
  text-decoration: none;
  background: #eee;
  font-size: 1rem;
}
.upgrade .dtp-actual-meridien a.selected {
  background: #981df0;
  color: #fff;
}
.upgrade .dtp-picker-time {
  position: absolute;
  width: 30px;
  height: 30px;
  font-size: 1em;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 500;
  text-align: center !important;
}
.upgrade .dtp-picker-time > a {
  display: block;
  line-height: 23px;
  padding: 0.3rem 0.3rem 0.3rem 0.3rem;
}
.upgrade .dtp-picker-time > a.dtp-select-hour.selected {
  background: #981df0;
  color: #fff;
}
.upgrade .dtp-picker-time > a.dtp-select-hour.disabled {
  color: #333333;
}
.upgrade .dtp-picker-time > a.dtp-select-minute.disabled {
  color: #333333;
}
.upgrade .dtp-picker-time > a.dtp-select-minute.selected {
  background: #981df0;
  color: #fff;
}
.upgrade .dtp-picker-clock {
  margin: 1rem 2rem 0 2rem;
  padding: 1rem;
  border-radius: 50% !important;
  background: white;
}
.upgrade .dtp-clock-center {
  width: 15px;
  height: 15px;
  background: #333333;
  border-radius: 50%;
  position: absolute;
  z-index: 50;
}
.upgrade html[class*=ie] .dtp-clock-center {
  display: none;
}
.upgrade .dtp-hand, .upgrade .dtp-hour-hand {
  position: absolute;
  width: 4px;
  margin-left: -2px;
  background: #333333;
  transform: rotate(0deg);
  transform-origin: bottom;
  z-index: 1;
}
.upgrade html[class*=ie] .dtp-hand, .upgrade html[class*=ie] .dtp-hour-hand {
  display: none;
}
.upgrade .dtp-minute-hand {
  width: 2px;
  margin-left: -1px;
}
.upgrade .dtp-hand.on {
  background: #981df0;
}
.upgrade .dtp-buttons {
  padding: 0 1rem 1rem 1rem;
  text-align: right;
}
.upgrade .dtp .invisible {
  visibility: hidden;
}
.upgrade .dtp-buttons.group {
  transform: translateY(-10px);
  margin-bottom: -10px;
  margin-left: -10px;
}
.upgrade .dtp-buttons.group > * {
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}
.upgrade .countdown {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #29293a;
}
.upgrade .countdown.countdown-expired {
  color: #c2c2c3;
}
.upgrade * + .countdown {
  margin-top: 20px;
}
.upgrade .countdown + .countdown {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .upgrade .countdown {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .upgrade .countdown {
    font-size: 28px;
  }
  .upgrade * + .countdown {
    margin-top: 40px;
  }
  .upgrade .countdown + .countdown {
    margin-top: 30px;
  }
}
.upgrade .counter-wrap {
  font-size: 24px;
}
.upgrade .counter-wrap .icon {
  font-size: 28px;
}
.upgrade .counter-wrap * + p {
  margin-top: 10px;
}
.upgrade .counter-wrap .icon + * {
  margin-top: 8px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .upgrade .cell-sm-3 .counter-wrap {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .upgrade .counter-wrap .icon {
    font-size: 32px;
  }
}
@keyframes sprite-animation {
  from {
    background-position: 0 0;
  }
}
.upgrade [data-isotope-layout] {
  display: block;
  transition: 0.4s all ease;
  min-height: 160px;
  z-index: 0;
}
.upgrade [data-isotope-layout] .row {
  margin-bottom: -30px;
}
.upgrade [data-isotope-layout] .row > [class*=col-] {
  margin-top: 30px;
}
.upgrade [data-isotope-layout] .row-narrow {
  margin-bottom: -10px;
}
.upgrade [data-isotope-layout] .row-narrow > [class*=col-] {
  margin-top: 10px;
}
.upgrade [data-isotope-layout] .row-no-gutter .gallery-item-image {
  width: 100%;
}
.upgrade [data-isotope-layout] .row-condensed {
  margin-bottom: 0;
}
.upgrade [data-isotope-layout] .row-condensed > [class*=col-] {
  margin-top: 0;
}
@media (min-width: 1200px) {
  .upgrade [data-isotope-layout] [class*=col-lg-3]:not([class*=col-xxl]):not([class*=col-xl]) {
    width: 24.8%;
  }
}
.upgrade [data-isotope-layout] [class*=col-] {
  display: block;
  opacity: 0;
  will-change: transform;
  backface-visibility: hidden;
  transition: 0.1s opacity ease-in;
}
@media (max-width: 479px) {
  .upgrade [data-isotope-layout] .row [class*=col-] {
    width: 100%;
  }
}
.upgrade [data-isotope-layout].isotope--loaded [class*=col-] {
  opacity: 1;
}
.upgrade [data-isotope-layout].isotope--loaded:after {
  opacity: 0;
  visibility: hidden;
}
.upgrade [data-isotope-layout].isotope-titled-gallery .row {
  margin-bottom: -50px;
}
.upgrade [data-isotope-layout].isotope-titled-gallery .row > [class*=col-] {
  margin-top: 50px;
}
.upgrade [data-isotope-layout].isotope-default {
  top: -20px;
}
.upgrade [data-isotope-layout].isotope-default .row {
  margin-bottom: -30px;
}
.upgrade [data-isotope-layout].isotope-default .row > [class*=col-] {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .upgrade [data-isotope-layout].isotope-md .row {
    margin-bottom: -60px;
  }
  .upgrade [data-isotope-layout].isotope-md .row > [class*=col-] {
    margin-top: 60px;
  }
}
@media (min-width: 1600px) {
  .upgrade [data-isotope-layout] .col-xl-2 {
    width: 16.5%;
  }
}
.upgrade [data-x-mode=true] [data-isotope-layout]::after {
  display: none !important;
}
.upgrade [data-isotope-layout=masonry] .gallery-item {
  max-height: 350px;
}
@media (min-width: 768px) {
  .upgrade [data-isotope-layout=masonry] .gallery-item {
    max-height: 480px;
  }
}
@media (min-width: 1600px) {
  .upgrade [data-isotope-layout=masonry] .gallery-item {
    max-height: none;
  }
}
@media (max-width: 767px) {
  .upgrade .isotope-xl .row > [class*=col-] .row {
    margin-bottom: -30px;
  }
  .upgrade .isotope-xl .row > [class*=col-] .row > [class*=col-] {
    margin-top: 35px;
  }
}
@media (min-width: 1600px) {
  .upgrade .isotope-xl .row > [class*=col-] .row {
    margin-bottom: -30px;
  }
  .upgrade .isotope-xl .row > [class*=col-] .row > [class*=col-] {
    margin-top: 66px;
  }
}
.upgrade .isotope-filters {
  position: relative;
  display: inline-block;
}
.upgrade .isotope-filters-list {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: 10px 0;
  z-index: 10;
  float: left;
  min-width: 130px;
  font-size: 16px;
  text-align: left;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow: hidden;
}
.upgrade .isotope-filters-list > li {
  display: block;
}
.upgrade .isotope-filters-list > li > a {
  display: block;
  position: relative;
  clear: both;
  color: #29293a;
  white-space: nowrap;
  line-height: 1.3;
  padding: 7px 12px;
  font-weight: 400;
}
.upgrade .isotope-filters-list > li > a.active, .upgrade .isotope-filters-list > li > a:hover {
  color: #981df0;
  background-color: #f2f3f9;
}
.upgrade .isotope-filters-list.active {
  display: block;
}
.upgrade .isotope-filters-toggle.active:focus {
  outline: none;
}
.upgrade .isotope-filters-title {
  display: none;
}
@media (min-width: 480px) {
  .upgrade .isotope-filters-list {
    right: 0;
    left: 0;
  }
}
@media (min-width: 992px) {
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list {
    position: static;
    display: inline-block;
    float: none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    border-bottom: 1px solid #f2f3f9;
    overflow: visible;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list > li, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list > li {
    display: inline-block;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list > li + li, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list > li + li {
    margin-left: 28px;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list > li + li, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list > li + li {
    margin-left: 55px;
  }
}
@media (min-width: 992px) {
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list a, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list a {
    display: inline-block;
    padding: 0 5px 13px;
    color: #29293a;
    font-size: 20px;
    transition: 0.3s all ease-in;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list a:after, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list a:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 0;
    height: 1px;
    background: #981df0;
    transition: 0.4s;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list a.active, .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list a:hover, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list a.active,
.upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list a:hover {
    color: #981df0;
    background-color: transparent;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list a.active:after, .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list a:hover:after, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list a.active:after,
.upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list a:hover:after {
    left: 0;
    width: 100%;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons {
    transform: translateY(-10px);
    margin-bottom: -10px;
    margin-left: -10px;
    border-bottom: 0;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons > *, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons > * {
    display: inline-block;
    margin-top: 10px;
    margin-left: 10px;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons > li, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons > li {
    padding: 0;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a {
    position: relative;
    display: inline-block;
    max-width: 100%;
    padding: 11px 25px;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    border: 2px solid;
    border-radius: 35px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    white-space: pre-wrap;
    transition: initial;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a, .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:focus, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a,
.upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:focus {
    color: #29293a;
    background-color: #fff;
    border-color: #ededed;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:hover, .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:active, .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:hover, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:hover,
.upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:active,
.upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a:hover {
    color: #fff;
    background-color: #981df0;
    border-color: #981df0;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a::after, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a::after {
    display: none;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a.active, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list.isotope-filters-list-buttons a.active {
    color: #fff;
    background: #981df0;
    border-color: #981df0;
  }
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-toggle,
.upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .block-top-level .big, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-toggle,
.upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .block-top-level .big {
    display: none;
  }
}
@media (min-width: 1200px) {
  .upgrade .desktop .isotope-filters-horizontal:not(.isotope-filter-collapse-lg) .isotope-filters-list a, .upgrade .desktop .isotope-filters-vertical:not(.isotope-filter-collapse-lg) .isotope-filters-list a {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .upgrade html .page .isotope-filters.isotope-filters-vertical {
    margin-top: 30px;
  }
  .upgrade html .page .isotope-filters.isotope-filters-vertical .isotope-filters-list {
    margin-bottom: -30px;
    padding: 0;
    border-bottom: none;
    border-right: 1px solid rgba(41, 41, 58, 0.1);
  }
  .upgrade html .page .isotope-filters.isotope-filters-vertical .isotope-filters-list > li {
    display: block;
    margin-top: 0;
    text-align: right;
  }
  .upgrade html .page .isotope-filters.isotope-filters-vertical .isotope-filters-list > li > a {
    padding: 0 25px;
  }
  .upgrade html .page .isotope-filters.isotope-filters-vertical .isotope-filters-list > li > a:after {
    bottom: 50%;
    right: -1px;
    left: auto;
    width: 1px !important;
    height: 0;
    background: #981df0;
  }
  .upgrade html .page .isotope-filters.isotope-filters-vertical .isotope-filters-list > li > a.active:after {
    bottom: 0;
    width: 1px;
    height: 100%;
    right: -1px;
    left: auto;
  }
  .upgrade html .page .isotope-filters.isotope-filters-vertical .isotope-filters-list > li + li {
    margin-left: 0;
    margin-top: 20px;
  }
  .upgrade html .page .isotope-filters.isotope-filters-vertical .isotope-filters-title {
    display: block;
  }
}
.upgrade .owl-carousel .animated, .upgrade .owl-carousel .desktop .pricing-box-alternative, .upgrade .desktop .owl-carousel .pricing-box-alternative, .upgrade .owl-carousel .jp-video:before {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.upgrade .owl-carousel .owl-animated-in {
  z-index: 0;
}
.upgrade .owl-carousel .owl-animated-out {
  z-index: 1;
}
.upgrade .owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.upgrade .owl-height {
  transition: height 500ms ease-in-out;
}
.upgrade .owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.upgrade .owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.upgrade .owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.upgrade .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.upgrade .owl-carousel .owl-controls .owl-nav .owl-prev,
.upgrade .owl-carousel .owl-controls .owl-nav .owl-next,
.upgrade .owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}
.upgrade .owl-carousel.owl-loaded {
  display: block;
}
.upgrade .owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.upgrade .owl-carousel.owl-hidden {
  opacity: 0;
}
.upgrade .owl-carousel .owl-refresh .owl-item {
  display: none;
}
.upgrade .owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  will-change: transform;
}
.upgrade .owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.upgrade .owl-carousel.owl-rtl {
  direction: rtl;
}
.upgrade .owl-carousel.owl-rtl .owl-item {
  float: right;
}
.upgrade .no-js .owl-carousel {
  display: block;
}
.upgrade .owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.upgrade .owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.upgrade .owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px "FontAwesome";
  cursor: pointer;
  z-index: 1;
  transition: scale 100ms ease;
}
.upgrade .owl-carousel .owl-video-play-icon:before {
  content: "";
}
.upgrade .owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.upgrade .owl-carousel .owl-video-playing .owl-video-tn,
.upgrade .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.upgrade .owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.upgrade .owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}
.upgrade .owl-prev, .upgrade .owl-next {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  margin-top: -20px;
  z-index: 10;
  cursor: pointer;
  background: #ed1c94;
  color: #fff;
  transition: 0.33s;
}
.upgrade .owl-prev::before, .upgrade .owl-next::before {
  position: relative;
  font-family: "Material Design Icons";
}
.upgrade .owl-prev:hover, .upgrade .owl-next:hover {
  background: #981df0;
}
.upgrade .owl-prev {
  left: 0;
}
.upgrade .owl-prev::before {
  content: "";
}
.upgrade .owl-next {
  right: 0;
}
.upgrade .owl-next::before {
  content: "";
}
.upgrade .owl-dots {
  text-align: center;
  margin-top: 30px;
}
.upgrade .owl-dot {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  text-align: center;
  outline: none;
  cursor: pointer;
  background-color: #c2c2c3;
  transition: 0.25s;
}
.upgrade .owl-dot:hover, .upgrade .owl-dot:focus {
  background-color: #981df0;
}
.upgrade .owl-dot.active {
  background-color: #981df0;
}
.upgrade .owl-dot + .owl-dot {
  margin-left: 20px;
}
.upgrade .owl-dot:only-child {
  display: none !important;
}
.upgrade .owl-carousel.owl-dots-secondary .owl-dot {
  border-color: #ededed;
}
.upgrade .owl-carousel.owl-dots-secondary .owl-dot:hover, .upgrade .owl-carousel.owl-dots-secondary .owl-dot.active {
  background-color: #ed1c94;
}
.upgrade .owl-carousel.owl-carousel-light-dots .owl-dot {
  border-color: #fff;
}
.upgrade .owl-carousel.owl-carousel-light-dots .owl-dot:hover, .upgrade .owl-carousel.owl-carousel-light-dots .owl-dot:focus {
  background-color: #fff;
}
.upgrade .owl-carousel.owl-carousel-light-dots .owl-dot.active {
  background-color: #fff;
}
.upgrade .owl-carousel figure img {
  width: 100%;
}
@media (min-width: 1600px) {
  .upgrade .owl-carousel-team .owl-dots {
    display: none !important;
  }
}
.upgrade .owl-carousel-inset .owl-stage-outer {
  padding: 15px 15px 15px 0;
}
@media (max-width: 991px) {
  .upgrade .owl-layout-1 .owl-nav {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .upgrade .owl-layout-1 {
    padding: 0 50px;
  }
  .upgrade .owl-layout-1 .owl-dots {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .upgrade .owl-layout-1 {
    padding: 0 100px;
  }
}
@media (min-width: 1600px) {
  .upgrade .owl-layout-1 {
    padding: 0 140px;
  }
}
@media (min-width: 1600px) {
  .upgrade * + .owl-layout-1 {
    margin-top: 90px;
  }
}
@media (min-width: 768px) {
  .upgrade * + .owl-layout-2 {
    margin-top: 55px;
  }
  .upgrade .owl-layout-2 .owl-dots {
    transform: translateY(-5px);
    margin-bottom: -5px;
    margin-left: -32px;
  }
  .upgrade .owl-layout-2 .owl-dots > * {
    display: inline-block;
    margin-top: 5px;
    margin-left: 32px;
  }
  .upgrade .owl-layout-2 .owl-dot {
    margin-top: 30px;
  }
}
.upgrade .owl-layout-3 .owl-prev {
  left: 40px;
}
.upgrade .owl-layout-3 .owl-next {
  right: 40px;
}
.upgrade .owl-layout-4 .owl-stage {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
@media (min-width: 768px) {
  .upgrade * + .owl-layout-4 {
    margin-top: 55px;
  }
  .upgrade .owl-layout-4 .owl-dots {
    margin-top: 0;
    transform: translateY(-5px);
    margin-bottom: -5px;
    margin-left: -32px;
  }
  .upgrade .owl-layout-4 .owl-dots > * {
    display: inline-block;
    margin-top: 5px;
    margin-left: 32px;
  }
  .upgrade .owl-layout-4 .owl-dot {
    margin-top: 30px;
  }
}
@media (min-width: 1600px) {
  .upgrade .owl-layout-4 .owl-dot {
    margin-top: 60px;
  }
}
@media (min-width: 1600px) {
  .upgrade .owl-carousel + .button-twitter {
    margin-top: 90px;
  }
}
@media (min-width: 768px) {
  .upgrade .owl-layout-5 .owl-dots {
    text-align: left;
  }
}
@media (min-width: 1600px) {
  .upgrade .owl-layout-5 .owl-dots {
    margin-top: 85px;
  }
}
@keyframes rd-navbar-slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes rd-navbar-slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
.upgrade .breadcrumbs-custom-wrap .rd-navbar-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1091;
}
.upgrade .breadcrumbs-custom-wrap .rd-navbar-wrap .rd-navbar {
  background: transparent;
}
.upgrade .breadcrumbs-custom-wrap .rd-navbar-wrap .rd-navbar--is-stuck:before {
  opacity: 1;
}
.upgrade .rd-navbar-wrap, .upgrade .rd-navbar,
.upgrade .rd-navbar-dropdown,
.upgrade .rd-navbar-megamenu,
.upgrade .rd-navbar-nav,
.upgrade .rd-navbar-panel, .upgrade .rd-navbar-static .rd-navbar-dropdown,
.upgrade .rd-navbar-static .rd-navbar-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu, .upgrade .rd-navbar-fixed .rd-navbar-nav-wrap, .upgrade .rd-navbar-fixed .rd-navbar-submenu, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-toggle, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-submenu {
  transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}
.upgrade .rd-navbar, .upgrade .rd-navbar.rd-navbar--is-clone {
  display: none;
}
.upgrade .rd-navbar-fixed,
.upgrade .rd-navbar-static,
.upgrade .rd-navbar-fullwidth,
.upgrade .rd-navbar-sidebar {
  display: block;
}
.upgrade .rd-navbar--no-transition, .upgrade .rd-navbar--no-transition * {
  transition: none !important;
}
.upgrade .rd-navbar-collapse-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #333333;
  display: none;
}
.upgrade .rd-navbar-collapse-toggle span {
  top: 50%;
  margin-top: -3px;
}
.upgrade .rd-navbar-collapse-toggle span, .upgrade .rd-navbar-collapse-toggle span:before, .upgrade .rd-navbar-collapse-toggle span:after {
  position: absolute;
  width: 6px;
  height: 6px;
  line-height: 6px;
  text-align: center;
  background: #333333;
  left: 50%;
  margin-left: -3px;
  border-radius: 50%;
  transition: 0.3s all ease;
}
.upgrade .rd-navbar-collapse-toggle span:before, .upgrade .rd-navbar-collapse-toggle span:after {
  content: "";
}
.upgrade .rd-navbar-collapse-toggle span:before {
  bottom: 100%;
  margin-bottom: 3px;
}
.upgrade .rd-navbar-collapse-toggle span:after {
  top: 100%;
  margin-top: 3px;
}
.upgrade .rd-navbar-collapse-toggle.active span {
  transform: scale(0.7);
}
.upgrade .rd-navbar-collapse-toggle.active span:before {
  transform: translateY(18px);
}
.upgrade .rd-navbar-collapse-toggle.active span:after {
  transform: translateY(-18px);
}
.upgrade .rd-navbar--is-stuck {
  box-shadow: 0 6px 8px rgba(110, 123, 157, 0.17);
}
.upgrade .rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.upgrade .rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}
.upgrade .rd-navbar {
  display: none;
  background: #fff;
}
.upgrade .rd-navbar-toggle,
.upgrade .rd-navbar-sidebar-toggle {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  color: #29293a;
  background-color: transparent;
  border: none;
  display: none;
}
.upgrade .rd-navbar-toggle span,
.upgrade .rd-navbar-sidebar-toggle span {
  position: relative;
  display: block;
  margin: auto;
  transition: 0.3s all ease;
}
.upgrade .rd-navbar-toggle span:after, .upgrade .rd-navbar-toggle span:before,
.upgrade .rd-navbar-sidebar-toggle span:after,
.upgrade .rd-navbar-sidebar-toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -8px;
  transition: 0.3s all ease;
}
.upgrade .rd-navbar-toggle span:after,
.upgrade .rd-navbar-sidebar-toggle span:after {
  top: 8px;
}
.upgrade .rd-navbar-toggle span:after, .upgrade .rd-navbar-toggle span:before, .upgrade .rd-navbar-toggle span,
.upgrade .rd-navbar-sidebar-toggle span:after,
.upgrade .rd-navbar-sidebar-toggle span:before,
.upgrade .rd-navbar-sidebar-toggle span {
  width: 24px;
  height: 4px;
  background-color: #29293a;
  backface-visibility: hidden;
  border-radius: 2px;
}
.upgrade .rd-navbar-toggle span,
.upgrade .rd-navbar-sidebar-toggle span {
  transform: rotate(180deg);
}
.upgrade .rd-navbar-toggle span:before, .upgrade .rd-navbar-toggle span:after,
.upgrade .rd-navbar-sidebar-toggle span:before,
.upgrade .rd-navbar-sidebar-toggle span:after {
  transform-origin: 1.71429px center;
}
.upgrade .rd-navbar-toggle.active span,
.upgrade .rd-navbar-sidebar-toggle.active span {
  transform: rotate(360deg);
}
.upgrade .rd-navbar-toggle.active span:before, .upgrade .rd-navbar-toggle.active span:after,
.upgrade .rd-navbar-sidebar-toggle.active span:before,
.upgrade .rd-navbar-sidebar-toggle.active span:after {
  top: 0;
  width: 15px;
}
.upgrade .rd-navbar-toggle.active span:before,
.upgrade .rd-navbar-sidebar-toggle.active span:before {
  -webkit-transform: rotate3d(0, 0, 1, -40deg);
  transform: rotate3d(0, 0, 1, -40deg);
}
.upgrade .rd-navbar-toggle.active span:after,
.upgrade .rd-navbar-sidebar-toggle.active span:after {
  -webkit-transform: rotate3d(0, 0, 1, 40deg);
  transform: rotate3d(0, 0, 1, 40deg);
}
.upgrade .rd-navbar-toggle:focus,
.upgrade .rd-navbar-sidebar-toggle:focus {
  outline: none;
}
.upgrade .rd-navbar-brand a {
  display: inline-block;
  vertical-align: middle;
}
.upgrade .rd-navbar-brand .logo-inverse {
  display: none;
}
.upgrade .breadcrumbs-custom-light .rd-navbar-brand .logo-inverse {
  display: inline-block;
}
.upgrade .breadcrumbs-custom-light .rd-navbar-brand .logo-default {
  display: none;
}
.upgrade .rd-navbar-dropdown {
  display: none;
}
.upgrade .rd-navbar-dropdown li + li,
.upgrade .rd-navbar-megamenu li + li {
  border-top: 1px solid #f2f3f9;
}
.upgrade .rd-navbar-search .rd-search-form-submit {
  font-size: 26px;
  color: #29293a;
}
.upgrade .rd-navbar-search .rd-search-form-submit:hover {
  color: #ed1c94;
}
.upgrade .rd-navbar-shop .rd-navbar-shop-icon span {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
}
.upgrade .rd-navbar-search-toggle {
  transition: none;
}
.upgrade .rd-navbar-nav > li > a {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.upgrade .rd-navbar-aside-right .button {
  margin-top: 0;
}
.upgrade .rd-navbar-top-panel * + .box-inline {
  margin-top: 0;
}
.upgrade .rd-navbar-login .rd-navbar-shop-icon span {
  padding-left: 5px;
  font: 300 18px/1.2 "Lato", Helvetica, Arial, sans-serif;
}
@media (min-width: 992px) {
  .upgrade .desktop .rd-navbar-wrap + .breadcrumbs-custom {
    padding-top: 200px;
    padding-bottom: 70px;
  }
  .upgrade .desktop .rd-navbar-wrap + .breadcrumbs-custom.breadcrumbs-blog-post {
    padding-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .upgrade .desktop .rd-navbar-wrap + .breadcrumbs-custom {
    padding-top: 170px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1600px) {
  .upgrade .desktop .rd-navbar-wrap + .breadcrumbs-custom {
    padding-top: 235px;
    padding-bottom: 100px;
  }
  .upgrade .desktop .rd-navbar-wrap + .breadcrumbs-custom.breadcrumbs-custom-lg {
    padding-top: 215px;
    padding-bottom: 160px;
  }
}
.upgrade .bg-gray-darker .logo-inverse, .upgrade .bg-additional-gray-darker .bg-additional-item .logo-inverse,
.upgrade .bg-gradient-2 .logo-inverse,
.upgrade .bg-gradient-1 .logo-inverse,
.upgrade .bg-image .logo-inverse, .upgrade .bg-additional-image .bg-additional-item .logo-inverse,
.upgrade .bg-secondary-2 .logo-inverse,
.upgrade .bg-gradient .logo-inverse, .upgrade .bg-gradient-1 .logo-inverse, .upgrade .bg-gradient-2 .logo-inverse, .upgrade .bg-gradient-3 .logo-inverse {
  display: block;
}
.upgrade .bg-gray-darker .logo-default, .upgrade .bg-additional-gray-darker .bg-additional-item .logo-default,
.upgrade .bg-gradient-2 .logo-default,
.upgrade .bg-gradient-1 .logo-default,
.upgrade .bg-image .logo-default, .upgrade .bg-additional-image .bg-additional-item .logo-default,
.upgrade .bg-secondary-2 .logo-default,
.upgrade .bg-gradient .logo-default, .upgrade .bg-gradient-1 .logo-default, .upgrade .bg-gradient-2 .logo-default, .upgrade .bg-gradient-3 .logo-default {
  display: none;
}
.upgrade .bg-gray-darker .rd-navbar-toggle span, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-toggle span, .upgrade .bg-gray-darker .rd-navbar-toggle span:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-toggle span:before, .upgrade .bg-gray-darker .rd-navbar-toggle span:after, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-toggle span:after,
.upgrade .bg-gray-darker .rd-navbar-sidebar-toggle span, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-sidebar-toggle span,
.upgrade .bg-gray-darker .rd-navbar-sidebar-toggle span:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-sidebar-toggle span:before,
.upgrade .bg-gray-darker .rd-navbar-sidebar-toggle span:after, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-sidebar-toggle span:after,
.upgrade .bg-gradient-2 .rd-navbar-toggle span,
.upgrade .bg-gradient-2 .rd-navbar-toggle span:before,
.upgrade .bg-gradient-2 .rd-navbar-toggle span:after,
.upgrade .bg-gradient-2 .rd-navbar-sidebar-toggle span,
.upgrade .bg-gradient-2 .rd-navbar-sidebar-toggle span:before,
.upgrade .bg-gradient-2 .rd-navbar-sidebar-toggle span:after,
.upgrade .bg-gradient-1 .rd-navbar-toggle span,
.upgrade .bg-gradient-1 .rd-navbar-toggle span:before,
.upgrade .bg-gradient-1 .rd-navbar-toggle span:after,
.upgrade .bg-gradient-1 .rd-navbar-sidebar-toggle span,
.upgrade .bg-gradient-1 .rd-navbar-sidebar-toggle span:before,
.upgrade .bg-gradient-1 .rd-navbar-sidebar-toggle span:after,
.upgrade .bg-image .rd-navbar-toggle span, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-toggle span,
.upgrade .bg-image .rd-navbar-toggle span:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-toggle span:before,
.upgrade .bg-image .rd-navbar-toggle span:after, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-toggle span:after,
.upgrade .bg-image .rd-navbar-sidebar-toggle span, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-sidebar-toggle span,
.upgrade .bg-image .rd-navbar-sidebar-toggle span:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-sidebar-toggle span:before,
.upgrade .bg-image .rd-navbar-sidebar-toggle span:after, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-sidebar-toggle span:after,
.upgrade .bg-secondary-2 .rd-navbar-toggle span,
.upgrade .bg-secondary-2 .rd-navbar-toggle span:before,
.upgrade .bg-secondary-2 .rd-navbar-toggle span:after,
.upgrade .bg-secondary-2 .rd-navbar-sidebar-toggle span,
.upgrade .bg-secondary-2 .rd-navbar-sidebar-toggle span:before,
.upgrade .bg-secondary-2 .rd-navbar-sidebar-toggle span:after,
.upgrade .bg-gradient .rd-navbar-toggle span, .upgrade .bg-gradient-1 .rd-navbar-toggle span, .upgrade .bg-gradient-2 .rd-navbar-toggle span, .upgrade .bg-gradient-3 .rd-navbar-toggle span,
.upgrade .bg-gradient .rd-navbar-toggle span:before, .upgrade .bg-gradient-1 .rd-navbar-toggle span:before, .upgrade .bg-gradient-2 .rd-navbar-toggle span:before, .upgrade .bg-gradient-3 .rd-navbar-toggle span:before,
.upgrade .bg-gradient .rd-navbar-toggle span:after, .upgrade .bg-gradient-1 .rd-navbar-toggle span:after, .upgrade .bg-gradient-2 .rd-navbar-toggle span:after, .upgrade .bg-gradient-3 .rd-navbar-toggle span:after,
.upgrade .bg-gradient .rd-navbar-sidebar-toggle span, .upgrade .bg-gradient-1 .rd-navbar-sidebar-toggle span, .upgrade .bg-gradient-2 .rd-navbar-sidebar-toggle span, .upgrade .bg-gradient-3 .rd-navbar-sidebar-toggle span,
.upgrade .bg-gradient .rd-navbar-sidebar-toggle span:before, .upgrade .bg-gradient-1 .rd-navbar-sidebar-toggle span:before, .upgrade .bg-gradient-2 .rd-navbar-sidebar-toggle span:before, .upgrade .bg-gradient-3 .rd-navbar-sidebar-toggle span:before,
.upgrade .bg-gradient .rd-navbar-sidebar-toggle span:after, .upgrade .bg-gradient-1 .rd-navbar-sidebar-toggle span:after, .upgrade .bg-gradient-2 .rd-navbar-sidebar-toggle span:after, .upgrade .bg-gradient-3 .rd-navbar-sidebar-toggle span:after {
  background: #fff;
}
.upgrade .bg-gray-darker .rd-navbar-fixed .rd-navbar-panel:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fixed .rd-navbar-panel:before,
.upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-panel:before,
.upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-panel:before,
.upgrade .bg-image .rd-navbar-fixed .rd-navbar-panel:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fixed .rd-navbar-panel:before,
.upgrade .bg-secondary-2 .rd-navbar-fixed .rd-navbar-panel:before,
.upgrade .bg-gradient .rd-navbar-fixed .rd-navbar-panel:before, .upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-panel:before, .upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-panel:before, .upgrade .bg-gradient-3 .rd-navbar-fixed .rd-navbar-panel:before {
  background: linear-gradient(-39deg, #470cb2 10%, #6d0eb1 40%);
}
.upgrade .bg-gray-darker .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gray-darker .rd-navbar-fixed .rd-navbar-shop a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fixed .rd-navbar-shop a,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-shop a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-shop a,
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-shop a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-shop a,
.upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-shop a,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-shop a,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-shop a,
.upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-shop a,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-shop a,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-shop a,
.upgrade .bg-image .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-image .rd-navbar-fixed .rd-navbar-shop a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fixed .rd-navbar-shop a,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-shop a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-shop a,
.upgrade .bg-image .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-image .rd-navbar-static .rd-navbar-shop a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-shop a,
.upgrade .bg-secondary-2 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-secondary-2 .rd-navbar-fixed .rd-navbar-shop a,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-shop a,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-shop a,
.upgrade .bg-gradient .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-3 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient .rd-navbar-fixed .rd-navbar-shop a, .upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-shop a, .upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-shop a, .upgrade .bg-gradient-3 .rd-navbar-fixed .rd-navbar-shop a,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-shop a, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-shop a, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-shop a, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-shop a,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-shop a, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-shop a, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-shop a, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-shop a {
  color: #fff;
}
.upgrade .bg-gray-darker .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gray-darker .rd-navbar-fixed .rd-navbar-shop a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fixed .rd-navbar-shop a:hover,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-shop a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-shop a:hover,
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-shop a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-shop a:hover,
.upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-shop a:hover,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-shop a:hover,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-shop a:hover,
.upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-shop a:hover,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-shop a:hover,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-shop a:hover,
.upgrade .bg-image .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-image .rd-navbar-fixed .rd-navbar-shop a:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fixed .rd-navbar-shop a:hover,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-shop a:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-shop a:hover,
.upgrade .bg-image .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-image .rd-navbar-static .rd-navbar-shop a:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-shop a:hover,
.upgrade .bg-secondary-2 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-secondary-2 .rd-navbar-fixed .rd-navbar-shop a:hover,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-shop a:hover,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-shop a:hover,
.upgrade .bg-gradient .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-3 .rd-navbar-fixed .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient .rd-navbar-fixed .rd-navbar-shop a:hover, .upgrade .bg-gradient-1 .rd-navbar-fixed .rd-navbar-shop a:hover, .upgrade .bg-gradient-2 .rd-navbar-fixed .rd-navbar-shop a:hover, .upgrade .bg-gradient-3 .rd-navbar-fixed .rd-navbar-shop a:hover,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-shop a:hover, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-shop a:hover, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-shop a:hover, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-shop a:hover,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-shop a:hover, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-shop a:hover, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-shop a:hover, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-shop a:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > a,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > a,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > a,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > a,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > a,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > a,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > a,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li > a,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li > a, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > a, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > a, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li > a,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li > a, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > a, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li > a {
  color: #fff;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li > a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > a:hover,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > a:hover,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > a:hover,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li > a:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > a:hover,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li > a:hover,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li > a:hover, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > a:hover, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > a:hover, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li > a:hover,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li > a:hover:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li > a:hover:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li > a:hover:before, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > a:hover:before, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > a:hover:before, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li > a:hover:before,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover:before {
  background-color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle {
  color: #fff;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover {
  color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.focus > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.focus > a, .upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.opened > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.opened > a,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.focus > a,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.opened > a,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.focus > a,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.opened > a,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.focus > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.focus > a,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.opened > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.opened > a,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.focus > a,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.opened > a,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.focus > a, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.focus > a, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.focus > a, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.focus > a,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.opened > a, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.opened > a, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.opened > a, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.opened > a,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a {
  color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.focus > a:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.focus > a:before, .upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.opened > a:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.focus > a:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.opened > a:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.focus > a:before, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.focus > a:before, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.focus > a:before, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.opened > a:before, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.opened > a:before, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.opened > a:before, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.opened > a:before,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a:before,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a:before {
  background-color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle {
  color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.active > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.active > a,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.active > a, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.active > a,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.active > a,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.active > a,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.active > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.active > a,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.active > a, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.active > a,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.active > a,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.active > a, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.active > a, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.active > a, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.active > a,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.active > a, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a {
  color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.active > a:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.active > a:before,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.active > a:before,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.active > a:before,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.active > a:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.active > a:before,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.active > a:before,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.active > a:before, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.active > a:before, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.active > a:before, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.active > a:before,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.active > a:before {
  background-color: #ffec17;
}
.upgrade .bg-gray-darker .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-gray-darker .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-additional-gray-darker .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-image .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-additional-image .bg-additional-item .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-secondary-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .bg-gradient .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-1 .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-2 .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle, .upgrade .bg-gradient-3 .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle {
  color: #ffec17;
}
.upgrade .rd-navbar-static .rd-navbar-nav,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav {
  text-align: center;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li {
  position: relative;
  display: inline-block;
  transition: 0.25s;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li.rd-navbar--has-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li.rd-navbar--has-megamenu {
  position: static;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > a {
  position: relative;
  display: inline-block;
  color: #333333;
  line-height: 1;
  transition: 0.25s;
  vertical-align: middle;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > a:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > a:hover {
  color: #ed1c94;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle {
  position: relative;
  font-family: "Material Design Icons";
  font-size: 0;
  cursor: pointer;
  vertical-align: middle;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:hover {
  color: #ed1c94;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before {
  content: "";
  position: absolute;
  font-size: 18px;
  top: 1px;
  left: 2px;
  line-height: 0;
}
.upgrade .ie-10 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .ie-11 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .ie-edge .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle, .upgrade .ie-10 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .ie-11 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle,
.upgrade .ie-edge .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle {
  font-size: 18px;
}
.upgrade .ie-10 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before,
.upgrade .ie-11 .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before,
.upgrade .ie-edge .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before, .upgrade .ie-10 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before,
.upgrade .ie-11 .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before,
.upgrade .ie-edge .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-submenu-toggle:before {
  line-height: 1.2;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li.focus > a, .upgrade .rd-navbar-static .rd-navbar-nav > li.opened > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li.focus > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li.opened > a {
  color: #ed1c94;
  background: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle, .upgrade .rd-navbar-static .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li.focus > .rd-navbar-submenu-toggle,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li.opened > .rd-navbar-submenu-toggle {
  color: #ed1c94;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li.active > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li.active > a {
  color: #ed1c94;
  background: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li.active > .rd-navbar-submenu-toggle {
  color: #ed1c94;
}
.upgrade .rd-navbar-static .rd-navbar-inner,
.upgrade .rd-navbar-fullwidth .rd-navbar-inner {
  position: relative;
  max-width: 1800px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1800px) {
  .upgrade .rd-navbar-static .rd-navbar-inner,
.upgrade .rd-navbar-fullwidth .rd-navbar-inner {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.upgrade .rd-navbar-static .rd-navbar-top-panel,
.upgrade .rd-navbar-fullwidth .rd-navbar-top-panel {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #29293a;
  color: #fff;
}
.upgrade .rd-navbar-static .rd-navbar-top-panel-inner,
.upgrade .rd-navbar-fullwidth .rd-navbar-top-panel-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1800px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1800px) {
  .upgrade .rd-navbar-static .rd-navbar-top-panel-inner,
.upgrade .rd-navbar-fullwidth .rd-navbar-top-panel-inner {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.upgrade .rd-navbar-static .rd-navbar-top-panel a,
.upgrade .rd-navbar-fullwidth .rd-navbar-top-panel a {
  color: #fff;
}
.upgrade .rd-navbar-static .rd-navbar-top-panel a:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-top-panel a:hover {
  color: #ffec17;
}
.upgrade .rd-navbar-static .rd-navbar-top-panel a.icon-gray-1,
.upgrade .rd-navbar-fullwidth .rd-navbar-top-panel a.icon-gray-1 {
  color: #c2c2c3;
}
.upgrade .rd-navbar-static .rd-navbar-top-panel a.icon-gray-1:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-top-panel a.icon-gray-1:hover {
  color: #fff;
}
.upgrade .rd-navbar-static .rd-navbar-aside-center,
.upgrade .rd-navbar-fullwidth .rd-navbar-aside-center {
  display: flex;
  align-items: center;
}
.upgrade .rd-navbar-static .rd-navbar-shop,
.upgrade .rd-navbar-fullwidth .rd-navbar-shop {
  font-size: 24px;
}
.upgrade .rd-navbar-static .rd-navbar-shop span,
.upgrade .rd-navbar-fullwidth .rd-navbar-shop span {
  font-size: 16px;
  padding-left: 10px;
}
.upgrade .rd-navbar-static .rd-navbar-shop .rd-navbar-shop-icon,
.upgrade .rd-navbar-fullwidth .rd-navbar-shop .rd-navbar-shop-icon {
  color: #333333;
}
.upgrade .rd-navbar-static .rd-navbar-shop .rd-navbar-shop-icon:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-shop .rd-navbar-shop-icon:hover {
  color: #ed1c94;
}
.upgrade .rd-navbar-static .rd-navbar-shop,
.upgrade .rd-navbar-static .rd-navbar-search,
.upgrade .rd-navbar-static .rd-navbar-search-1,
.upgrade .rd-navbar-fullwidth .rd-navbar-shop,
.upgrade .rd-navbar-fullwidth .rd-navbar-search,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 {
  position: relative;
  display: inline-flex;
  margin-left: 30px;
  vertical-align: middle;
}
@media (min-width: 1800px) {
  .upgrade .rd-navbar-static .rd-navbar-shop,
.upgrade .rd-navbar-static .rd-navbar-search,
.upgrade .rd-navbar-fullwidth .rd-navbar-shop,
.upgrade .rd-navbar-fullwidth .rd-navbar-search {
    margin-left: 50px;
  }
}
.upgrade .rd-navbar-static .rd-navbar-search.active .rd-search,
.upgrade .rd-navbar-static .rd-navbar-search-1.active .rd-search,
.upgrade .rd-navbar-fullwidth .rd-navbar-search.active .rd-search,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1.active .rd-search {
  visibility: visible;
  opacity: 1;
}
.upgrade .rd-navbar-static .rd-navbar-search .form-label,
.upgrade .rd-navbar-static .rd-navbar-search-1 .form-label,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .form-label,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .form-label {
  font-size: 18px;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle {
  display: inline-flex;
  color: #333333;
  font-size: 30px;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle:hover {
  color: #ed1c94;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span, .upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span:before, .upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span:after {
  transition: 0.3s all ease-in-out;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span:before, .upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span:before {
  content: "";
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
  font-family: "Material Design Icons";
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle span:after {
  content: "";
  transform: rotate(-90deg) scale(0.4);
  opacity: 0;
  visibility: hidden;
  font-family: "Material Design Icons";
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle.active span:before,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle.active span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle.active span:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle.active span:before {
  opacity: 0;
  visibility: hidden;
  transform: rotate(90deg) scale(0.4);
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle.active span:after,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-navbar-search-toggle.active span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-navbar-search-toggle.active span:after,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-navbar-search-toggle.active span:after {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}
.upgrade .rd-navbar-static .rd-navbar-search .form-wrap,
.upgrade .rd-navbar-static .rd-navbar-search-1 .form-wrap,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .form-wrap,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .form-wrap {
  margin-bottom: 0;
}
.upgrade .rd-navbar-static .rd-navbar-search .form-input,
.upgrade .rd-navbar-static .rd-navbar-search-1 .form-input,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .form-input,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .form-input {
  padding-right: 50px;
  box-shadow: 0 2px 5px rgba(41, 41, 58, 0.05);
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-search,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-search,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-search,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-search {
  position: absolute;
  top: calc(100% + 29px);
  right: 0;
  width: 380px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 3;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-search-form-submit,
.upgrade .rd-navbar-static .rd-navbar-search-1 .rd-search-form-submit,
.upgrade .rd-navbar-fullwidth .rd-navbar-search .rd-search-form-submit,
.upgrade .rd-navbar-fullwidth .rd-navbar-search-1 .rd-search-form-submit {
  position: absolute;
  top: 17px;
  right: 0;
  width: 50px;
  padding: 0;
  border: none;
  font-size: 26px;
  background-color: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown {
  position: absolute;
  left: 0;
  width: 310px;
  background: #fff;
  z-index: 5;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown.rd-navbar-open-left,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown.rd-navbar-open-left {
  right: 0;
  left: auto;
}
.upgrade .rd-navbar-static .rd-navbar-nav li.focus > .rd-navbar-dropdown,
.upgrade .rd-navbar-static .rd-navbar-nav li.opened > .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav li.focus > .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav li.opened > .rd-navbar-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.upgrade .rd-navbar-static .rd-navbar-nav li.focus > .rd-navbar-megamenu,
.upgrade .rd-navbar-static .rd-navbar-nav li.opened > .rd-navbar-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav li.focus > .rd-navbar-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav li.opened > .rd-navbar-megamenu {
  opacity: 1;
  visibility: visible;
  transform: translate3d(-50%, 0, 0);
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown,
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-megamenu {
  z-index: 15;
  visibility: hidden;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown .rd-navbar-dropdown {
  left: 100%;
  top: 0;
  margin-top: -8px;
  margin-left: 8px;
  z-index: 2;
  transform: translate(30px, 0);
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown > li.focus > .rd-navbar-dropdown,
.upgrade .rd-navbar-static .rd-navbar-nav > li > .rd-navbar-dropdown > li.opened > .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown > li.focus > .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li > .rd-navbar-dropdown > li.opened > .rd-navbar-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.upgrade .rd-navbar-static .rd-navbar-dropdown,
.upgrade .rd-navbar-static .rd-navbar-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu {
  position: absolute;
  top: 100%;
  display: block;
  padding: 8px 0;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  border-radius: 6px;
  box-shadow: 0 6px 8px rgba(110, 123, 157, 0.17);
}
.upgrade .rd-navbar-static .rd-navbar-dropdown li > a,
.upgrade .rd-navbar-static .rd-navbar-megamenu li > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown li > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu li > a {
  font-size: 16px;
  transition: 0.15s ease-in-out;
}
.upgrade .rd-navbar-static .rd-navbar-dropdown li > a:before,
.upgrade .rd-navbar-static .rd-navbar-megamenu li > a:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown li > a:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu li > a:before {
  content: "";
  font-family: "Material Design Icons";
  margin-left: -20px;
  transition: opacity 0.15s ease-in-out, 0.15s margin ease-in-out;
  opacity: 0;
  padding-right: 5px;
}
.upgrade .rd-navbar-static .rd-navbar-dropdown li.focus > a:before,
.upgrade .rd-navbar-static .rd-navbar-dropdown li.opened > a:before,
.upgrade .rd-navbar-static .rd-navbar-dropdown li > a:hover:before,
.upgrade .rd-navbar-static .rd-navbar-megamenu li.focus > a:before,
.upgrade .rd-navbar-static .rd-navbar-megamenu li.opened > a:before,
.upgrade .rd-navbar-static .rd-navbar-megamenu li > a:hover:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown li.focus > a:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown li.opened > a:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown li > a:hover:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu li.focus > a:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu li.opened > a:before,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu li > a:hover:before {
  margin-left: 0;
  opacity: 1;
}
.upgrade .rd-navbar-static .rd-navbar-dropdown,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown {
  width: 310px;
  background: #fff;
  padding: 5px 28px;
  transform: translateY(30px);
}
.upgrade .rd-navbar-static .rd-navbar-dropdown > li > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown > li > a {
  display: block;
  padding: 13px 20px 13px 0;
  color: #29293a;
  background: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-dropdown > li > a:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown > li > a:hover {
  color: #ed1c94;
  background: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-dropdown > li.focus > a, .upgrade .rd-navbar-static .rd-navbar-dropdown > li.opened > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown > li.focus > a,
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown > li.opened > a {
  color: #ed1c94;
  background: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu {
  left: 50%;
  display: flex;
  width: 98%;
  max-width: 1800px;
  padding: 10px 40px 30px;
  transform: translate3d(-50%, 30px, 0);
  background: #fff;
}
@media (min-width: 1800px) {
  .upgrade .rd-navbar-static .rd-navbar-megamenu,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu {
    padding: 10px 49px 30px;
  }
}
.upgrade .rd-navbar-static .rd-navbar-megamenu > li,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu > li {
  width: 100%;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu > li + li,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu > li + li {
  border-top: 0;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu > li > ul a,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu > li > ul a {
  display: block;
  padding: 13px 10px 13px 0;
  color: #000000;
  background: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu > li > ul a:hover,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu > li > ul a:hover {
  color: #ed1c94;
  background: transparent;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu-banner,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu-banner {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu-banner > li:first-of-type,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu-banner > li:first-of-type {
  min-width: 300px;
  position: relative;
  overflow: hidden;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu-banner > li:first-of-type > img,
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu-banner > li:first-of-type > img {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate3d(0, -60%, 0) scale(1.25);
  height: 100%;
  width: auto;
  max-width: none;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu-banner > li:nth-child(n+1),
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu-banner > li:nth-child(n+1) {
  padding-top: 10px;
  padding-bottom: 10px;
}
.upgrade .rd-navbar-static.rd-navbar--is-clone,
.upgrade .rd-navbar-fullwidth.rd-navbar--is-clone {
  display: block;
  transform: translateY(-100%);
}
.upgrade .rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck,
.upgrade .rd-navbar-fullwidth.rd-navbar--is-clone.rd-navbar--is-stuck {
  transform: translateY(0%);
}
.upgrade .rd-navbar-static.rd-navbar--is-stuck, .upgrade .rd-navbar-static.rd-navbar--is-clone,
.upgrade .rd-navbar-fullwidth.rd-navbar--is-stuck,
.upgrade .rd-navbar-fullwidth.rd-navbar--is-clone {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  background: #fff;
}
.upgrade .rd-navbar-static.rd-navbar--is-stuck .rd-navbar-top-panel, .upgrade .rd-navbar-static.rd-navbar--is-clone .rd-navbar-top-panel,
.upgrade .rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-top-panel,
.upgrade .rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-top-panel {
  display: none;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav-wrap,
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner {
  width: 270px;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  line-height: 34px;
  color: #333333;
  background: #fff;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.25);
  z-index: 998;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav-wrap:before, .upgrade .rd-navbar-fixed .rd-navbar-nav-wrap:after,
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner:before,
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner:after {
  content: "";
  display: block;
  height: 56px;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar,
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner::-webkit-scrollbar {
  width: 4px;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-thumb,
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner::-webkit-scrollbar-thumb {
  background: #c47df6;
  border: none;
  border-radius: 0;
  opacity: 0.2;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-track,
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner::-webkit-scrollbar-track {
  background: #981df0;
  border: none;
  border-radius: 0;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav .rd-navbar-dropdown > li > a,
.upgrade .rd-navbar-sidebar .rd-navbar-nav .rd-navbar-dropdown > li > a {
  padding-left: 20px;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav .rd-navbar-dropdown ul > li > a,
.upgrade .rd-navbar-sidebar .rd-navbar-nav .rd-navbar-dropdown ul > li > a {
  padding-left: 25px;
}
.upgrade .rd-navbar-static {
  display: block;
}
.upgrade .rd-navbar-static .rd-navbar-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upgrade .rd-navbar-static .rd-navbar-nav {
  text-align: center;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > a {
  font-size: 18px;
}
.upgrade .rd-navbar-static .rd-navbar-aside-right {
  display: flex;
  align-items: center;
}
.upgrade .rd-navbar-static .rd-navbar-aside-right-inner {
  white-space: nowrap;
}
.upgrade .rd-navbar-static .rd-navbar-top-panel {
  text-align: left;
  padding: 15px 0;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li {
  margin: 0 20px;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > a {
  padding: 30px 0;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
  height: 3px;
  content: "";
  display: inline-block;
  opacity: 0;
  transition: 250ms;
  background: #ed1c94;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li > a:hover:before {
  right: 0;
  opacity: 1;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li.focus > a:before, .upgrade .rd-navbar-static .rd-navbar-nav > li.opened > a:before {
  right: 0;
  opacity: 1;
}
.upgrade .rd-navbar-static .rd-navbar-nav > li.active > a:before {
  right: 0;
  opacity: 1;
}
.upgrade .rd-navbar-static .rd-navbar-dropdown,
.upgrade .rd-navbar-static .rd-navbar-megamenu {
  margin-top: 0;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu {
  max-width: 1200px;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu > li + li {
  margin-left: 85px;
}
.upgrade .rd-navbar-static .rd-navbar-megamenu-banner > li + li {
  margin-left: 45px;
}
.upgrade .rd-navbar-static .rd-navbar-shop .rd-navbar-shop-icon {
  font-size: 30px;
}
.upgrade .rd-navbar-static .rd-navbar-shop .rd-navbar-shop-icon span {
  position: relative;
  top: -3px;
  font-size: 18px;
  padding-left: 13px;
  vertical-align: middle;
}
.upgrade .rd-navbar-static .rd-navbar-search .rd-navbar-search-toggle span {
  font-size: 30px;
}
.upgrade .rd-navbar-static.rd-navbar--is-stuck, .upgrade .rd-navbar-static.rd-navbar--is-clone {
  will-change: transform;
}
.upgrade .rd-navbar-static.rd-navbar--is-stuck .rd-navbar-nav > li > a, .upgrade .rd-navbar-static.rd-navbar--is-clone .rd-navbar-nav > li > a {
  padding: 25px 0;
}
.upgrade .breadcrumbs-custom-wrap .rd-navbar-static .rd-navbar-nav > li > a:before {
  top: 0;
  bottom: auto;
}
@media (min-width: 1600px) {
  .upgrade .rd-navbar-static .rd-navbar-nav > li > a {
    padding: 40px 0;
  }
  .upgrade .rd-navbar-static .rd-navbar-dropdown,
.upgrade .rd-navbar-static .rd-navbar-megamenu {
    margin-top: 0;
  }
}
@media (min-width: 1800px) {
  .upgrade .rd-navbar-static .rd-navbar-nav > li {
    margin: 0 30px;
  }
}
.upgrade .rd-navbar-fullwidth {
  display: block;
}
.upgrade .rd-navbar-fullwidth .rd-navbar-inner {
  padding-top: 20px;
  padding-bottom: 20px;
}
.upgrade .rd-navbar-fullwidth .rd-navbar-aside-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.upgrade .rd-navbar-fullwidth .rd-navbar-panel {
  text-align: center;
}
.upgrade .rd-navbar-fullwidth .rd-navbar-nav > li {
  margin: 0 18px;
}
.upgrade .rd-navbar-fullwidth .rd-navbar-dropdown {
  margin-top: 20px;
}
.upgrade .rd-navbar-fullwidth .rd-navbar-megamenu > li + li {
  margin-left: 30px;
}
.upgrade .rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-inner, .upgrade .rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-inner {
  padding-top: 13px;
  padding-bottom: 13px;
}
.upgrade .rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-aside-right, .upgrade .rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-aside-right {
  margin-top: 0;
}
.upgrade .rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-dropdown, .upgrade .rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-dropdown {
  margin-top: 15px;
}
.upgrade .rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-panel, .upgrade .rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-panel {
  display: none;
}
.upgrade .rd-navbar-fixed {
  display: block;
}
.upgrade .rd-navbar-fixed .rd-navbar-toggle {
  display: inline-block;
}
.upgrade .rd-navbar-fixed .rd-navbar-brand {
  position: relative;
  top: 2px;
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 22px;
  line-height: 1;
}
.upgrade .rd-navbar-fixed .rd-navbar-brand img {
  max-width: 100px;
  height: auto;
}
.upgrade .rd-navbar-fixed .rd-navbar-panel {
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 4px;
  height: 56px;
  color: #29293a;
  z-index: 999;
}
.upgrade .rd-navbar-fixed .rd-navbar-panel:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.25);
  background: #fff;
}
.upgrade .rd-navbar-fixed .rd-navbar-panel > * {
  z-index: 1;
}
.upgrade .rd-navbar-fixed .rd-navbar-top-panel.rd-navbar-collapse .box-inline {
  display: table;
}
.upgrade .rd-navbar-fixed .rd-navbar-top-panel.rd-navbar-collapse .box-inline > * {
  display: table-cell;
  vertical-align: top;
}
.upgrade .rd-navbar-fixed .rd-navbar-aside-right-inner {
  position: fixed;
  top: 4px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.upgrade .rd-navbar-fixed .rd-navbar-search,
.upgrade .rd-navbar-fixed .rd-navbar-search-1 {
  display: inline-flex;
  z-index: 1000;
  position: relative;
}
.upgrade .rd-navbar-fixed .rd-navbar-search .rd-search,
.upgrade .rd-navbar-fixed .rd-navbar-search-1 .rd-search {
  position: absolute;
  right: 4px;
  top: calc(100% + 12px);
  width: 240px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: translateX(-10px);
}
.upgrade .rd-navbar-fixed .rd-navbar-search .rd-search-results-live,
.upgrade .rd-navbar-fixed .rd-navbar-search-1 .rd-search-results-live {
  display: none;
}
.upgrade .rd-navbar-fixed .rd-navbar-search .form-input,
.upgrade .rd-navbar-fixed .rd-navbar-search-1 .form-input {
  padding-right: 50px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);
}
.upgrade .rd-navbar-fixed .rd-navbar-search .rd-search-form-submit,
.upgrade .rd-navbar-fixed .rd-navbar-search-1 .rd-search-form-submit {
  position: absolute;
  top: 17px;
  right: 0;
  width: 50px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #000000;
}
.upgrade .rd-navbar-fixed .rd-navbar-search .rd-search-form-submit:hover,
.upgrade .rd-navbar-fixed .rd-navbar-search-1 .rd-search-form-submit:hover {
  color: #981df0;
}
.upgrade .rd-navbar-fixed .rd-navbar-search.active .rd-search,
.upgrade .rd-navbar-fixed .rd-navbar-search-1.active .rd-search {
  opacity: 1;
  visibility: visible;
  transform: none;
}
.upgrade .rd-navbar-fixed .rd-navbar-shop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  z-index: 1000;
}
.upgrade .rd-navbar-fixed .rd-navbar-shop .rd-navbar-shop-icon {
  font-size: 22px;
}
.upgrade .rd-navbar-fixed .rd-navbar-shop .rd-navbar-shop-icon span {
  font-size: 16px;
  padding-left: 5px;
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle {
  display: inline-flex;
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle span {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle span, .upgrade .rd-navbar-fixed .rd-navbar-search-toggle span:before, .upgrade .rd-navbar-fixed .rd-navbar-search-toggle span:after {
  transition: 0.3s all ease-in-out;
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle span:before, .upgrade .rd-navbar-fixed .rd-navbar-search-toggle span:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle span:before {
  content: "";
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
  font-family: "Material Design Icons";
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle span:after {
  content: "";
  transform: rotate(-90deg) scale(0.4);
  opacity: 0;
  visibility: hidden;
  font-family: "Material Design Icons";
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle.active span:before {
  opacity: 0;
  visibility: hidden;
  transform: rotate(90deg) scale(0.4);
}
.upgrade .rd-navbar-fixed .rd-navbar-search-toggle.active span:after {
  transform: rotate(0deg) scale(1);
  opacity: 1;
  visibility: visible;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  color: #333333;
  background: #fff;
  transform: translateX(-120%);
}
.upgrade .rd-navbar-fixed .rd-navbar-nav-wrap.active {
  transform: translateX(0);
}
.upgrade .rd-navbar-fixed .rd-navbar-nav {
  display: block;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav:before, .upgrade .rd-navbar-fixed .rd-navbar-nav:after {
  content: "";
  display: block;
  height: 8px;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav li > a {
  display: block;
  padding: 11px 56px 11px 16px;
  color: #333333;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav li > a:first-letter {
  text-transform: uppercase;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav li:hover > a, .upgrade .rd-navbar-fixed .rd-navbar-nav li.active > a, .upgrade .rd-navbar-fixed .rd-navbar-nav li.opened > a {
  background: #981df0;
  color: #fff;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav li:hover > .rd-navbar-submenu-toggle, .upgrade .rd-navbar-fixed .rd-navbar-nav li.active > .rd-navbar-submenu-toggle, .upgrade .rd-navbar-fixed .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle {
  color: #fff;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle:after {
  transform: rotate(180deg);
}
.upgrade .rd-navbar-fixed .rd-navbar-nav .rd-navbar-submenu-toggle {
  cursor: pointer;
  color: #333333;
}
.upgrade .rd-navbar-fixed .rd-navbar-nav .rd-navbar-submenu-toggle::after {
  content: "";
  position: absolute;
  top: 22px;
  right: 0;
  margin-top: -22px;
  width: 65px;
  height: 48px;
  font: 400 28px "Material Design Icons";
  line-height: 48px;
  text-align: center;
  transition: 0.3s transform ease;
  z-index: 2;
}
.upgrade .rd-navbar-fixed .rd-navbar-dropdown,
.upgrade .rd-navbar-fixed .rd-navbar-megamenu {
  display: none;
  margin-top: 4px;
}
.upgrade .rd-navbar-fixed .rd-navbar-megamenu-banner > li:first-of-type {
  display: none;
}
.upgrade .rd-navbar-fixed .rd-navbar-submenu {
  position: relative;
}
.upgrade .rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown > li > a,
.upgrade .rd-navbar-fixed .rd-navbar-submenu .rd-megamenu-list > li > a {
  padding: 7px 56px 7px 30px;
}
.upgrade .rd-navbar-fixed .rd-navbar-submenu .rd-megamenu-header {
  padding-left: 20px;
}
.upgrade .rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li > a,
.upgrade .rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li > a {
  padding-left: 48px;
}
.upgrade .rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.upgrade .rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-megamenu {
  display: block;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse {
  position: fixed;
  right: 4px;
  top: 64px;
  transform: translateX(-10px);
  padding: 15px 10px;
  width: 280px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(110, 123, 157, 0.17);
  text-align: left;
  font-size: 14px;
  opacity: 0;
  pointer-events: none;
  z-index: 997;
  transition: opacity 0.3s, transform 0.3s;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse.active {
  transform: none;
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse-toggle {
  position: fixed;
  right: 4px;
  top: 4px;
  display: inline-block;
  z-index: 1000;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse,
.upgrade .rd-navbar-fixed .rd-navbar-collapse a.icon,
.upgrade .rd-navbar-fixed .rd-navbar-collapse .list-inline a,
.upgrade .rd-navbar-fixed .rd-navbar-collapse a.rd-navbar-shop-icon {
  color: #29293a;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse:hover,
.upgrade .rd-navbar-fixed .rd-navbar-collapse a.icon:hover,
.upgrade .rd-navbar-fixed .rd-navbar-collapse .list-inline a:hover,
.upgrade .rd-navbar-fixed .rd-navbar-collapse a.rd-navbar-shop-icon:hover {
  color: #ed1c94;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse .rd-navbar-search,
.upgrade .rd-navbar-fixed .rd-navbar-collapse .rd-navbar-shop {
  position: relative;
  top: auto;
  right: auto;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse .rd-navbar-search-toggle {
  display: none;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse .rd-navbar-search .rd-search {
  position: relative;
  width: 260px;
  opacity: 1;
  visibility: visible;
  right: auto;
  transform: translateX(0);
  margin-bottom: 10px;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse .rd-navbar-shop {
  display: inline-flex;
  margin-right: 10px;
  vertical-align: middle;
}
.upgrade .rd-navbar-fixed .rd-navbar-collapse .rd-navbar-top-panel-inner + .rd-navbar-top-panel-inner {
  margin-top: 10px;
}
.upgrade .rd-navbar-fixed.active .rd-navbar-nav {
  transform: translateX(0);
}
.upgrade .rd-navbar-fixed.rd-navbar--is-clone {
  display: none;
}
.upgrade .rd-navbar-fixed .rd-navbar-fixed--visible {
  display: block;
}
.upgrade .rd-navbar-fixed .rd-navbar-fixed--hidden {
  display: none;
}
.upgrade html.rd-navbar-fixed-linked .page {
  padding-top: 55px;
}
.upgrade .rd-navbar-sidebar {
  display: block;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-toggle {
  position: fixed;
  top: 8px;
  left: 8px;
  display: block;
  z-index: 999;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  transform: translateX(-100%);
  box-shadow: none;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner.active {
  transform: translateX(0);
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.25);
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-top-panel {
  order: 1;
  padding: 0 20px;
  text-align: left;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-brand {
  margin-bottom: 30px;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-search {
  display: inline-flex;
  margin-top: 10px;
  padding: 0 10px;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-search .rd-search {
  position: relative;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-search .form-input {
  padding-right: 50px;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-search .rd-search-form-submit {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #000000;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-search .rd-search-form-submit:hover {
  color: #981df0;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-search .rd-search-results-live {
  display: none;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav {
  display: block;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav:before, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav:after {
  content: "";
  display: block;
  height: 8px;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li > a {
  display: block;
  padding: 11px 56px 11px 16px;
  color: #333333;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li > a:first-letter {
  text-transform: uppercase;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li:hover > a, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li.active > a, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li.opened > a {
  background: #981df0;
  color: #fff;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li:hover > .rd-navbar-submenu-toggle, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li.active > .rd-navbar-submenu-toggle, .upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle {
  color: #fff;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle:after {
  transform: rotate(180deg);
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav li + li {
  margin-top: 4px;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav .rd-navbar-submenu-toggle {
  cursor: pointer;
  color: #333333;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-nav .rd-navbar-submenu-toggle::after {
  content: "";
  position: absolute;
  top: 22px;
  right: 0;
  margin-top: -22px;
  width: 65px;
  height: 48px;
  font: 400 28px "Material Design Icons";
  line-height: 48px;
  text-align: center;
  transition: 0.3s transform ease;
  z-index: 2;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-dropdown,
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-megamenu {
  display: none;
  margin-top: 4px;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-submenu {
  position: relative;
}
.upgrade .rd-navbar-sidebar .rd-navbar-sidebar-inner .rd-navbar-submenu .rd-navbar-dropdown > li > a {
  padding-left: 30px;
}
