.exit-row-view {
  display: inline-block;
  margin: 15px 0px;
}

.exit-row-image {
  width: 100%;
  max-width: 100px;
  margin-right: 15px;
}

.exit-row-desc {
  display: inline-block;
  width: 100%;
}
