#error-page {
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: #2D2D2D;
}

#error-jumbotron {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 0;
  background-color: #FFFFFF;
}

#error-heading {
  font-size: 70px;
  color: #666;
  margin: 80px 0 50px 0;
}

#error-text {
  color: #666;
  margin: 50px 0 50px 0;
}

#error-footer {
  text-align: center;
}

#error-page-footer-form {
  display: inline-block;
  margin: 20px 0 20px 0;
  width: 600px;
  border-radius: 2px;
  border-color: #152932;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
  color: #666;
  height: 30px;
}

#error-footer>a {
  display: inline-block;
  margin: 20px 10px 20px 10px;
  color: #666;
}

#error-footer>a:hover {
  text-decoration: none;
}
#error-footer>a:focus {
  text-decoration: none;
}
