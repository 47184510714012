.source-urls-form {
  display: block;
  width: 100%;
  padding: 2px;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
}

.source-urls-form>input {
  display: block;
  border: none;
  outline: none;
  box-shadow: none;
}

.source-urls-form>input:focus {
  box-shadow: none;
}

.entered-source-url {
  display: inline-block;
  width: 100%;
  background-color: #aaa;
  color: #fff;
  padding: 2px;
  margin: 2px 0px;
  border: none;
  text-align: left;
}

.entered-source-urls-list {
  display: inline-block;
  width: 100%;
  padding: 2px;
}
