/* Data Grid panel */
#data-grid-heading {
  font-size: 20px;
  padding-left: 14px;
}

@media (min-width: 768px) {
  #data-grid-column-1 {
    border-right: 1px solid #F2F2F2;
  }
  #data-grid-column-2 {
    border-right: 1px solid #F2F2F2;
  }
}

.data-grid-row-1 {
  border-bottom: 1px solid #F2F2F2;
}
.data-grid-row-2 {
  border-bottom: 1px solid #F2F2F2;
}

@media (max-width: 767px) {
  .data-grid-row-3 {
    border-bottom: 1px solid #F2F2F2;
  }
}

.data-grid-column {
}

@media (min-width: 768px) {
  .data-grid-row {
    height: 100px;
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .data-grid-row {
    height: 80px;
    padding: 10px;
  }
}
.data-grid-cell-heading {
  display: inline-block;
  width: 100%;
  color: #C6C6C6;
}
.data-grid-cell-value {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

