@charset "UTF-8";
html body.theme_surprise {
  /*SCSSにするからそれで書く*/
}
html body.theme_surprise #sidebar {
  background-color: indigo;
}
html body.theme_surprise #content-panel > div > div.tags-filter > span, html body.theme_surprise .btn-primary {
  color: white !important;
  background-color: red !important;
  border-color: pink !important;
}
html body.theme_surprise #top-navbar .container {
  background-color: palegoldenrod;
}
html body.theme_surprise #my-account-dropdown {
  background-color: palegoldenrod;
}
html body.theme_surprise #footer {
  background-color: greenyellow;
}
html body.theme_surprise a {
  color: red;
}
html body.theme_surprise .sidebar-link-button {
  color: white;
}
html body.theme_surprise #top-navbar > nav > div > div.navbar-form > div[role=combobox] > input[type=text] {
  background-color: deeppink !important;
  border-color: pink !important;
}
@media screen and (max-width: 768px) {
  div.panel-qa {
    width:100% !important;
    margin-left: 0 !important;
  }
}
.list-review-panel-like-empty.qbpane,.list-review-panel-like.qbpane {
  background-size: 1.1em;
}
.material-icons.qbpane {
  font-size: 1.4em;
}