.datavase-resource-preview {

}

.datavase-resource-preview img {
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.datavase-resource-preview-link {
  height: 50px;
  display: inline-block;
}
.datavase-resource-preview-link span {
  font-size: 18px;
  font-weight: bold;
  color: #666;
}
