#details-page-contents {
  margin: auto;
  max-width: 1310px;
}

.side-padded-panel>.panel-body {
  padding: 10px 0px;
}

.modal-body{
  overflow: hidden;
}

.form-control{
  margin-bottom: 10px;
}

.cancel-button {
  float: left;
}

.submit-button {
  float: right;
}

#details-main-tabs-pane-review .panel-body {
  line-height: 1.25em;
}
