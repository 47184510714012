@charset "UTF-8";
body {
    background: gray;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "游ゴシック体", YuGothic, "Yu Gothic Medium", sans-serif;
}
.review-panel {
    background: white;
}
.review-panel .rating-stars-fill > i {
    color: #FFDA00;
}
.review-panel .rating-stars-fill > .partial {
    color: transparent;
}
.review-panel .rate {
    color: #FFDA00;
    font-size: 9px !important;
    margin-top: 3rem !important;
    margin-bottom: 2rem !important;
}
.review-panel .rate .material-icons {
    font-size: 21px !important;
}
.content .rating-label {
    font-size: 14px !important;
    margin-top: 3rem !important;
    margin-bottom: 2rem !important;
}
.review-panel-parent .panel-body {
    width: 100%;
    padding: 1rem 0;
}
.review-panel {
    padding: 0 0.5rem !important;
}
.review-panel:not(:last-child) {
    border-bottom: solid 10px #eee;
    margin-bottom: 1rem;
}
.review-panel:last-child {
    padding-bottom: 0.1px;
}
html body div .review-panel a,#content-panel div > p > div > div.name-bg > div > div.from-bg > span {
    color: darkgray;
}
#ugpm img {
    width: 100%;
}
.modal-very-big {
    width: 60%;
}
.modal-full {
    width: inherit;
}
.modal-full img#ImageMB {
    width: 65%;
    margin-left: calc(35% / 2)
}
.modal-full button {
    width: 100%;
    padding: 0.5em;
    margin: 0;
}
.name-bg {
    font-size: 14px;
    height: 8rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.name-bg .from-bg {
    margin-left: 1.4%;
    margin-top: -1.4% !important;
    margin-bottom: 3em !important;
    display: inline-block;
    width: 97%;
    height: 4rem;
    background-color: #4A2F4A;
    color: white;
    /*background: url(../assets/from-to.svg);
    background-position: left bottom !important;
    background-repeat: no-repeat !important;*/
}
.modal-very-big a:focus,.modal-very-big a:hover {
    text-decoration: none;
}
.name-bg .from-bg img {
    margin-top: 0.5em;
}
.name-bg .from-bg span {
    margin-top: -0.5% !important;
}
.name-bg .from-bg img {
    margin-left: 30px !important;
    margin-right: 1% !important;
}
.name-bg .from-bg {
    margin-bottom: 1.5rem;
}
.name-bg .from-bg span {
    font-size: 14px;
}

.name-bg .from-bg img {
    height: 3em;
    margin-left: 3.7rem;
    border: solid 1px #e0e0e0;
    border-radius: 15px;
}
.name-bg .to-bg {
    display: inline-block;
    font-size: 18px; /*Tabでは大きすぎ*/
    font-weight: bold;
}
.name-bg .to-bg span {
    margin-top: 0.3em;
}
.name-bg .to-bg span span {
    display: block;
    margin-top: -2.8em;
}
.name-bg span, .name-bg img {
    display: inline-block;
    height: 3rem;
    vertical-align: middle;
}
.name-bg span {
    margin-top: 1.2em;
}
.name-bg .to-bg span {
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 15em;
    padding-top: 0;
    margin-left: 0.5em;
}
.name-bg .to-bg img {
    height: 4.5rem;
    border-radius: 5px;
    background: white;
}
.name-bg img {
    margin-top: 0.4rem;
}

.content {
    font-size: 14px;
    padding: 0.1rem 2rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
}
.content div.bottom-detail {
    margin-top: 3rem;
}
.content div.bottom-detail span.like-count {
    float: left;
    font-size: 12px;
}
.content div.bottom-detail span.share-count {
    font-size: 12px;
    float: right;
}
.content div.bottom-detail br.clearfix {
    content: "";
    clear: both;
}
.content p, .content span {
    margin-right: 0.85rem;
}
.content .action-button {
    text-align: center;
    margin: 0.75rem 0 0.25rem 0;
}
.content .action-button:not(:last-child) {
    margin-bottom: 1.25rem;
}
.content .action-button > span {
    text-align: center;
    display: inline-block;
    font-size: 10.5px;
    width: 28%;
}
.content .action-button span div {
    width: 100%;
}
.list-review-panel-like {
    background-image: url(../assets/heart.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.list-review-panel-like-empty {
    background-image: url(../assets/heart-empty.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.click {
    cursor: pointer;
}
.list-review-panel-comment {
    background-image: url(../assets/comment.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.list-review-panel-share {
    background-image: url(../assets/share.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
div.name-bg > div > div.from-bg > span {
    margin-top: -0.5em;
    margin-left: 0.5em;
    padding-top:0.7em;
}
@media screen and (min-width: 1235px) {
    .review-panel-parent {
        width: 50%;
        margin: 20px auto;
    }
}
@media screen and (min-width: 1235px) {
    .investor-pane-navbar .navbar-header {
        width: 50% !important;
        margin-left: 25% !important;
    }
}
@media screen and (max-width:346px) {
    .content .action-button span {
        width: 29% !important;
        font-size: 0.63em !important;
    }
}
@media screen and (max-width:435px) {
    .content .action-button span {
        width: 30%;
        font-size: 0.8em;
    }
    .content .action-button span:first-child {
        margin-left: 0;
    }
}
@media screen and (max-width: 350px) {
    .comment-mobile-sm {
        font-size: 0.99em;
    }
}
@media screen and (max-width: 9999px) {
    .name-bg {
        font-size: 1rem;
        height: 8.5rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
    }
    .name-bg .to-bg span span {
        margin-top: -1.55em;
    }
    .name-bg .from-bg {
        display: block;
        margin-bottom: 0.8rem;
    }
    .name-bg .to-bg {
        margin-top: -1rem;
        padding: 0;
        margin-left: 1.2rem;
    }
    .name-bg .to-bg span {
        padding-top: 0;
        margin-top: 0.3rem;
    }
}
@media screen and (max-width: 525px) {
    .name-bg .to-bg span {
        width: 13em;
    }
    .content .action-button > span {
        font-size: 8.5px;
    }
}
@media (min-width: 1px) {
    .container {
        width: 100% !important;
    }
}
@media (max-width: 767px) {
    nav.investor-pane-navbar.navbar.navbar-default > div > div > ul {
        width: 100% !important;
        margin-left: 0 !important;
    }
    .review-panel-parent{
        overflow-x: hidden !important;
    }
}
@media (min-width: 768px) and (max-width: 825px) {
    nav.investor-pane-navbar.navbar.navbar-default {
        margin-top: 20px !important;
    }
    .review-panel-parent{
        overflow-x: hidden !important;
    }
}
@media (min-width: 826px) and (max-width: 926px) {
    nav.investor-pane-navbar.navbar.navbar-default {
        margin-top: 0 !important;
    }
}
/*特定のpxからはmargin-right入れないといけない*/