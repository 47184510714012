.screenname-saved-message {
  margin-left: 10px;
  transition: opacity 0.5s;
}

.screenname-saved-message-shown {
  opacity: 1;
}

.screenname-saved-message-hidden {
  opacity: 0;
}

.account-details-label {
  display: inline-block;
  width: 140px;
}

.account-details-email {
  font-weight: bold;
  margin-right: 20px;
}

.account-details-screenname {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  padding: 7px 0 8px 0;
}

.account-details-edit-button {
  border: none;
  background-color: transparent;
  padding: 0;
}
.account-details-edit-button i{
  vertical-align: middle;
  font-size: 18px;
}

.account-details-screenname-form {
  width: 200px;
  margin: 0;
  display: inline-block;
}

.account-details-save-button {
  margin-left: 6px;
}
