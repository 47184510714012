.add-button {
  border: none;
  width: 50px;
  height: 50px;
}
.add-button:hover {
  background-color: transparent;
}

.add-to-list-icon {
  color: #4D7BB2;
}
.remove-from-list-icon {
  color: #C15753;
}
