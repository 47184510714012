#react-autowhatever-1 {
  background: #FFF;
  z-index: 999 !important;
}

.navbar-toggle.collapsed.clicked {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.navbar-toggle.collapsed {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.container {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.container::before {
  display: none !important;
}

.container::after {
  display: none !important;
}

.navbar-header {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
}