html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
  color: #494949;
  background-color: #0D1F29;
  overflow-y: scroll;
}
ul {
  padding: 0;
}
li {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}

.panel {
  border-radius: 0px;
  padding: 15px;
}

.empty-panel {
  border: none;
  box-shadow: none;
  margin: 0;
}
.empty-panel>.panel-collapse>.panel-body {
  padding: 0;
}
.datavase {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
}

/********************************************/
/* Bootstrap Col in Mobile                  */
/********************************************/
@media (max-width: 768px) {
 .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 8px;
    padding-left: 8px;
  }
}

/********************************************/
/* Eliminate Bootstrap Taste                */
/********************************************/
.modal-content {
  border-radius: 0px;
}
.btn {
  border-radius: 0px;
}
.btn-xs {
  border-radius: 0px;
}
.form-control {
  border-radius: 0px;
}

/********************************************/
/* Tag Button                               */
/********************************************/
.tag-button {
  display: inline-block;
  border-radius: 0;
  border: 0;
  font-size: 12px;
  padding: 5px 10px;
  margin: 3px;
  background-color: #143548;
  color: #fff;
}
/********************************************/
/* Add to List Button                       */
/********************************************/
.add-to-list-button {
  display: inline-flex;
  justify-content: center;
  background-color: #ED6D44;
  border: 1px solid #ED6D44;
  border-radius: 0px;
  color: #FFF;
}
.add-to-list-button:hover {
  background-color: #FFF;
  color: #ED6D44;
  border-color: #ED6D44;
}
.add-to-list-button:focus {
  box-shadow: none;
  outline: none;
}
.add-to-list-button-icon {
  font-size: 19px;
}
/********************************************/
/* Constribute Button                       */
/********************************************/
.contribute-button {
  display: inline-flex;
  justify-content: center;
  background-color: #FFF;
  border: 1px solid #898989;
  border-radius: 0px;
  color: #898989;
}
.contribute-button:hover {
  background-color: #898989;
  color: #FFF;
  border-color: #898989;
}
.contribute-button:focus {
  box-shadow: none;
  outline: none;
}

/********************************************/
/* Tabbed Panels                            */
/********************************************/
.tab-pane>.panel-default {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}
.jumbo-tabs>ul {
  border-bottom: none;
  margin-bottom: 20px;
}
.jumbo-tabs>ul>li {
  background-color: #EFEFEF;
}
.jumbo-tabs>ul>li.active>a {
  background-color: #EFEFEF;
}
.jumbo-tabs>ul>li>a {
  border-bottom: none;
}
.jumbo-tabs>ul>li>a:hover {
  background-color: #EFEFEF;
}
.jumbo-tabs>ul>li.active>a:hover {
  background-color: #EFEFEF;
}
.jumbo-tabs>ul>li.active>a:focus {
  background-color: #EFEFEF;
}
.nav-tabs {
  border-bottom: 2px solid #F2F2F2;
}
.nav-tabs>li {
  margin-bottom: -2px;
}
.nav-tabs>li>a {
  font-size: 20px;
  border: none;
  border-bottom: 2px solid #F2F2F2;
  padding: 0px 0px;
  margin: 0px 20px;
  color: #494949;
}
.nav-tabs>li.active>a {
  border: none;
  border-bottom: 2px solid #4C9689;
}
.nav-tabs>li>a:hover {
  border: none;
  border-bottom: 2px solid #F2F2F2;
  background-color: #FFF;
}
.nav-tabs>li>a:focus {
  border: none;
  outline: none;
  border-bottom: 2px solid #4C9689;
  background-color: #FFF;
}
.nav-tabs>li>a:visited {
  border: none;
  outline: none;
  border-bottom: 2px solid #4C9689;
  background-color: #FFF;
}
.nav-tabs>li.active>a:hover {
  border: none;
  outline: none;
  border-bottom: 2px solid #4C9689;
}
.nav-tabs>li.active>a:focus {
  border: none;
  outline: none;
  border-bottom: 2px solid #4C9689;
}
#sidebar > ul > li:nth-child(3) > a {
  display: inline-flex !important;
}

@media (max-width: 475px) {
  .mob-100pct {
    width: 100% !important;
  }
}