#terms-and-privacy-page {
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  text-align: center;
}

#terms-and-privacy-contents {
  background-color: #efefef;
  width: 100%;
}

#terms-and-privacy-contents-container {
  display: inline-block;
  max-width: 1100px;
  padding: 10px;
  text-align: left;
  padding-top: 40px;
  padding-bottom: 80px;
}

#terms-and-privacy-contents-container>h1 {
  padding-bottom: 30px;
  padding-top: 30px;
}

#terms-and-privacy-contents-container>p {
  padding-bottom: 10px;
  line-height: 1.8;
}
