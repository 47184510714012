.filter-configuration-value-form {
  border-radius: 3px;
  border: none;
  background-color: #00a8ff;
  color: #fff;
}
.filter-configuration-value-form::-webkit-input-placeholder { color: #9ccde6; }  /* WebKit, Blink, Edge */
.filter-configuration-value-form:-moz-placeholder { color: #9ccde6; }  /* Mozilla Firefox 4 to 18 */
.filter-configuration-value-form::-moz-placeholder { color: #9ccde6; }  /* Mozilla Firefox 19+ */
.filter-configuration-value-form:-ms-input-placeholder { color: #9ccde6; }  /* Internet Explorer 10-11 */
.filter-configuration-value-form::-ms-input-placeholder { color: #9ccde6; }  /* Microsoft Edge */

.filter-configuration-comparator-form {
  border-radius: 3px;
  border: none;
  background-color: #00a8ff;
  color: #fff;
}
