.integration-logo {
  border-radius: 0px;
  border: 1px solid #ddd;
  width: 100%;
}

.integration-service-name {
  display: inline-block;
  width: 100%;
  font-weight: bold;
}

.integration-service-row {
  display: inline-block;
  width: 100%;
  margin: 10px 0px 0px 0px;
}
