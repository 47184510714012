* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;

  * {
    box-sizing: inherit;

    &:before,
    &:after {
      box-sizing: border-box;
    }
  }
}

html,
body {
  position: relative;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1;
  scroll-behavior: smooth;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;

  @media only screen and(-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

article,
aside,
figure,
footer,
header,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  padding: 0;
  text-align: left;
}

a {
  color: inherit;
  text-decoration: none !important;
  cursor: pointer !important;
}

div.panel, div.panel-default,div.datavase-article-container {
  border-radius: 8px;
}

select::-ms-expand {
  display: none;
}

iframe {
  border: none;
}
