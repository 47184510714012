html body.theme1 {
  background-color: #4A2F4A;
}

html body.theme1 #sidebar {
  background-color: #4A2F4A;
}

html body.theme1 #content-panel>div>div.tags-filter>span,
html body.theme1 .btn-primary {
  color: #FFF !important;
  background-color: #E0319C !important;
  border-color: #E0319C !important;
}

html body.theme1 #my-account-dropdown {
  background-color: #4A2F4A;
}

html body.theme1 #sidebar>ul>li.current-selected {
  background-color: #E0319C;
}

html body.theme1 #sidebar>ul>li:hover {
  background-color: #480035;
}

html body.theme1 #footer {
  background-color: #4A2F4A;
}

html body.theme1 a {
  color: #767676;
}

html body.theme1 .sidebar-link-button {
  color: white;
}

html body.theme1 #lists-search-form,
html body.theme1 #top-navbar>nav>div>div.navbar-form>div[role=combobox]>input[type=text] {
  background-color: #412941 !important;
  border-color: #412941 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

html body.theme1 .nav-tabs>li.active>a,
html body.theme1 .nav-tabs>li>a:visited,
html body.theme1 .nav-tabs>li>a:hover,
html body.theme1 .nav-tabs>li.active>a:focus {
  border-bottom: 2px solid #E0319C;
}


.theme1 #top-navbar {
  background-color: #4A2F4A;
}

.theme1 #searchform {
  background-color: #412941;
}

.theme1 #panelBtn {
  color: #E0319C;
  border: 2px solid #E0319C;
}

.theme1 #panelBtn:hover {
  color: #fff;
  background: #E0319C;
}

.theme1 #panelTop {
  background: #4A2F4A;
}

.theme1 #rank {
  background: #E0319C;
}