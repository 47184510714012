.autosuggest-form>div>div {
  background-color: #fff;
  margin-top: -5px;
  color: #666;
  text-align: left;
  z-index: 5;
  position: absolute;
  width: 90%;
  box-shadow: 0px 0px 10px 0px rgba(102,102,102,1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(102,102,102,1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(102,102,102,1);
}

