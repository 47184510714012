.or-line {
	color: #666;
	font-size: 14px;
	display: flex;
	align-items: center;
}
.or-line:before, .or-line:after {
	content: "";
	flex-grow: 1;
	height: 1px;
	background: #ddd;
	display: block;
}
.or-line:before {
	margin-right: .9em;
}
.or-line:after {
	margin-left: .9em;
}
